import React, { useEffect, useState, useRef } from 'react';
import './header.css'
import Logo from '../../assets/images/logos/repustar_logo.png';
import Utils from '../../utils/utils.js';
import notificationIcon from '../../assets/images/logos/notifications.png';
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import jwt_decode from "jwt-decode";

export default function Nav() {
    const history = useHistory();
    const loc = useLocation();
    useEffect(() => {
        //if(!loc.pathname.includes('/review/')){
        if (!Utils.checkLogin()) { handleLogin() }
        if (!Utils.checkAccess()) { history.push('/suspension') };
        //}

    }, []);


    const handleLogout = () => {
        let contentPartner= Utils.checkContentPartnerWriter();
        localStorage.clear();
        window.location.href = contentPartner ? Utils.getMinioragneRedirectURI('logout') + "/partnerconsole/login" : Utils.getMinioragneRedirectURI('logout');
    };

    const handleLogin = () => {
        let miniorangeJWT = Utils.getParameterByName('id_token');
        if (miniorangeJWT != null) {
            afterLogin(miniorangeJWT);
        } else {
            window.location.href = Utils.getMinioragneRedirectURI('login');
        }
    }

    const afterLogin = async (token) => {
        let res = jwt_decode(token);
        const apiPath = Utils.getAPIDescription('get_user_details') + '?userId=' + res.sub + '&source=login';
        let config = {
            headers: {
                email: res.email
            }
        };
        localStorage.setItem('repustar_token', token);
        try {
            let data = await axios.get(apiPath, config);
            if (data && data.data && data.data.body) {
                localStorage.setItem('user_id', data.data.body.id);
                localStorage.setItem('user_types', data.data.body.userType);
                localStorage.setItem('email', data.data.body.email);
                localStorage.setItem('username', data.data.body.name);
                localStorage.setItem('status', data.data.body.status);
                localStorage.setItem('org_id', data.data.body.org_id);
                if(data.data.body.newsroom_user_name) localStorage.setItem('newsroom_user_name', data.data.body.newsroom_user_name);
                if(data.data.body.newsroom_user_application_password) localStorage.setItem('newsroom_user_application_password', data.data.body.newsroom_user_application_password);
                if(data.data.body.newsroom_home_url) localStorage.setItem('newsroom_home_url', data.data.body.newsroom_home_url);

                if (data.data.body.state && data.data.body.state === 0) {
                    localStorage.setItem('access', 'blocked');
                } else {
                    localStorage.setItem('access', 'granted');
                }

                //history.push('/');
            }

        } catch (e) {
            console.log(`Error+${e}`);
        }
    }

    return (
        <div className="app-header">
            <div className="colw-100">
                <div className="pull-left colw-qtr vertically-middle border-right">
                    <a href="/">
                        <img className="repustar-logo" src={Logo} />
                    </a>
                </div>
                <div className="pull-right user-section">
                    <div className="user-content vertically-middle pull-right semi-bold-font">
                        {/* <div className="pull-left not-box"><img src={notificationIcon}/><span>Notifications</span></div> */}
                        {localStorage.getItem('firstname') && localStorage.getItem('lastname') && <div className="pull-left mr-2">{localStorage.getItem('firstname').trim() + " " + localStorage.getItem('lastname').trim()}</div>}
                        <div className="pull-left cursor-pointer" onClick={() => { handleLogout() }}>Log Out</div>
                    </div>
                </div>
            </div>
        </div>
    );
}