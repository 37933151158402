import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import Twitter from '../../assets/images/twitter.png';
import FSBird from '../../assets/images/fs-half.svg';
import Star from '../../assets/images/star.svg';
import './flagTile.css'
import Utils from '../../utils/utils';
import { Popconfirm, notification } from 'antd';
import Loading from '../../assets/images/loader.gif';
import axios from 'axios';
import WhiteTick from '../../assets/images/white-tick.svg'
import Web from '../../assets/images/website.png';
import Facebook from '../../assets/images/fb_logo.svg';
import FeatureBtn from '../../assets/images/feature_btn.svg';
import FeatureBtnDis from '../../assets/images/feature_btn_disabled.svg';
import PinnedIndi from '../../assets/images/pin_blue.svg';
import GoldPin from '../../assets/images/gold-pin.svg';
import BookmarkGray from '../../assets/images/bookmark-gray.svg';
import BlackPin from '../../assets/images/black-pin.svg';
import BlueArrow from '../../assets/images/banner-arrow_op.svg';

export default function FlagTile({ flagData, statusType, listState, featuredOnly, onFlagClick, updatingId, allocateStatus, setActiveFlagId, setShowPromoteModal, viewed, userid, flagList, setFlagList, contentPartnerMode, isDummy }) {
	const [isModerator, setIsModerator] = useState(false);
	const [upvoteLoading, setUpvoteLoading] = useState(false);
	const [isTwitterFlag, setIsTwitterFlag] = useState(false);
	const [disableFeatureBtn, setDisableFeatureBtn] = useState(false);
	const [featureTxt, setFeatureTxt] = useState(null);
	const [editMode, setEditMode] = useState(false);
	const [featureLoading, setFeatureLoading] = useState(false);
	const [expandEditorComm, setExpandEditorComm] = useState(false);
	const [expandFeatureComm, setExpandFeatureComm] = useState(false);
	const [isConsoleFlag, setIsConsoleFlag] = useState(false);

	useEffect(() => {

		setIsModerator(Utils.getAdminPermission())
		// setIsModerator(false)
		// setIsModerator(true)
	}, [])

	useEffect(() => {
		setIsTwitterFlag(flagData.__typename === 'twitter_flag' || flagData.__typename === 'Twitter' || (flagData?.articleLink && flagData?.articleLink.includes('https://x.com')));
		setDisableFeatureBtn(flagData.featured);
		if (flagData.featured) {
			setFeatureTxt(flagData.featured_comment);
		}
		else {
			setFeatureTxt(flagData.what.replace('@AskRepustar ', ''));
		}
		setIsConsoleFlag(flagData.__typename === 'tipline_test_flag' || flagData.__typename === 'tipline_flag' )
	}, [flagData])

	const checkBottomPanelCondition = () => {
		return (flagData.allocatedStatus === 'Eligible' || flagData.allocatedStatus === 'Archived' || flagData.direct_reviews_count > 0 || isModerator) && !contentPartnerMode
	};

	const checkIfSuperContributor = () => {
		return  Utils.hasFlagControlPermission();
	};

	const upvoteFlag = () => {
		setUpvoteLoading(true)
		let apiPath = Utils.getAPIDescription("upvote-flag");
		let headers = {
			"Content-Type": "application/json",
			userid: userid,
		};
		axios
			.post(apiPath, { flag_id: flagData.id, comment: '' }, { headers: headers })
			.then((res) => {
				if (res.data.statusCode === 200) {
					handleAfterUpvote(1)
				}
				else {
					alert("Something went wrong!")
				}
				setUpvoteLoading(false)
			})
			.catch((err) => {
				setUpvoteLoading(false)
				console.log(err);
				alert("Something went wrong!")
			});
	};

	const removeUpvote = () => {
		setUpvoteLoading(true)
		let api = Utils.getAPIDescription('remove-upvote');
		let header = {
			"Content-Type": "application/json",
			userid: userid,
		}
		axios.post(api, { 'flag_id': flagData.id }, { headers: header }).then((res) => {
			// console.log(res)
			if (res.data.statusCode === 200) {
				handleAfterUpvote(-1)
			}
			else {
				alert("Something went wrong")
			}
			setUpvoteLoading(false)
		}).catch((err) => {
			console.log(err)
			setUpvoteLoading(false)
			alert("Something went wrong")
		})
	}
	const handleAfterUpvote = (action) => {
		let tmpList = flagList.slice()
		tmpList = tmpList.map(item => {
			if (item.id === flagData.id) {
				item.upvote_count = 'upvote_count' in item ? item.upvote_count + action : 1
				if (action === 1) {
					if ('upvoted_user_id_list' in item) {
						item.upvoted_user_id_list.push(userid)
					}
					else {
						item.upvoted_user_id_list = [userid]
					}
				}
				else {
					let index = item.upvoted_user_id_list.indexOf(userid)
					item.upvoted_user_id_list.splice(index, 1)
				}
				// console.log("voter list", item.upvoted_user_id_list)
			}
			return item
		})
		setFlagList(tmpList)
	}

	const featureFlagOpera = (action) => {
		setFeatureLoading(true);
		let apiPath = Utils.getAPIDescription("feature-flag");
		let headers = {
			"Content-Type": "application/json",
			userid: userid,
		};
		let bodyVal = { flag_id: flagData.id, featured_comment: featureTxt };
		if (action === 'remove') {
			bodyVal = { flag_id: flagData.id, featured_comment: featureTxt, remove_featured: true };
		}
		axios
			.post(apiPath, bodyVal, { headers: headers })
			.then((res) => {
				if (res.data.statusCode === 200) {
					setEditMode(false);
					if (action === 'remove') {
						handleAfterFeature('remove');
					}
					else {
						handleAfterFeature('add');
					}

				}
				else {
					alert("Something went wrong!")
				}
				setFeatureLoading(false);
			})
			.catch((err) => {
				setFeatureLoading(false);
				console.log(err);
				alert("Something went wrong!")
			});
	};

	const handleAfterFeature = (action) => {
		let tmpList = flagList.slice()
		if (featuredOnly) {
			tmpList = tmpList.filter(item => {
				return item.id !== flagData.id
			})
		}
		else {
			tmpList = tmpList.map(item => {
				if (item.id === flagData.id) {
					if (action === 'add') {
						item.featured = true;
						item.featured_comment = featureTxt;
					}
					if (action === 'remove') {
						item.featured = false;
						setDisableFeatureBtn(false);
						setFeatureTxt(null);
					}
				}
				return item
			})
		}

		if (action === 'remove') {
			notification.success({ "message": `Flag Un-Featured successfully.` });
		} else {
			notification.success({ "message": `Flag featured successfully.` });
		}
		setFlagList(tmpList);
	}


	const onInputTextChange = (e) => {
		setFeatureTxt(e.target.value)
	};


	return (
		<div className={((isDummy ? 'flag-tile-dummy' : 'flag-tile') + (viewed ? (isDummy ? ' flag-tile-dummy-viewed' : ' viewed-tile') : ''))}>

			{editMode ? <>
				<div className='featured-panel'>
					<div className='editor-comment-block'>
						<input className='feature-comment-input' placeholder='Type somthing here...' value={featureTxt} onChange={onInputTextChange} ></input>
					</div>

					<div className='control-panel'>
						{featureLoading && <div className='mr-2'><img src={Loading} width="18px" height="18px" /></div>}
						<div className="mx-2 px-1 edit-btnn cursor-pointer" onClick={() => { setEditMode(false); if (!flagData.featured) { setDisableFeatureBtn(false) } }}>Cancel</div>
						<div><button className="opaque-btn fn-12 fit-content px-3 mx-2" onClick={() => { featureFlagOpera('add'); }}>Feature</button></div>
					</div>
				</div>
			</> : <>
				{flagData.featured && !contentPartnerMode && <div className='featured-panel'>
					<div className='editor-comment-block'>
						<div className={expandFeatureComm ? 'feat-comment-expand cursor-pointer' : 'feat-comment cursor-pointer'} onClick={() => { setExpandFeatureComm(!expandFeatureComm); }} >{flagData.featured_comment}</div>
					</div>

					{isModerator && <div className='control-panel'>
						{featureLoading && <div className='mr-2'><img src={Loading} width="18px" height="18px" /></div>}
						<div className="mx-2 px-1 edit-btnn cursor-pointer" onClick={() => { setEditMode(true); }}>Edit</div>
						<div className="edit-btnn cursor-pointer" onClick={() => { featureFlagOpera('remove'); }}>Remove</div>
					</div>}
				</div>}
			</>}

			<div className={'flag-main-div' + (viewed ? ' viewed-main-div' : '') + (isDummy ? ' flag-main-div-dummy' : '') + (!checkBottomPanelCondition() ? ' pb-3' : '')}>
				<div className='top-div'>
					<a href={flagData?.flag_url_link ? `#${flagData.id}` : "javascript:void(0)"} className='un-link top-left' onClick={() => onFlagClick(flagData.id, flagData)}>
						<div className='top-left'>
							{flagData.tags.length > 0 && <div className='tags'>{flagData.tags.join(', ')}</div>}
							<div className='date-time'>{`${moment(flagData.createdAt).fromNow()} · ${moment(flagData.createdAt).format("Do MMMM YYYY, h:mm A")}`}</div>
						</div>
					</a>

					{flagData?.original_question && <div className='flag-created-marker'>Updated</div>}
					{/* <div className='top-right'>
						{upvoteLoading && <div className='mr-2'><img src={Loading} width="20px" height="20px" /></div>}
						{!contentPartnerMode && <div
							className={'upvote-div' + ('upvoted_user_id_list' in flagData && flagData.upvoted_user_id_list.includes(userid) ? " upvoted-width" : '')}
							onClick={() => {
								if ('upvoted_user_id_list' in flagData && flagData.upvoted_user_id_list.includes(userid)) {
									removeUpvote()
								}
								else {
									upvoteFlag()
								}
							}}>
							<div className='text'>{'upvoted_user_id_list' in flagData && flagData.upvoted_user_id_list.includes(userid)
								? <span><img src={WhiteTick} width="11" style={{ marginBottom: '2px' }} />&nbsp;Upvoted!</span>
								: "Upvote"}</div>
							<div className='count'>{flagData.upvote_count ?? 0}</div>
						</div>}
						{contentPartnerMode && <div className='flag-info-cp'>
							{(flagData.is_bookmarked || listState === 'bookmark') && <div className='cp-info-item'>
								<img src={BookmarkGray} width="10" />
							</div>}
							<div className='cp-info-item ml-2'>
								<img style={{ transform: "rotate(270deg)" }} width="12" className='mr-1' src={BlueArrow} />
								{flagData.upvote_count ?? 0}
							</div>
						</div>}
					</div> */}
				</div>
				<a href={flagData?.flag_url_link ? `#${flagData.id}` : "javascript:void(0)"} className='un-link' onClick={() => onFlagClick(flagData.id, flagData)}>
					<div className='mid-grid'>
						<div className='mid-right'>
							{flagData.flag_url_link === null ?
								<div className='no-claim-link-div'>No claim link provided</div>
								:

								<>
									<div className='user-name'>
										<img
											src={flagData.platform === "Instagram" ? flagData.previewImage :
												(isTwitterFlag || flagData.platform === "Twitter") ? Twitter :
													flagData.articleLink && (flagData.articleLink.includes('facebook.com') || flagData.platform === "Facebook") ? Facebook :
														Web}
											width="20" />
										&nbsp;&nbsp;
										{flagData.broadcaster_display_name && flagData.broadcaster_display_name !== "N.A" ?
											flagData.broadcaster_display_name :
											flagData.broadcaster_display_name === "N.A" && flagData.articleLink ?
												Utils.extractDomain(flagData.articleLink) :
												flagData.broadcasterName === 'Direct from source' ?
													flagData.contentSource :
													flagData.broadcasterName ??
													(flagData.broadcasterDetails ? flagData.broadcasterDetails[0].handle : "")}
									</div>
									<div className='comment'>
										{flagData.__typename === 'twitter_flag' || flagData.__typename === 'Twitter' || flagData.platform === 'Twitter'
											? flagData.broadcasterName ? flagData.content_title : <span className="gray-font"><i>This flag was a standalone question</i></span>
											: flagData.content_title
										}
									</div>
								</>
								}

						</div>
						<div className={'mid-left mid-left-bordered'}>
							<div className='user-name'>
								{!isConsoleFlag && <img src={isTwitterFlag ? FSBird : flagData.org_logo_url ?? null} width={isTwitterFlag ? "10" : "20"} className={!isTwitterFlag ? 'circle-logo' : ""} />}
								{isConsoleFlag && <img src={flagData.tipline_org_logo_url} width={20} className={'circle-logo'} height={20}/>}
								&nbsp;&nbsp;
								{( `${flagData.user_display_name ?? flagData.twitter_user ?? ""}`)}
								{flagData?.tipline_user_key && ` : ${flagData?.tipline_user_key}`}
								{/* {isConsoleFlag && `${flagData.tipline_username}`} */}
									
							</div>
							<div className='comment'>
								{(flagData.what) ? flagData.what : ""}
							</div>
						</div>
					</div>
				</a>
			</div>
			{(checkIfSuperContributor()) && <div className={'bottom-panel' + (viewed ? ' viewed-bottom' : '')}>
				<div className='editor-comment-block cursor-pointer'>
					<a href={`#${flagData.id}`} className='un-link' >
						{((flagData.allocatedStatus === 'Eligible') || flagData.allocatedStatus === 'Archived' && flagData.comments && flagData.comments !== '') && <div className={expandEditorComm ? 'editor-comment-expand' : 'editor-comment'} onClick={() => { setExpandEditorComm(!expandEditorComm); }}>
							<img src={Star} width="15" /> <span className='editor-txt'>Editor</span>{flagData.comments && flagData.comments !== '' ? flagData.comments : <span className='no-comments'>No comments from Editor</span>}
						</div>}
					</a>
				</div>
				<div className='control-panel'>
					{checkIfSuperContributor() && <div className='control-grid'>
						{updatingId === flagData.id && <img src={Loading} width="15px" height="15px" className="ml-2" />}

						{
							<div
								className='mx-2 px-1 cursor-pointer'
								disabled={disableFeatureBtn}
								onClick={() => {
									if (!disableFeatureBtn) {
										setDisableFeatureBtn(true);
										setEditMode(true);
									}
								}}
							>
								<img src={disableFeatureBtn ? FeatureBtnDis : FeatureBtn} width="28" />
							</div>
						}

						{flagData.allocatedStatus !== 'Eligible' && flagData.allocatedStatus !== 'Archived' &&
							<button
								className='opaque-btn fn-12 fit-content mx-2 px-3'
								onClick={() => {
									if (flagData.allocatedStatus !== 'Eligible') {
										setActiveFlagId(flagData.id)
										setShowPromoteModal(true)
									}
									else {
										allocateStatus(flagData.id, 'valid')
									}
								}}
							>
								Highlight
							</button>
						}
						{flagData.allocatedStatus === 'Eligible' &&
							<button
								className='opaque-btn fn-12 fit-content mx-2'
								onClick={() => {
									allocateStatus(flagData.id, 'valid')
								}}
							>
								Un-Highlight
							</button>
						}
						<Popconfirm
							title={`Are you sure you want to ${flagData.allocatedStatus === 'Archived' ? 'unarchive' : 'archive'} this flag?`}
							onConfirm={() => allocateStatus(flagData.id, flagData.allocatedStatus === 'Archived' ? 'Valid' : 'Archived')}
							okText="Yes"
							cancelText="No"
						>
							<button className="opaque-btn fn-12 fit-content px-3 mx-2" >
								{flagData.allocatedStatus === 'Archived'
									? <div className="status-btn">
										{/* <img src={Archived} width="13px" /> */}
										<div className="">Unarchive</div>
									</div>
									: "Archive"
								}
							</button>
						</Popconfirm>
					</div>}
					{flagData.direct_reviews_count > 0 && <div className='ans-tag'>Answered</div>}
					{flagData.pinned_content && <div className='pinned-ind'>
						<img src={flagData.pinned_content.is_featured ? GoldPin : PinnedIndi} width="auto" height={"15px"} />
					</div>}
				</div>
			</div>}
		</div>
	)
};
