/*
  GA event for different purposes. This has env check and internal user check as well.
*/
let env_check = process.env.REACT_APP_ENV === 'PROD';
let actions = {
    trackEvent: (category, action) => {
        window.gtag('event', action, {
            event_category: category,
        })

    },
    trackEventWithValue: (category, action, label, value) => {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            event_value: value
        })

    },
    trackEventWithLabel: (category, action, label) => {
        window.gtag('event', action, {
            event_category: category,
            event_label: label
        })

    },
    pageView: () => {
        window.gtag('event', 'page_view')
    }
};

export let GA = actions;