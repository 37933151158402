import { notification, Popconfirm } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';
import Loading from '../../assets/images/loader.gif';
import HideEye from '../../assets/images/hide_eye.svg';
import PreviewEye from '../../assets/images/preview_eye.svg';
import DoneBtn from '../../assets/images/done-btn-thk.svg';
import Utils from '../../utils/utils';
import './pincontrol.css';

export default function PinControl({ pinData, setPinData, setEditPin, flagId, afterPin, isPinned }) {
	const [url, setUrl] = useState('')
	const [comment, setComment] = useState('')
	const [contentTitle, setContentTitle] = useState('')
	const [articleImgUrl, setArticleImgUrl] = useState('')
	const [pinDataLoading, setPinDataLoading] = useState(false)
	const [submitLoading, setSubmitLoading] = useState(false)
	const [isFeaturedPin, setIsFeaturedPin] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const [showThanksMsg, setShowThanksMsg] = useState(false);

	useEffect(() => {
		if (pinData) {
			setContentTitle(pinData.content_title ?? pinData.title)
			if (pinData.url) setUrl(pinData.url)
			if (pinData.pin_comment) setComment(pinData.pin_comment)
			if (pinData.image) setArticleImgUrl(pinData.image)
			if (pinData.article_image) setArticleImgUrl(pinData.article_image)
			if (pinData.is_featured) setIsFeaturedPin(true)
		}
	}, [pinData])

	const validateURL = (url) => {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
	}

	const scrapData = (url) => {
		if (!validateURL(url)) {
			notification.error({ message: "Invalid URL", duration: 3 });
			return;
		}
		setPinDataLoading(true)
		let api = Utils.getAPIDescription('scrape-source') + '?scrape=true&source=contributor&get_rating='+ true;
		let body = {
			"url": url
		}
		axios.post(api, body).then((res) => {
			console.log(res.data)
			if ('statusCode' in res.data && res.data.statusCode === 200) {
				setShowThanksMsg(false);
				setPinData(res.data.data)
			}
			else {
				notification.error({ "message": "Something went wrong while fetching article data.", duration: 3 });
			}
			setPinDataLoading(false)
		}).catch(err => {
			console.log(err)
			notification.error({ "message": "Something went wrong while fetching article data.", duration: 3 });
			setPinDataLoading(false)
		})
	}

	const handleOnKeyDown = (e) => {
		if (e.key === 'Enter') {
			scrapData();
		}
	}

	const isInCoreIndex = (pinData) => {
		if('in_core_index' in pinData){
			return (pinData.in_core_index)
		}
		else{
			return false
		}
	}

	const isInvalidUrl = (pinData) => {
			return ((pinData.site_rating!== null && pinData.site_rating!== undefined && pinData.site_rating.icon_color !== 'green') || pinData.status !== "Approved")
	}

	const handlePaste = (e) => {
		let clipboardData, pastedData;
        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('Text');
        scrapData(pastedData);
	}

	const submitSource = (act) => {
		console.log(act);
		let apiPath = Utils.getAPIDescription("submit-source");
		let headers = {
				"Content-Type": "application/json",
				userid: `${localStorage.getItem('user_id')}`,
		};
		let bodyVal = { id: pinData.id, user_id: `${localStorage.getItem('user_id')}`, action: "verification_asked", verification_asked: act };

		axios.post(apiPath, bodyVal, { headers: headers })
				.then((res) => {
					if (res.data.statusCode === 200) {
							setShowThanksMsg(true);
						}
					else {
						alert("Something went wrong!")
					}
				})
				.catch((err) => {
						console.log(err);
						alert("Something went wrong!")
				});

	}

	const featurePin = () => {
				let apiPath = Utils.getAPIDescription("feature-flag");
				let headers = {
						"Content-Type": "application/json",
						userid: `${localStorage.getItem('user_id')}`,
				};
				let bodyVal = { flag_id: flagId, type: "pin_feature"};
				if(!isFeaturedPin){
					if(!pinData.is_featured){
						afterPin(comment, url, contentTitle, articleImgUrl, false);
					}
					bodyVal = { flag_id: flagId, type: "pin_feature", remove_featured: true};
				}
				axios.post(apiPath, bodyVal, { headers: headers })
						.then((res) => {
				if (res.data.statusCode === 200) {
							if(!isFeaturedPin){
								afterPin(comment, url, contentTitle, articleImgUrl, false);
							}
							else{
								afterPin(comment, url, contentTitle, articleImgUrl, true);
							}
						}
				else {
					alert("Something went wrong!")
				}
			})
						.catch((err) => {
								console.log(err);
								alert("Something went wrong!")
						});
		};

	const pinContent = () => {
		setSubmitLoading(true);
		let api = Utils.getAPIDescription('pin-content')
		let body = {
			"flag_id": flagId,
			"pinned_url": url,
			"content_title": contentTitle,
			"article_image":articleImgUrl,
			"website_name": pinData.website_name,
			"publisher_type": pinData.publisher_type,
			"comment": comment,
			"pinned_class": "Helpful Context",
			"org_id": localStorage.getItem('org_id'),
			"pinner": localStorage.getItem('email')
		}
		axios.post(api, body).then(res => {
			// console.log(res.data)
			setSubmitLoading(false);
			featurePin();
			// afterPin(comment, url, contentTitle);
		}).catch(err => {
			setSubmitLoading(false);
			console.log(err);
			notification.error({ message: "Something went wrong while pinning. Please try again.", duration: 3 });
		})
	}

	return (<div className='pin-control'>
		<div className='input-label'>URL:</div>
		<div className="d-flex justify-content-between align-items-center">
			<input
				className='input-box'
				value={url}
				onChange={(e) => setUrl(e.target.value)}
				onPaste={handlePaste}
				onKeyDown={handleOnKeyDown}
				// disabled={isPinned}
			/>
			{pinDataLoading && <div>
				<img src={Loading} width="20px" height="20px" />
			</div>}
		</div>
		{pinData && <div className='pin-details'>

			{!(isPinned || isInCoreIndex(pinData)) && showThanksMsg &&
				<div className="pin-det-non-appro mt-2">
					<div>< img src={DoneBtn} className="mt-3" /></div>
					<div className="mt-2">Submitted, thank you.</div>
					<div className="mt-2 thank-msg-text2">For now, please find a different URL to pin.</div>
				</div>}

			{!(isPinned || isInCoreIndex(pinData)) && !showThanksMsg && (pinData.site_rating === null || pinData.site_rating.icon_color !== 'green' ?
			<><div className="pin-det-non-appro">This URL doesn’t meet pinning criteria. Try another one. <br/>
			{pinData.site_rating !==null && pinData.site_rating !==undefined &&  `This URL doesn't have a High Factuality` }</div>
			</>
			: pinData.status !== "Approved" &&

			<div className="pin-det-non-appro">
				<div className="mt-2">This URL is not in the verified source directory. Do you want to submit it for research and addition</div>
				<div className="mt-2 mb-2">
				<button className='cancel-btn-pin-no'
					onClick={() => submitSource('No')}
				>
					No
				</button>
				<button className='done-btn-pin-yes'
					onClick={() => submitSource('Yes')}
				>
					Yes
				</button>
				</div>
			</div>)

			}

			<div className={isPinned || isInCoreIndex(pinData) || !isInvalidUrl(pinData)? 'det-item' :'det-item disab-cls'}>
				<div className='label'>Publisher</div>
				<div className='value'>
					<a href={pinData.domainURL} target='_blank' rel='noreferrer noopener'>{pinData.website_name}</a>
				</div>
			</div>
			<div className={isPinned || isInCoreIndex(pinData) || !isInvalidUrl(pinData)? 'det-item' :'det-item disab-cls'}>
				<div className='label'>Publisher Type</div>
				<div className='value'>
					{pinData.publisher_type ?? "News and reference media"}
				</div>
			</div>
			<div className={isPinned || isInCoreIndex(pinData) || !isInvalidUrl(pinData)? 'det-item' :'det-item disab-cls'}>
				<div className='label'>Article</div>
				<div className='value'>
					<a href={url} target='_blank' rel='noreferrer noopener'>{pinData.title}</a>
				</div>
			</div>
			<div className={isPinned || isInCoreIndex(pinData) || !isInvalidUrl(pinData)? 'det-item' :'det-item disab-cls'}>
				<div className='label'>Display Title</div>
				<div className='value'>
					<input className='title-input' value={contentTitle} onChange={(e) => setContentTitle(e.target.value)} />
				</div>
			</div>
		</div>}
		<div className={!(isPinned || (pinData && isInCoreIndex(pinData))) && pinData && pinData.status !== "Approved"?'mt-3 input-label disab-cls':'mt-3 input-label'}>Claim-responsive comment: <span className="fn-12">(Max limit: 40 words)</span></div>
		<input className={!(isPinned || (pinData && isInCoreIndex(pinData))) && pinData && pinData.status !== "Approved"?'input-box disab-cls':'input-box'} value={comment}  maxlength="350" onChange={(e) => setComment(e.target.value)} />

		<div className={!(isPinned || (pinData && isInCoreIndex(pinData))) && pinData && pinData.status !== "Approved"? "mt-3 pin-feature-div disab-cls" : "mt-3 pin-feature-div"}>
			{!Utils.checkContentPartnerWriter()
				? <div className='mr-4 mt-1 cursor-pointer'>
					<span onClick={() => setIsFeaturedPin(!isFeaturedPin)}> <Switch checked={isFeaturedPin} onChange={() => setIsFeaturedPin(!isFeaturedPin)} /> Featured pin</span>
				</div>
				: <div></div>
			}
			<div className='mr-4 mt-1 cursor-pointer'><span onClick={() => setShowPreview(!showPreview)}>{showPreview? <span><img src={HideEye} className="mr-1" width="25" height="25"/>Hide</span>: <span>< img src={PreviewEye} className="mr-1" width="25" height="25"/>Preview</span>} </span></div>
		</div>

		{pinData && showPreview && <div className="mt-3 pin-preview-div">
				<div><img src={articleImgUrl} className="arti-previmg" width="100%" height="auto" /></div>
				<div className="mt-2 mb-2 prev-titl">{comment}</div>
				<div className='pin-preview-text'>
					{pinData.website_name? pinData.website_name : pinData.url? pinData.url.split("/")[2] !== undefined? pinData.url.split("/")[2]: "Source": "Source"}
					<a className='blue-font' href={pinData.url} target='_blank' rel='noreferrer noopener'>
							&nbsp;<u>{contentTitle}</u>
					</a>
				</div>
		</div>}

		<div className='button-grid'>
			<button className='cancel-btn-pin'
				onClick={() => setEditPin(false)}
			>
				Cancel
			</button>
			<Popconfirm
				placement='bottom'
				title={<span>Are you ready to submit your pin? <br/>This will send the content to users <br/>and make the pin visible on this flag.</span>}
				onConfirm={pinContent}
				okText="Yes, Pin!"
				cancelText="Cancel"
				disabled={!(isPinned || (pinData && isInCoreIndex(pinData))) && (url === '' || comment === '' || (pinData && isInvalidUrl(pinData))) }
			>
				<button
					className='done-btn-pin'
					disabled={!(isPinned || (pinData && isInCoreIndex(pinData))) && (url === '' || comment === '' || (pinData && isInvalidUrl(pinData)))}
					// onClick={() => pinContent()}
				>
					Done
				</button>
			</Popconfirm>

			{submitLoading && <div className='ml-3'>
				<img src={Loading} width="23px" height="23px" />
			</div>}
		</div>
	</div>)
}
