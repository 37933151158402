import React, { useEffect, useState, useRef } from 'react';
import './footer.scss';
import FSLogo from '../../../../assets/images/FSLogo.svg';
import Notification from '../../../../assets/images/notification-outline.svg';
import { Avatar, Badge, Button, Menu, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Utils from '../../../../utils/utils';


export default function Footer() {

  return (<div className="footer-container">
    <div className="copyright">
      Copyright
    </div>
  </div>);
};

