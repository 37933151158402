import React, { Component, useEffect, useState } from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState, CompositeDecorator, } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './richtexteditor.css';

const styleMap = {
  'UNDERLINE': {
    'textDecoration': 'underline'
  }
};

export default function RichTextEditor(props) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  
  useEffect(() => {
    const blocksFromHTML = htmlToDraft(props.review)
    const content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
    setEditorState(EditorState.createWithContent(content))
  }, [props.review])

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    props.updatereview(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }

  
  return (
    <div>
      <Editor  
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        customStyleMap={styleMap}
        toolbar={{
              options: ['list', 'history'],
              inline: { inDropdown: false },
              list: { inDropdown: false },
              history: { inDropdown: false },
        }}
      />
    </div>
  )
}
