import React, {useEffect, useState, useRef} from 'react';
import './tilelist.css';
import Corner from '../../../assets/images/corner.png';


function Tilelist(props) {
    const { datasource, selectedindex, onselect, id, data } = props;
    const [selectedItem, setSelectedItem] = useState({ id: -1, value: '' });
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        // Avoid directly mutating props.selectedindex
        const initialSelectedIndex = selectedindex || -1;
        setSelectedItem(datasource[initialSelectedIndex]);
        setSelectedIndex(initialSelectedIndex);
    }, [datasource, selectedindex]);

    const setTileSelection = (item) => {
        onselect(item);
        setSelectedItem(item);
        setSelectedIndex(item.id);
    }

    return (
        <div className="tile-list">
            <div className="tile-box" style={{ height: '250px', width: '100%' }}>
                {datasource.map((item) => (
                    <div
                        className={`tile-item relative-position ${selectedIndex === item[id] ? 'tile-selected' : 'tile-default'}`}
                        key={`tle-${item[id]}`}
                        onClick={() => setTileSelection(item)}
                    >
                        <img src={item[data]} alt="Tile" />
                        <img className='selected-corner' src={Corner} alt="Selected Corner" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Tilelist;