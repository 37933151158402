import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import './flagdetails.css';
import Utils from '../../utils/utils.js';
import axios from 'axios';
import Loading from '../../assets/images/loader.gif';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import moment from 'moment';
import Cross from '../../assets/images/cross.svg';
import { notification, Popconfirm } from 'antd';
import { Tweet } from 'react-twitter-widgets';
import Star from '../../assets/images/star.svg';
import RelatedFCBox from '../relatedfcbox/relatedfcbox';
import RelatedNewsBox from '../relatednewsbox/relatednewsbox';
import FcAndNewsBox from '../fcandnewsbox/fcandnewsbox';
import FSBird from '../../assets/images/fs-half.svg';
import Pin from '../../assets/images/black-pin.svg';
import EditPin from '../../assets/images/edit-pin.svg';
import Archived from '../../assets/images/archived.svg'
import Question from '../../assets/images/question.png';
import PinControl from '../pincontrol/pincontrol';
import GoldPin from '../../assets/images/gold-pin.svg';
// import view from '../../assets/images/view.svg';
// import like from '../../assets/images/like.svg';
// import Web from '../../assets/images/website.png';
// import Twitter from '../../assets/images/twitter.png';

export default function FlagDetails(props) {

    const [flagId, setFlagId] = useState('');
    const [tipDetails, setTipDetails] = useState(null);
    const [relatedList, setRelatedList] = useState(null);
    const [relatedListAll, setRelatedListAll] = useState(null);
    const [relatedFlags, setRelatedFlags] = useState([]);
    const [relatedReviews, setRelatedReviews] = useState([]);
    const [interestedIn, setInterestedIn] = useState([]);
    const [rrLoader, setRrLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [notRemoved, setNotRemoved] = useState(true);
    const [bookmarkCom, setBookmarkCom] = useState('');
    const [supportCount, setSupportCount] = useState(0);
    const [revMessage, setRevMessage] = useState('');
    const [relatedFC, setRelatedFc] = useState([]);
    const [relatedNews, setRelatedNews] = useState([]);
    const [newRelatedList, setNewRelatedList] = useState([]);
    const [show, setShow] = useState(false);
    const [isModerator, setIsModerator] = useState(false);
    const [editPin, setEditPin] = useState(false);
    const [pinData, setPinData] = useState(null);
    const [isPinned, setIsPinned] = useState(false);
    const [removePinLoading, setRemovePinLoading] = useState(false);
    const [combinedList, setCombinedList] = useState([]);
    const [combinedListAll, setCombinedListAll] = useState([]);
    const [isContentPartner, setIsContentPartner] = useState(false);
    const [isContentPartnerAdmin, setIsContentPartnerAdmin] = useState(false);
    const [isConsoleFlag, setIsConsoleFlag] = useState(false);
    const [isTiplineFlag, setIsTiplineFlag] = useState(false);
    const [isContributorFlag, setIsContributorFlag] = useState(false);
    const [isFromPartnerConsole, setIsFromPartnerConsole] = useState(false);

    const history = useHistory();
    const source = axios.CancelToken.source();
    let baseContURL = 'https://repustar.com/contributor/';
    if (process.env.REACT_APP_ENV === 'PROD') {
        baseContURL = 'https://repustar.com/contributor/';
    } else {
        baseContURL = 'https://dev.repustar.com/contributor/';
    }

    useEffect(() => {
        setIsModerator(Utils.getAdminPermission())
        setIsContentPartner((localStorage?.user_types).split(',').includes("content_partner"));
        setIsContentPartnerAdmin((localStorage?.user_types).split(',').includes("content_partner_admin"));
        let path = history.location.pathname.split("/");
        setIsFromPartnerConsole(path && path.length > 0 && history.location.pathname.split("/")[1] === 'partnerconsole')
        return () => {
            setEditPin(false);
            setPinData(null);
            setIsPinned(false);
            source.cancel();
        }

    }, []);

    useEffect(() => {
        setIsConsoleFlag(sessionStorage.tab_of === 'console_flag')
        setFlagId(props.flagId);
    }, [props.flagId]);

    useEffect(() => {
        if (flagId) {
            setEditPin(false);
            setPinData(null);
            setIsPinned(false);
            getMyTips();
            // getRelatedList();

        }
    }, [flagId])

    useEffect(() => {
        if (tipDetails && 'pinned_content' in tipDetails && tipDetails.pinned_content.title) {
            setPinData({ ...tipDetails.pinned_content, ...{ "content_title": tipDetails.pinned_content.title } });
            setIsPinned(true);
        }
        if (tipDetails) {
            setIsTiplineFlag(tipDetails.__typename === "tipline_flag" || tipDetails.__typename === 'tipline_test_flag')
            setIsContributorFlag(tipDetails.__typename === "contributor_flag")
        }
        // if (tipDetails) {
        //     setIsConsoleFlag(tipDetails.__typename === "tipline_flag" || tipDetails.__typename === 'tipline_test_flag')
        // }
    }, [tipDetails])

    const getMyTips = async () => {
        setIsLoading(true);
        let apiPath = Utils.getAPIDescription('get-my-tips');
        try {
            // Load async data from an inexistent endpoint.
            let data = await axios.get(apiPath + '?id=' + flagId + '&subflags=' + true + '&source=' + (isFromPartnerConsole ? 'content_partner' : 'contributor'), { headers: { "userid": `${localStorage.getItem('user_id')}` } });
            if (data && data.data && data.data.body[0]) {
                setTipDetails(data.data.body[0]);
                setIsLoading(false);
                setSupportCount(data.data.body[0].supportCount);
                setBookmarkCom(data.data.body[0].bookmarkComment ? data.data.body[0].bookmarkComment : "");
            }
        } catch (e) {
            console.log(`Error+${e}`);
        }
        setIsSaved(false);
    }

    const getRelatedList = async () => {
        setRrLoader(true);
        let apiPath = Utils.getAPIDescription('get-related-list');
        try {
            let data = await axios.get(apiPath + '?id=' + flagId + '&subflags=' + true + '&search_type=latest' + '&source=contributor', { cancelToken: source.token });
            if (data && data.data && data.data.body) {
                setRelatedList(data.data.body.relevant_list)
                setRelatedListAll(data.data.body.relevant_list_all)
                setRelatedNews(data.data.body.contextual_list)
                setNewRelatedList(data.data.body.related_list)
                setCombinedListAll(data.data.body.n_all_results);
                setCombinedList(data.data.body.n_new_results_only);
                setRrLoader(false)
            } else {
                setRelatedList([])
                setRelatedListAll([])
                setRelatedNews([])
                setNewRelatedList([])
                setCombinedListAll([]);
                setCombinedList([]);
                setRrLoader(false)
            }
        } catch (e) {
            setRrLoader(false)
            setRelatedList([])
            setRelatedListAll([])
            setRelatedNews([])
            setNewRelatedList([])
            setCombinedListAll([]);
            setCombinedList([]);
            console.log(`Error+${e}`)
        }
    }

    const onBookmarkChange = (e) => {
        setBookmarkCom(e.target.value);
    }
    const saveBookmark = () => {
        let api = Utils.getAPIDescription('save-bookmark');
        axios.post(api, {
            "flagId": flagId,
            "userId": localStorage.getItem('user_id'),
            "comments": bookmarkCom
        }, { headers: { "userid": `${localStorage.getItem('user_id')}` } }).then((res) => {
            // console.log(res);
        }).catch((err) => {
            console.log(err);
        });
        setIsSaved(true);
        setNotRemoved(true);
        notification.success({
            message: 'Bookmarked flag successfully.',
            duration: 1.5,
            top: 70,
            placement: 'topRight',
        });
        props.afterBookmark(flagId, true)
    }

    const removeBookmark = () => {
        let api = Utils.getAPIDescription('save-bookmark');
        axios.post(api, {
            "flagId": flagId,
            "userId": localStorage.getItem('user_id'),
            "comments": bookmarkCom,
            "action": "delete"
        }, { headers: { "userid": `${localStorage.getItem('user_id')}` } }).then((res) => {
            // console.log(res);
        }).catch((err) => {
            console.log(err);
        });
        setIsSaved(false);
        setNotRemoved(false);
        notification.success({
            message: 'Bookmark removed successfully.',
            duration: 1.5,
            top: 70,
            placement: 'topRight',
        });
        props.afterBookmark(flagId, false)
    }

    const supportFlag = () => {
        let apiPath = Utils.getAPIDescription('support-flag');
        axios.post(apiPath, { "id": flagId }, { headers: { "userid": localStorage.getItem('user_id') } })
            .then((res) => {
                //console.log(res.data.body.supporters.length);
                setSupportCount(res.data.body.supporters.length);
            })
    }

    const afterPin = (comment, url, title, article_image, is_featured) => {
        setEditPin(false);
        setIsPinned(true);
        props.onPinAndUnpinning(flagId, 1, {
            "pin_comment": comment, "url": url, "title": title, "article_image": article_image, "is_featured": is_featured, "org_details": {
                "org_id": localStorage.getItem('org_id'),
                "org_name": localStorage.getItem('org_name')
            }
        });
        setPinData(pinData => {
            return {
                ...pinData, ...{
                    "pin_comment": comment, "url": url, "content_title": title, "article_image": article_image, "is_featured": is_featured, "org_details": {
                        "org_id": localStorage.getItem('org_id'),
                        "org_name": localStorage.getItem('org_name')
                    }
                }
            }
        })
    }

    const removePin = () => {
        setRemovePinLoading(true)
        let api = Utils.getAPIDescription('pin-content') + "?remove_pin=true"
        let body = {
            "flag_id": flagId
        }
        axios.post(api, body).then(res => {
            setRemovePinLoading(false);
            setIsPinned(false);
            setPinData(null);
            setIsPinned(false);
            props.onPinAndUnpinning(flagId, 0, {});
        }).catch(err => {
            setRemovePinLoading(false);
            notification.error({ message: "Something went wrong!", duration: 3 });
            console.log(err)
        })
    }
    const deleteThisFlag = async () => {
        let apiPath = Utils.getAPIDescription('tips');
        try {
            apiPath += `?id=${flagId}&createdAt=${tipDetails.createdAt}`
            // Delete console flag
            let data = await axios.delete(apiPath, { headers: { "userid": `${localStorage.getItem('user_id')}` } });
            if (data && data.data && data.data.body) {
                notification.success({ message: data.data.body });
                props.setVisible(false);
                window.history.pushState({}, null, window.location.href.split('#')[0]);
            }
        } catch (e) {
            console.log(`Error+${e}`);
        }
    }
    const bookmarkContent = (
        <div className='bookmark-pop pl-4'>
            {/* <div className='my-2'>Add a Comment</div> */}
            {/* <textarea className='comment-box' onChange={onBookmarkChange}  value={bookmarkCom}></textarea> */}
            <div className='pop-bottom'>
                <div className='mr-3 cursor-pointer' onClick={e => {
                    e.preventDefault();
                    setShow(false);
                }} onBlur={() => {
                    setShow(false);
                }}>Cancel
                </div>
                {tipDetails && !isLoading
                    ? <>{(tipDetails.isBookmarked || isSaved) && notRemoved
                        ? <div>
                            <button className='book-button' onClick={(e) => {
                                removeBookmark();
                                e.preventDefault();
                                setShow(false);
                            }} onBlur={() => {
                                setShow(false);
                            }}>Remove Bookmark
                            </button>
                        </div>
                        : <div>
                            <button className='book-button' onClick={(e) => {
                                saveBookmark();
                                e.preventDefault();
                                setShow(false);
                            }} onBlur={() => {
                                setShow(false);
                            }}>Bookmark Flag
                            </button>
                        </div>} </>
                    : null
                }
            </div>
        </div>
    );


    const checkFacebookURLValidForEmbed = (url) => {
        // console.log((url.includes('facebook.com') && url.includes('posts')) || (url.includes('facebook.com') && url.includes('photo.php')));
        return (url.includes('facebook.com') &&

                (
                        url.includes('posts') ||
                        url.includes('photo.php') ||
                        url.includes('/photos/') ||
                        url.includes('/photo?fbid=') ||
                        url.includes('/photo/?fbid=')
                )
        )
    };

    const generateFacebookLinkIFrame = (url) => {
        let src = "https://www.facebook.com/plugins/post.php?width=552&height=500&href="+encodeURIComponent(url);

        return <iframe
            src={src}
            width="100%"
            height="500"
            style={{border: "none" , overflow: "hidden", minHeight: "200px"}}
            scrolling="no"
            frameBorder="0"
            allowFullScreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
        </iframe>
    };


    return (
        <>
            <div className='flag-details-main'>
                {
                    tipDetails && !isLoading ?
                        <>
                            <div className='flag-header'>
                                {/* {tipDetails.direct_reviews_count > 0 ? <div className="red-outline align-self-start">ANSWERED</div>: <div></div>} */}
                                {/* Don't Remove the "status-ans-block" below this. I'm pretty sure it's coming back */}
                                {/* <div className='status-ans-block'>
                                    {tipDetails.allocatedStatus === 'Archived' && <div className="status">
                                        <div>
                                            <img src={Archived} width="13px" />
                                        </div>
                                        <div className="ml-1">Archived</div>
                                    </div>}
                                    {tipDetails.allocatedStatus === 'Eligible' && <div className="status">
                                        <div>
                                            <img src={Star} width="13px" />
                                        </div>
                                        <div className="ml-1">Highlighted</div>
                                    </div>}
                                    <div>
                                        {tipDetails.direct_reviews_count > 0 && <div className='ans-tag'>Answered</div> }
                                    </div>
                                </div> */}
                                <div className='pin-title'>
                                    {editPin ? "Pinning" : ""}
                                </div>
                                <div className="d-flex align-items-center">
                                    {isConsoleFlag && <button className='book-button delete-button mr-2' onClick={(e) => {
                                        deleteThisFlag();
                                    }}>Delete this Flag</button>}
                                    {removePinLoading &&
                                        <div className='mr-2'><img src={Loading} width="25" height="25" /></div>}
                                    {!isConsoleFlag && tipDetails.allocatedStatus !== 'Archived' && <div>
                                        {(tipDetails.isBookmarked || isSaved) && notRemoved
                                            ? <button className='book-button' disabled={editPin} onClick={(e) => {
                                                removeBookmark();
                                                e.preventDefault();
                                            }}>Remove Bookmark</button>
                                            : <button className='book-button' disabled={editPin} onClick={(e) => {
                                                saveBookmark();
                                                e.preventDefault();
                                            }}>Bookmark this flag</button>
                                        }
                                    </div>}
                                    {!props.fromReview && tipDetails.allocatedStatus !== 'Archived' && <div className='mx-2'>
                                        {(!props.contentPartnerMode && window.location.href.includes("partnerconsole") )? <Link to={'/partnerconsole/create-fact-brief?flagId=' + flagId}>
                                            <button disabled={editPin} className='blue-button'>WRITE BRIEF</button>
                                        </Link>:<Link to={'/create-fact-brief?flagId=' + flagId}>
                                            <button disabled={editPin} className='blue-button'>WRITE BRIEF</button>
                                        </Link>}
                                        {(isModerator || props.contentPartnerMode) && (!isContentPartner && !isContentPartnerAdmin) && <>
                                            {!isPinned
                                                ? <button
                                                    disabled={editPin}
                                                    className='blue-button ml-2'
                                                    onClick={() => {
                                                        setEditPin(true);
                                                    }}
                                                >
                                                    {"PIN CONTENT"}
                                                </button>
                                                : <Popconfirm
                                                    placement='bottom'
                                                    title="Are you sure you want to unpin?"
                                                    onConfirm={removePin}
                                                    okText="Yes"
                                                    cancelText="Cancel"
                                                    disabled={editPin}
                                                >
                                                    {tipDetails?.pinned_content?.org_details?.org_id === localStorage.getItem('org_id') &&
                                                        <button className='gray-button ml-2' disabled={editPin}>
                                                            UNPIN CONTENT
                                                        </button>
                                                    }
                                                </Popconfirm>
                                            }
                                        </>}
                                    </div>}
                                    <div
                                        className='mx-2 cursor-pointer'
                                        onClick={() => {
                                            setShow(false);
                                            props.setVisible(false);
                                            window.history.pushState({}, null, window.location.href.split('#')[0]);
                                        }}
                                    >
                                        <img src={Cross} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {editPin && <PinControl
                                    setEditPin={setEditPin}
                                    pinData={pinData}
                                    setPinData={setPinData}
                                    flagId={flagId}
                                    afterPin={afterPin}
                                    isPinned={isPinned}
                                />
                                }
                            </div>
                            <div className='flag-det-board'>
                                <div className='tag-list'>{tipDetails.tags.join(', ')}</div>
                                <div
                                    className='date'>{`${moment(parseInt(tipDetails.createdAt)).fromNow()} · ${moment(parseInt(tipDetails.createdAt)).format("Do MMMM YYYY, h:mm A")}`}</div>

                                <div className='status-tag-user-box'>
                                    {(tipDetails.__typename === 'twitter_flag' || tipDetails.__typename === 'Twitter')
                                        &&
                                        <a href={`https://factsparrow.repustar.com/user/${tipDetails.twitter_user}?userid=${tipDetails.twitter_user_id}`}
                                            target='_blank' rel='noreferrer noopener'>
                                            <div className='user-tag'>
                                                <img src={FSBird}
                                                    width="10" />&nbsp;&nbsp;{tipDetails.__typename === 'twitter_flag' ? tipDetails.user_display_name ?? tipDetails.twitter_user : tipDetails.author}
                                            </div>
                                        </a>}
                                    {/* : <div className='user-tag'>
                                            {tipDetails.user_display_name ?? tipDetails.username ?? tipDetails.author}
                                        </div>} */}
                                </div>

                                {/* <div className='date'><span className='font-weight-bold'>User Comment: </span> {tipDetails.what}</div>
                                <div className='date'>by <span className='gray-font'>{tipDetails.__typename === 'twitter_flag' ? tipDetails.twitter_user : tipDetails.author}</span></div> */}
                                {/* {tipDetails.comments && <div className='comment'><img src={Star} width="15" height="15" className="mb-1"/><span className='font-weight-bold ml-2'>Repustar Comments: </span>{tipDetails.comments}</div>} */}
                                {pinData && isPinned && !editPin && <div className='pinned-block'>
                                    <div className='pinned-block-header'>
                                        <div className='pb-left'>
                                            <img src={pinData.is_featured ? GoldPin : Pin} width={"11px"} />&nbsp;&nbsp;Pinned response from&nbsp;
                                            <a
                                                style={{ color: "#000000" }} rel='noreferrer nopener'
                                                href={(pinData.org_details && pinData.org_details.org_slug) ? (baseContURL + pinData.org_details.org_slug) : ''}
                                                target={"_blank"}
                                            >
                                                <b><span className='fnw-600'><u>{pinData.org_details ? pinData.org_details.org_name : ""}</u>!</span></b>
                                            </a>
                                        </div>
                                        <div className="pop-inf-div">

                                            <div className='mr-4 tooltip-ques' style={{ height: "17px" }}>
                                                <OverlayTrigger id='4' trigger="click" placement='bottom' rootClose overlay={
                                                    <Popover id='4'>
                                                        <Popover.Content>
                                                            <div className='fn-14'>
                                                                Editors pin links they believe will either answer this question or provide helpful context on the topic.
                                                            </div>
                                                        </Popover.Content>
                                                    </Popover>
                                                }>
                                                    <img src={Question} alt="info" className="cursor-pointer" width={17} height={17} />
                                                </OverlayTrigger>
                                            </div>

                                            {isModerator &&
                                                <div className='pb-right cursor-pointer' onClick={() => setEditPin(true)}>
                                                    <img src={EditPin} width="14px" />
                                                </div>}
                                        </div>
                                    </div>
                                    <div className='pb-body'>
                                        <div className='fn-14 fnw-600 pin-title-des'>{pinData.pin_comment}</div>
                                        <div className='mt-3 pin-highlight'>
                                            {pinData.website_name ? pinData.website_name : pinData.url ? pinData.url.split("/")[2] !== undefined ? pinData.url.split("/")[2] : "Source" : "Source"}
                                            <a className='blue-font' href={pinData.url} target='_blank' rel='noreferrer noopener'>
                                                &nbsp;<u>{pinData.content_title}</u>
                                            </a>
                                        </div>
                                    </div>
                                </div>}
                                {(isTiplineFlag || isContributorFlag) && <div className='user_query'>
                                    {tipDetails.what}
                                </div>}
                                {/* <div className='encon'>ENCOUNTERED ON:</div> */}
                                {
                                    (tipDetails.__typename === 'twitter_flag' || tipDetails.__typename === 'twitter_test_flag' || tipDetails.campaignDetails.articleLink.includes('https://twitter.com') || tipDetails.campaignDetails.articleLink.includes('https://x.com')) ?
                                        <div className=''>
                                            <Tweet
                                                className='flag-tweet'
                                                tweetId={tipDetails.campaignDetails.articleLink.split('status/')[1].split('?')[0]}
                                                renderError={() => <div className="tweet-err-box">
                                                    This Tweet has been deleted.
                                                </div>}
                                            />
                                        </div>
                                        :
                                        (checkFacebookURLValidForEmbed(tipDetails.campaignDetails.articleLink) ?

                                                generateFacebookLinkIFrame(tipDetails.campaignDetails.articleLink) :

                                                <a href={tipDetails.campaignDetails.videoLink ? tipDetails.campaignDetails.videoLink : tipDetails.campaignDetails.articleLink}
                                                    target='_blank' style={{ color: 'black', textDecoration: 'none' }}>
                                                    <div className='source'>
                                                        <div className={'left-img ' + (tipDetails.tags.length > 0 ? '' : 'p-2')}><img src={tipDetails.previewImage}
                                                            style={{ width: `${tipDetails.tags.length > 0 ? '100%' : '50%'}` }} /></div>
                                                        <div className='right-sor'>
                                                            <div className='broadcaster'>
                                                                {tipDetails.campaignDetails.broadcasterName === 'Direct from source' ?
                                                                    tipDetails.campaignDetails.contentSource :
                                                                    tipDetails.campaignDetails.broadcasterName !== "N.A" ?
                                                                        tipDetails.campaignDetails.broadcasterName :
                                                                        tipDetails.campaignDetails.articleLink ?
                                                                            Utils.extractDomain(tipDetails.campaignDetails.articleLink) :
                                                                            ""}
                                                            </div>
                                                            <div
                                                                className={`title-con ${tipDetails.tags.length > 0 ? '' : 'no-preview'}`}>{tipDetails.campaignDetails && tipDetails.campaignDetails.name}</div>
                                                            {tipDetails.campaignDetails.publishedDate ?
                                                                <div className='fn-14 gray-font mt-2'>Published
                                                                    on: {moment(tipDetails.campaignDetails.publishedDate).format("DD MMM YYYY")}</div> : null}
                                                        </div>
                                                    </div>
                                                </a>
                                        )
                                }
                                {!rrLoader ?
                                    <>
                                        {/* <><div className='my-5'>
                                            <RelatedFCBox
                                                relatedList={relatedList}
                                                relatedListAll={relatedListAll}
                                                isLoading={rrLoader}
                                                newRelatedList={newRelatedList}
                                            />
                                        </div>
                                        <div className='my-5'>
                                            <RelatedNewsBox
                                                data={relatedNews}
                                                isLoading={rrLoader}
                                                revsData={relatedFC}
                                                width100={true}
                                                source={"FS Landing Page"}
                                                tweetMode={false}
                                            />
                                        </div> </> */}
                                        {/* <div className='my-5'>
                                            <FcAndNewsBox
                                                newsData={combinedList ?? []}
                                                allData={combinedListAll ?? []}
                                                // data={relatedNews}
                                                isLoading={rrLoader}
                                                revsData={relatedFC}
                                                width100={true}
                                                source={"FS Landing Page"}
                                                tweetMode={false}
                                            />
                                        </div> */}
                                        {/* <div className='rev-box'>
                                            <div className='heading'>Related Flags</div>
                                            <div className='rev-board'>
                                                {relatedFlags.length > 0 ? React.Children.toArray(relatedFlags.slice(0, 3).map((item, id) => (
                                                    <div className='rev-card-flag' key={"rel_flags_" + id}>
                                                        <div>
                                                            <div className='blue-font'>{item.tags.join(', ')}</div>
                                                            <div className='my-1 cursor-pointer' onClick={() => setFlagId(item.id)}>{item.what}</div>
                                                            <div className='mt-2 small-gray-font'>Editor's suggestion: {item.comments}</div>
                                                            <div>
                                                                <span className='time-ago'>{moment(parseInt(item.createdAt)).fromNow()}</span>
                                                                <span className='num ml-1'>0</span><span className='time-ago'>&nbsp;Reviews</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <img src={item.__typename === 'twitter_flag' ? Twitter : Web} width='20px'></img>
                                                        </div>
                                                    </div>

                                                ))) : <div className='no-data'>No related flags to show now</div>}
                                            </div>
                                        </div> */}
                                    </>
                                    :
                                    <center><img src={Loading} width='30px' /></center>
                                }
                            </div>
                        </>
                        :
                        <center><img src={Loading} width='30px' /></center>
                }
            </div>
        </>
    )
}
