import React from "react";
import fsHalf from '../../../../../assets/images/fs-half.svg';
import FSBirdGrey from '../../../../../assets/images/console/grey_fs_bird.svg';
import HeartGrey from '../../../../../assets/images/console/grey_heart.svg';

export const Answered = (props) => {
  return <div className="answered_container">
    {/* <div className="top-bar">
      258 Fact-seekers answered
    </div> */}

    <div className="body-content">
      <div className="heading">
        <div className="left-section">
          Answered ({props.recommendData.answered ? props.recommendData.answered.length : 0})
        </div>
        <div className="right-section">
          {props.recommendData.factseekers_answered} Fact seekers answered
        </div>

      </div>
      {props.recommendData.answered.map((qus, index) =>
        <div className="contents" onClick={(e) => {
          props.setShowOpportunitiesDetails(true); props.setSelectedOpportunitiesDetails(qus);
        }}>
          <div className="question">
            <div className="text">
              {qus.factcheck_details.claimReviewed}
            </div>
            <div className="right-section">
              {/* <div className="view-count">
                <img src={HeartGrey} alt="FS" width={15} style={{ marginRight: "3px" }} />
                <span>
                  230
                </span>
              </div> */}
              <div className="fact-seeker-counts">
                <img src={FSBirdGrey} alt="FS" width={10} />
                <span>
                  {qus.total_factseekers_count}
                </span>
              </div>

            </div>

          </div>
          {/* <div className="date">
            25 Nov 2019
          </div> */}
          <div className="date">
            <div className="publisher-name">
              {qus.org_name}
            </div>
            <div className="date">
              {qus.factcheck_details.datePublished}
            </div>

          </div>
        </div>

      )}



    </div>
  </div>;
}
