import React, {useEffect, useState} from 'react';
import styles from './quiz-admin.module.scss';
import { AppstoreAddOutlined } from '@ant-design/icons';
import axios from 'axios';
import {Button, DatePicker, Input, Modal, notification, Row, Select, Statistic, Table, Tag} from 'antd';
import moment from 'moment-timezone';
import Utils from "../../../../utils/utils";
import OrgBanner from "../../../OrgBanner/orgbanner";
import Loading from "../../../../assets/images/loader.gif";
import QuizInstallationGuide from "./quiz-installation-guide";
import SearchInput from "./brief-search-input";
import useOrgStore from "../../store/orgsStore";

function useDebounce(cb, delay) {
    const [debounceValue, setDebounceValue] = useState(cb);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(cb);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [cb, delay]);
    return debounceValue;
}


export default function QuizPage(props) {
    const [openModel, setOpenModel] = useState(false);
    const [openDelModel, setOpenDelModel] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [question1, setQuestion1] = useState(null);
    const [question2, setQuestion2] = useState(null);
    const [question3, setQuestion3] = useState(null);
    const [loading, setLoading] = useState(false);

    const [questions, setQuestions] = useState([
        {review_url: '', tweet_url: ''},
        {review_url: '', tweet_url: ''},
        {review_url: '', tweet_url: ''},
    ]);

    const [tweet1, setTweet1] = useState(null);
    const [tweet2, setTweet2] = useState(null);
    const [tweet3, setTweet3] = useState(null);
    const [quizTitle, setQuizTitle] = useState(null);
    const [quizInfoText, setQuizInfoText] = useState(null);
    const [quizId, setQuizId] = useState(null);
    const [quizDelId, setQuizDelId] = useState(null);
    const [quizImage, setQuizImage] = useState(null);
    const [quizList, setQuizList] = useState(null);
    const [topicImagesList, setTopicImagesList] = useState(null);
    const [quizPublishDate, setQuizPublishDate] = useState(null);
    const [quizOrg, setQuizOrg] = useState(null);
    const [endPageText, setEndPageText] = useState("Stay up to date!");
    const [endPageLink, setEndPageLink] = useState(null);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [orgsList, setOrgsList] = useState([]);
    const [quizStatus, setQuizStatus] = useState("draft");


    const [showQuizInstallation, setShowQuizInstallation] = useState(false);

    const { orgDetails, fetchOrgDetails, isFetched } = useOrgStore();

    useEffect(() => {
        if (!isFetched) {
            fetchOrgDetails();
        }
    }, [fetchOrgDetails, isFetched]);

    const {Option} = Select;
    const {TextArea} = Input;
    let orgList = [{'id': 3, 'name': "Gigafact"}, {'id': 1174, 'name': "NewsCo"}, {
        'id': 1813,
        'name': "Skeptical Science"
    }, {'id': 1814, 'name': "Lead Stories"}, {'id': 1892, 'name': "The Associated Press"}, {
        'id': 2354,
        'name': "EconoFact"
    }, {'id': 2360, 'name': "Gossip Cop"}, {'id': 2680, 'name': "Nevada Independent"}, {
        'id': 2742,
        'name': "Repustar"
    }, {'id': 2790, 'name': "USAFacts"}, {'id': 2876, 'name': "Mississippi Center for Investigative Reporting"}];

    let review_url = ''
    let env = process.env.REACT_APP_ENV;
    if (env === 'PROD') {
        review_url = 'https://gigafact.org/fact-briefs/';
        orgList = [{'id': 3, 'name': "Gigafact"}, {'id': 1813, 'name': "Skeptical Science"}, {
            'id': 1814,
            'name': "Lead Stories"
        }, {'id': 1892, 'name': "The Associated Press"}, {'id': 2354, 'name': "EconoFact"}, {
            'id': 2360,
            'name': "Gossip Cop"
        }, {'id': 2680, 'name': "Nevada Independent"}, {'id': 2742, 'name': "Repustar"}, {
            'id': 2790,
            'name': "USAFacts"
        }, {'id': 2876, 'name': "Mississippi Center for Investigative Reporting"}];
    } else {
        review_url = 'https://dev.gigafact.org/fact-briefs/';
        orgList = [{'id': 3, 'name': "Gigafact"}, {'id': 327, 'name': "Nevada Independent"}, {
            'id': 779,
            'name': "Skeptical Science"
        }, {'id': 782, 'name': "Lead Stories"}, {'id': 810, 'name': "AP NEWS"}, {
            'id': 957,
            'name': "Repustar"
        }, {'id': 998, 'name': "Mississippi Center for Investigative Reporting"}, {'id': 999, 'name': "USAFacts"}];
    }



    useEffect(() => {
        document.title = "Repustar | Quiz Page";
        document.body.style.background = '#e5e5e5';
        fetchQuiz();
        fetchImages();
        briefsListFetch();
        // fetchAllOrgs();

        return () => {
            document.body.style.background = 'white';
        }
    }, []);

    // useEffect(() => {
    //     fetchQuiz();
    // }, [selectedOrg])

    const [briefsList, setBriefsList] = useState([]);

    // const fetchOrgDetails = () => {
    //     let api =
    //         Utils.getAPIDescription("get-org-details") +
    //         "?org_id=" +
    //         localStorage.getItem("org_id");
    //     axios.get(api).then((res) => {
    //         let data = res.data.data;
    //         setOrgDetails(data)
    //     });
    // };

    const briefsListFetch = () => {

        let api =
            Utils.getAPIDescription("quiz-admin-briefs-search") +
            "?org_id=" +
            localStorage.getItem("org_id") + "&search_string=";

        axios.get(api).then((res) => {
            let data = res.data.response;
            setBriefsList(data);
        });
    };

    // const [searchResults, setSearchResults] = useState([]);
    // const [seachQuery, setSearchQuery] = useState();
    //
    // const handleSearch = (newValue) => {
    //     fetch(newValue, setData);
    // };
    // const handleChange = (newValue) => {
    //     setValue(newValue);
    // };
    //
    // const searchBriefs = (search_query, callback) => {
    //     let api =
    //         Utils.getAPIDescription("quiz-admin-briefs-search") +
    //         "?org_id=" +
    //         localStorage.getItem("org_id") + "&search_string="+search_query;
    //     axios.get(api).then((res) => {
    //         let data = res.data.data;
    //         setOrgDetails(data)
    //     });
    // };

    const fetchQuiz = async () => {
        // let quizListAPI = Utils.getAPIDescription('weekly-quiz-listing')+'?num_weeks='+8;
        setLoading(true);
        let quizListAPI = Utils.getAPIDescription('weekly-quiz-new-listing') + '?org_id=' + localStorage.getItem('org_id');
        const result = await axios.get(quizListAPI).catch((error) => {
            console.log('Error Fetching Quiz list: ' + error)
        });
        if (result.status === 200) {
            let data = result.data;
            // console.log(data.response);
            setQuizList(data.response);
        }
        setLoading(false);
    };

    const fetchImages = async () => {
        let fetchImagesAPI = Utils.getAPIDescription('fetch-topic-images');
        const result = await axios.post(fetchImagesAPI, {'categories': []}).catch((error) => {
            console.log('Error Fetching topic Images: ' + error)
        });
        if (result.status === 200) {
            let data = result.data;
            // console.log(data.fallback_images);
            setTopicImagesList(data.fallback_images);
        }
    };

    // const fetchOrgs = async () =>{
    //   let fetchOrgsAPI = Utils.getAPIDescription('fetch-orgs');
    //   const result = await axios.get(fetchOrgsAPI, {'categories': []}).catch((error) => {console.log('Error Fetching orgs: '+error)});
    //   if(result.status === 200){
    //   let data = result.data;
    //   // console.log(data);
    //   // setTopicImagesList(data.fallback_images);
    // }
    // };


    const handleQuestionChange = (url_val, idx) => {
        console.log(url_val)
        // if(idx === 0){
        //   setQuestion1(url_val.target.value);
        //   onReviewPaste(url_val.target.value, 0);
        // }
        // if(idx === 1){
        //   setQuestion2(url_val.target.value);
        //   onReviewPaste(url_val.target.value, 1);
        // }
        // if(idx === 2){
        //   setQuestion3(url_val.target.value);
        //   onReviewPaste(url_val.target.value, 2);
        // }

        const newQuestions = [...questions];
        // newQuestions[idx].review_url = url_val.target.value;
        if (url_val)
            newQuestions[idx].review_url = url_val;
        else
            newQuestions[idx].review_url = '';
        setQuestions(newQuestions);
    };

    const handleTweetURLChange = (tweet_url_val, idx) => {
        // if(idx === 0){
        //   setTweet1(tweet_url_val.target.value);
        // }
        // if(idx === 1){
        //   setTweet2(tweet_url_val.target.value);
        // }
        // if(idx === 2){
        //   setTweet3(tweet_url_val.target.value);
        // }
        const newQuestions = [...questions];
        newQuestions[idx].tweet_url = tweet_url_val.target.value;
        setQuestions(newQuestions);
    };

    const addQuestion = () => {
        setQuestions([...questions, {review_url: '', tweet_url: ''}]);
    };

    const handleTitleChange = (title_val) => {
        setQuizTitle(title_val.target.value);
    }
    const handleEndTextChange = (val) => {
        setEndPageText(val.target.value);
    }
    const handleEndLinkChange = (val) => {
        setEndPageLink(val.target.value);
    }

    const handleInfoTextChange = (info_text_val) => {
        setQuizInfoText(info_text_val.target.value);
    }

    function onDateChange(date, dateString) {
        let dt_val = moment(date).format();
        console.log(dt_val);
        if (dt_val !== 'Invalid date') {
            setQuizPublishDate(dt_val);
        }
    }

    const onReviewPaste = async (review_url_val, idx) => {
        if (review_url_val !== null) {
            let getFlagTweetAPI = Utils.getAPIDescription('get-tweet-for-review');
            let token = localStorage.getItem('repustar_token');
            const result = await axios.post(getFlagTweetAPI, {'review_url': review_url_val}, {headers: {"Authorization": token}}).catch((error) => {
                console.log('Error while getting the tweet for a review: ' + error)
            });
            // console.log(std_tag_val);
            if (result.status === 200) {
                let data = result.data;
                if (idx === 0 && data.twitter_reply_id) {
                    setTweet1('https://twitter.com/TwitterSupport/status/' + data.twitter_reply_id);
                }
                if (idx === 1 && data.twitter_reply_id) {
                    setTweet2('https://twitter.com/TwitterSupport/status/' + data.twitter_reply_id);
                }
                if (idx === 2 && data.twitter_reply_id) {
                    setTweet3('https://twitter.com/TwitterSupport/status/' + data.twitter_reply_id);
                }
            }
        }
    };

    const validateQuestions = () => {
        if (questions.length < 3) {
            return false;
        }

        for (let i = 0; i < 3; i++) {
            if (!questions[i] || !questions[i].review_url.trim()) {
                return false;
            }
        }

        // All validation passed
        return true;
    };

    const uploadQuiz = async () => {

        if (!quizTitle) {
            setErrMsg("Please Enter quiz title");
        } else if (!validateQuestions()) {
            setErrMsg("Please Enter three fact-brief URLs");
        } else if (!quizInfoText) {
            setErrMsg("Please Enter Quiz Info Text");
        } else if (!quizPublishDate) {
            setErrMsg("Please add a publish date");
        } else if (!quizImage) {
            setErrMsg("Please add a image");
        } else {
            setLoading(true);
            let weeklyQuizAPI = Utils.getAPIDescription('add-weekly-quiz');

            let body = {
                'quiz_title': quizTitle,
                'articleImage': quizImage,
                'quiz_info': quizInfoText,
                'published_on': quizPublishDate,
                'org_id': localStorage.getItem('org_id'),
                'end_page_text': endPageText,
                'end_page_link': endPageLink,
                'quiz_questions': questions,
                'status': 'published'
            };


            const result = await axios.post(weeklyQuizAPI, body).catch((error) => {
                console.log('Error while adding a quiz: ' + error)
            });
            setOpenModel(false);
            // console.log(std_tag_val);
            setQuizTitle(null);
            setQuizImage(null);
            setQuestions([{review_url: '', tweet_url: ''},
                {review_url: '', tweet_url: ''},
                {review_url: '', tweet_url: ''}]);
            setTweet1(null);
            setTweet2(null);
            setTweet3(null);
            setQuizPublishDate(null);
            setQuizInfoText(null);
            setQuizOrg(null);
            setEndPageLink(null);
            setEndPageText(null);
            setErrMsg(null);

            setTimeout(function () {
                fetchQuiz();
                setLoading(false);
            }, 2000);

            notification.success({
                message: 'Quiz created successfully!',
                duration: 3,
                placement: 'topRight',
                className: 'ca_toaster'
            });
        }
    };

    const openTab = (href, target = '_blank') => {
        const link = Object.assign(document.createElement('a'), { href, target });
        document.body.append(link);
        link.click();
        link.remove();
        console.log(`Opening: ${href}`);
    }

    const handlePreview = async () => {
        if (quizId) {
            let url =  process.env.REACT_APP_ENV === 'PROD' ? 'https://gigafact.org/quiz/' + quizId : 'https://dev.gigafact.org/quiz/' + quizId;
            window.open(url + "?preview=true", "_blank");
        }
        else {
            if (!quizTitle) {
                setErrMsg("Please Enter quiz title");
            } else if (!validateQuestions()) {
                setErrMsg("Please Enter three fact-brief URLs");
            } else if (!quizInfoText) {
                setErrMsg("Please Enter Quiz Info Text");
            } else if (!quizPublishDate) {
                setErrMsg("Please add a publish date");
            } else if (!quizImage) {
                setErrMsg("Please add a image");
            } else {
                setLoading(true);
                let weeklyQuizAPI = Utils.getAPIDescription('add-weekly-quiz');

                let body = {
                    'quiz_title': quizTitle,
                    'articleImage': quizImage,
                    'quiz_info': quizInfoText,
                    'published_on': quizPublishDate,
                    'org_id': localStorage.getItem('org_id'),
                    'end_page_text': endPageText,
                    'end_page_link': endPageLink,
                    'quiz_questions': questions,
                    'status': 'draft'
                };

                const result = await axios.post(weeklyQuizAPI, body).catch((error) => {
                    console.log('Error while adding a quiz: ' + error)
                });
                setOpenModel(false);
                // console.log(std_tag_val);
                setQuizTitle(null);
                setQuizImage(null);
                setQuestions([{review_url: '', tweet_url: ''},
                    {review_url: '', tweet_url: ''},
                    {review_url: '', tweet_url: ''}]);
                setTweet1(null);
                setTweet2(null);
                setTweet3(null);
                setQuizPublishDate(null);
                setQuizInfoText(null);
                setQuizOrg(null);
                setEndPageLink(null);
                setEndPageText(null);
                setErrMsg(null);

                // notification.success({
                //     message: 'Quiz created successfully!',
                //     duration: 3,
                //     placement: 'topRight',
                //     className: 'ca_toaster'
                // });

                // setTimeout(function () {
                //     console.log("HERE")
                //
                // }, 1500);

                setTimeout(function () {
                    const quiz_id = result.data.quiz_id;
                    let url =  process.env.REACT_APP_ENV === 'PROD' ? 'https://gigafact.org/quiz/' + quiz_id : 'https://dev.gigafact.org/quiz/' + quiz_id;
                    openTab(url + "?preview=true", "_blank");
                    fetchQuiz();
                    setLoading(false);
                }, 2000);
            }
        }


    };

    const editQuiz = async () => {
        if (!quizTitle) {
            setErrMsg("Please Enter quiz title");
        } else if (!validateQuestions()) {
            setErrMsg("Please Enter three fact-brief URLs");
        } else if (!quizInfoText) {
            setErrMsg("Please Enter Quiz Info Text");
        } else if (!quizPublishDate) {
            setErrMsg("Please add a publish date");
        } else if (!quizImage) {
            setErrMsg("Please add a image");
        } else {
            setLoading(true);
            let weeklyQuizAPI = Utils.getAPIDescription('add-weekly-quiz');
            const result = await axios.post(weeklyQuizAPI, {
                'quiz_id': quizId,
                'quiz_title': quizTitle,
                'articleImage': quizImage,
                'quiz_info': quizInfoText,
                'published_on': quizPublishDate,
                'org_id': localStorage.getItem('org_id'),
                'end_page_text': endPageText,
                'end_page_link': endPageLink,
                'quiz_questions': questions,
                'status': 'published'
            }).catch((error) => {
                console.log('Error while editing a quiz: ' + error)
                notification.error({
                    message: 'Error while editing a quiz',
                    duration: 3,
                    placement: 'topRight',
                    className: 'ca_toaster'
                });
            });

            notification.success({
                message: 'Quiz updated successfully!',
                duration: 3,
                placement: 'topRight',
                className: 'ca_toaster'
            });
            setLoading(false);
            setOpenModel(false);
            // console.log(std_tag_val);
            setQuizId(null);
            setQuizTitle(null);
            setQuizImage(null);
            setQuestions([{review_url: '', tweet_url: ''},
                {review_url: '', tweet_url: ''},
                {review_url: '', tweet_url: ''}]);
            setTweet1(null);
            setTweet2(null);
            setTweet3(null);
            setQuizPublishDate(null);
            setQuizInfoText(null);
            setQuizOrg(null);
            setEndPageLink(null);
            setEndPageText(null);
            setErrMsg(null);
            setTimeout(function () {
                fetchQuiz();
            }, 2000);
        }
    };

    const constructQuizLink = (quiz_id, status) => {
        // process.env.REACT_APP_ENV === 'PROD' ? copyToClipboard('https://gigafact.org/quiz/' + quiz_id) : copyToClipboard('https://dev.gigafact.org/quiz/' + quiz_id);
        let url =  process.env.REACT_APP_ENV === 'PROD' ? 'https://gigafact.org/quiz/' + quiz_id : 'https://dev.gigafact.org/quiz/' + quiz_id;
        if (status === 'draft')
            url = url + "?preview=true";
        window.open(url, "_blank");
    };

    const copyToClipboard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        notification.success({
            message: 'Quiz URL Copied',
            duration: 3,
            placement: 'topRight',
            className: 'ca_toaster'
        });
    };

    const deleteQuiz = async (quiz_id) => {
        if (quiz_id !== null) {
            let deleteQuizAPI = Utils.getAPIDescription('delete-weekly-quiz');
            let token = localStorage.getItem('repustar_token');
            const result = await axios.post(deleteQuizAPI, {'quiz_id': quiz_id}, {headers: {"Authorization": token}}).catch((error) => {
                console.log('Error while adding a quiz: ' + error)
            });
            notification.info({
                message: 'Quiz delete',
                duration: 3,
                placement: 'topRight',
                className: 'ca_toaster'
            });
            // console.log(std_tag_val);
            setTimeout(function () {
                fetchQuiz();
            }, 2000);
        }
    };

    const columns = [
        {
            title: 'Question',
            width: '70%',
            render: (record) => (
                <p><a href={record.review_url} target="_blank">{record.question}</a></p>
            )
        },
        {
            title: 'Answer',
            render: (record) => (
                <p>{record.answer}</p>
            )
        }
    ];

    return (
        <>
            <div className={styles.quizPage}>
                {loading ? <div className='text-center'><img alt={"Loading"} src={Loading} width='20px' className={styles.loader}/></div> : ''}
                <OrgBanner orgdetails={orgDetails}/>
                <div className={styles.quizContainer}>

                    <Modal centered={true} title={null} visible={showQuizInstallation}
                           onCancel={() => { setShowQuizInstallation(false) }} footer={[
                        <Button key="back" onClick={() => { setShowQuizInstallation(false) }} >
                            Close
                        </Button>
                    ]} width={700}>

                        <QuizInstallationGuide orgDetails={orgDetails} quizList={quizList}/>
                    </Modal>

                    <div className={styles.qzAddBtnOuter}>
                        <Button type="primary" className={styles.qzAddBtn} onClick={() => {
                            setOpenModel(true)
                        }}>Add Quiz</Button>
                        <Button icon={<AppstoreAddOutlined />} className={styles.qzAddBtn} onClick={()=> setShowQuizInstallation(true)}>
                            Installation Guide
                        </Button>
                    </div>
                    <div>
                        {((!quizList || quizList?.length === 0) && !loading) && <div className={styles.quizRowHeader} style={{display: "flex", justifyContent: "center", fontSize: "24px", marginTop: "60px"}}>No quizzes found. Add a new quiz to get started!</div>}
                        {quizList && quizList.map((item, index) => (
                            <>
                                <div className={styles.quizRowHeader}>
                                    <Row gutter={16} style={{justifyContent: "space-between", alignItems: "center", margin: 0}}>
                                        <div style={{display: "flex", gap: "50px"}}>
                                            <Statistic title="Quiz Title:"
                                                       value={item.quiz_title ? item.quiz_title : ''}/>
                                            <Statistic title="Publish Date:"
                                                       value={item.status === 'draft' ? '-' : item.published_on ? moment(item.published_on).format('LL') : ''}/>
                                            <Statistic title="Status:"
                                                       value={" " }
                                                       prefix={<Tag style={{fontSize: "20px", padding: "5px 10px"}} color={item.status === 'draft' ? '#c29131' : '#87d068'}>{item.status || "Published"}</Tag> }
                                                       valueStyle={{textTransform: "capitalize", color: item.status === 'draft' ? 'yellow' : 'green'}}/>
                                        </div>

                                        <div style={{display: "flex", gap: "10px"}}>

                                            <Button type="info" className="qz-delete-btn" onClick={() => {
                                                setQuizId(item.quiz_id);
                                                setQuizTitle(item.quiz_title);
                                                setQuizImage(item.articleImage);
                                                setQuestions(item.questions);
                                                setQuestion1(item.questions[0].review_url);
                                                setQuestion2(item.questions[1].review_url);
                                                setQuestion3(item.questions[2].review_url);
                                                setTweet1(item.questions[0].twitter_url);
                                                setTweet2(item.questions[1].twitter_url);
                                                setTweet3(item.questions[2].twitter_url);
                                                setQuizPublishDate(item.published_on);
                                                setQuizInfoText(item.quiz_info);
                                                setQuizOrg(item.org_info ? item.org_info.org_id : null);
                                                setQuizStatus(item.status || 'published')
                                                setEndPageLink(item.org_info ? item.org_info.end_page_link : null);
                                                setEndPageText(item.org_info ? item.org_info.end_page_text : null);
                                                setOpenModel(true);
                                            }}>Edit Quiz</Button>


                                            <Button type="danger" className="qz-delete-btn" onClick={() => {setQuizDelId(item.quiz_id); setOpenDelModel(true);} }>Delete Quiz</Button>


                                            <Button type="primary" className="qz-delete-btn" onClick={() => {constructQuizLink(item.quiz_id, item.status);} }>Open Quiz</Button>

                                        </div>

                                    </Row>
                                </div>

                                <div className={styles.tablStyl} style={{border: '1px solid #197aba'}}>
                                    <Table pagination={false} dataSource={item.questions} columns={columns}/>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>


            <Modal
                title={quizId === null ? "Create a new quiz" : "Edit Quiz"}
                className={"add-quiz-modal"}
                centered
                closable={false}
                visible={openModel}
                footer={<>
                    <span className={styles.tagSelectWrapper}/>
                    <Button onClick={() => {
                        setOpenModel(false);
                        setQuizId(null);
                        setQuizTitle(null);
                        setQuizImage(null);
                        setQuestion1(null);
                        setQuestion2(null);
                        setQuestion3(null);
                        setQuestions([{review_url: '', tweet_url: ''},
                            {review_url: '', tweet_url: ''},
                            {review_url: '', tweet_url: ''}]);
                        setTweet1(null);
                        setTweet2(null);
                        setTweet3(null);
                        setQuizPublishDate(null);
                        setQuizInfoText(null);
                        setQuizOrg(null);
                        setEndPageLink(null);
                        setEndPageText(null);
                        setErrMsg(null);
                    }}>Cancel</Button>

                    <Button onClick={() => handlePreview()}>Preview</Button>

                    {quizId === null ? <Button type="primary" onClick={() => {
                            uploadQuiz();
                        }}>Publish</Button> :
                        <Button type="primary" onClick={() => {
                            editQuiz();
                        }}>{quizStatus === 'draft' ? "Publish" : "Update"}</Button>}
                </>}
                width={800}
            >
                <div>
                    {errMsg ? <p style={{color: "red"}}>{errMsg}</p> : null}
                    <div className={styles.qzModelTxt}>
                        <span className={styles.qzModelTxt}><b>Quiz Title: </b>&nbsp;&nbsp; <Input placeholder="Quiz Title"
                                                                                                   className={styles.quizInputBox}

                                                                                              defaultValue={null}
                                                                                              value={quizTitle}
                                                                                              onChange={(value) => handleTitleChange(value)}/></span>
                    </div>
                    <div className={styles.qzModelTxt}>
                        <span className={styles.qzModelTxt}><b>Questions: </b></span>
                        {questions.map((question, index) => (
                            <div key={index}>
                          <span className={styles.qzModelTxt} style={{display: 'inline-block'}}>
                              {index + 1}. <SearchInput placeholder={"Search for a brief or enter Gigafact fact brief URL"}
                                                        style={{width: '95%'}}
                                                        value={(!question|| !question.review_url.trim()) ? null : question.review_url}
                                                        onChange={(value) => {handleQuestionChange(value, index)}}
                                                        initialOptions={briefsList}
                              />
                            {/*{index + 1}. <Input placeholder="Enter Gigafact fact brief URL" style={{width: '95%'}}*/}
                            {/*                    className={styles.quizInputBox}*/}
                            {/*                    value={question.review_url}*/}
                            {/*                    onChange={(value) => handleQuestionChange(value, index)}/>*/}
                          </span>
                                {/*<span className={styles.qzModelTxt} style={{ display: 'inline-block' }}>*/}
                                {/*    &nbsp;&nbsp;&nbsp; <Input placeholder="Flag Tweet URL" style={{ width: '90%' }} value={question.tweet_url} onChange={(value) => handleTweetURLChange(value, index)} />*/}
                                {/*</span>*/}
                            </div>
                        ))}
                        {/* Logic to add more questions */}
                        {/*TODO: Uncomment  when quiz client is implemented for dynamic questions */}
                        {/*<div style={{textAlign: "center"}}>*/}
                        {/*  <Button onClick={addQuestion} type={"dashed"}>Add Another Question</Button>*/}
                        {/*</div>*/}
                    </div>
                    <div className={styles.qzModelTxt}>
                        <span className={styles.qzModelTxt}><b>Publish Date: </b>&nbsp; <DatePicker onChange={onDateChange}
                                                                                                    className={styles.quizDatepicker}
                                                                                               style={{width: '100%'}}
                                                                                               value={quizPublishDate !== null ? moment(quizPublishDate) : null}/></span>
                    </div>

                    <div className={styles.qzModelTxt}>
                        <span className={styles.qzModelTxt}><b>Image:</b></span>
                        <span
                            className={`${styles.qzModelTxt} select-cutom`}>{/*<Input placeholder="Quiz Image URL" style={{ width: '75%' }}  defaultValue={null} onChange={(value) =>setQuizImage(value.target.value)}/>*/}
                            <Select placeholder="Select Status" style={{width: '100%'}} value={quizImage}
                                    className={styles.quizSelectBox}
                                    onChange={(value) => setQuizImage(value)}>
                            <Option value={null}>Select Image</Option>
                                    {topicImagesList ? topicImagesList.map((item, idx) => <Option
                                        value={item.image_url.replace('(', '%28').replace(')', '%29')} key={`image_option_${idx}`}><img
                                        key={`image_${idx}`}
                                        src={item.image_url.replace('(', '%28').replace(')', '%29')} alt='cate_image'
                                        className={styles.topicImageCont}/> &nbsp;&nbsp; <span
                                        style={{color: 'black'}}>{item.name}</span></Option>) : null}
                      </Select>
                    </span>
                    </div>
                    {/*<div className={styles.qzModelTxt}>*/}
                    {/*    <span className={styles.qzModelTxt}><b>Organization:</b></span>*/}
                    {/*    <span className={styles.qzModelTxt}>*/}
                    {/*  <Select placeholder="Select Status" style={{width: '75%'}} value={quizOrg}*/}
                    {/*          onChange={(value) => setQuizOrg(value)}>*/}
                    {/*    <Option value={null}>Select Organization</Option>*/}
                    {/*      {orgsList ? orgsList.map(item => <Option value={item.page_id}> <span*/}
                    {/*          style={{color: 'black'}}>{item.org_name}</span></Option>) : null}*/}
                    {/*  </Select>*/}
                    {/*</span>*/}
                    {/*</div>*/}

                    {/*<div className={styles.qzModelTxt}>*/}
                    {/*    <span className={styles.qzModelTxt}><b>End Page Text: </b>&nbsp;&nbsp; <Input*/}
                    {/*        className={styles.quizInputBox}*/}
                    {/*        placeholder="End page text" style={{width: '75%'}} defaultValue={null} value={endPageText}*/}
                    {/*        onChange={(value) => handleEndTextChange(value)}/></span>*/}
                    {/*</div>*/}

                    <div className={styles.qzModelTxt}>
                        <span className={styles.qzModelTxt}><b>Newsletter sign up link: </b>&nbsp;&nbsp; <Input
                            className={styles.quizInputBox}
                            placeholder="Provide the newsletter link" style={{width: '100%'}} defaultValue={null} value={endPageLink}
                            onChange={(value) => handleEndLinkChange(value)}/></span>
                    </div>

                    <div className={styles.qzModelTxt}>
                        <span className={styles.qzModelTxt}><b>Quiz introduction blurb: </b>&nbsp;&nbsp;</span>
                        <span className={styles.qzModelTxt} style={{display: 'inline-block'}}>
                    <TextArea rows={4} placeholder="Quiz Text" style={{width: '100%'}} defaultValue={null}
                              className={styles.quizInputBox}
                              value={quizInfoText} onChange={(value) => handleInfoTextChange(value)}/></span>
                    </div>


                </div>
            </Modal>

            <Modal
                title="Delete Quiz"
                centered
                closable={false}
                visible={openDelModel}
                footer={<>
                    <span className={styles.tagSelectWrapper}/>
                    <Button onClick={() => {
                        setOpenDelModel(false);
                    }}>Cancel</Button>
                    <Button type="danger" onClick={() => {
                        setOpenDelModel(false);
                        deleteQuiz(quizDelId);
                        setQuizDelId(null);
                    }}>Delete</Button>
                </>}
                width={400}
            >
                <div>
                    <div className={styles.qzModelTxt}>
                        <span className={styles.qzModelTxt}><b>Are you sure you want to delete this quiz?</b></span>
                    </div>
                </div>
            </Modal>
        </>
    )

}

