// Network.js
import React, { useState, useEffect } from 'react';
import { Table, Spin, Tag, Menu, Button, notification, Input, Tabs } from 'antd';
import { EditOutlined, UserOutlined, PartitionOutlined } from '@ant-design/icons';
import axios from 'axios';
import Utils from '../../utils/utils';
import OrgBanner from '../../components/OrgBanner/orgbanner';
import ServicesModal from '../../components/ServicesModal/ServicesModal';
import './network.scss';
import moment from 'moment-timezone';
import styles from "../ServicesModal/ServicesModal.module.scss";
import Users from "../users/users";
import {useHistory} from "react-router-dom";

const { Search } = Input;


const tabItems = [
  {
    key: 'orgs_list',
    label: 'Network',
  },
  {
    key: 'users_list',
    label: 'All Users',
  },
];

const OrgsList = ({handleSearch, searchInput, filteredOrgStatus, orgStatus, isLoading, columns}) => {
  const history = useHistory();
  return (<div className={"org-list-container"}>
    <div className="publish-header">
      {/*<span>Gigafact network admin</span>*/}

      <div
          style={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <span>Search by Organization's name</span>
        <Search
            placeholder="Search users"
            allowClear
            onChange={handleSearch}
            style={{ width: 300, marginRight: 10 }}
        />
      </div>

      <Button
          type="primary"
          onClick={() => {history.push("/partnerconsole/create-new-organization")}}
          size={"large"}
      >
        Add New Organisation
      </Button>
      {/*<Search*/}
      {/*    placeholder="Search by organization name or slug"*/}
      {/*    allowClear*/}
      {/*    enterButton="Search"*/}
      {/*    size="large"*/}
      {/*    onChange={(e) => handleSearch(e.target.value)}*/}
      {/*    style={{ width: 400, marginLeft: 20 }}*/}
      {/*/>*/}
    </div>
    {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', height: '50vh', alignItems: 'center' }}>
          <Spin size="large" />
        </div>
    ) : (
        <Table columns={columns} dataSource={searchInput ? filteredOrgStatus : orgStatus} rowKey="page_id" scroll={true}/>
    )}
  </div>)
};


const Network = () => {
  const [orgDetails, setOrgDetails] = useState(null);
  const [orgStatus, setOrgStatus] = useState([]);
  const [filteredOrgStatus, setFilteredOrgStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [orgPreview,setOrgPreview] = useState({})
  const [services, setServices] = useState({
    factbrief_service: false,
    claimsdb_service: false,
    drafterai_service: false
  });
  const [selfPublishStatus, setSelfPublishStatus] = useState(false);

  useEffect(() => {
    if (refresh) {
      getOrgsInfo();
      const api = Utils.getAPIDescription('get-org-list-updated');
      axios
        .get(api)
        .then((res) => {
          const data = res.data.orgs;
          setOrgStatus(data);
          setFilteredOrgStatus(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setRefresh(false);
  }, [refresh]);

  const getOrgsInfo = () => {
    const api = `${Utils.getAPIDescription('get-org-details')}?org_id=${localStorage.getItem('org_id')}`;
    axios
      .get(api)
      .then((res) => {
        if (res.data?.data) {
          setOrgDetails(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openServicesModal = (org) => {
    setOrgPreview(org)
    setSelectedOrgId(org.page_id);
    setServices({
      factbrief_service: org.services?.includes('factbrief_service') || false,
      claimsdb_service: org.services?.includes('claimsdb_service') || false,
      drafterai_service: org.services?.includes('drafterai_service') || false
    });
    setSelfPublishStatus(org.self_publish_status || false);
    setShowModal(true);
  };

  const handleServicesConfirm = async () => {

    if (window.confirm("Are you sure you want to make this change?")) {
      const activeServices = Object.entries(services)
          .filter(([_, isEnabled]) => isEnabled)
          .map(([service]) => service);

      const requestBody = {
        org_id: selectedOrgId,
        self_publish_status: selfPublishStatus,
        services: activeServices
      };

      const api = Utils.getAPIDescription('update-org');
      try {
        const response = await axios.post(api, requestBody);
        if (response.status === 200) {
          notification.success({ message: 'Organization Updated Successfully', duration: 3, placement: 'topRight' });
          setShowModal(false);
          setRefresh(true);
        } else {
          console.log(response.status);
        }
      } catch (error) {
        console.log(error);
      }
    }

  };

  const handleServiceChange = (serviceName, checked) => {
    setServices(prev => ({ ...prev, [serviceName]: checked }));
  };

  const actionsMenu = (org) => (
    <Menu>
      <Menu.Item key="services" onClick={() => openServicesModal(org)}>
        Services
      </Menu.Item>
    </Menu>
  );

  const debounceSearch = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = debounceSearch((e) => {
    setSearchInput(e.target.value);
    const filteredData = orgStatus.filter(
      (org) =>
        org.org_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        org.slug.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredOrgStatus(filteredData);
  }, 500);

  const serviceLabels = {
    factbrief_service: { label: 'Fact brief Service', color: '#108ee9' },
    claimsdb_service: { label: 'Parser Service', color: 'orange' },
    drafterai_service: { label: 'Drafter AI Service', color: 'green' },
    self_publish: { label: 'Fact brief Service (Self publish)', color: '#108ee9' }
  };

  const orderedServices = ['factbrief_service', 'claimsdb_service', 'drafterai_service'];

  const columns = [
    {
      title: 'Organization Logo',
      // dataIndex: 'page_id',
      key: 'page_id',
      render: (text, record) => (
        <div className={styles['org-logo']}>
          <img src={record?.logoURL} alt="org logo" className={styles['org-logo-img']} />
        </div>
      )
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Organization Name',
      dataIndex: 'org_name',
      key: 'org_name',
    },
    {
      title: 'Services Enabled',
      dataIndex: 'services',
      key: 'services',
      render: (services, record) => {
        // Initialize an array to hold the tags
        let tags = [];

        // Check if services is an array and map over it to create tags
        if (Array.isArray(services)) {

          const sortedServices = orderedServices.filter(service => services.includes(service));

          tags = sortedServices.map(service => {
            let label = serviceLabels[service]?.label || service;
            if (service === 'factbrief_service' && record?.self_publish_status) {
              label = 'Fact brief Service (Self publish)';
            }
            return (

                  <Tag style={{fontSize: "0.9rem", padding: "3px 5px", borderRadius: "12px", textAlign: "center"}} key={service} color={serviceLabels[service]?.color || 'default'}>
                    {label}
                  </Tag>

            );
          });
        }

        return <div style={{display: "flex", flexDirection: "column", gap: "10px", width: "fit-content"}}>{tags}</div>;
      }
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment.utc(text).local().format('MM/DD/YYYY hh:mm A'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        // <Dropdown overlay={actionsMenu(record)} trigger={['click']}>
          <Button style={{border: "none"}} icon={<EditOutlined style={{fontSize: "24px"}} />} onClick={() => openServicesModal(record)} size={"middle"}/>
        // </Dropdown>
      ),
    },
  ];

  const onCancelModal = () => {
    if(window.confirm("Are you sure you want to go back? Unsaved changes will be lost")){
      setShowModal(false)
    }
  };

  return (
    <div className="dashboard-container">
      <div className="org-tipline-admin">
        <OrgBanner orgdetails={orgDetails} />

      </div>
      <ServicesModal
        orgPreview={orgPreview}
        visible={showModal}
        services={services}
        selfPublishStatus={selfPublishStatus}
        onCancel={onCancelModal}
        onConfirm={handleServicesConfirm}
        onServiceChange={handleServiceChange}
        onSelfPublishChange={(checked) => setSelfPublishStatus(checked)}
      />


      <div className="app-container">
        <Tabs
            type="card"
            defaultActiveKey="orgs_list"
            size={"large"}
            style={{paddingTop: "15px"}}
        >

          {tabItems.map((item, idx) => {
            console.log(idx)
            return (
                <Tabs.TabPane tab={<span style={{display: "flex", alignItems: "center"}}>
                                    {item.key === 'orgs_list' && <PartitionOutlined />}
                                    {item.key === 'users_list' && <UserOutlined />}
                                    {item.label}
                                  </span>
                              }
                              key={item.key}>
                  {item.key === 'orgs_list' && <OrgsList handleSearch={handleSearch}
                                                        searchInput={searchInput}
                                                        filteredOrgStatus={filteredOrgStatus}
                                                        isLoading={isLoading}
                                                        orgStatus={orgStatus}
                                                        columns={columns}/> }
                  {item.key === 'users_list' && <Users /> }
                </Tabs.TabPane>);
          })}
        </Tabs>



        {/*{tabItems.map((idx, item) => {*/}
        {/*  */}
        {/*})}*/}

      </div>
    </div>
  );
};

export default Network;