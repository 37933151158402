import React, {useEffect, useState, useRef} from 'react';
import Triangle from '../../../assets/images/triangle.png';
import "./tooltip.css";

export default function Tooltip(props) {
    const tipRef = useRef(null);
    const [tip, setTip] = useState(false);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutSide);
        return () => {
            //Component unmount codes need to be added here
            document.removeEventListener("mousedown", handleClickOutSide);
        }

    }, []);

    useEffect(() => {
        if(props.content){setTip(true)};
    }, [props.content]);

    const handleClickOutSide = (event) => {
        if (tipRef.current && !tipRef.current.contains(event.target)) {
          props.hidetip();
        }
    }

    
    return (
        <div className="tooltip-panel" ref={tipRef}>
            <div>
                <img src={Triangle} className="triangle"/>
            </div>
            <div className="tooltip-content" dangerouslySetInnerHTML={{__html: props.content}}></div>
        </div>
    )
}

