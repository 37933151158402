import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Utils from '../../../../utils/utils';
import Loading from '../../../../assets/images/loader.gif';
import './my-account.scss';
import { notification, Popover, Modal, Button, Input, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import S3 from 'react-aws-s3';


const bucketName = 'gigafact-app-images';
const folderName = 'resources';

const config = {
    bucketName: bucketName,
    dirName: folderName,
    region: 'us-east-2',
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
};


export default function MyAccount(props) {
    const userId = localStorage.getItem('user_id');
    const [isAdmin, setIsAdmin] = useState(false);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState({});
    const [isfNameDisabled, setIsfNameDisabled] = useState(true);
    const [islNameDisabled, setIslNameDisabled] = useState(true);
    const [isusernameDisabled, setIsusernameDisabled] = useState(true);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [orgDes, setOrgDes] = useState('');
    const [userName, setUserName] = useState('');
    const [orgName, setOrgName] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [redditUrl, setRedditUrl] = useState('');
    const [fbUrl, setFbUrl] = useState('');
    const [instaUrl, setInstaUrl] = useState('');
    const [linkedInUrl, setlinkedInUrl] = useState('');
    const [webUrl, setWebUrl] = useState('');
    const [isDesDisabled, setIsDesDisabled] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [orgFile, setOrgFile] = useState(null);
    const [orgLogo, setOrgLogo] = useState(null);
    const [dpFile, setDpFile] = useState(null);
    const [dpUrl, setDpUrl] = useState(null);
    const [logo1, setLogo1] = useState(null);
    const [logo2, setLogo2] = useState(null);
    const [link, setLink] = useState(null);
    const [logo1Loading, setLogo1Loading] = useState(false);
    const [logo2Loading, setLogo2Loading] = useState(false);
    //initial setup modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [errors, setErrors] = useState(null);
    const [modalFormLoading, setmodalFormLoading] = useState(false);

    const [isCPAdmin, setIsCPAdmin] = useState(false);


    useEffect(() => {
        getDetails();
        setEmail(localStorage.getItem('email'));
        setIsAdmin(localStorage.getItem('user_types').includes('org_admin'));
        setIsCPAdmin(localStorage.getItem('user_types').includes('content_partner_admin'));
        setUserName(localStorage.getItem('username'));
        setDpUrl(localStorage.getItem('profile_img'));
        setFname(localStorage.getItem('firstname'));
        setLname(localStorage.getItem('lastname'));
        setIsModalVisible(!!localStorage.getItem('initial_setup'))
    }, []);
    

    const getDetails = () => {
        let api = Utils.getAPIDescription('get-org-details') + '?org_id=' + localStorage.getItem('org_id');
        axios.get(api)
            .then((res) => {
                let data = res.data.data;
                if (data) {
                    console.log(data);
                    setOrgName(data.org_name ? data.org_name : '');
                    setOrgDes(data.description ? data.description : '');
                    setLogo1(data.logoURL ? data.logoURL : '');
                    setLogo2(data.tiplineLogoURL ? data.tiplineLogoURL : '');

                    if (data && data.links && data.links.Twitter) setTwitterUrl(data.links.Twitter);
                    if (data && data.links && data.links.Reddit) setRedditUrl(data.links.Reddit);
                    if (data && data.links && data.links.Facebook) setFbUrl(data.links.Facebook);
                    if (data && data.links && data.links.Instagram) setInstaUrl(data.links.Instagram);
                    if (data && data.links && data.links.Linkedin) setlinkedInUrl(data.links.Linkedin);
                    if (data && data.links && data.links.Website) setWebUrl(data.links.Website);
                }

            })
    };
    
    const passwordChange = (e) => {
        if (e.target.name === 'des')
            setOrgDes(e.target.value);
    };
    
    const onProfileUpload = (e, type) => {
        // console.log(e.target.files[0].type.includes('image'));
        if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
            if (e.target.files[0].size / 1024 / 1024 < 3) {
                encodeBase64(e.target.files[0], type);
            }
            else {
                alert("Image must be smaller than 3MB!");
            }
        }
        else {
            alert("You can only upload JPG/PNG file!");
        }
    };

    const encodeBase64 = (file, type) => {
        let reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                let Base64 = reader.result;
                if (type === 'profile-pic') setDpFile(Base64);
                if (type === 'org-logo') setOrgFile(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    }

    useEffect(() => {
        if (dpFile) {
            uploadImage('profile');
        }
    }, [dpFile])

    useEffect(() => {
        if (orgFile) {
            uploadImage('logo');
        }
    }, [orgFile]);

    const [logoUpdated, setLogoUpdated] = useState(false);
    useEffect(() => {
        if (logoUpdated) {
            updateOrganization();
            setLogoUpdated(false);
        }
    }, [logo1, logo2, logoUpdated]);

    const uploadImage = (type) => {
        let api = Utils.getAPIDescription('update-org') + '?action=upload&type=' + type;
        let body = {
            "image_encoded": type === 'profile' ? dpFile.split('base64,')[1] : orgFile.split('base64,')[1],
            "org_id": localStorage.getItem('org_id'),
            "reviewer_id": localStorage.getItem('user_id')
        }
        if (type === 'profile') {
            setDpUrl(Loading);
        }
        else {
            setOrgLogo(Loading);
        }
        axios.post(api, body)
            .then((res) => {
                if (res.data.message) {
                    if (type === 'profile') {
                        setDpUrl(res.data.image_url);
                    }
                    else {
                        setOrgLogo(res.data.image_url);
                    }
                }
            }).catch((err) => {
                console.log(err);
                alert('something went wrong!');
                if (type === 'profile') {
                    setDpUrl(null);
                }
                else {
                    setOrgLogo(null);
                }
            })
    }

    const updateDes = () => {
        setIsDesDisabled(!isDesDisabled);
        //message.loading({ content: 'Updating...', key });
        if (!isDesDisabled) {
            setApiLoading(true);
            let api = Utils.getAPIDescription('update-org');
            axios.post(api, { "org_id": localStorage.getItem('org_id'), "description": orgDes, "links": {} })
                .then(() => {
                    getDetails();
                    setApiLoading(false);
                    notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight', });
                });
        }
    }
    const onfNameClick = () => {
        setIsfNameDisabled(!isfNameDisabled);
        if (!isfNameDisabled) {
            let api = Utils.getAPIDescription('update-user');
            axios.post(api, { userid: localStorage.getItem('user_id'), firstname: fname })
                .then((res) => {
                    //console.log(res);
                    localStorage.setItem('firstname', fname);
                    notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight', });
                }).catch((err) => {
                    console.log(err);
                })
        }
    }
    const onlNameClick = () => {
        setIslNameDisabled(!islNameDisabled);
        if (!islNameDisabled) {
            let api = Utils.getAPIDescription('update-user');
            axios.post(api, { userid: localStorage.getItem('user_id'), lastname: lname })
                .then((res) => {
                    //console.log(res);
                    localStorage.setItem('lastname', lname);
                    notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight', });
                }).catch((err) => {
                    console.log(err);
                })
        }
    }
    const onuserNameClick = () => {
        setIsusernameDisabled(!isusernameDisabled);
        if (!isusernameDisabled) {
            let api = Utils.getAPIDescription('update-user');
            axios.post(api, { userid: localStorage.getItem('user_id'), username: userName })
                .then((res) => {
                    //console.log(res);
                    localStorage.setItem('username', userName);
                    notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight', });
                }).catch((err) => {
                    console.log(err);
                })
        }
    }

    const onNameChange = (e) => {
        if (e.target.name === 'fname') {
            setFname(e.target.value);
        }
        if (e.target.name === 'lname') {
            setLname(e.target.value);
        }
        if (e.target.name === 'username') {
            setUserName(e.target.value);
        }
    }
    async function fileUpload(e, type) {
        const file = e.target.files[0];

        let filename = `${(localStorage.org_name).replaceAll(" ", "_")}_${type}_${new Date().getTime()}.${(file.name.split(".").reverse()[0])}`;

        if (type === 'logo1') setLogo1Loading(true);
        if (type === 'logo2') setLogo2Loading(true);

        try {
            const ReactS3Client = new S3(config);

            ReactS3Client.uploadFile(file, filename).then((data) => {
                if (type === 'logo1') {
                    setLogo1Loading(false);
                    setLogo1(data.location);
                    setLogoUpdated(true);
                }
                if (type === 'logo2') {
                    setLogo2Loading(false);
                    setLogo2(data.location);
                    setLogoUpdated(true);

                }
            }).catch(err => {
                console.log(err);
                alert('Something went wrong')
            });
            
            // let fileKey = await UploadFile(filename, file, options);
            //
            // if (type === 'logo1') {
            //     setLogo1Loading(false);
            //     setLogo1(process.env.REACT_APP_S3_URL + "/public/" + filename)
            // }
            // if (type === 'logo2') {
            //     setLogo2Loading(false);
            //     setLogo2(process.env.REACT_APP_S3_URL + "/public/" + filename)
            //
            // }
            // console.log(fileKey);
        } catch (error) {

        }
        // try {
        //   await Storage.put(file.name, file, {
        //     contentType: "image/png", // contentType is optional
        //   });
        // } catch (error) {
        //   console.log("Error uploading file: ", error);
        // }
    }
    // const validateModalForm = () => {
    //     let error = {}
    //     if (!logo1 && modalSubmitted) error.logo1 = "Logo 1 is Required"
    //     if (!logo2 && modalSubmitted) error.logo2 = "Logo 2 is Required"
    //     if (!link && modalSubmitted) {
    //         error.link = "Link Required"
    //     }
    //     setErrors(error);
    //     setModalSubmitted(false);
    // }
    const updateOrganization = (e) => {
        if (e) {
            e.preventDefault();
            setmodalFormLoading(true);
        }
        // alert(Object.keys(errors).length > 0)
        // // if (Object.keys(errors).length > 0) return
        let api = Utils.getAPIDescription('update-org');
        let payload = {
            logo1: logo1,
            logo2: logo2,
            links: { website: link },
            org_id: localStorage.getItem('org_id')
        }
        axios.post(api, payload)
            .then(() => {
                getDetails();
                notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight', });
                setmodalFormLoading(false);
                setIsModalVisible(false);
                localStorage.removeItem('initial_setup');
            }).catch(err => {
                console.log(err);
                setmodalFormLoading(false);

            });
    }
    return (
        <div>
            <div className='mt-5 mb-2 fn-14'>Email</div>
            <div className='fn-18 my-2'>{email}</div>
            <Link to="/password-reset"><button className='blue-btn mt-3'>Change Password</button></Link>
            <div className="rev-name-box-top">
                <div className="mt-3">
                    <div className="name-div-profile">
                        <div className="fn-14">First Name:
                            <input className="password-inp colw-90 fn-16" name="fname" disabled={isfNameDisabled} value={fname} onChange={onNameChange} />
                        </div>
                        <div className="align-self-end">
                            <button className='blue-btn ml-3' onClick={onfNameClick}>{isfNameDisabled ? 'EDIT' : 'SAVE'}</button>
                        </div>
                    </div>
                    <div className="name-div-profile mt-3">
                        <div className="fn-14" >Last Name:
                            <input className="password-inp colw-90 fn-16" name="lname" disabled={islNameDisabled} value={lname} onChange={onNameChange} />
                        </div>
                        <div className="align-self-end">
                            <button className='blue-btn ml-3' onClick={onlNameClick}>{islNameDisabled ? 'EDIT' : 'SAVE'}</button>
                        </div>
                    </div>
                </div>
                {!Utils.checkContentPartnerWriter() && <div className='upload-section'>
                    <div>
                        {dpUrl && <img src={dpUrl + ('?v=' + Date.now())} className='profile-pic' />}
                    </div>
                    <div>
                        <div className='fn-14'>Reviewer Profile Image</div>
                        <input id="profile-pic" type="file" onChange={(e) => onProfileUpload(e, 'profile-pic')} style={{ display: "none" }} accept="image/*" />
                        <button className='blue-btn mt-2' onClick={() => document.getElementById("profile-pic").click()}>UPLOAD IMAGE</button>
                    </div>
                </div>}

            </div>

            {/* <div className='mt-4 fn-14'>Password</div>
            <input name='password' className='password-inp' type='password' value={password} disabled={isPassDisabled} onChange={passwordChange} />
             */}


            <div className='rev-name-box mt-5'>
                <div>
                    <div className='fn-14'>Organization Name</div>
                    <div className='mt-2 fn-18'>{orgName}</div>
                </div>

                {isAdmin && <div className=''>
                    {/* <div>
                        <span>{isAdmin}</span>
                        {orgLogo && <img src={orgLogo + ('?v=' + Date.now())} className='org-pic' />}
                    </div> */}
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className='fn-14 d-flex'>Logo 1 &nbsp;
                                <Popover
                                content={<><div>File formats : JPG, JPEG, PNG</div><div>(Maxium upload file size: 2 mb).</div></>}
                                title={null}
                                trigger="click"
                                >
                                    <QuestionCircleOutlined />
                                </Popover>
                            </div>
                            <input id="logo1" type="file" onChange={(e) => fileUpload(e, 'logo1')} style={{ display: "none" }} accept="image/*" />
                            <button className='blue-btn mt-2' onClick={() => document.getElementById("logo1").click()}>UPLOAD IMAGE</button>
                            <div className='org-logo p-3'>
                                {logo1 && <img src={logo1 + ('?v=' + Date.now())} className='org-pic' />}
                            </div>
                        </div>
                        <div>
                            <div className='fn-14 d-flex'>Logo 2   &nbsp;<Popover
                                content={<><div>File formats : JPG, JPEG, PNG</div><div>(Maxium upload file size: 5 mb).</div></>}
                                title={null}
                                trigger="click"
                            >
                                <QuestionCircleOutlined />
                            </Popover></div>
                            <input id="logo2" type="file" onChange={(e) => fileUpload(e, 'logo2')} style={{ display: "none" }} accept="image/*" />
                            <button className='blue-btn mt-2' onClick={() => document.getElementById("logo2").click()}>UPLOAD IMAGE</button>
                            <div className='org-logo p-3'>
                                {logo2 && <img src={logo2 + ('?v=' + Date.now())} className='org-pic' style={{height: '60px', width: 'auto'}}/>}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
            {isCPAdmin && <><div className='fn-14 my-3'>Organization: Brief Description</div>
                <div className='org-des-container'>
                    <textarea name='des' className='des-area' value={orgDes} onChange={passwordChange} disabled={isDesDisabled}/>
                    <button className='blue-btn ml-3' style={{height: 'fit-content'}} onClick={updateDes}>{!isDesDisabled ? 'Save' : 'Edit'}</button>{apiLoading && <img className='ml-2' src={Loading} width='30px' height='31px'/>}
                </div>

            </>}
            {/* {isAdmin && <div className='colw-50 mt-5'>
                <SocialRow img={Web} url={webUrl} payload={'website'} getDetails={getDetails} />
                <SocialRow img={Twitter} url={twitterUrl} payload={'twitter'} getDetails={getDetails} />
                <SocialRow img={Reddit} url={redditUrl} payload={'reddit'}  getDetails={getDetails} />
                <SocialRow img={FB} url={fbUrl} payload={'facebook'} getDetails={getDetails} />
                <SocialRow img={LinkedIn} url={linkedInUrl} payload={'linkedin'} getDetails={getDetails} />
                <SocialRow img={InstaImg} url={instaUrl} payload={'instagram'} getDetails={getDetails} />
            </div>} */}
            <Modal title={null} visible={isModalVisible} closable={false} footer={null}>
                <div className="initial_setup_container">
                    <div className="top-bar">
                        <div className="heading">
                            Welcome!
                        </div>
                        <div className="sub-heading">
                            Please complete your profile.
                        </div>
                    </div>
                    <div className="body-content">
                        <form onSubmit={(e) => { updateOrganization(e) }}>
                            <div className="input-block">
                                <div className="label">
                                    Logo 1 &nbsp; <Popover
                                        content={<><div>File formats : JPG, JPEG, PNG</div><div>(Maxium upload file size: 128 mb).</div></>}
                                        title={null}
                                        trigger="click"
                                    >
                                        <QuestionCircleOutlined />
                                    </Popover>
                                </div>
                                <div className="button-block">
                                    <div className="upload-btn">
                                        <Button onClick={() => document.getElementById("logo1").click()} loading={logo1Loading}>Upload Image</Button>

                                    </div>
                                    <div className="preview-block">
                                        {logo1Loading ? <Spin size="small" /> :
                                            <img src={logo1} alt="" />}
                                    </div>

                                </div>
                                <div className="error-block">
                                    {errors && errors.logo1 && <>{errors.logo1}</>}
                                </div>
                            </div>
                            <div className="input-block">
                                <div className="label">
                                    Logo 2 &nbsp; <Popover
                                        content={<><div>File formats : JPG, JPEG, PNG</div><div>(Maxium upload file size: 128 mb).</div></>}
                                        title={null}
                                        trigger="click"
                                    >
                                        <QuestionCircleOutlined />
                                    </Popover>
                                </div>
                                <div className="button-block">
                                    <div className="upload-btn">
                                        <Button onClick={() => document.getElementById("logo2").click()} loading={logo2Loading}>Upload Image</Button>
                                    </div>
                                    <div className="preview-block">

                                        {logo2Loading ? <Spin size="small" /> :
                                            <img src={logo2} alt="" />}
                                    </div>
                                </div>
                                <div className="error-block">
                                    {errors && errors.logo2 && <>{errors.logo2}</>}

                                </div>
                            </div>
                            <div className="input-block">
                                <div className="label">
                                    Website URL*
                                </div>
                                <div className="input">
                                    <div className="web-link">
                                        <Input placeholder="Enter website URL" value={link} onChange={(e) => { setLink(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="error-block">
                                    {errors && errors.link && <>{errors.link}</>}

                                </div>
                            </div>
                            <div className="submit-block">
                                <div className="btn">
                                    <div className="submit">
                                        <Button htmlType='submit' loading={modalFormLoading}>Submit</Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
