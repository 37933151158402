import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { notification, Select } from 'antd';
import { Link, useHistory } from "react-router-dom";
import Utils from '../../../../utils/utils';
import axios from 'axios';
import Loading from '../../../../assets/images/loader.gif';

export default function CPUpdateEditor(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [reqErr, setReqErr] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [role, setRole] = useState('content_partner');
    const { Option } = Select;
    const user_id = Utils.getParameterByName("userId");

    useEffect(() => {
        if (localStorage.getItem('user_types').includes('org_admin')) {
            setIsAdmin(true);
        }
        getUserDetails();
    }, [])

    const handleOnChange = (e) => {
        switch (e.target.name) {
            case "fname":
                setFirstName(e.target.value)
                break;
            case "lname":
                setLastName(e.target.value)
                break;
        }
    }

    const getUserDetails = () => {
        setIsLoading(true);
        let api = Utils.getAPIDescription('get_user_details') + "?userId=" + user_id;
        let config = {
            headers: {
                email: localStorage.getItem('email')
            }
        }
        axios.get(api, config).then((res) => {
            if (res && res.data && res.data.body) {
                let body = res.data.body;
                setFirstName(body.firstname);
                setLastName(body.lastname);
                if (body.userType.includes('content_partner')) {
                    setRole("content_partner");
                }
                if (body.userType.includes('content_partner_admin')) {
                    setRole('content_partner_admin');
                }
            }
            else {
                notification.error({ message: "Something Went Wrong" })
            }
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            notification.error({ message: "Something Went Wrong" })
            setIsLoading(false);
        })
    }

    const updateUser = () => {
        setIsLoading(true)
        let api = Utils.getAPIDescription('update-user');
        let body = {
            "userid": user_id,
            "firstname": firstName,
            "lastname": lastName,
            "role": role
        }
        axios.post(api, body).then((res) => {
            if (res.data && res.data.statusCode === 200) {
                notification.success({ message: "Information updated successfully." })
                if (user_id === localStorage.getItem('user_id')) {
                    localStorage.setItem('firstname', firstName);
                    localStorage.setItem('lastname', lastName);
                }
            }
            else {
                notification.error({ message: "Something Went Wrong" })
            }
            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            notification.error({ message: "Something Went Wrong" });
            setIsLoading(false);
        })
    }
    const checkForm = () => {
        if (firstName === "" || lastName === "" || role === "") {
            setReqErr(true);
        }
        else {
            updateUser()
        }
    }
    return (
        <div className="app-workspace">
            <div className="app-content">
                {isAdmin ? <div className="add-cpuser-container pull-left">
                    <Link to="/partnerconsole/edit-profile#2"><button className='blue-border-btn mb-4 fn-14'>BACK</button></Link>
                    <div className='create-heading'>Update Team Member {isLoading && <img src={Loading} className="ml-2" width="25px" />}</div>
                    <div className="add-user">
                        <div className="name-section">
                            <div className="name-div">
                                <div className="fn-16 fnw-600 mb-2">First Name:</div>
                                <input className="user-input" name="fname" onChange={handleOnChange} value={firstName} />
                                {!firstName && reqErr && <div className="err-msg">This field is required</div>}
                            </div>
                            <div className="name-div ml-5">
                                <div className="fn-16 fnw-600 mb-2" >Last Name:</div>
                                <input className="user-input" name="lname" onChange={handleOnChange} value={lastName} />
                                {!lastName && reqErr && <div className="err-msg">This field is required</div>}
                            </div>
                        </div>
                        <div className="fn-16 fnw-600 mt-5">
                            <span className="">Role:</span>
                            <span className='select-wrapper ml-2'>
                                <Select style={{ width: '200px' }} bordered={false} notFoundContent={null} onChange={(val) => setRole(val)} value={role}>
                                    <Option value='content_partner_admin'>Editor</Option>
                                    <Option value="content_partner">Writer</Option>
                                </Select>
                            </span>
                        </div>
                        {!role && reqErr && <div className="err-msg">This field is required</div>}
                        <div className="mt-5">
                            <Button type="primary" size="large" onClick={checkForm}>Update</Button>
                        </div>
                    </div>
                </div> :
                    <div className="add-user-container pull-left">
                        <h2>Permission Denied</h2>
                    </div>}
            </div>
        </div>
    )
}
