import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Utils from '../../utils/utils';
import Loading from '../../assets/images/loader.gif';
import { Avatar, Popover, Switch } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import './editReviewers.css';
import { notification } from 'antd';
import { Link } from 'react-router-dom';

export default function EditReviewers(props) {
    
    const [revsData, setRevsData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    useEffect(() => {
        getDetails();
    }, [])
    
    const getDetails = () => {
        setDataLoading(true);
        let api = Utils.getAPIDescription('get-reviewers') + '?org_id=' + (localStorage.getItem('org_id') !== 'undefined' ? localStorage.getItem('org_id') : 0);
        axios.get(api)
            .then((res) => {
                if (res.data.reviewers) setRevsData(res.data.reviewers);
                setDataLoading(false);
            });
    }
    
    const UserRow = (props) => {
        const [userStatus, setUserStatus] = useState(null);
        const [isLoading, setIsLoading] = useState(false);
        useEffect(() => {
            if (props.data && props.data.state) {
                setUserStatus(props.data.state ? true : false);
            }
        },[props])
        
        const changeStatus = (newStatus) => {
            setIsLoading(true);
            let api = Utils.getAPIDescription('manage-reviewer-state');
            axios.post(api, { "reviewer_id": props.data.id, state: newStatus })
                .then((res) => {
                    notification.success({
                        message: 'State changed successfully',
                        duration: 3,
                        placement: 'topRight',    
                    });
                    setIsLoading(false);
                    setUserStatus(newStatus);
            })
        }
        
        return (
            <div className='user-row'>
                <div>
                    <Avatar size={60} icon={<UserOutlined />} />
                </div>
                <div className='ml-2'>
                    <div className='fn-18'>
                        {'firstname' in props.data && 'lastname' in props.data ? props.data.firstname.trim() + ' ' + props.data.lastname.trim() : props.data.name}
                        {props.data.isOrgAdmin && ' (Admin)'}
                        {props.data.email === localStorage.getItem('email') && ' (You)'}
                    </div>
                    <div className='gray-font fn-14 mt-2'>
                        <span>{props.data.reviews} Fact Briefs</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <span className='ml-3'>{props.data.views} Views</span> */}
                        {/*<span className=''>last login: {moment(props.data.lastLogin).fromNow()}</span>*/}
                        <span className=''>last login: {moment.utc(props.data.lastLogin).local().fromNow()}</span>
                    </div>
                </div>
                {localStorage.getItem('user_types').includes('org_admin') && <div className="d-flex align-items-center justify-content-around">
                    <Link to={"/update-editor?userId=" + props.data.id}><button className="blue-border-btn-1px">Edit</button></Link>
                    <div className="">
                        <Switch checked={userStatus} onChange={changeStatus} loading={isLoading} />
                    </div>
                </div>}
            </div> 
        )
    }
    
    return (
        <div className='mt-5'>
            <div className='revs-board'>
                <div className="editor-header mb-3">
                    <div className='blue-font'>{revsData.length} Users</div>
                    {localStorage.getItem('user_types').includes('org_admin') ? <Link Link to="/add-user"><button className='blue-btn'>ADD NEW</button></Link> : null}
                    <div className="self-end gray-font mr-2 align-self-end">
                        User Status
                    </div>
                </div>

                {!dataLoading || revsData.length > 0 ? revsData.map((item) => (
                    <UserRow data={item}/>
                )) : <center><img src={Loading} width='30px'/></center>}
            </div>
        </div>
    )
}