import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import Utils from '../../utils/utils';
import Cross from '../../assets/images/cross.svg';
import Edit from '../../assets/images/edit.png';
import Delete from '../../assets/images/delete.png';
import Search from '../../assets/images/search.png';
import Loading from '../../assets/images/loader.gif';
import Iicon from '../../assets/images/logos/Iicon.png';
import Dropdown from '../../components/common/dropdown/dropdown';
import Tooltip from '../../components/common/tooltip/tooltip';
import { isWebUri } from 'valid-url';
import './sourcecontrol.css'


function extractDomain(url) {
    let domain;
    //find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf("://") > -1) {
        domain = url.split('/')[2];
    }
    else {
        domain = url.split('/')[0];
    }
    //find & remove port number
    domain = domain.split(':')[0];

    //remove www
    if (domain.indexOf("www.") === 0) {
        domain = domain.substring(4);
    }

    return domain;
}

export default function SourceControl(props) {
    // console.log(props)
    const [loading, setLoading] = useState(false);
    const [sourceData, setSourceData] = useState(undefined);
    const [reviewCount, setReviewCount] = useState(undefined);
    const [typeId, setTypeId] = useState(undefined);
    const [publishId, setPublishId] = useState(undefined);
    const [selectedContentIndex, setSelectedContentIndex] = useState(undefined);
    const [publishIndex, setPublishIndex] = useState(0);
    const [itemIndex, setItemIndex] = useState(undefined);
    const [typeIndex, setTypeIndex] = useState(0);
    const [isURLValid, setURLValid] = useState(true);
    const [title, setTitle] = useState('');
    const [extractedTitle, setExtractedTitle] = useState('');
    const [siteName, setSiteName] = useState(undefined);
    const [pageUrl, setPageUrl] = useState('');
    const [publisherType, setPublisherType] = useState('');
    const [showInfo, setShowInfo] = useState(false);
    const [contentHover, setContentHover] = useState(false);
    const [publishHover, setPublishHover] = useState(false);
    const [platform, setPlatform] = useState(undefined);
    const [sourceError, setSourceError] = useState(undefined);

    const contentHtml = '<p><strong>Numerical </strong><strong>data</strong><span style="font-weight: 400;">: Statistics. Census, unemployment rate, mortality figures, income, poll results, etc.</span></p>'+
    '<p><strong>Research</strong><strong> study: </strong><span style="font-weight: 400;">A report with conclusions, that may be based on numerical data or other documented sources. Often authored by academics or staff in think tanks, but also by government bodies or some privately-funded sources.</span></p>'+
    '<p><strong>Transcriptions</strong><strong> and live accounts:</strong><span style="font-weight: 400;"> Speeches, statements, court decisions, recorded words/images/videos, on-the-scene reporting</span></p>'+
    '<p><strong>Helpful </strong><strong>context</strong><strong>:</strong><span style="font-weight: 400;"> Other articles that add clarity, understanding or additional information relevant to the topic</span></p>';

    const publisherHtml = '<p><strong>University</strong><span style="font-weight: 400;">:&nbsp; accredited higher-learning institutions. Generally recognizable via .edu domains.</span></p><p>&nbsp;</p>'+
    '<p><strong>Academic publisher:&nbsp;</strong></p>'+
    '<ul>'+
    '<li style="font-weight: 400;"><span style="font-weight: 400;">Peer reviewed research journals - may be published by private companies (Elsevier, Wiley, etc.), independent (New England Journal of Medicine), associations (American Medical Association, etc.)</span></li>'+
    '<li style="font-weight: 400;"><span style="font-weight: 400;">Prepress sites: PLOS, medRxiv</span></li>'+
    '<li style="font-weight: 400;"><span style="font-weight: 400;">Often hosted at network sites such as SSRN.com, ScienceDirect.com, Wiley, Taylor &amp; Francis</span></li>'+
    '<li style="font-weight: 400;"><span style="font-weight: 400;">Also hosted at </span><a href="https://www.nlm.nih.gov/portals/public.html"><span style="font-weight: 400;">National Library of Medicine</span></a><span style="font-weight: 400;">, part of National Institutes of Health (nlm.nih.gov)</span></li>'+
    '</ul>'+
    '<p><span style="font-weight: 400;">When in doubt </span><a href="https://www.angelo.edu/library/handouts/peerrev.php"><span style="font-weight: 400;">consider this guide to making sure</span></a></p>'+
    '<p>&nbsp;</p>'+
    '<p><strong>News and reference media: </strong><span style="font-weight: 400;">Public and private news media across all formats - including privately-owned and -managed reference sources (i.e., not World Bank or Census, but, for instance, Merriam-Webster or Britannica)</span></p>'+
    '<p><span style="font-weight: 400;">Possible screening to be developed</span></p>'+
    '<p><span style="font-weight: 400;">Run against Media Bias/Fact Check (and/or NewsGuard?)</span></p>'+
    '<p><span style="font-weight: 400;">1) Clear automatically Factual/Highly factual (see t</span><a href="https://mediabiasfactcheck.com/methodology/"><span style="font-weight: 400;">heir methodology</span></a><span style="font-weight: 400;">).&nbsp;</span></p>'+
    '<p><span style="font-weight: 400;">2) Flag Mixed.&nbsp;</span></p>'+
    '<p><span style="font-weight: 400;">3) Ban (unless we make an exception) Low.&nbsp;</span></p>'+
    '<p><span style="font-weight: 400;">4) if known as &ldquo;extreme bias&rdquo; flag with &ldquo;Are you Sure&rdquo; or ban outright - but would need an exception process</span></p>'+
    '<p><strong>Government or &ldquo;public sector&rdquo;:</strong></p>'+
    '<ul>'+
        '<li style="font-weight: 400;"><span style="font-weight: 400;">Any .gov</span></li>'+
        '<li style="font-weight: 400;"><span style="font-weight: 400;">Multilateral government institutions which are .org - OECD, UN, World Bank, etc. - and other orgs created by international treaty such as the International Civil Aviation Organization.&nbsp;</span></li>'+
    '</ul>'+
    '<p><strong>Politicians and campaigns:</strong></p>'+
    '<p><span style="font-weight: 400;">Unelected or out-of-office politicians. Also unofficial content not hosted on .gov sites.</span></p>'+
    '<p><strong>Nonprofits</strong></p>'+
    '<p><span style="font-weight: 400;">All types of .org that don&rsquo;t fit elsewhere: Think tanks, public interest groups, trade and professional groups, labor unions, advocacy groups, charities.&nbsp;</span></p>'+
    '<p><span style="font-weight: 400;">Outside the US, this would include what are typically described &ldquo;non-governmental organizations&rdquo; or NGOs.</span></p>'+
    '<p><strong>Business</strong></p>'+
    '<p><span style="font-weight: 400;">Generally .com but as that includes news media can&rsquo;t be pre-determined. Includes law and other professional firms, private research firms, etc.</span></p>'+
    '<p><strong>Private individuals</strong></p>'+
    '<p><span style="font-weight: 400;">Social or Personal Media Post - anything off social media including YouTube, Reddit, TikTok, and any individual&rsquo;s blog.</span></p>';

    // useEffect(() => {
    //     //fetchFormData();
    //     if(props.value){
    //         //fetchSource();
    //        }
    //
    // }, [props.value]);

    useEffect(() => {
        if(props.relevanttypes){
            setTypeIndex(props.relevanttypes[props.index]);
        }
        if(props.relevantpublishers){
            setPublishIndex(props.relevantpublishers[props.index]);
        }
    }, [props]);

    /*useEffect(() => {
        debugger;
        if(siteName){
            debugger;
        }
    }, [siteName]);*/
    useEffect(() => {
        setPublisherType(props.relevantpublishertypes[props.index]);
    }, [props.relevantpublishertypes[props.index]]);

    useEffect(() => {
        setSiteName(props.sitenames[props.index]);
        setShowInfo(props.showinfo[props.index]);
        if(props.selectedcontenttype[props.index]){
            if(props.contenttypesuggestion[props.index]){
                
                let col = props.contenttypesuggestion[props.index];
                let itm = props.selectedcontenttype[props.index];
                for(let i in col){
                    if(col[i]['sourceType'] === itm){
                        setItemIndex(i);
                    }
                }
                //setItemIndex();
            }
            
            //setSelectedContentIndex(props.selectedcontenttype[props.index]);
        }
        //console.log('siteName'+props.sitenames[props.index]+'/'+typeof props.sitenames[props.index]);
        /*console.log('content type suggestion'+props.contenttypesuggestion[props.index]);
        console.log('selected Contentt type'+props.selectedcontenttype[props.index]);*/
    }, [props.sitenames, props.value, props.showinfo, props.selectedcontenttype, props.contenttypesuggestion, props.index]);

    useEffect(() => {
        if(props.platform && props.platform[props.index] !== ''){
            setPlatform(props.platform[props.index]);
        }else{
            setPlatform(undefined);
        }
    }, [props.platform, props.index]);

    useEffect(() => {
        props.updateshowinfo(props.index, showInfo);
    }, [showInfo, props.index]);

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            setSourceError(undefined);
            if(!validateURL(props.value)){
                setURLValid(false);
            }else{
                setURLValid(true);
               // fetchSource();
                await invokeScrapper();
            }
        }
    };

    const updateTitle = (evt) => {
        setTitle(evt.target.value);
        props.updatesource(evt.target.value, props.index);
    }

    const pasteURL = async (e) => {
        setSourceError(undefined);
        let clipboardData, pastedData;
        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('Text');

        if(!validateURL(pastedData)){
            setURLValid(false);
        }else{
            setURLValid(true);
            //fetchSource();
            props.updateitem('links', props.index, pastedData);
            await invokeScrapper(pastedData)
        }
    }

    const fetchSource = async () => {
        //setLoading(true);
        let apiPath = Utils.getAPIDescription('source-search');
        let body = {"query": `${props.value}`};
        try {
            let data =  await axios.post(apiPath, body);
            if(data && data.data){
              // setLoading(false);
               setSourceData(data.data.source_search_res);
               setReviewCount(data.data.review_count);
            }
        }catch (e) {
            console.log(`Error+${e}`);
            //setRelatedListLoader(false);
        }
    }

    const invokeScrapper =  async (val = undefined) => {
        setLoading(true);
        props.setIsSourceControlScrapping(true);
        console.log("scrapper invoked");
        let apiPath = Utils.getAPIDescription('scrape-source') + '?scrape=true';
        try {
            // Load async data from an inexistent endpoint.
            let vl = undefined;
            if(props.value && props.value !== ''){
                vl = props.value;
            }
            if(val){
                vl = val;
            }
            let data = await axios.post(apiPath, {"url" : vl});
            
            if (data && data.data) {
                if(data.data.statusCode === 400){
                    setLoading(false);
                    props.setIsSourceControlScrapping(false);
                    setSourceError(data.data.message);
                    return;
                }else{
                    setSourceError(undefined);
                }
                let sdata = data.data.data;
                setTitle(sdata.title);
                setExtractedTitle(sdata.title);
                setPageUrl(sdata.domainURL);
                setPublisherType(sdata.publisher_type);
                setSiteName(sdata.website_name);
                if(sdata.Platform){
                    setPlatform(sdata.Platform); 
                    props.updateplatform(props.index, sdata.Platform);
                }else{
                    setPlatform(undefined); 
                    props.updateplatform(props.index, '');
                }
                props.updatesitename(props.index, sdata.website_name);
                props.updatesource(sdata.title, props.index);
                props.updatecontents(sdata.content_types_all, props.index);
                props.contentchange(sdata.content_types_all[0]['sourceType'], props.index);
                props.updatepublishertype(props.index, sdata.publisher_type);
                setLoading(false);
                props.setIsSourceControlScrapping(false);
                props.updateshowinfo(props.index, true);
            }
        }catch (e) {
            console.log(`Error+${e}`);
        }
    }

    const addSource = async () => {
        let apiPath = Utils.getAPIDescription('save-draft-wrapper');
        let arr = [];
        arr.push();
        let body = [props.titlesource[props.index]["data"],  props.value, parseInt(typeId), parseInt(publishId)]
        try {
            let data =  await axios.post(apiPath, body);
            if(data && data.data && data.data.message === 'Success'){
            }
        }catch (e) {
            console.log(`Error+${e}`);
            //setRelatedListLoader(false);
        }
    }

    const validateURL = (str) =>{
        let pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return pattern.test(str);
    }

    const onInputChange = (event) => {
       // validateURL(event.target.value);
            //console.log('valid');
    }

    return (
        <div className="source-control colw-100 pull-left relative-position">
            {props.linksource.length > 1 && !loading && <img className="delete-btn ml-2" src={Delete} onClick={props.removeitem('links', props.index)}/>}
            {siteName && typeof props.sitenames[props.index] === 'string' && !showInfo && !loading && <img className="pen-btn" src={Edit} onClick={()=>{setShowInfo(true); props.updateshowinfo(props.index, true);}}/>}
            <div className="relative-position inline-block colw-100">
                {!showInfo && siteName && siteName !== "" && typeof siteName === 'string' ?
                    <input
                        type="text"
                        className="pull-left source-search drag-bg"
                        placeholder="Relevant Links"
                        disabled
                        value={props.sitenames[props.index]+': '+props.titlesource[props.index]["data"]} title={props.sitenames[props.index]+': '+props.titlesource[props.index]["data"]} style={{cursor: 'grab'}}/>

                        :

                    <input type="text" className="pull-left source-search" placeholder="Relevant Links" onChange={props.updateitem('links', props.index)} value={props.value} onKeyDown={handleKeyDown} onPaste={pasteURL}/>
                }
                {sourceError && <div className="err-msg">{sourceError}</div>}
                {loading &&  <img className="source-loader" src={Loading}/>}
                {!isURLValid && <span className="err-msg">Please Enter Valid URL</span>}
            </div>
            {showInfo &&
                <div className="source-box">
                    {platform &&
                        <>
                            <div className="colw-100 inline-block">
                                <div className="pull-left ref-lbl" style={{width:'auto'}}>Platform</div>
                            </div>
                            <div className="colw-100 inline-block frm-dta " style={{fontSize: '12px', lineHeight: '18px', fontWeight: 'normal'}}>{platform}</div>
                        </>
                    }

                    <div className="colw-100 d-inline-flex align-items-baseline frm-dta">
                        <div className="pull-left ref-lbl">Publisher Display Name</div>
                        <input type="text" className="pull-left display-title" value={props.sitenames[props.index]} onChange={(event => { props.updatesitename(props.index, event.target.value);})}/>
                    </div>

                    <div className="colw-100 d-inline-flex align-items-baseline frm-dta">
                        <div className="pull-left ref-lbl">Publisher Link</div>
                        <a href={`https://${extractDomain(props.value)}`}  target="_blank" rel={"noreferrer"} className={"blue-font"} style={{textDecoration: 'none', fontSize: "14px"}}>{extractDomain(props.value)}</a>
                    </div>

                    {/*<div className="colw-100 inline-block">*/}
                    {/*    <div className="pull-left ref-lbl" style={{width:'auto'}}>Publisher Link</div>*/}
                    {/*</div>*/}
                    {/*<div className="colw-100 inline-block frm-dta " style={{fontSize: '12px', lineHeight: '18px', fontWeight: 'normal'}}>*/}
                    {/*    <a href={extractDomain(props.value)}  target="_blank" rel={"noreferrer"} className={"blue-font"} style={{textDecoration: 'none'}}>{extractDomain(props.value)}</a>*/}
                    {/*</div>*/}

                    <div className="colw-100 inline-block frm-dta">
                        <div className="pull-left ref-lbl">
                            <div className="pull-left">Publisher Type</div>
                            <div className="pull-left relative-position">&nbsp;<img className="cursor-pointer" src={Iicon} onClick={()=>{setPublishHover(true)}}/>{publishHover && <Tooltip content={publisherHtml} hidetip={()=>{setPublishHover(false)}}/>}</div>
                        </div>
                    <div className="colw-100 inline-block" style={{ fontSize: '12px', lineHeight: '18px', fontWeight: 'normal' }}>{publisherType}</div>
                    </div>
                    <div className="colw-100 inline-block frm-dta">
                        <div className="pull-left ref-lbl">Article</div>
                        <div className="colw-100 inline-block" style={{fontSize: '14px', lineHeight: '18px', fontWeight: 'normal'}}>
                            <a href={props.value} target="_blank" rel={"noreferrer"} className={"blue-font"}>{props.titlesource[props.index]["data"]}</a>
                        </div>
                    </div>
                    <div className="colw-100 d-inline-flex align-items-baseline frm-dta">
                        <div className="pull-left ref-lbl">Display Title</div>
                        <input type="text" className="pull-left display-title" value={props.titlesource[props.index]["data"]} onChange={updateTitle}/>
                    </div>

                    {/*{props.contenttypesuggestion && props.contenttypesuggestion.length > 0 && <div className="colw-100 inline-block frm-dta"><div className="pull-left ref-lbl">*/}
                    {/*    <div className="pull-left">Content Type</div>*/}
                    {/*    <div className="pull-left relative-position">&nbsp;<img className="cursor-pointer" src={Iicon} onClick={()=>{setContentHover(true)}}/>{contentHover && <Tooltip content={contentHtml} hidetip={()=>{setContentHover(false)}}/>}</div>*/}
                    {/*    </div><Dropdown datasource={props.contenttypesuggestion && props.contenttypesuggestion[props.index] && props.contenttypesuggestion[props.index]} id="id" value="sourceType" itemindex={itemIndex ? itemIndex : props.contentindex} onchange={props.contentchange} index={props.index}/></div>*/}
                    {/*}*/}
                    <div className="source-submit-button-container">
                        <div id="source-submit-button" className={props.titlesource[props.index]["data"] !== '' ? "add-new pull-left" : "add-new pull-left disabled-div"}  onClick={()=>{setShowInfo(false); props.updateshowinfo(props.index, false);}}>Done</div>
                        {props.titlesource[props.index]["data"] === '' && <div className="submit-err-msg">Please add the Display Title</div>}
                    </div>
                </div>
            }
        </div>
    );
}
