import React, { useEffect, useState, useRef } from 'react';
import { Button, Tooltip, notification } from 'antd';
import axios from 'axios';
import { EyeFilled, TwitterOutlined, CloseCircleFilled } from '@ant-design/icons'
import fsHalf from '../../../../../assets/images/fs-half.svg';
import linkIcon from '../../../../../assets/images/console/link_icon.svg';
import noNewOpportunities from '../../../../../assets/images/console/no_new_opportunities.svg';
import Utils from '../../../../../utils/utils.js';

export const OpportunityToAnswer = (props) => {
  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    setQuestions(props.recommendData.recommendations)
  }, []);
  const openWindow = (e, data, type) => {
    // e.stopPropagation();
    // let classNames = ["close-btn", "see_flags_btn"]
    // let openNewWindow = true;
    // let element = e.target;
    // while (element.nodeName !== "BUTTON" && element.nodeName !== "BODY") {
    //   element = element.parentNode;
    // }
    // element.className.split(" ").forEach(cls => {
    //   if (classNames.includes(cls)) openNewWindow = false;
    // });
    // if (openNewWindow) {
    //   window.open(data, '_blank')
    // } else {
    //   if (element.className.split(" ").includes("see_flags_btn")) {
    //     props.setShowOpportunitiesDetails(true); props.setSelectedOpportunitiesDetails(data);
    //   }
    // }
    switch (type) {
      case 'see_flag':
        props.setShowOpportunitiesDetails(true);
        props.setSelectedOpportunitiesDetails(data);
        break;
      case 'open_artical':
        window.open(data, '_blank')
        break;
      default:
        break;
    }
  }
  const doActions = async (flag, action) => {
    let api = Utils.getAPIDescription('content-partner-recommend-answers-action');
    try {
      let payload = {
        key: flag.key,
        action,
        org_id: localStorage.org_id,
        org_name: localStorage.org_name,
        user_id: localStorage.user_id,
      }
      let res = await axios.post(api, payload);
      if (res.status === 200 && res.data && res.data.statusCode && res.data.statusCode === 200) {

        // Set cache control flag for recommendation API to fresh copy
        props.updateCacheControl('fresh');
        props.getRecmondedAnswered();
      }
    } catch (error) {
      notification.error({ message: error.response?.data?.message })
    }
  }

  return <div className="opportunity-container">
    <div className="top-bar">
      <div className="heading">
      Welcome to  Answers (Beta)! Here you can send your recently published content to people who have  requested fact checks on that claim. Click an article below to get started.       </div>
    </div>
    <div className="body-content">
      <div className="heading">
        Opportunities to answer ({questions?questions.length:0})
      </div>
      <div className='scroll-section'>
        {!props.isLoading && questions.length <= 0 && <div className='no-result'>
          <div className="img-section">
            <img src={noNewOpportunities} alt="No result" />
          </div>
          <div className="text">
            No new opportunities to answer
          </div>
        </div>}
        {questions && questions.map(qus =>
          <>
            <div div className="tiles" >
              <Button type="text" className='close-btn' icon={<CloseCircleFilled />} onClick={() => { doActions(qus, "ignore_all") }} />
              <div className="image-block">
                <img src={qus.factcheck_details.articleImage} alt="Image" width={100} />
              </div>
              <div className="description-block" onClick={(e) => { openWindow(e, qus, 'see_flag') }}>

                <div className="question">
                  <div className="text">
                    {qus.factcheck_details.claimReviewed}
                  </div>

                </div>
                <div className="date">
                  <div className="publisher-name">
                    {qus.org_name}

                  </div>
                  <div className="date">
                    {qus.factcheck_details.datePublished}
                  </div>

                </div>
              </div>
              <div className="action-section">
                <div className="fact-seeker-counts">
                  <img src={fsHalf} alt="FS" width={10} />
                  <span>
                    {qus.total_factseekers_count}
                  </span>
                </div>
                <div className="see_flags">

                  <Tooltip placement="right" title={'Click to see the article'}>
                    <div className="link-icon" onClick={(e) => { openWindow(e, qus.factcheck_details.url, 'open_artical') }}>
                      <img src={linkIcon} alt="FS" width={23} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  </div >;
}
