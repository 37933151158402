
import React, { useEffect, useState, useRef } from 'react';
import './tipline-installation-guide.scss';
import { Avatar, Button, Space, Table, Tag, Tooltip, notification } from 'antd';
import { LeftOutlined, CopyOutlined } from '@ant-design/icons';
import axios from 'axios';
import Utils from '../../../../utils/utils.js';
const { Column } = Table;

export default function TiplineInstallationGuide(props) {
    const copyToClipboard = (e) => {
        let elemt = e.target;
        while (elemt.nodeName !== "PRE" && elemt.nodeName !== "BODY") {
            elemt = elemt.parentElement
        }
        navigator.clipboard.writeText(elemt.innerText);
        notification.success({
            message: 'Copied to clipboard',
            description:""
                // elemt.innerText
        });
    }
    return (<div className="installation-guide-container">
        <div className='heading'>
            Tipline Installation Guide
        </div>
        {props.orgDetails && <ol>
            <li>
                Add this iframe code to your html file. <br />

                <pre className='my-3 p-3'>
                    <Button className='copy_to_clipboard' icon={<CopyOutlined />} size="small" onClick={copyToClipboard} />
                    {`<iframe class="tipline_frame" 
src="https://${process.env.REACT_APP_ENV === 'PROD'?'':"dev."}gigafact.org/tipline/${props.orgDetails.slug}" 
style="border: none; height: 450px" width="100%">
</iframe>`}
                </pre>
                <Tooltip title="search">
                </Tooltip>
            </li>
            <li>
                Add this media query to your css file. <br />
                <pre className='my-3 p-3'>
                    <Button className='copy_to_clipboard' icon={<CopyOutlined />} size="small" onClick={copyToClipboard} />
                    {`@media screen and (max-width: 768px) {
   .tipline_frame {
       height: 450px !important;
   }
}
`
                    }

                </pre>
            </li>
            <li>
                You can customize this media query according to the container or custom breakpoints you are using. For example, if you are using 550px as your breakpoint for mobile view, use this code. <br />
                <pre className='my-3 p-3'>
                    <Button className='copy_to_clipboard' icon={<CopyOutlined />} size="small" onClick={copyToClipboard} />
                    {`@media screen and (max-width: 550px) {
   .tipline_frame {
       height: 450px !important;
   }
}`
                    }
                </pre>
                Make sure the iframe height is 450px for mobile view.
            </li>
        </ol>}
    </div>)
}