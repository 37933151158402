//import { history } from '../helpers';
import axios from 'axios';

import { awsConfig } from '../config';

export class Utils {
    //Getting API description based on environment

    static getAPIDescription(signature) {
        //Added by Alok:- Setting the default axios header for auth token, No need to call localstorage all the time to get the token, will improvise this code later
        let token = localStorage.getItem('repustar_token');
        axios.defaults.timeout = 180000;
        if (token) {
            axios.defaults.headers.common['Authorization'] = token;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }

        let env = process.env.REACT_APP_ENV;
        console.log("Env:", env);
        // let env='PROD';
        let apiGateWay = '';
        let serviceGateWay = '';
        let searchGateWay = '';
        let tagGateWay = '';
        let socialMediaGateWay = '';
        let dashboardGateway = '';
        let sourceGateway = '';
        let scrapperGateway = '';
        let scrapArticleGateway = '';

        if (env === 'PROD') {
            apiGateWay = 'https://api.gigafact.org/';
            serviceGateWay = 'https://cms.gigafact.org/api/';
            searchGateWay = 'https://prod-searchalgorithm.repustar.com/';
            tagGateWay = 'https://prod-tagsearch.repustar.com/';
            socialMediaGateWay = 'https://searchtwitterbot.repustar.com/';
            dashboardGateway = 'https://kygoh4cqzd.execute-api.us-east-2.amazonaws.com/prod/v3/app/';
            sourceGateway = 'https://sourcesearch.repustar.com/';
            scrapperGateway = 'https://kygoh4cqzd.execute-api.us-east-2.amazonaws.com/prod/common-services/';
            scrapArticleGateway = 'https://prod-scrape.gigafact.org/';
        } else {
            apiGateWay = 'https://dev-api.gigafact.org/';
            serviceGateWay = 'https://dev-cms.gigafact.org/api/';
            searchGateWay = 'https://dev-searchalgorithm.repustar.com/';
            tagGateWay = 'https://dev-tagsearch.repustar.com/';
            socialMediaGateWay = 'https://dev-searchtwitterbot.repustar.com/';
            dashboardGateway = "https://kygoh4cqzd.execute-api.us-east-2.amazonaws.com/dev/v3/app/";
            sourceGateway = 'https://dev-sourcesearch.repustar.com/';
            scrapperGateway = 'https://kygoh4cqzd.execute-api.us-east-2.amazonaws.com/dev/common-services/';
            scrapArticleGateway = 'https://dev-scrape.gigafact.org/';
        }
        switch (signature) {
            case 'get-my-tips':
                return apiGateWay + 'flags/get-flag-details';
            // TODO: Update endpoint
            case 'search-wrapper':
                return apiGateWay + 'search/searchwrapper/';
            case 'get-related-list':
                return apiGateWay + 'flags/get-related-list';
            case 'get-revision':
                return serviceGateWay + 'get_revision';
            case 'graph-ql':
                return serviceGateWay + 'graphiql';
            // TODO: Update endpoint
            case 'search-review':
                return searchGateWay + 'repustar_reviews_search';
            // TODO: Update endpoint
            case 'get-sources':
                return apiGateWay + 'app/external-sources/get-allsources';
            // TODO: Update endpoint
            case 'add-sources':
                return apiGateWay + 'app/external-sources/add-source';
            case 'get_user_details':
                return apiGateWay + 'admin/get-user-details';
            case 'explore-flags':
                return apiGateWay + 'flags/explore-flags';
            case 'check-unique-username':
                return apiGateWay + 'admin/username-unique-check';
            // TODO: Update endpoint
            case 'sign-up':
                return apiGateWay + 'app/signup';
            // TODO: Update endpoint
            case 'ask-repustar':
                return apiGateWay + 'v2/ask-repustar';
            // TODO: Update endpoint
            case 'share-content':
                return apiGateWay + 'v2/app/share-content';
            case 'category-source':
                return apiGateWay + 'partner-console/suggest-tags?type=category&';
            case 'subcategory-source':
                return apiGateWay + 'partner-console/suggest-tags?type=subcategory&';
            case 'geotag-source':
                return apiGateWay + 'partner-console/suggest-tags?type=geotag&';
            // TODO: Update endpoint
            case 'add-tagdirectory':
                return apiGateWay + 'tags/addtagdirectory';
            // TODO: Update endpoint
            case 'auto-suggest':
                return apiGateWay + 'tags/searchtagsdirectory?';
            case 'get-tag':
                return apiGateWay + 'partner-console/get-tags';
            case 'get-tag-new':
                return apiGateWay + 'partner-console/get-tags';
            // TODO: Update endpoint
            case 'get-tags-content/flag':
                return tagGateWay + 'tag_flags';
            // TODO: Update endpoint
            case 'social-media-search':
                return socialMediaGateWay + 'social_media_search';
            // TODO: Update endpoint
            case 'review-flag-split':
                return socialMediaGateWay + 'reviews_and_flags_split_by_cat';
            // TODO: Update endpoint
            case 'dashboard-subcategory':
                return dashboardGateway + 'dashboard-subcategory';
            // TODO: Update endpoint
            case 'socialmedia-subcategory-feed':
                return dashboardGateway + 'socialmedia-subcategory-feed';
            case 'save-draft-wrapper':
                return apiGateWay + 'partner-console/save-draft-wrapper';
            case 'delete-review':
                return apiGateWay + 'partner-console/delete-page';
            // TODO: Update endpoint
            case 'source-search':
                return sourceGateway + 'source_search';
            case 'form-data':
                return apiGateWay + 'partner-console/form-data';
            // TODO: Update endpoint
            case 'external-dashboard':
                return dashboardGateway + 'external-dashboard';
            case 'page-submit':
                return apiGateWay + 'partner-console/page-submit';
            case 'publish-unpublish-brief':
                return apiGateWay + 'partner-console/publish-unpublish-reviews';
            case 'show-draft':
                return apiGateWay + 'partner-console/show-draft';
            // TODO: Update endpoint
            case 'scrape-articles':
                return scrapperGateway + 'scrape-articles';
            case 'scrape-source':
                return apiGateWay + 'sources/source-details';
            case 'featured-reviews':
                return apiGateWay + 'partner-console/featured-reviews';
            case 'cat-subcat-listing':
                return apiGateWay + 'partner-console/cat-subcat-listing';
            case 'reviews-by-user':
                return apiGateWay + 'partner-console/briefs-by-user';
            case 'get-org-details':
                return apiGateWay + 'organisations/get-org-details';
            case 'get-social-media-config':
                return apiGateWay + 'organisations/get-social-media-config';
            case 'create-social-media-config':
                return apiGateWay + 'organisations/create-social-media-config';
            case 'get-reviewers':
                return apiGateWay + 'organisations/get-reviewers-of-org';
            case 'get-author':
                return apiGateWay + 'partner-console/get-author-list';
            case 'add-author':
                return apiGateWay + 'organisations/add-author/';
            case 'remove-author':
                return apiGateWay + 'organisations/remove-author/';
            case 'get-aud-manager':
                return apiGateWay + 'partner-console/get-audience-managers-list';
            case 'add-aud-manager':
                return apiGateWay + 'partner-console/add-audience-manager';
            case 'remove-aud-manager':
                return apiGateWay + 'partner-console/remove-audience-manager/';
            case 'manage-reviewer-state':
                return apiGateWay + 'organisations/manage-reviewer-state';
            case 'manage-org-domains':
                return apiGateWay + 'organisations/manage-org-domains';
            case 'update-org':
                return apiGateWay + 'organisations/update-organisation';
            // TODO: Update endpoint
            case 'review-platform-wrapper':
                return apiGateWay + 'app/review-platform-wrapper';
            // TODO: Update endpoint
            case 'support-flag':
                return apiGateWay + 'tips/support-flag';
            case 'get-bookmarked-flag':
                return apiGateWay + 'flags/get-bookmarked-flags';
            case 'save-bookmark':
                return apiGateWay + 'flags/bookmark-flag';
            case 'archive-flag':
                return apiGateWay + 'flags/archive-flag';
            case 'review-details':
                return apiGateWay + 'partner-console/brief-details';
            case 'scrap-article':
                return scrapArticleGateway + 'scrape_article_m2';
            case 'update-user':
                return apiGateWay + 'admin/update-user';
            case 'check-username':
                return apiGateWay + 'admin/username-unique-check';
            case 'change-reviewer':
                return apiGateWay + 'partner-console/change-reviewer';
            case 'get-author-list':
                return apiGateWay + 'partner-console/get-author-list';
            case 'update-author':
                    return apiGateWay + 'partner-console/update-author';
            case 'suggest-images':
                return apiGateWay + 'partner-console/suggest-brief-images';
            case 'image-search':
                return apiGateWay + 'partner-console/search-brief-images';
            case 'review-listing-new':
                return apiGateWay + 'partner-console/brief-listing';
            case 'weekly-quiz-list':
                return apiGateWay + '/quiz/quiz-listing';
            // TODO: Update endpoint
            case 'search-claims-and-news':
                return apiGateWay + 'v3/webapp/workbench/search-claims-and-news';
            case 'allocate-status':
                return apiGateWay + 'flags/update-status';
            case 'upload-review-img':
                return apiGateWay + 'partner-console/upload-brief-image';
            case 'feature-flag':
                return apiGateWay + 'flags/feature-flag';
            // TODO: Update endpoint
            case 'upvote-flag':
                return apiGateWay + 'v3/webapp/workbench/upvote-flag';
            case 'submit-source':
                return apiGateWay + 'sources/update-source';
            // TODO: Update endpoint
            case 'remove-upvote':
                return apiGateWay + 'v3/webapp/workbench/remove-upvote';
            // TODO: Update endpoint
            case 'pin-content':
                return apiGateWay + 'v3/webapp/workbench/pin-content-for-flag';
            // TODO: Update endpoint
            case 'get-featured-flags':
                return apiGateWay + 'v3/webapp/workbench/get-featured-flags';
            case 'get-org-list':
                return apiGateWay + 'quiz/admin/get-org-list';
            case 'get-org-list-updated':
                return apiGateWay + 'organisations/get-org-list-updated';
            case 'ask-repustar-new':
                return apiGateWay + 'flags/check-eligibility';
            case 'share-content-new':
                return apiGateWay + 'flags/create-flag';
            case 'get_flags_chart_data':
                return apiGateWay + 'partner-console/get-flags-chart-data';
            case 'get_tipline_queries':
                return apiGateWay + 'partner-console/get-tipline-queries';
            case 'get-tipline-notifications':
                return apiGateWay + 'partner-console/notifications';
            case 'tipline-notifications-read':
                return apiGateWay + 'partner-console/notifications/read';
            case 'tips':
                return apiGateWay + 'flags';
            case 'notificaiton-settings-update-preferences':
                return apiGateWay + 'partner-console/notifications/update-preferences';
            // TODO: Update endpoint
            case 'content-partner-recommend-answers':
                return apiGateWay + 'v3/content-partner/recommend-answers';
            // TODO: Update endpoint
            case 'content-partner-recommend-answers-action':
                return apiGateWay + 'v3/content-partner/recommend-answers-action';
            case 'create-new-org-link':
                return apiGateWay + 'organisations/create-new-org-link';
            case 'new-org-validate-token':
                return apiGateWay + 'organisations/new-org-validate-token';
            case 'create-organisation':
                return apiGateWay + 'organisations/create-organisation';
            case 'send-setup-email-to-users':
                return apiGateWay + 'organisations/send-email-to-new-user';

            //  Quiz Endpoints
            case 'add-weekly-quiz':
                return apiGateWay+'quiz/create-update-quiz';
            case 'delete-weekly-quiz':
                return apiGateWay+'quiz/delete-quiz';
            case 'get-tweet-for-review':
                return apiGateWay+'quiz/get-tweet-for-review';
            case 'weekly-quiz-listing':
                return apiGateWay+'quiz/quiz-listing';
            case 'weekly-quiz-new-listing':
                return apiGateWay+'quiz/admin/quiz-listing';
            case 'quiz-admin-briefs-search':
                return apiGateWay + 'quiz/admin/search-briefs';
            case 'fetch-topic-images':
                return apiGateWay+'partner-console/suggest-brief-images';
            case 'get-all-users':
                return apiGateWay+'admin/get-all-users';
            case 'get-stats':
                return apiGateWay+'admin/get-stats';
            case 'export-stats':
                return apiGateWay+'admin/factbrief-stats-export';

            case 'quiz-get-cta-items':
                return apiGateWay+'quiz/v2/admin/get-cta-items';
            case 'quiz-save-cta':
                return apiGateWay+'quiz/v2/admin/save-cta';
            case 'quiz-update-cta':
                return apiGateWay+'quiz/v2/admin/update-cta';
            case 'set-cta-status':
                return apiGateWay+'quiz/v2/admin/set-cta-status';
            case 'get-quiz-stats':
                return apiGateWay+'quiz/v2/admin/get-quiz-stats';

            default:
                return '';
        }
    }

    static getAppInfo(title, isHelpful) {
        let appInfo = {};
        try {
            appInfo.article_URL = document.location.href;
            appInfo.title = title;
            appInfo.helpful = isHelpful;
        } catch (err) {
            console.log(err);
        }
        return appInfo;
    }

    static getShareInfo(type) {
        let shareInfo = {};
        try {
            shareInfo.share_type = type;
            shareInfo.article_URL = document.location.href;
        } catch (err) {
            console.log(err);
        }
        return shareInfo;
    }

    static awsConfig() {
        return {
            Auth: {
                mandatorySignIn: true,
                region: awsConfig.cognito.REGION,
                userPoolId: awsConfig.cognito.USER_POOL_ID,
                identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
                userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
                oauth: awsConfig.oauth
            },
            Storage: {
                region: awsConfig.s3.REGION,
                bucket: awsConfig.s3.BUCKET,
                identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID
            },
            API: {
                endpoints: [
                    {
                        name: "notes",
                        endpoint: awsConfig.apiGateway.URL,
                        region: awsConfig.apiGateway.REGION
                    },
                ]
            },
            ssr: true
        }
    }


    static checkLogin() {
        if (localStorage && localStorage.getItem('user_id')) {
            return true;
        } else {
            return false;
        }
    }

    static checkAccess() {
        if (localStorage && localStorage.getItem('access') === 'blocked') {
            return false;
        } else {
            return true;
        }
    }

    static getAdminPermission() {
        if (localStorage.getItem('user_types')  &&  localStorage.getItem('org_id') === '3' && localStorage.getItem('user_types').includes('repustar_platform_moderator')) {
            return true
        }
        return false
    }

    static hasFlagControlPermission() {
        if (localStorage.getItem('user_types')  &&  ['3', '3'].includes(localStorage.getItem('org_id')) && localStorage.getItem('user_types').includes('content_partner_admin'))
            return true;
    }

    static checkContentPartnerWriter() {
        if (localStorage.getItem('user_types') && localStorage.getItem('user_types').split(',').includes('content_partner') && !localStorage.getItem('user_types').split(',').includes('content_partner_admin')) {
            return true
        }
        return false
    }

    static getRedirectPath() {
        return new Promise((resolve) => {
            const cachedPath = localStorage.getItem('redirect_path');
            if (cachedPath) {
                resolve(cachedPath);
                return;
            }

            this.fetchRedirectPath().then(path => {
                localStorage.setItem('redirect_path', path);
                resolve(path);
            }).catch(() => {
                resolve("/partnerconsole/edit-profile");
            });
        });
    }

    static async fetchRedirectPath() {
        try {
            const orgId = localStorage.getItem('org_id');
            const api = this.getAPIDescription('get-org-details') + '?org_id=' + orgId;
            const response = await axios.get(api);
            const data = response?.data?.data;
            
            if (data && data?.services) {
                if (data?.services?.includes('factbrief_service')) {
                    return "/partnerconsole/flags";
                } else if (data?.services?.includes('claimsdb_service')) {
                    return "/partnerconsole/parser";
                }
            }
            return "/partnerconsole/edit-profile";
        } catch (error) {
            console.error('Error fetching org details:', error);
            throw error;
        }
    }

    static checkIfOrgCanSelfPublish() {
        return localStorage.getItem('org_self_publish') === 'true';

    }

    static checkContentPartnerEditor() {
        if (localStorage.getItem('user_types') && localStorage.getItem('user_types').split(',').includes('content_partner_admin')) {
            return true
        }
        return false
    }

    static checkContentPartnerContributor() {
        if (localStorage.getItem('user_types') && localStorage.getItem('user_types').split(',').includes('cp_contributor')) {
            return true
        }
        return false
    }

    static checkIfPartnerOrg() {
        let env = process.env.REACT_APP_ENV;
        let org_id = localStorage.getItem('org_id');
        // TODO Make dynamic, save in global state
        let org_ids = env === 'PROD' ? ['3', '3181', '3185', '2680'] : ['3', '1031', '327', '1032'];
        return org_ids.includes(org_id);
    }

    static updateAutoSuggest(tagPayLoads) {
        const updateSuggestion = async () => {
            let endpoint = Utils.getAPIDescription('add-tagdirectory');
            let res = await axios.post(endpoint, tagPayLoads);
            if (res.status === 200) {
                console.log('updated');
            } else {
                console.log('Some Error Occured.');
                return;
            }
        }
        updateSuggestion();
    }

    static getMinioragneRedirectURI(signature) {
        let env = process.env.REACT_APP_ENV;

        let loginURL = '';
        let logoutURL = '';
        if (env === 'PROD') {
            loginURL = 'https://repustar.xecurify.com/moas/broker/login/jwt/239947?client_id=f6KeyLIHh2agElX5&redirect_uri=https://contributor.gigafact.org/&relay_state=' + encodeURIComponent(window.location.href);
            logoutURL = 'https://repustar.xecurify.com/moas/idp/oidc/logout?post_logout_redirect_uri=https://contributor.gigafact.org';
        }
        else {
            loginURL = 'https://repustar.xecurify.com/moas/broker/login/jwt/239947?client_id=nc2b8Hpgo8xK7dKZ&redirect_uri=https://dev-contributor.gigafact.org/&relay_state=' + encodeURIComponent(window.location.href);
            logoutURL = 'https://repustar.xecurify.com/moas/idp/oidc/logout?post_logout_redirect_uri=https://dev-contributor.gigafact.org';
        }

        if (window.location.href.includes('localhost')) {
            loginURL = 'https://repustar.xecurify.com/moas/broker/login/jwt/239947?client_id=tZPj3mirjOam0TCl&redirect_uri=http://localhost:3000/&relay_state=' + encodeURIComponent(window.location.href);
            logoutURL = 'https://repustar.xecurify.com/moas/idp/oidc/logout?post_logout_redirect_uri=http://localhost:3000';
        }

        switch (signature) {
            case 'login':
                return loginURL;
            case 'logout':
                return logoutURL;
            default:
                return null;
        }
    }

    static getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    static getBaseURL() {
        let env = process.env.REACT_APP_ENV;
        if (env === "DEV") {
            return 'https://dev.gigafact.org';
        } else if (env === 'PROD') {
            return 'https://gigafact.org';
        } else {
            return 'http://localhost:3000'
        }
    }

    static getBaseDomain() {
        let env = process.env.REACT_APP_ENV;
        if (env === "DEV") {
            return 'dev.gigafact.org';
        } else if (env === 'PROD') {
            return 'gigafact.org';
        } else {
            return 'localhost:3000'
        }
    }

    static openNewTab(url) {
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = url;
        a.click();
    }

    static checkSeparatorMapping(str, separator, index) {
        try {
            let itm = str.split(separator)[index];
            if (itm) {
                return itm;
            } else {
                return undefined;
            }
        } catch (err) {
            return undefined;
        }
    }

    static getCollectionIdexbyValue(collection, key, value,type=undefined) {
        if(type==='authorName'){
            for (let obj in collection) {
                if (collection[obj][key].startsWith(value)) {
                  return parseInt(obj);
                }
              }
        }
        else{
            for (let obj in collection) {
                if (collection[obj][key] === value) {
                    return parseInt(obj);
                }
            }
        }
        return 0;
    }

    static extractDomain(url) {
        try {
            // This function extracts the domain from a given URL.
            const domain = new URL(url).hostname;
            // This removes 'www.' from the domain, if present.
            return domain.replace('www.', '');
        }
        catch (e) {
            return url;
        }

    }

}

export default Utils;
