import { notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Loading from '../../../../assets/images/loader.gif';
import Utils from '../../../../utils/utils.js';
import './answer.scss';
import { Answered } from "./answered/answered";
import { OpportunityToAnswerDetails } from "./opportunity-to-answer-details/opportunity-to-answer-details";
import { OpportunityToAnswer } from "./opportunity-to-answer/opportunity-to-answer";




export const Answer = (props) => {
    const [showOpportunitiesDetails, setShowOpportunitiesDetails] = useState(false);
    const [recommendData, setRecommendData] = useState(null);
    const [selectedOpportunitiesDetails, setSelectedOpportunitiesDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory()

    const [dataStaleState, setDataStaleState] = useState(true);



    const updateCacheControl = (state) => {
        //5 mins
        let cache_time = 5*60000;

        if (state === 'fresh') {
            localStorage.removeItem('recommend_data_cache_control');
        }
        else {
            let ls_item = localStorage.getItem("recommend_data_cache_control");
            if (ls_item) {
                let cache_control_parsed = JSON.parse(localStorage.getItem("recommend_data_cache_control"));
                let cache_control = {value: 'cache', timestamp: new Date(cache_control_parsed.timestamp)};
                localStorage.setItem('recommend_data_cache_control', JSON.stringify(cache_control));
            }
            else {
                let curr_time = new Date().getTime();
                let cache_control = {value: state, timestamp: new Date(curr_time + cache_time)};
                localStorage.setItem('recommend_data_cache_control', JSON.stringify(cache_control));
            }
        }
    };

    useEffect(() => {
        setTimeout(() => {
          document.title = "Gigafact Partner Console";
        }, 1000);
      }, []);


    useEffect(() => {
        // getRecmondedAnswered();
        routerWatch();
    }, []);

    useEffect(() => {
        if (!showOpportunitiesDetails) {
            getRecmondedAnswered();
        } else {
            history.replace({
                search: `key=${selectedOpportunitiesDetails.key}`
            })
        }
    }, [showOpportunitiesDetails, dataStaleState]);

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const key = query.get('key');
        if (recommendData && key) {
            let temp =recommendData.answered.concat(recommendData.recommendations);
            let searched=temp.find((a=>decodeURI(a.key)===decodeURI(key)))
            setSelectedOpportunitiesDetails(searched);
            setShowOpportunitiesDetails(true);

        }
    }, [recommendData, dataStaleState]);


    const routerWatch = () => {
        history.listen(loc => {
            const query = new URLSearchParams(loc.search);
            const key = query.get('key');
            if (key) { 
                setShowOpportunitiesDetails(true);
            } else {
                setSelectedOpportunitiesDetails(null);
                getRecmondedAnswered();
                setShowOpportunitiesDetails(false);
            }
        })
    };

    const getCacheControlFlag = () => {
        let cache_control = localStorage.getItem("recommend_data_cache_control");
        let cache_flag = 'fresh';
        if (cache_control) {
            let cache_control_parsed = JSON.parse(localStorage.getItem("recommend_data_cache_control"));
            let curr_ts = new Date();
            let cache_ts = new Date(cache_control_parsed.timestamp);

            if (cache_control_parsed.value === 'cache' && curr_ts > cache_ts) {
                // console.log("Invalidate cache", cache_control_parsed.value);
                /* Invalidate Cache after 5 mins */
                cache_flag = 'fresh';
                updateCacheControl('fresh');
            }
            else {
                cache_flag = cache_control_parsed.value;
            }
        }
        else {
            updateCacheControl('fresh');
        }

        return cache_flag;
    };


    const getRecmondedAnswered = async () => {
        if (dataStaleState) {
            setIsLoading(true);
            setRecommendData(null)
            let api = Utils.getAPIDescription('content-partner-recommend-answers');
            let params = '?org_id=' + localStorage.org_id;

            let cache_flag = getCacheControlFlag();

            let headers = {'c-cache-control': cache_flag};

            try {
                let res = await axios.get(api + params, {headers: headers});
                if (res.status === 200 && res.data && res.data.statusCode) {
                    setRecommendData(res.data.response)

                    updateCacheControl('cache');
                    // localStorage.setItem('recommend_stale_state', 'false');
                    setDataStaleState(false);
                }
                setIsLoading(false);

            } catch (error) {
                setIsLoading(false);
                notification.error({ message: error.response?.data?.message })
            }
        };
    };
    return <div className="answer-container">
        {isLoading && <div className='loading-icon'>
            <img src={Loading}></img>
        </div>}
        {!showOpportunitiesDetails && recommendData && <>
            <OpportunityToAnswer setShowOpportunitiesDetails={setShowOpportunitiesDetails} showOpportunitiesDetails={showOpportunitiesDetails} recommendData={recommendData} setSelectedOpportunitiesDetails={setSelectedOpportunitiesDetails} getRecmondedAnswered={getRecmondedAnswered} isLoading={isLoading} updateCacheControl={updateCacheControl}/>
            <Answered setShowOpportunitiesDetails={setShowOpportunitiesDetails} showOpportunitiesDetails={showOpportunitiesDetails} recommendData={recommendData} selectedOpportunitiesDetails={selectedOpportunitiesDetails} setSelectedOpportunitiesDetails={setSelectedOpportunitiesDetails} updateCacheControl={updateCacheControl}/>  </>
        }
        {showOpportunitiesDetails && recommendData && <>
            <OpportunityToAnswerDetails setShowOpportunitiesDetails={setShowOpportunitiesDetails} showOpportunitiesDetails={showOpportunitiesDetails} setSelectedOpportunitiesDetails={setSelectedOpportunitiesDetails} selectedOpportunitiesDetails={selectedOpportunitiesDetails} updateCacheControl={updateCacheControl}/>
        </>
        }
    </div>
}
