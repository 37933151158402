
import React, {useState} from 'react';
import styles from "./quiz-admin.module.scss";
import {Button, Tooltip, notification, Divider, Popover} from 'antd';
import { CopyOutlined } from '@ant-design/icons';

export default function QuizInstallationGuide(props) {
    const copyToClipboard = (e) => {
        let elemt = e.target;
        while (elemt.nodeName !== "PRE" && elemt.nodeName !== "BODY") {
            elemt = elemt.parentElement
        }
        navigator.clipboard.writeText(elemt.innerText);
        notification.success({
            message: 'Copied to clipboard',
            description:""
            // elemt.innerText
        });
    }

    const [preview, setPreview] = useState(false);


    if (preview) {
        return (<div className={styles.installationGuideContainer}>
            <div className={styles.heading}>
                Quiz Installation Guide - Iframe
            </div>
            <div style={{display: "flex", justifyContent: "center", padding: "10px 0"}}>
                <Button onClick={() => setPreview(false)}>Go Back</Button>
            </div>
            <iframe className="gigafact_quiz_iframe"
                    src={`https://${process.env.REACT_APP_ENV === 'PROD'?'':'dev.'}gigafact.org/quiz-iframe?org=${props.orgDetails.slug}`}
                    style={{border: "none"}} height={"550px"} width="100%">
            </iframe>
        </div>)
    }
    else {
        return (<div className={styles.installationGuideContainer}>
            <div className={styles.heading}>
                Quiz Installation Guide - Iframe
            </div>

            {props.quizList && props.quizList?.length === 0 ?
                <div style={{display: "flex", justifyContent: "center", padding: "10px 0 0 0"}}>
                    <Popover content={"You have not added any quiz. Add a quiz to check out the preview."}>
                        <Button type={"primary"} onClick={() => setPreview(true)} disabled={props.quizList && props.quizList?.length === 0}>Preview</Button>
                    </Popover>
                </div>
                :
                <div style={{display: "flex", justifyContent: "center", padding: "10px 0 0 0"}}>
                    <Button type={"primary"} onClick={() => setPreview(true)} disabled={props.quizList && props.quizList?.length === 0}>Preview</Button>
                </div>
            }

            <Divider/>

            {props.orgDetails && <ol>
                <li>
                    Add this iframe code to your html file. <br />

                    <pre className='my-3 p-3'>
                    <Button className={styles.copyToClipboard} icon={<CopyOutlined />} size="small" onClick={copyToClipboard} />
                        {`<iframe class="gigafact_quiz_iframe" 
    src="https://${process.env.REACT_APP_ENV === 'PROD'?'':"dev."}gigafact.org/quiz-iframe?org=${props.orgDetails.slug}" 
    style="border: none" width="100%">
</iframe>`}
                </pre>
                    <Tooltip title="search">
                    </Tooltip>
                </li>
                <li>
                    Add this media query to your css file. <br />
                    <pre className='my-3 p-3'>
                    <Button className={styles.copyToClipboard} icon={<CopyOutlined />} size="small" onClick={copyToClipboard} />
                        {`@media screen and (max-width: 768px) {
   .gigafact_quiz_iframe {
       height: 550px !important;
   }
}
`
                        }

                </pre>
                </li>
                <li>
                    You can customize this media query according to the container or custom breakpoints you are using. For example, if you are using 550px as your breakpoint for mobile view, use this code. <br />
                    <pre className='my-3 p-3'>
                    <Button className={styles.copyToClipboard} icon={<CopyOutlined />} size="small" onClick={copyToClipboard} />
                        {`@media screen and (max-width: 550px) {
   .gigafact_quiz_iframe {
       height: 550px !important;
   }
}`
                        }
                </pre>
                    Make sure the iframe height is 550px for mobile and web view.
                </li>
            </ol>}
        </div>)
    }


}