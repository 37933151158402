import { notification } from 'antd';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Utils from '../../../utils/utils';
import Loading from '../../../assets/images/loader.gif';
export default function SocialRow (props) {
        const [isDisabled, setIsDisabled] = useState(true);
        const [url, setUrl] = useState('');
        const [loading, setLoading] = useState(false);
        useEffect(() => {
            setUrl(props.url);
        }, [props.url])
        const changeUrl = (e) => {
           setUrl(e.target.value);
        }
        const updateLink = () => {
            setLoading(true);
            //message.loading({ content: 'Updating...', key });
			let api = Utils.getAPIDescription('update-org');
            const links = {
                [props.payload] : url,
			}
			
            axios.post(api, { "org_id": localStorage.getItem('org_id'), "links": links })
                .then(() => {
                    props.getDetails();
                    notification.success({ message: 'Profile Updated Successfully!', duration: 3, });
                    setLoading(false);
                });
        }
        const onButtonClick = () => {
            if(!isDisabled) updateLink();
            setIsDisabled(!isDisabled);
            // document.getElementById(props.payload).focus();
        }
        
        return (
            <div className='social-row'>
                <div>
                    <img src={props.img} width='30px' />
                </div>
                <div className='ml-3'>
                    <div className='fn-14'>{props.payload.charAt(0).toUpperCase() + props.payload.slice(1)}</div>
                    <input id={props.payload} className='password-inp mt-2 fn-14 colw-90' value={url} onChange={changeUrl} disabled={isDisabled} />
                </div>
                <div className="edit-btn-end">
                    <button className='blue-btn' onClick={onButtonClick}>{isDisabled ? 'Edit' : 'Save'}</button>{loading && <img className='ml-2' src={Loading} width='25px' />}
                </div>
            </div>
        )
    }