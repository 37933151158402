let cognito;
let oauth;
if (process.env.REACT_APP_ENV === 'PROD') {
    cognito={
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-2_Qla8K6T7e",
        APP_CLIENT_ID: "5iflcjnpgnd9v7p18k8ko6aqtf",
        IDENTITY_POOL_ID: "us-east-2:1c25596b-76c3-4f05-b310-a7a3ad4b45a7"
        
    };
    oauth = {
        domain: 'gigafact-prod.auth.us-east-2.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://contributor.gigafact.org/',
        redirectSignOut: 'https://contributor.gigafact.org/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
}else if (process.env.REACT_APP_ENV === 'DEV') {
    cognito={
        REGION:"us-east-2",
        APP_CLIENT_ID: "3dcfj4hl2l9aih11f07q95fahh",
        USER_POOL_ID: "us-east-2_TPiQEKBoG",
        IDENTITY_POOL_ID: "us-east-2:1c25596b-76c3-4f05-b310-a7a3ad4b45a7"
    };
    oauth = {
        domain: 'gigafact-dev.auth.us-east-2.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://dev-contributor.gigafact.org/',
        redirectSignOut: 'https://dev-contributor.gigafact.org/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
}else{
    cognito={
        REGION:"us-east-2",
        APP_CLIENT_ID: "3dcfj4hl2l9aih11f07q95fahh",
        USER_POOL_ID: "us-east-2_TPiQEKBoG",
        IDENTITY_POOL_ID: "us-east-2:1c25596b-76c3-4f05-b310-a7a3ad4b45a7"
    };
    oauth = {
        domain: 'gigafact-dev.auth.us-east-2.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://dev-contributor.gigafact.org/',
        redirectSignOut: 'https://dev-contributor.gigafact.org/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
}

export const awsConfig = {
    oauth: oauth,
    s3: {
        REGION: "us-east-2",
        // BUCKET: "repustardev"
        BUCKET: "gigafact-app-images",
      },
    apiGateway: {
        REGION: "us-east-2",
        URL: `${window.location.origin}/`
    },
    cognito:cognito
};
