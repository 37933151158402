import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Utils from '../../utils/utils';
import Loading from '../../assets/images/loader.gif';
import './myaccount.css';
import { Upload, Avatar } from 'antd';
import Twitter from '../../assets/images/logos/twitter.png';
import Reddit from '../../assets/images/logos/reddit.png';
import FB from '../../assets/images/logos/facebook.svg'
import LinkedIn from '../../assets/images/logos/linkedin.svg';
import InstaImg from '../../assets/images/logos/insta70d495b3.svg';
import Web from '../../assets/images/website.png';
import { notification } from 'antd';
import { Link } from 'react-router-dom';
import SocialRow from '../common/socialRow/socialRow';
export default function MyAccount(props) {
    const userId = localStorage.getItem('user_id');
    const [isAdmin, setIsAdmin] = useState(false);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState({});
    const [isfNameDisabled, setIsfNameDisabled] = useState(true);
    const [islNameDisabled, setIslNameDisabled] = useState(true);
    const [isusernameDisabled, setIsusernameDisabled] = useState(true);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [orgDes, setOrgDes] = useState('');
    const [userName, setUserName] = useState('');
    const [orgName, setOrgName] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [redditUrl, setRedditUrl] = useState('');
    const [fbUrl, setFbUrl] = useState('');
    const [instaUrl, setInstaUrl] = useState('');
    const [linkedInUrl, setlinkedInUrl] = useState('');
    const [webUrl, setWebUrl] = useState('');
    const [isDesDisabled, setIsDesDisabled] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [orgFile, setOrgFile] = useState(null);
    const [orgLogo, setOrgLogo] = useState(null);
    const [dpFile, setDpFile] = useState(null);
    const [dpUrl, setDpUrl] = useState(null);
    useEffect(() => {
        getDetails();
        setEmail(localStorage.getItem('email'));
        setIsAdmin(localStorage.getItem('user_types').includes('org_admin'));
        setUserName(localStorage.getItem('username'));
        setDpUrl(localStorage.getItem('profile_img'));
        setFname(localStorage.getItem('firstname'));
        setLname(localStorage.getItem('lastname'));
    }, [])
    const getDetails = () => {
        let api = Utils.getAPIDescription('get-org-details') + '?org_id=' + localStorage.getItem('org_id');
        axios.get(api)
            .then((res) => {
                let data = res.data.data;
                if (data) {
                    setOrgName(data.org_name ? data.org_name : '');
                    setOrgDes(data.description ? data.description : '');
                    setOrgLogo(data.logoURL ? data.logoURL : '');
                    if (data && data.links && data.links.Twitter) setTwitterUrl(data.links.Twitter);
                    if (data && data.links && data.links.Reddit) setRedditUrl(data.links.Reddit);
                    if (data && data.links && data.links.Facebook) setFbUrl(data.links.Facebook);
                    if (data && data.links && data.links.Instagram) setInstaUrl(data.links.Instagram);
                    if (data && data.links && data.links.Linkedin) setlinkedInUrl(data.links.Linkedin);
                    if (data && data.links && data.links.Website) setWebUrl(data.links.Website);
                }

        })
    }
    const passwordChange = (e) => {
        if (e.target.name === 'des')
            setOrgDes(e.target.value);
    }
    const onProfileUpload = (e, type) => {
        // console.log(e.target.files[0].type.includes('image'));
        if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
            if(e.target.files[0].size / 1024 / 1024 < 3) {
                encodeBase64(e.target.files[0], type);
            }
            else {
                alert("Image must be smaller than 3MB!");
            }
        }
        else {
            alert("You can only upload JPG/PNG file!");
        }
    }

    const encodeBase64 = (file, type) => {
        let reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                let Base64 = reader.result;
                if (type === 'profile-pic') setDpFile(Base64);
                if (type === 'org-logo') setOrgFile(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    }

    useEffect(() => {
        if (dpFile) {
            uploadImage('profile');
        }
    }, [dpFile])

    useEffect(() => {
        if (orgFile) {
            uploadImage('logo');
        }
    },[orgFile])

    const uploadImage = (type) => {
        let api = Utils.getAPIDescription('update-org') + '?action=upload&type=' + type;
        let body = {
            "image_encoded":type === 'profile' ? dpFile.split('base64,')[1] : orgFile.split('base64,')[1],
            "org_id": localStorage.getItem('org_id'),
            "reviewer_id": localStorage.getItem('user_id')
        }
        if (type === 'profile') {
            setDpUrl(Loading);
        }
        else {
            setOrgLogo(Loading);
        }
        axios.post(api, body)
            .then((res) => {
                if (res.data.message) {
                    if (type === 'profile') {
                        setDpUrl(res.data.image_url);
                    }
                    else {
                        setOrgLogo(res.data.image_url);
                    }
                }
        }).catch((err) => {
                console.log(err);
            alert('something went wrong!');
            if (type === 'profile') {
                setDpUrl(null);
            }
            else {
                setOrgLogo(null);
            }
            })
    }

    const updateDes = () => {
        setIsDesDisabled(!isDesDisabled);
        //message.loading({ content: 'Updating...', key });
        if (!isDesDisabled) {
             setApiLoading(true);
            let api = Utils.getAPIDescription('update-org');
            axios.post(api, { "org_id": localStorage.getItem('org_id'), "description": orgDes, "links": {}})
                .then(() => {
                    getDetails();
                    setApiLoading(false);
                    notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight',});
                });
        }
    }
    const onfNameClick = () => {
        setIsfNameDisabled(!isfNameDisabled);
        if (!isfNameDisabled) {
            let api = Utils.getAPIDescription('update-user');
            axios.post(api, { userid: localStorage.getItem('user_id'), firstname: fname })
                .then((res) => {
                    //console.log(res);
                    localStorage.setItem('firstname', fname);
                    notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight',});
                }).catch((err) => {
                    console.log(err);
            })
        }
    }
    const onlNameClick = () => {
        setIslNameDisabled(!islNameDisabled);
        if (!islNameDisabled) {
            let api = Utils.getAPIDescription('update-user');
            axios.post(api, { userid: localStorage.getItem('user_id'), lastname: lname })
                .then((res) => {
                    //console.log(res);
                    localStorage.setItem('lastname', lname);
                    notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight',});
                }).catch((err) => {
                    console.log(err);
            })
        }
    }
    const onuserNameClick = () => {
        setIsusernameDisabled(!isusernameDisabled);
        if (!isusernameDisabled) {
            let api = Utils.getAPIDescription('update-user');
            axios.post(api, { userid: localStorage.getItem('user_id'), username: userName })
                .then((res) => {
                    //console.log(res);
                    localStorage.setItem('username', userName);
                    notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight',});
                }).catch((err) => {
                    console.log(err);
            })
        }
    }

    const onNameChange = (e) => {
        if (e.target.name === 'fname') {
            setFname(e.target.value);
        }
        if (e.target.name === 'lname') {
            setLname(e.target.value);
        }
        if (e.target.name === 'username') {
            setUserName(e.target.value);
        }
    }
    
    return (
        <div>
            <div className='mt-5 mb-2 fn-14'>Email</div>
            <div className='fn-18 my-2'>{email}</div>
            <Link to="/password-reset"><button className='blue-btn mt-3'>Change Password</button></Link>
            <div className="rev-name-box-top">
                <div className="mt-3">
                    <div className="name-div-profile">
                        <div className="fn-14">First Name:
                            <input className="password-inp colw-90 fn-16" name="fname" disabled={isfNameDisabled} value={fname} onChange={onNameChange}/>
                        </div>
                        <div className="align-self-end">
                            <button className='blue-btn ml-3' onClick={onfNameClick}>{isfNameDisabled ? 'EDIT' : 'SAVE'}</button>
                        </div>
                    </div>
                    <div className="name-div-profile mt-3">
                        <div className="fn-14" >Last Name:
                            <input className="password-inp colw-90 fn-16" name="lname" disabled={islNameDisabled} value={lname} onChange={onNameChange}/>
                        </div>
                        <div className="align-self-end">
                            <button className='blue-btn ml-3' onClick={onlNameClick}>{islNameDisabled ? 'EDIT' : 'SAVE'}</button>
                        </div>
                    </div>
                </div>
                {!Utils.checkContentPartnerWriter() && <div className='upload-section'>
                    <div>
                        {dpUrl && <img src={dpUrl + ('?v=' + Date.now())} className='profile-pic' />}
                    </div>
                    <div>
                        <div className='fn-14'>Reviewer Profile Image</div>
                        <input id="profile-pic" type="file" onChange={(e) => onProfileUpload(e, 'profile-pic')} style={{ display: "none" }} />
                        <button className='blue-btn mt-2' onClick={() => document.getElementById("profile-pic").click()}>UPLOAD IMAGE</button>
                    </div>
                </div>}
                
            </div>
            
            {/* <div className='mt-4 fn-14'>Password</div>
            <input name='password' className='password-inp' type='password' value={password} disabled={isPassDisabled} onChange={passwordChange} />
             */}

            
            <div className='rev-name-box mt-5'>
                <div>
                    <div className='fn-14'>Organization Name</div>
                    <div className='mt-2 fn-18'>{orgName}</div>
                </div>

                {isAdmin && <div className='upload-section'>
                    <div>
                        <span>{isAdmin}</span>
                        {orgLogo && <img src={orgLogo+('?v='+Date.now())} className='org-pic' />}
                    </div>
                    <div>
                        <div className='fn-14'>Organization Logo</div>
                        <input id="org-logo" type="file" onChange={(e) => onProfileUpload(e, 'org-logo')} style={{ display: "none" }} />
                        <button className='blue-btn mt-2' onClick={() => document.getElementById("org-logo").click()}>UPLOAD IMAGE</button>
                    </div>
                </div>}
            </div>
            {isAdmin && <><div className='fn-14 my-3'>Organization: Brief Description</div>
            <textarea name='des' className='des-area' value={orgDes} onChange={passwordChange} disabled={isDesDisabled}></textarea>
            <button className='blue-btn ml-3' onClick={updateDes}>{!isDesDisabled ? 'Save' : 'Edit'}</button>{apiLoading && <img className='ml-2' src={Loading} width='25px' />}
            </>}
            {isAdmin && <div className='colw-50 mt-5'>
                <SocialRow img={Web} url={webUrl} payload={'website'} getDetails={getDetails} />
                <SocialRow img={Twitter} url={twitterUrl} payload={'twitter'} getDetails={getDetails} />
                <SocialRow img={Reddit} url={redditUrl} payload={'reddit'}  getDetails={getDetails} />
                <SocialRow img={FB} url={fbUrl} payload={'facebook'} getDetails={getDetails} />
                <SocialRow img={LinkedIn} url={linkedInUrl} payload={'linkedin'} getDetails={getDetails} />
                <SocialRow img={InstaImg} url={instaUrl} payload={'instagram'} getDetails={getDetails} />
            </div>}
            
        </div>
    )
}
