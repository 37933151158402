import React, { useEffect, useState } from 'react';
import Sus from '../../assets/images/suspension.svg'; 
import './suspension.css';
import Header from '../header/header.js';

export default function Suspension(props) {

    useEffect(() => {

    }, []);

    return(
        <>
            <Header/>
            <div className="suspension-page">
                <img src={Sus}/>
            </div>
            <h3 className="text-center">We are Sorry</h3><br/>
            <div className="text-center txt-style">The page you are trying to access has been restricted.</div><br/>
            <div className="text-center txt-style">Please contact administrator: <span className="blue-font">admin@repusar.com</span></div>
        </>

    )
}
