import React, { useEffect, useState, useRef } from 'react';
import './notifications.scss';
import FSLogo from '../../../../assets/images/FSLogo.svg';
import Notification from '../../../../assets/images/notification-outline.svg';
import { Avatar, Badge, Button, Menu, Dropdown, Drawer, Tabs, TabPane } from 'antd';
import { UserOutlined, CloseOutlined, SettingOutlined } from '@ant-design/icons';
import Utils from '../../../../utils/utils';
import axios from 'axios';
import NotificationTiles from './notification-tiles/notification-tiles';
import NotificationSettings from './notification-settings/notification-settings';

export default function Navbar(props) {
  const { TabPane } = Tabs;
  const [selectedFlag, setSelectedFlag] = useState(null);

  useEffect(() => {
    const getNotifications = () => {
      let api = Utils.getAPIDescription('get-tipline-notifications') + '?org_id=' + (localStorage.getItem('org_id') !== 'undefined' ? localStorage.getItem('org_id') : 0);
      axios.get(api)
          .then((res) => {
            if (res.status === 200) {
              if (res.data && res.data.statusCode === 200) {
                let response = res.data.response;
                props.setNotifications(response)
              }
            }
          });
    }

    if (props.isLogin) {
      getNotifications();
    }
  }, [props.isLogin]);



  return (<div className="notification-container">
    <Drawer placement="right" visible={props.openNotificaiton} closable={false} onClose={() => { props.setopenNotificaiton(false) }} className="notification-drawer">
      <div className="notification-header">
        <div className="text">
          Notifications
        </div>
        <div className="right-section">
            <div className="settings-btn">
              <Button shape='circle' type="text" icon={<SettingOutlined />} onClick={() => { props.setopenNotificaitonSettings(true); props.setopenNotificaiton(false) }} />
            </div>
          <div className="close-btn">
            <Button shape='circle' type="text" icon={<CloseOutlined />} onClick={() => { props.setopenNotificaiton(false) }} />
          </div>
        </div>
      </div>
      <div className="notification-tabs">
        <Tabs defaultActiveKey="1">
          <TabPane tab={
            <span className='tab-label'>
              Unread ({props.notifications ? props.notifications.unread_count : 0})
            </span>
          } key="1">
            {props.notifications && props.notifications.unread && props.notifications.unread.map((notification, i) => {
              return (<NotificationTiles item_key={`unread_${i}`} notification={notification} notifications={props.notifications} selectedFlag={selectedFlag} setSelectedFlag={setSelectedFlag} setNotifications={props.setNotifications} type='unread' />)
            })}
          </TabPane>
          <TabPane tab={
            <span className='tab-label'>
              All ({props.notifications ? props.notifications.all_count : 0})
            </span>
          } key="2">
            {props.notifications && props.notifications.all && props.notifications.all.map(function (notification, i) {
              return <NotificationTiles item_key={`all_${i}`} notification={notification} notifications={props.notifications} selectedFlag={selectedFlag} setSelectedFlag={setSelectedFlag} setNotifications={props.setNotifications} type='all' />
            })}
            {/* {props.notifications && props.notifications.read && props.notifications.read.map(function (notification, i) {
              return <NotificationTiles notification={notification} notifications={props.notifications} selectedFlag={selectedFlag} setSelectedFlag={setSelectedFlag} setNotifications={props.setNotifications} type='all_read' />
            })} */}
          </TabPane>
        </Tabs>
      </div>
    </Drawer>
    <Drawer placement="right" visible={props.openNotificaitonSettings} closable={false} onClose={() => { props.setopenNotificaitonSettings(false) }} className="notification-drawer">
      <NotificationSettings {...props}/>
    </Drawer>
  </div>);
};

