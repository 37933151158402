import React, { useEffect, useState } from 'react';
import './addnewauthor.css';
import { notification, Select } from 'antd';
import { useHistory } from "react-router-dom";
import Utils from '../../../../utils/utils';
import axios from 'axios';

export default function CPAddNewAuthor(props) {
	const [reqErr, setReqErr] = useState(false);
	const [isAdmin, setIsAdmin] = useState('testing');
	const [authorName, setAuthorName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	useEffect(() => {
		if (localStorage.getItem('user_types').includes('org_admin')) {
			setIsAdmin(true);
		}else{
			setIsAdmin(false);
		}
		document.title = "Add Author";
	}, [])



	const checkForm = () => {
		if (authorName === "" ) {
			setReqErr(true);
		}
		else {
			addAuthor();
		}
	}

	const addAuthor = () => {
		let api = Utils.getAPIDescription('add-author');
		setIsLoading(true);
		axios.post(api, { "author_name": authorName,  "orgId": JSON.parse(localStorage.getItem('org_id'))})
		    .then((res) => {
		        notification.success({
		            message: 'Author added successfully',
		            duration: 3,
		            placement: 'topRight',    
		        });
		        setIsLoading(false);
		});
	}
	return (
		<div className="app-workspace">
            <div className="app-content">
				{isAdmin ? <div className="add-user-container pull-left">
					<button className='blue-border-btn mb-4 fn-14' onClick={() => { history.goBack() }}>BACK</button>
					<div className='create-heading'>Add New Author</div>
					<div className="add-user">
						<div className="name-section">
							<div className="name-div">
								<div className="fn-13 fnw-600 mb-2">Author Name:</div>
								<input className="user-input" name="fname" onChange={(evt)=>{setAuthorName(evt.currentTarget.value)}} />
								{!authorName && reqErr && <div className="err-msg">This field is required</div>}
							</div>
							<div className="add-auth-btn">
								<button class="blue-btn ml-4" onClick={()=>{checkForm()}}>{isLoading ? <span>ADDING...</span> : <span>ADD NEW</span>}</button>
							</div>
						</div>
					</div>
				</div> :
					<div className="add-user-container pull-left">
						<h2>Permission Denied</h2>
					</div>}
			</div>
		</div>
	)
}
