import React, {useEffect} from 'react';


export default function ErrorFallback(error, componentStack, resetError) {


    return (
        <React.Fragment>
            <h1>You have encountered an error</h1>
            <div>{error.toString()}</div>
            <div>{componentStack}</div>
            <button
                onClick={() => {
                    resetError();
                }}
            >
                Click here to reset!
            </button>
        </React.Fragment>
    );
}