import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, notification, Switch } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Utils from '../../../../../utils/utils';
import './notification-settings.scss';

export default function NotificationSettings(props) {
    // const [emailNotificationPref, setEmailNotificationPref] = useState(false);
    const [tiplineFlagNotificationsEmail, setTiplineFlagNotificationsEmail] = useState(false);
    const [newOpportunityNotificationEmail, setNewOpportunityNotificationEmail] = useState(false);
    const [callUpdate, setCallUpdate] = useState(false);
    const [loading, setLodaing] = useState(false);

    useEffect(() => {
        getUserInfo();
        // console.log(props)
    }, []);
    useEffect(() => {
        if (callUpdate) {
            submitPreferences();
        }
    }, [callUpdate]);
    useEffect(() => {
        if(!props.openNotificaitonSettings){
            props.setopenNotificaiton(true)
        }
    }, [props.openNotificaitonSettings]);
    


    const getUserInfo = async () => {
        try {
            const apiPath = Utils.getAPIDescription('get_user_details') + '?userId=' + localStorage.user_id;
            let config = {
                headers: {
                    email: localStorage.email
                }
            };
            let data = await axios.get(apiPath, config);
            let res = data.data;
            if (res.statusCode == 200) {
                setTiplineFlagNotificationsEmail(res.body?.notification_prefs?.tipline_flag_notifications_email)
                setNewOpportunityNotificationEmail(res.body?.notification_prefs?.new_opportunity_notification_email)
                // setEmailNotificationPref(res.body.notification_prefs.email_notification)
            } else {
                console.error(res)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const submitPreferences = () => {
        setLodaing(true)
        // e.preventDefault();
        let api = Utils.getAPIDescription('notificaiton-settings-update-preferences');
        let payload = {
            "tipline_flag_notifications_email": tiplineFlagNotificationsEmail,
            "new_opportunity_notification_email": newOpportunityNotificationEmail
        }

        axios.post(api, payload, { headers: { "userid": `${localStorage.getItem('user_id')}` } })
            .then((res) => {
                setCallUpdate(false);
                setLodaing(false)
                if (res.data.statusCode === 200) {
                    notification.success({
                        message: res.data.message,
                        duration: 3,
                        placement: 'topRight',
                    });
                } else {
                    notification.error({
                        message: res.data.message,
                        duration: 10,
                        placement: 'topRight',
                    });
                }
            });

    }
    return (<div className="notification-settings-container">

        <div className="notification-header">
            <div className="text">
                Notifications Settings
            </div>
            <div className="right-section">
                <div className="close-btn">
                    <Button shape='circle' type="text" icon={<CloseOutlined />} onClick={() => { props.setopenNotificaitonSettings(false) }} />
                </div>
            </div>
        </div>
        <div className="back-button">
            <div className='back-btn' onClick={() => { props.setopenNotificaitonSettings(false);}}><LeftOutlined /> &nbsp; Back</div>
            {/* <Button type="text" className='back-btn'  >  </Button> */}
        </div>
        <div className="body-content">
            <div className="heading">
                Manage the emails you receive
            </div>
            <div className="sub-heading">
                We’ll let you know when:
            </div>
            <div className="options-sections">
                {/* <form onSubmit={submitPreferences}> */}
                <div className='input-section'>
                    <Switch checked={tiplineFlagNotificationsEmail} onChange={(val) => { setTiplineFlagNotificationsEmail(val); setCallUpdate(true); }}/>  <span className='label'>A new tipline flag has been created.</span>
                </div>
                {/*<div className='input-section'>*/}
                {/*    <Switch checked={newOpportunityNotificationEmail} onChange={(val) => { setNewOpportunityNotificationEmail(val); setCallUpdate(true); }}></Switch>  <span className='label'>New opportunities identified.</span>*/}
                {/*</div>*/}
                {/* <div className='button-section'>
                        <Button className='submit-btn' htmlType='submit' loading={loading}>Save Preferences</Button>
                    </div> */}
                {/* </form> */}
                {/* <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={1} className="options">Get notified by email soon as tips are submitted</Radio>
                        <Radio value={2} className="options">Receive a batch of notifications daily</Radio>
                        <Radio value={3} className="options">Receive a batch of notifications weekly</Radio>
                        <Radio value={4} className="options">No email notifications, please</Radio>
                    </Space>
                </Radio.Group> */}
            </div>
        </div>

    </div>)
}