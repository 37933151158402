import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  Modal,
  notification,
  Select,
  Spin,
  Table,
  Popover,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import axios from "axios";
import Utils from "../../utils/utils";
import CPAddUser from "../partner-console/pages/add-user/add-user";
import styles from "./users.module.scss";
import moment from "moment-timezone";

const { Search } = Input;
const { Option } = Select;

const Users = (props) => {
  const getInitialOrgId = () => {
    const pathParts = window.location.pathname.split("/");
    return pathParts.length > 3 && pathParts[3] ? pathParts[3] : 0;
  };

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [modalState, setModalState] = useState({
    visible: false,
    type: null,
    userId: null,
    user:null,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(
    { value: props.org_id } || { value: parseInt(getInitialOrgId()) }
  );
  const [userStatus, setUserStatus] = useState("active");
  const [notificationPrefs, setNotificationPrefs] = useState({
    tipline_flag_notifications_email: false,
    new_opportunity_notification_email: false,
  });

  const [orgsServicesMap, setOrgServicesMap] = useState({});

  // Combine role states
  const [roles, setRoles] = useState({
    main: "",
    factbrief: "",
    claimsdb: "",
    drafterai:""
  });

  const fetchOrgList = useCallback(async () => {
    try {
      const api = Utils.getAPIDescription("get-org-list-updated");
      const res = await axios.get(api);
      const data = res.data.orgs;
      const orgsWithAll = [{ page_id: 0, org_name: "All" }, ...data];
      setOrgList(orgsWithAll);

      // Create the hash map
      const servicesMap = data.reduce((acc, org) => {
        acc[org.page_id] = org.services || [];
        return acc;
      }, {});

      setOrgServicesMap(servicesMap);
    } catch (error) {
      console.error("Error fetching org list:", error);
      notification.error({ message: "Failed to fetch organizations" });
    }
  }, []);

  useEffect(() => {
    fetchOrgList();
  }, [fetchOrgList]);

  const fetchUsers = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const api = `${Utils.getAPIDescription(
          "get-all-users"
        )}?page=${page}&limit=${
          pagination.pageSize
        }&search_query=${searchQuery}&org_id=${
          selectedOrgId.value || "All"
        }&state=${userStatus}`;
        const response = await axios.get(api);
        if (response.data) {
          const usersData = response.data.body.map((user) => ({
            ...user,
            role: mapRole(user.userType),
            fbrole: getadditionalrole(user.userType, "fb"),
            claimsdbrole: getadditionalrole(user.userType, "cdb"),
            drafterairole: getadditionalrole(user.userType, "dai"),
          }));
          setUsers(usersData);
          setPagination((prev) => ({
            ...prev,
            total: response.data.total_count,
            current: page,
          }));
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        notification.error({ message: "Failed to fetch users" });
      } finally {
        setLoading(false);
        setInitialLoading(false);
      }
    },
    [pagination.pageSize, searchQuery, selectedOrgId, userStatus]
  );

  useEffect(() => {
    if (!modalState.visible) {
      fetchUsers(pagination.current);
    }
  }, [fetchUsers, modalState.visible, pagination.current]);

  const debouncedFetchUsers = useCallback(() => debounce(fetchUsers, 600), [fetchUsers]);

  useEffect(() => {
    if (searchQuery !== "")
      debouncedFetchUsers();
  }, [debouncedFetchUsers, searchQuery]);

  const handleSearch = (e) => setSearchQuery(e.target.value);
  const handleOrgFilter = (org) => {
    setSelectedOrgId(org);
  };
  const handleStatusChange = (status) => setUserStatus(status);

  const updateUser = async (userId, firstName, lastName, roles) => {
    if (window.confirm("Confirm update user roles")) {
      setLoading(true);
      try {
        const api = Utils.getAPIDescription("update-user");
        const body = {
          userid: userId,
          firstname: firstName,
          lastname: lastName,
          role: mapRoleToApi(roles.main),
          factbrief_service: { role: mapRoleToApi(roles.main) },
          claimsdb_service: { role: mapRoleToApi(roles.claimsdb) },
          drafterai_service: { role: mapRoleToApi(roles.drafterai) }
        };
        const response = await axios.post(api, body);
        if (response.data && response.data.statusCode === 200) {
          notification.success({
            message: "Information updated successfully.",
          });
          if (userId === localStorage.getItem("user_id")) {
            localStorage.setItem("firstname", firstName);
            localStorage.setItem("lastname", lastName);
          }
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.id === userId
                ? {
                    ...user,
                    firstname: firstName,
                    lastname: lastName,
                    role: roles.main,
                    factbrief_service: { role: roles.main },
                    claimsdb_service: { role: roles.claimsdb },
                    drafterai_service: { role: roles.drafterai }
                  }
                : user
            )
          );
          setModalState({ visible: false, type: null, userId: null });
        } else {
          throw new Error("Update failed");
        }
      } catch (error) {
        console.error("Error updating user:", error);
        notification.error({ message: "Failed to update user information" });
      } finally {
        setLoading(false);

        setTimeout(() => {
          fetchUsers();
        }, 1000);

      }
    }
  };

  const updateNotificationPreferences = async (userId) => {
    setLoading(true);
    try {
      const api = Utils.getAPIDescription(
        "notificaiton-settings-update-preferences"
      );
      const payload = { ...notificationPrefs };
      const response = await axios.post(api, payload, {
        headers: { userid: `${userId}` },
      });
      if (response.data && response.data.statusCode === 200) {
        notification.success({
          message: "Notification preferences updated successfully.",
        });
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      console.error("Error updating notification preferences:", error);
      notification.error({
        message: "Failed to update notification preferences",
      });
    } finally {
      setLoading(false);
      setModalState((prev) => ({ ...prev, visible: false }));
    }
  };
  const changeStatus = async (userId, newStatus) => {
    setLoading(true);
    try {
      const api = Utils.getAPIDescription("manage-reviewer-state");
      const body = { reviewer_id: userId, state: newStatus };
      await axios.post(api, body);
      notification.success({
        message: "User status updated",
        duration: 3,
        placement: "topRight",
      });
      fetchUsers(pagination.current);
    } catch (error) {
      console.error("Error changing user status:", error);
      notification.error({ message: "Failed to update user status" });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveUser = (userId) => {
    if (window.confirm("Deactivate user?")) {
      changeStatus(userId, false);
    }
  };

  const actionsMenu = (user) => (
    <Menu>
      <Menu.Item
        key="updatePassword"
        onClick={() => {
          /* TODO: Implement password update */
        }}
        disabled
      >
        Update Password
      </Menu.Item>
      <Menu.Item key="removeUser" onClick={() => handleRemoveUser(user.id)}>
        Remove User
      </Menu.Item>
      <Menu.Item
        key="muteNotifications"
        onClick={() =>
          setModalState({
            visible: true,
            type: "notifications",
            userId: user.id,
          })
        }
      >
        Notifications Settings
      </Menu.Item>
      <Menu.Item
        key="changeRoles"
        disabled={
          props?.services
              ? !props.services.factbrief_service && !props.services.claimsdb_service && !props?.services?.drafterai_service
              : !orgsServicesMap[user.org_id]?.includes('factbrief_service') && !orgsServicesMap[user.org_id]?.includes('claimsdb_service') && !orgsServicesMap[user.org_id]?.includes('drafterai_service')
        }
        onClick={() => {
          if (
            props?.services
            ? props.services.factbrief_service || props.services.claimsdb_service || !props?.services?.drafterai_service
            : orgsServicesMap[user.org_id]?.includes('factbrief_service') || orgsServicesMap[user.org_id]?.includes('claimsdb_service') || orgsServicesMap[user.org_id]?.includes('drafterai_service')
          ) {
            setRoles({
              main: user.role,
              factbrief: getadditionalrole(user.userType, "fb"),
              claimsdb: getadditionalrole(user.userType, "cdb"),
              drafterai: getadditionalrole(user.userType, "dai")
            });
            setModalState({ visible: true, type: "roles", userId: user.id, user: user });
          }
        }}
      >
        {(props?.services
              ? !props?.services?.factbrief_service && !props?.services?.claimsdb_service && !props?.services?.drafterai_service
              : !orgsServicesMap[user.org_id]?.includes('factbrief_service') && !orgsServicesMap[user.org_id]?.includes('claimsdb_service') && !orgsServicesMap[user.org_id]?.includes('drafterai_service')) ? (
          <Popover
            content="Please enable at least one service to use this feature"
            trigger="hover"
            placement="left"
          >
            <span>Update Roles</span>
          </Popover>
        ) : (
          <span>Update Roles</span>
        )}
      </Menu.Item>
    </Menu>
  );

  const getColumns = (services) => {
    const columns = [
      {
        title: "Name",
        dataIndex: "firstname",
        key: "firstname",
        render: (text, record) => `${record.firstname} ${record.lastname}`,
      },
      { title: "Email", dataIndex: "email", key: "email" },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text, record) =>
          record.createdAt
            ? moment.utc(record.createdAt).local().fromNow()
            : "-",
      },
      {
        title: "Last Login",
        dataIndex: "lastLogin",
        key: "lastLogin",
        render: (text, record) =>
          record.lastLogin
            ? moment.utc(record.lastLogin).local().fromNow()
            : "-",
      },
      { title: "Newsroom", dataIndex: "org_name", key: "org_name" },
    ];

    if (services?.factbrief_service) {
      columns.push({
        title: "Factbrief Service Role",
        dataIndex: "fbrole",
        key: "fbrole",
      });
    }

    if (services?.claimsdb_service) {
      columns.push({
        title: "Parser Role",
        dataIndex: "claimsdbrole",
        key: "claimsdbrole",
      });
    }

    if (services?.drafterai_service) {
      columns.push({
        title: "Drafter AI Role",
        dataIndex: "drafterairole",
        key: "drafteraibrole",
      });
    }

    columns.push({
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Dropdown overlay={actionsMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    });

    return columns;
  };

  const columns = getColumns(props?.services);

  const addUserCompRef = useRef();

  const callRegisterUser = () => {
    console.log(addUserCompRef);
    if (addUserCompRef.current) {
      addUserCompRef.current.registerUser();
    }
  };

  const createUpdateUser = () => {
    if (modalState.type === "notifications") {
      updateNotificationPreferences(modalState.userId);
    } else if (modalState.type === "roles") {
      updateUser(
        modalState.userId,
        users.find((user) => user.id === modalState.userId).firstname,
        users.find((user) => user.id === modalState.userId).lastname,
        roles
      );
    } else if (modalState.type === "addUser") {
      callRegisterUser();
    }
  };

  return (
    <div className={styles.MainContainer}>
      {initialLoading ? (
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <div
            className={styles.SearchContainer}
            style={{ alignItems: "flex-end" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <span>Search by email or name</span>
              <Search
                placeholder="Search users"
                allowClear
                onChange={handleSearch}
                style={{ width: 300, marginRight: 10 }}
              />
            </div>
            {props?.type !== "services_modal" && (
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <span>Newsroom Filter</span>
                <Select
                  placeholder="Filter by Organization"
                  style={{ width: 200 }}
                  onChange={handleOrgFilter}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={selectedOrgId}
                  labelInValue
                >
                  {orgList.map((org) => (
                    <Option key={org.page_id} value={org.page_id}>
                      {org.org_name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <span>Filter By Status</span>
              <Select
                value={userStatus}
                onChange={handleStatusChange}
                style={{ width: 200 }}
              >
                <Option value="active">Active</Option>
                <Option value="inactive">Inactive</Option>
              </Select>
            </div>
            <Button
              type="primary"
              onClick={() =>
                setModalState({ visible: true, type: "addUser", userId: null })
              }
              size={"large"}
            >
              Add User
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={users}
            pagination={pagination}
            loading={loading}
            onChange={(pagination) => fetchUsers(pagination.current)}
            rowKey="id"
          />
        </>
      )}
      <Modal
        title={
          modalState.type === "roles"
            ? "Update Roles"
            : modalState.type === "notifications"
            ? "Notification Settings"
            : "Add User"
        }
        visible={modalState.visible}
        onOk={createUpdateUser}
        onCancel={() =>
          setModalState({ visible: false, type: null, userId: null })
        }
        footer={[
          <Button
            key="back"
            onClick={() =>
              setModalState({ visible: false, type: null, userId: null })
            }
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={createUpdateUser}
          >
            {modalState.type === "roles"
              ? "Update"
              : modalState.type === "notifications"
              ? "Update"
              : "Create"}
          </Button>,
        ]}
      >
        {modalState.type === "notifications" ? (
          <div className={styles.NotificationsContainer}>
            <Checkbox
              checked={notificationPrefs.tipline_flag_notifications_email}
              onChange={(e) =>
                setNotificationPrefs({
                  ...notificationPrefs,
                  tipline_flag_notifications_email: e.target.checked,
                })
              }
            >
              Tipline Flag Notifications Email
            </Checkbox>
            <Checkbox
              checked={notificationPrefs.new_opportunity_notification_email}
              onChange={(e) =>
                setNotificationPrefs({
                  ...notificationPrefs,
                  new_opportunity_notification_email: e.target.checked,
                })
              }
            >
              New Opportunity Notification Email
            </Checkbox>
          </div>
        ) : modalState.type === "roles" ? (
          <div>
            {/*<div style={{marginBottom: "10px"}}>*/}
            {/*  <span>Existing Role</span>*/}
            {/*  <Select*/}
            {/*      value={roles.main}*/}
            {/*      onChange={(value) => setRoles({ ...roles, main: value })}*/}
            {/*      style={{ width: '100%' }}*/}
            {/*  >*/}
            {/*    <Option value="content_partner_admin">Editor</Option>*/}
            {/*    <Option value="content_partner">Writer</Option>*/}
            {/*  </Select>*/}
            {/*</div>*/}

            {((props?.services
              && props.services.factbrief_service)
              || orgsServicesMap[modalState?.user.org_id]?.includes('factbrief_service')) && (
              <div style={{ marginBottom: "10px" }}>
                <span>Fact brief service access level:</span>
                <Select
                  value={roles.main}
                  onChange={(value) =>
                    setRoles({ ...roles, factbrief: value, main: value })
                  }
                  style={{ width: "100%" }}
                >
                  <Option value="content_partner">Writer</Option>
                  <Option value="content_partner_admin">Editor/Admin</Option>
                </Select>
              </div>
            )}
            {((props?.services
              && props.services.claimsdb_service)
              || orgsServicesMap[modalState?.user.org_id]?.includes('claimsdb_service')) && (
              <div style={{ marginBottom: "10px" }}>
                <span>Parser service access level:</span>
                <Select
                  value={roles.claimsdb}
                  onChange={(value) => {
                    setRoles({ ...roles, claimsdb: value });
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="claimsdb_admin">Parser Admin</Option>
                  <Option value="claimsdb_user">User</Option>
                  <Option value="claimsdb_contributor">Database Manager</Option>
                </Select>
              </div>
            )}
            {((props?.services
              && props.services.drafterai_service)
              || orgsServicesMap[modalState?.user.org_id]?.includes('drafterai_service')) && (
              <div style={{ marginBottom: "10px" }}>
                <span>Drafter AI access level:</span>
                <Select
                  value={roles.drafterai}
                  onChange={(value) => {
                    setRoles({ ...roles, drafterai: value });
                  }}
                  style={{ width: "100%" }}
                >
                  <Option value="drafterai_user">Drafter AI User</Option>
                  {/* <Option value="claimsdb_contributor">Database Manager</Option> */}
                </Select>
              </div>
            )}
          </div>
        ) : (
          <CPAddUser
            modal={true}
            org_id={selectedOrgId.value}
            setSelectedOrgId={setSelectedOrgId}
            orgList={orgList ? orgList : []}
            setVisibility={setModalState}
            type={props?.type}
            services={props?.services}
            ref={addUserCompRef}
          />
        )}
      </Modal>
    </div>
  );
};

export default Users;

function debounce(func, wait) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

function mapRole(userType) {
  if (userType.includes("content_partner_admin")) {
    return "Editor/Admin";
  } else if (userType.includes("content_partner")) {
    return "Writer";
  }
  return userType;
}

function mapRoleToApi(role) {
  if (role === "Editor/Admin") {
    return "content_partner_admin";
  } else if (role === "Writer") {
    return "content_partner";
  } else if (role === "Database Manager") {
    return "claimsdb_contributor";
  } else if (role === "User Manager") {
    return "claimsdb_user";
  } else if (role === "User") {
    return "claimsdb_user";
  }
  else if (role === "Parser Admin") {
    return "claimsdb_admin";
  }
  else if (role === "Drafter AI User") {
    return "drafterai_user";
  }

  return role;
}

function getadditionalrole(userType, type) {
  if (type === "cdb") {
    if (userType.includes("claimsdb_user")) {
      return "User";
    } else if (userType.includes("claimsdb_contributor")) {
      return "Database Manager";
    }
    else if (userType.includes("claimsdb_admin")) {
      return "Parser Admin";
    }
    return "";
  } 
  else if (type === "dai") {
    if (userType.includes("drafterai_user")) {
      return "User";
    } else if (userType.includes("drafterai_contributor")) {
      return "Drafter AI Manager";
    }
    return "";
  }
  else {
    if (userType.includes("content_partner_admin")) {
      return "Editor/Admin";
    }
    if (
      userType.includes("content_partner") ||
      userType.includes("cp_contributor")
    ) {
      return "Writer";
    }
    return "";
  }
}
