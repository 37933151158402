import React, {useEffect, useRef, useState} from 'react';
import {Button, notification} from "antd";
import styles from "./preview.module.scss";
import Loading from "../../../../../assets/images/loader.gif";
import {useHistory} from "react-router-dom";
import Utils from "../../../../../utils/utils";
import axios from "axios";




export default function FactBriefPreview(props) {
    let pageId = props.match.params.pageId;
    const history = useHistory();
    const isDraft = Utils.getParameterByName('draft') === 'true';
    let iframeURL;

    let baseBriefURL = Utils.getBaseURL();
    // baseBriefURL = 'http://localhost:8080'

    if (isDraft)
        iframeURL = `${baseBriefURL}/fact-briefs/${pageId}|draft?header=false`;
    else
        iframeURL = `${baseBriefURL}/fact-briefs/${pageId}?header=false`;


    // const [message, setMessage] = useState('');
    const [loading, stillLoading] = useState(true);
    const [canSelfPublish, setCanSelfPublish] = useState(false);
    const [briefPublished, setBriefPublished] = useState(false);


    const [checklistStep, setChecklistStep] = useState(0);
    const [checklistDone, setChecklistDone] = useState(false);
    const [openTour, setOpenTour] = useState(false);




    const finishLoading = () => {
        console.log("FINISHED")
        stillLoading(false);
        const iframe = document.getElementById("brief-preview");

        console.log(iframe);

        // setIframeContent(iframe.contentWindow.document.documentElement.innerHTML);

        // console.log(iframe.contentWindow.document.documentElement.innerHTML);

        console.log(iframe.getElementsByClassName('factbriefs_header__WE6L6'));

    };

    const sendMessageToIFrame = (message) => {
        const iframe = document.getElementById("brief-preview");
        let r = iframe.contentWindow.postMessage({message: message}, baseBriefURL);
        setOpenTour(true);
        // console.log(r)
    };

    // useEffect(() => {
    //     console.log(message)
    // }, [message]);

    const viewLiveBrief = () => {
        Utils.openNewTab(Utils.getBaseURL() + '/fact-briefs/' + pageId);
    };

    const publishBrief = async () => {
        stillLoading(true);
        let briefId = Utils.getParameterByName('pageId');
        
        let newsroomUserName = localStorage.getItem('newsroom_user_name')
        let newsroomUserApplicationPassword = localStorage.getItem('newsroom_user_application_password')
        let newsroomPluginHomeUrl = localStorage.getItem('newsroom_home_url')

        let orgName = localStorage.getItem('org_name')
        let userEmailId = localStorage.getItem('email')

        let apiPath = Utils.getAPIDescription('publish-unpublish-brief');

        let body = {
            'action': 'publish',
            'pageId': briefId,
            'newsroomUserName': newsroomUserName,
            'newsroomUserApplicationPassword': newsroomUserApplicationPassword,
            'newsroomPluginHomeUrl': newsroomPluginHomeUrl,
            'orgName': orgName,
            'userId': userEmailId
        };

        axios.post(apiPath, body).then((response) => {
            if (response.status === 200 && response.data.statusCode === 200) {
                setBriefPublished(true);
                notification.success({
                    message: "Brief published successfully!",
                    placement: "bottomRight",
                })
            }
            else {
                console.log(response);
                notification.error({
                    message: "Something went wrong while publishing",
                    placement: "bottomRight",
                })
            }
        }).catch((error) => {
            console.log(error);
            notification.error({
                message: "Something went wrong while publishing",
                placement: "bottomRight",
            })
        }).finally(() => {
            stillLoading(false);
        })


    };

    const backToEditing = () => {
        history.push(`/partnerconsole/create-fact-brief/${pageId}`)
    };

    // This hook is listening an event that came from the Iframe
    useEffect(() => {
        // document.domain = 'localhost';

        if (localStorage.getItem('org_self_publish') === 'true' && localStorage?.user_types.split(',').includes("content_partner_admin")) {
            setCanSelfPublish(true);
        }
        // window.scrollTo({ top: 0, behavior: 'smooth' });

        if (sessionStorage.getItem(`checklist_complete_${pageId}`) === 'true'){
            setChecklistDone(true);
        }

        const handler = (ev) => {
            console.log('ev', ev.data);

            // if (typeof ev.data !== 'object') return;
            // if (!ev.data.type) return;
            // if (ev.data.type !== 'button-click') return;
            // if (!ev.data.message) return;

            if (ev.data.type === 'checklist_step'){
                setChecklistStep(parseInt(ev.data.message))
            }

            if (ev.data.payload?.event === 'shutdown') {
                setOpenTour(false);
            }

            if (ev.data.type === 'checklist_closed'){
                setOpenTour(false);

                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            if (ev.data.type === "checklist_done"){
                setChecklistDone(true);
                sessionStorage.setItem(`checklist_complete_${pageId}`, 'true');
                setOpenTour(false);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };

        window.addEventListener('message', handler);

        return () => window.removeEventListener('message', handler)
    }, []);

    useEffect(() => {

        if(canSelfPublish && isDraft)
            document.getElementById('action_panel').scrollIntoView();
        
    }, [canSelfPublish, isDraft]);

    return(<div style={{width: "100%", height: "100%"}}>
            {(canSelfPublish && isDraft) &&
                <div className={openTour ? styles.preview_action_div_hidden : styles.preview_action_div} id={'action_panel'}>
                    <div className={styles.preview_action_inner_div}>
                        {briefPublished?
                        <div className={styles.preview_action_text1}>Your fact brief has been published!</div>
                        :
                        <>
                        <div className={styles.preview_action_text1}>This is a preview of what your fact brief will look like when published.</div>
                        <div className={styles.preview_action_text1}>Run through the pre-publish checklist to ensure your fact brief is ready!</div>
                        </>
                        }
                    </div>
                    <div className={styles.preview_action_inner_div2}>
                        {!checklistDone ?
                            <Button type={"primary"} className={styles.publish_button} onClick={() => sendMessageToIFrame("START_TOUR")}>Begin checklist</Button> :

                            briefPublished ?
                                <Button type={"primary"} className={styles.publish_button} onClick={() => viewLiveBrief()}>View live Brief</Button> :
                                <Button type={"primary"} className={styles.publish_button} onClick={() => publishBrief()}>Publish</Button>
                        }
                        {
                            !briefPublished &&
                            <div className={styles.back_button} onClick={()=> backToEditing()}>Back to editing</div>
                        }
                    </div>
                </div>
            }
            {/*<Tour*/}
            {/*    ref={joyrideRef}*/}
            {/*    steps={getTourSteps()}*/}
            {/*    startTour={openTour}*/}
            {/*    setStartTour={setOpenTour}*/}
            {/*    dispatch={null}*/}
            {/*/>*/}
            {loading ? <div className='text-center'><img alt={"Loading"} src={Loading} width='20px' className={styles.loader}/></div> : ''}
            <iframe id={'brief-preview'}
                    title={"Brief Preview"}
                    src={iframeURL}
                    style={{border: 'none'}}
                    width={"100%"}
                    height={"100%"}
                    onLoad={finishLoading}/>
           </div>)
}
