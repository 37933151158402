import React, { useState, useEffect } from 'react';
import { Dropdown, Input } from 'antd';
import Downarrow from '../../../assets/images/arrowdown.png';

const selectBoxStyles = {
  width: '250px',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid black',
};

const inputStyles = {
  width: '100%',
  border: 'none',
  outline: 'none',
  paddingRight: '30px',
};

const dropdownStyles = {
  width: '250px',
  maxHeight: '150px',
  overflowY: 'auto',
  zIndex: 1000,
  border: '1px solid #ececec',
  backgroundColor: 'white',
};

const itemStyles = {
  borderBottom: '1px solid #ececec',
  padding: '3px 5px',
  fontSize: '13px',
  cursor: 'pointer',
};

const SelectBox = (props) => {
  // console.log(props.datasource)
  const [dataSource, setDataSource] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (props.datasource.length > 0) {
      createSelectBox(props.datasource);
    }
  }, [props.datasource]);

  const createSelectBox = (source) => {
    setDataSource(source);
  };

  const handleOptionSelect = (item) => {
    setSelectedValue(item);
    props.onchange(item);
  };

  const onInputChange = (e) => {
    setQuery(e.target.value);
  };

  const filteredOptions = dataSource.filter((item) =>
    item.toLowerCase().includes(query.toLowerCase())
  );

  const menu = (
    <div style={dropdownStyles}>
      {filteredOptions.map((item, indx) => (
        <div
          key={indx}
          style={itemStyles}
          onClick={() => handleOptionSelect(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} getPopupContainer={(trigger) => trigger.parentElement}>
      <div style={selectBoxStyles}>
        <Input
          placeholder={props.placeholder}
          value={query}
          onChange={onInputChange}
          style={inputStyles}
        />
        <img src={Downarrow} alt="select box dropdown" style={{ margin: '10px' }} />
      </div>
    </Dropdown>
  );
};

export default SelectBox;
