import React from 'react';

const Tag = ({ tagLabel, removeTag }) => {
    return (
            <>
                <div className="pull-left" style={{fontSize:'12px',color:'darkslategray'}}>
                    <span className="tagLabel">{tagLabel}</span>
                </div>
                <div className="pull-right cursor-pointer" onClick={() => {}}>
                    <div onClick={() => removeTag()} className="remove-tag">&times;</div>
                </div>
            </>
    );
};

export default Tag;

