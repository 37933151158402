import React, {useEffect, useState} from 'react';
import { Select, Spin } from 'antd';
import Utils from "../../../../utils/utils";
import axios from "axios";

let timeout;
let currentValue;

const fetch = (value, callback, setLoading) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    const searchBriefs = () => {
        setLoading(true);

        let api =
            Utils.getAPIDescription("quiz-admin-briefs-search") +
            "?org_id=" +
            localStorage.getItem("org_id") + "&search_string="+value;

        axios.get(api).then((res) => {
            let data = res.data.response;
            callback(data);
            setLoading(false);
        });
    };
    if (value) {
        timeout = setTimeout(searchBriefs, 500);
    } else {
        callback([]);
    }
};
const SearchInput = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [data]);

    // const [value, setValue] = useState();

    const handleSearch = (newValue) => {
        fetch(newValue, setData, setLoading);
    };
    const handleChange = (newValue) => {
        props.onChange(newValue);
        // setValue(newValue);
    };
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        // TODO: Implement paginated API call
        if (bottom) { console.log("YAAY") }
    };
    return (
        <Select
            showSearch
            value={props.value}
            loading={loading}
            dropdownRender={(menu) => (
                <>
                    {loading ?
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Spin />
                        </div>
                        :
                        menu}
                </>
            )}
            allowClear
            onPopupScroll={handleScroll}
            placeholder={props.placeholder}
            style={props.style}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={data.length > 0 ?
                (data || []).map((d) => ({
                value: 'https://gigafact.org/fact-briefs/' + d.slug,
                label: d.title,
            })) :
            props.initialOptions.map((d) => ({
                value: 'https://gigafact.org/fact-briefs/' + d.slug,
                label: d.title,
            }))}
        />
    );
};

export default SearchInput;