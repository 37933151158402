import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Modal,
  notification,
  Slider,
  Select,
  Switch,
} from "antd";
import AvatarEditor from "react-avatar-editor";
import styles from "./selfpublishingflow.module.scss";
import { CognitoSignUp } from "../partner-console/utilities/Auth";
import Utils from "../../utils/utils";
import axios from "axios";
import S3 from "react-aws-s3";
import CPAddUser from "../partner-console/pages/add-user/add-user";
import { useHistory } from "react-router-dom";
import { Spin } from "antd";
import { ToggleButton } from "react-bootstrap";
import { RequiredAstreix } from "../partner-console/pages/organization-management/create-new-organization/create-new-organization";

const SelfPublishingFlow = (props) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    websiteUrl: "",
    consoleImage: null,
    tiplineImage: null,
    tiplineMetaImage: null,
    facebookurl: "",
    instagramurl: "",
    linkedinurl: "",
    redditurl: "",
    twitterurl: "",
    self_publish_status: false,
  });

  const [errors, setErrors] = useState({});
  const [imageEditor, setImageEditor] = useState({
    display: false,
    scale: 1,
    image: null,
    type: "",
  });
  const editorRef = useRef(null);
  const history = useHistory();

  const [consoleImage, setConsoleImage] = useState(null);
  const [addUserModalVisibility, setAddUserModalVisibility] = useState(false);
  const [tiplineImage, setTiplineImage] = useState(null);
  const [tiplineMetaImage, setTiplineMetaImage] = useState(null);
  const [consoleImageType, setConsoleImageType] = useState("png");
  const [tiplineImageType, setTiplineImageType] = useState("png");
  const [tiplineMetaImageType, setTiplineMetaImageType] = useState("png");
  const [selectedServices, setSelectedServices] = useState([]);
  const [selfPublishStatus, setSelfPublishStatus] = useState(false);
  const [selectedTiplineFIle, setSelectedTiplineFile] = useState(null);
  const [createNew, setcreateNew] = useState(false);
  const [addUserKey, setAddUserKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [orgId, setOrgID] = useState("");
  const [orgList, setOrgList] = useState([]); // State to store organization list
  const handleModalVisibility = () => {
    setAddUserModalVisibility(!addUserModalVisibility);
    history.push(`/partnerconsole/users/${orgId}`);
  };
  const refreshAddUserModal = () => {
    setAddUserKey((prevKey) => prevKey + 1);
  };

  const bucketName = "gigafact-app-images";
  const folderName = "resources";

  useEffect(() => {
    console.log(consoleImageType);
  }, [consoleImageType]);

  const config = {
    bucketName: bucketName,
    dirName: folderName,
    region: "us-east-2",
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  };

  const fileUpload = async (file, type) => {
    let filename = "";
    if (type === "logo1") {
      filename = `${props.org_name.replaceAll(
        " ",
        ""
      )}_Logo.${consoleImageType}`;
    } else if (type === "logo2") {
      filename = `${props.org_name.replaceAll(
        " ",
        ""
      )}_Tipline_logo.${tiplineImageType}`;
    } else {
      filename = `${props.org_name.replaceAll(
        " ",
        ""
      )}_tipline_metaimage.${tiplineMetaImageType}`;
    }
    console.log(file, type);

    // Helper function to convert base64 to a File object
    const base64ToFile = (base64, filename, mimeType) => {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new File([byteArray], filename, { type: mimeType });
    };

    // Extract base64 part from the file data URL
    const base64Data = file?.split(",")[1];
    const mimeType = file?.match(/:(.*?);/)[1];

    // Convert base64 to File
    if (file) {
      const pngFile = base64ToFile(base64Data, filename, mimeType);

      try {
        const ReactS3Client = new S3(config);
        const data = await ReactS3Client.uploadFile(pngFile, filename);
        return data.location;
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Something went wrong!",
          duration: 3,
          placement: "topRight",
        });
        throw error;
      }
    }
  };

  const validateInputs = () => {
    let errors = {};
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      websiteUrl,
      consoleImage,
      tiplineImage,
    } = formData;

    if (!firstName.trim()) errors.firstName = "First Name is required";
    if (!lastName.trim()) errors.lastName = "Last Name is required";
    if (!password.trim()) errors.password = "Password is required";
    else if (password.length < 8)
      errors.password = "Password must be at least 8 characters long";
    if (password !== confirmPassword)
      errors.confirmPassword = "Passwords do not match";
    if (!websiteUrl.trim()) errors.websiteUrl = "Website URL is required";
    else if (!/^https?:\/\/.+/.test(websiteUrl))
      errors.websiteUrl = "Invalid Website URL";
    if (!consoleImage) errors.consoleImage = "Newsroom Logo is required";
    if (!tiplineImage)
      errors.tiplineImage = "Newsroom Banner Image is required";

    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageSelect = (type, e) => {
    const file = e.target.files[0];
    console.log(type, file);

    if (file) {
      if (type === "consoleImage") {
        setConsoleImageType(e.target.files[0]?.name?.split(".").reverse()[0]);
      } else if (type === "tiplineImage") {
        setTiplineImageType(e.target.files[0]?.name?.split(".").reverse()[0]);
      } else {
        setTiplineMetaImage(e.target.files[0]?.name?.split(".").reverse()[0]);
      }
      setImageEditor({
        display: true,
        scale: 1,
        image: URL.createObjectURL(file),
        type,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateInputs();
    if (Object.keys(errors).length === 0) {
      try {
        await createOrganization();
        console.log("Form submitted successfully");
      } catch (error) {
        console.error("Error:", error);
        notification.error({
          message: error.message,
          duration: 5,
          placement: "topRight",
        });
      }
    } else {
      setErrors(errors);
      console.log("Validation errors:", errors);
    }
  };

  const createOrganization = async () => {
    setLoading(true);
    let api = Utils.getAPIDescription("create-organisation");
    let payload = { org_name: props.org_name };
    try {
      const res = await axios.post(api + "?source=self-setup", payload);
      if (res.data.statusCode && res.data.statusCode === 200) {
        console.log("org created");
        await createAuthUser(res.data.response.org_id);
        setOrgID(res.data.response.org_id);
        let org_id = res.data.response.org_id;
        try {
          const logo1 = await fileUpload(formData.consoleImage, "logo1");
          const logo2 = await fileUpload(formData.tiplineImage, "logo2");
          const logo3 = await fileUpload(formData.tiplineMetaImage, "logo3");
          await updateOrganization(
            res.data.response.org_id,
            logo1,
            logo2,
            logo3
          );
          setLoading(false);
          setDisabled(true);
          // setAddUserModalVisibility(true)
          history.push(`/partnerconsole/network`);
        } catch (uploadErr) {
          setLoading(false);
          console.error("Error uploading logos:", uploadErr);
          notification.error({ message: uploadErr.message });
        }
      } else {
        setLoading(false);
        notification.error({ message: res.data.message });
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
      notification.error({ message: err.message });
    }
  };

  const handleCancelSelf = () => {
    setFormData({
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        websiteUrl: "",
        consoleImage: null,
        tiplineImage: null,
        tiplineMetaImage: null,
        facebookurl: "",
        instagramurl: "",
        linkedinurl: "",
        redditurl: "",
        twitterurl: "",
        self_publish_status: false
    });
    props?.handleCancel();
};


  const createAuthUser = async (orgId) => {
    let payload = {
      username: props.admin_email,
      password: formData.password,
      attributes: {
        "custom:first_name": formData.firstName,
        "custom:last_name": formData.lastName,
        "custom:role": "content_partner_admin",
        "custom:org_id": String(orgId),
      },
    };
    try {
      await CognitoSignUp(payload);
    } catch (error) {
      notification.error({
        message: error.message,
        duration: 5,
        placement: "topRight",
      });
    }
  };

  const updateOrganization = async (orgId, logo1, logo2, logo3) => {
    let api = Utils.getAPIDescription("update-org");
    let payload = {
      logo1: logo1,
      logo2: logo2,
      logo3: logo3,
      services: selectedServices,
      self_publish_status: selfPublishStatus,
      links: {
        website: formData.websiteUrl,
        facebook: formData.facebookurl,
        twitter: formData.twitterurl,
        linkedin: formData.linkedinurl,
        instagram: formData.instagramurl,
        reddit: formData.redditurl,
      },
      org_id: String(orgId),
    };
    let config = {
      headers: { Authorization: localStorage.getItem("repustar_token") },
    };
    try {
      await axios.post(api, payload, config);
      notification.success({
        message: "Setup Complete!",
        duration: 3,
        placement: "topRight",
      });
    } catch (err) {
      console.error(err);
      notification.error({ message: err.message });
    }
  };

  const handleImageUpdate = () => {
    if (editorRef.current) {
      console.log(editorRef.current);
      const canvas = editorRef.current.getImage();
      const croppedImage = canvas.toDataURL();
      if (imageEditor.type === "consoleImage") {
        setConsoleImage(croppedImage);
        setFormData({ ...formData, consoleImage: croppedImage });
      } else if (imageEditor.type === "tiplineImage") {
        setTiplineImage(croppedImage);
        setFormData({ ...formData, tiplineImage: croppedImage });
      } else {
        setTiplineMetaImage(croppedImage);
        setFormData({ ...formData, tiplineMetaImage: croppedImage });
      }
      setImageEditor((prevState) => ({ ...prevState, display: false }));
    }
  };

  const handleZoomChange = (scale) => {
    setImageEditor((prevState) => ({ ...prevState, scale }));
  };

  const fetchOrgList = async () => {
    let api = Utils.getAPIDescription("get-org-list");
    axios
      .get(api)
      .then((res) => {
        let data = res.data.orgs;
        // Filter out organizations except your own
        const filteredOrgs = data.filter((org) => org.page_id === orgId);
        setOrgList(filteredOrgs);
      })
      .catch((error) => {
        console.error("Error fetching org list:", error);
      });
  };

  return (
    <div className={styles.selfPublishingFlowContainer}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label className={styles.label}>Select Services</label>
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select services"
              value={selectedServices}
              onChange={(value) => setSelectedServices(value)}
            >
              <Select.Option value="factbrief_service">
                FactBrief Service
              </Select.Option>
              <Select.Option value="claimsdb_service">
                Parser Service
              </Select.Option>
              <Select.Option value="drafterai_service">
                Drafter AI Service
              </Select.Option>
            </Select>
          </div>
          <>
            {selectedServices.includes("factbrief_service") && (
              <div
                className={styles.formGroup}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <label className={styles.label}>Self Publish Status</label>
                <Switch
                  checked={selfPublishStatus}
                  onChange={(checked) =>
                    setSelfPublishStatus(
                      selectedServices.includes("factbrief_service")
                        ? checked
                        : false
                    )
                  }
                />
              </div>
            )}
          </>

          <div className={styles.formGroup}>
            <label className={styles.label}>
              Primary Admin First Name
              <RequiredAstreix />
            </label>
            <Input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="Enter Primary Admin First Name"
            />
            {errors.firstName && (
              <div className={styles.error}>{errors.firstName}</div>
            )}
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Primary Admin Last Name
              <RequiredAstreix />
            </label>
            <Input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Enter Primary Admin Last Name"
            />
            {errors.lastName && (
              <div className={styles.error}>{errors.lastName}</div>
            )}
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Password
              <RequiredAstreix />
            </label>
            <Input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter Primary Admin Password"
            />
            {errors.password && (
              <div className={styles.error}>{errors.password}</div>
            )}
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Confirm Password
              <RequiredAstreix />
            </label>
            <Input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm Primary Admin Password"
            />
            {errors.confirmPassword && (
              <div className={styles.error}>{errors.confirmPassword}</div>
            )}
          </div>
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Website URL
            <RequiredAstreix />
          </label>
          <Input
            type="text"
            name="websiteUrl"
            value={formData.websiteUrl}
            onChange={handleInputChange}
            placeholder="Enter Website URL"
          />
          {errors.websiteUrl && (
            <div className={styles.error}>{errors.websiteUrl}</div>
          )}
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Facebook URL</label>
          <Input
            type="text"
            name="facebookurl"
            value={formData.facebookurl}
            onChange={handleInputChange}
            placeholder="Enter Facebook URL"
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Instagram URL</label>
          <Input
            type="text"
            name="instagramurl"
            value={formData.instagramurl}
            onChange={handleInputChange}
            placeholder="Enter Instagram URL"
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>LinkedIn URL</label>
          <Input
            type="text"
            name="linkedinurl"
            value={formData.linkedinurl}
            onChange={handleInputChange}
            placeholder="Enter LinkedIn URL"
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Reddit URL</label>
          <Input
            type="text"
            name="redditurl"
            value={formData.redditurl}
            onChange={handleInputChange}
            placeholder="Enter Reddit URL"
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Twitter URL</label>
          <Input
            type="text"
            name="twitterurl"
            value={formData.twitterurl}
            onChange={handleInputChange}
            placeholder="Enter Twitter URL"
          />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Newsroom Logo <RequiredAstreix />
          </label>
          <div>
            <Button
              onClick={() =>
                document.getElementById("consoleImageInput").click()
              }
            >
              {consoleImage ? "Change Image" : "Upload Image"}
            </Button>
            <Input
              id="consoleImageInput"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageSelect("consoleImage", e)}
              style={{ display: "none" }}
            />
          </div>
          {errors.consoleImage && (
            <div className={styles.error}>{errors.consoleImage}</div>
          )}
          {consoleImage && (
            <div className={styles.previewBlockLogo}>
              <img
                src={consoleImage}
                alt="Console Preview"
                className={styles.previewImage}
              />
            </div>
          )}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Newsroom Banner Image
            <RequiredAstreix />
          </label>
          <div>
            <Button
              onClick={() =>
                document.getElementById("tiplineImageInput").click()
              }
            >
              {tiplineImage ? "Change Image" : "Upload Image"}
            </Button>
            <Input
              id="tiplineImageInput"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageSelect("tiplineImage", e)}
              style={{ display: "none" }}
            />
          </div>
          {errors.tiplineImage && (
            <div className={styles.error}>{errors.tiplineImage}</div>
          )}
          {tiplineImage && (
            <div className={styles.previewBlockLogo2}>
              <img
                src={tiplineImage}
                alt="Tipline Preview"
                className={styles.previewImageBanner}
              />
            </div>
          )}
        </div>
        <Modal
          open={addUserModalVisibility}
          onCancel={handleModalVisibility}
          footer={null}
          style={{ width: "100%", resize: "auto" }}
        >
          <CPAddUser
            key={addUserKey}
            org_id={orgId.toString()}
            modal={true}
            setAddUserKey={refreshAddUserModal}
            addUserKey={addUserKey}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              onClick={handleModalVisibility}
              type="default"
              style={{ marginTop: 16 }}
            >
              Close
            </Button>
          </div>
        </Modal>
        <div className={styles.formGroup}>
          <label className={styles.label}>Tipline Meta Image</label>
          <div>
            <Button
              onClick={() =>
                document.getElementById("tiplineMetaImageInput").click()
              }
            >
              {tiplineMetaImage ? "Change Image" : "Upload Image"}
            </Button>
            <Input
              id="tiplineMetaImageInput"
              type="file"
              accept="image/*"
              onChange={(e) => handleImageSelect("tiplineMetaImage", e)}
              style={{ display: "none" }}
            />
          </div>
          {tiplineMetaImage && (
            <div className={styles.previewBlockTiplineMetacard}>
              <img
                src={tiplineMetaImage}
                alt="Tipline Meta Preview"
                className={styles.previewImageTiplineMeta}
              />
            </div>
          )}
        </div>
        <div style={{ display: "flex",gap:'20px' }}>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.submitBtn}
            disabled={loading || disabled}
          >
            {loading ? <Spin /> : "Create Organization"}
          </Button>
          {!loading && (
            <Button
              type="primary"
              onClick={handleCancelSelf}
              style={{ width: "200px" }}
              className={styles.submitBtnCancel}
            >
              Cancel
            </Button>
          )}
        </div>
      </form>

      <Modal
        title="Adjust Image"
        visible={imageEditor.display}
        onOk={handleImageUpdate}
        onCancel={() =>
          setImageEditor((prevState) => ({ ...prevState, display: false }))
        }
      >
        {imageEditor.type === "consoleImage" && (
          <AvatarEditor
            ref={editorRef}
            image={imageEditor.image}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.6]}
            scale={imageEditor.scale}
            rotate={0}
          />
        )}
        {imageEditor.type === "tiplineImage" && (
          <AvatarEditor
            ref={editorRef}
            image={imageEditor.image}
            width={410}
            height={100}
            border={1}
            color={[129, 129, 129, 0.6]} // RGBA
            scale={imageEditor.scale}
            rotate={0}
          />
        )}
        {imageEditor.type === "tiplineMetaImage" && (
          <AvatarEditor
            ref={editorRef}
            image={imageEditor.image}
            width={480}
            height={250}
            border={1}
            color={[129, 129, 129, 0.6]} // RGBA
            scale={imageEditor.scale}
            rotate={0}
          />
        )}

        <Slider
          min={1}
          max={10}
          step={0.1}
          value={imageEditor.scale}
          onChange={handleZoomChange}
          style={{ marginTop: "10px" }}
        />
      </Modal>
    </div>
  );
};

export default SelfPublishingFlow;
