import { LeftOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AlertedBellIcon from '../../../../../assets/images/console/alerted_icon.svg';
import Facebook from '../../../../../assets/images/fb_logo.svg';
import fsHalf from '../../../../../assets/images/fs-half.svg';
import Twitter from '../../../../../assets/images/twitter.png';
import Web from '../../../../../assets/images/website.png';
import Utils from '../../../../../utils/utils.js';
import FlagDetails from '../flag-details/flag-details';
import { useHistory } from "react-router-dom";





export const OpportunityToAnswerDetails = (props) => {
  const [questions, setQuestions] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [flagId, setFlagId] = useState(null);
  const [actionTaken, setActionTaken] = useState(false);
  const [openSeekersModal, setOpenSeekersModal] = useState(false);
  const [selectedFlag, setSelelctedFlag] = useState(null);
  const history = useHistory()

  useEffect(() => {
    setQuestions(props.selectedOpportunitiesDetails)
  }, []);

  const doActions = async (flag, action) => {
    setActionTaken(true)
    let index = props.selectedOpportunitiesDetails.flags.indexOf(flag)
    let temp = Object.assign({}, props.selectedOpportunitiesDetails);
    temp.flags[index].action = action;
    temp.flags[index].loading = true;
    props.setSelectedOpportunitiesDetails(temp)
    let api = Utils.getAPIDescription('content-partner-recommend-answers-action')
    try {
      let payload = {
        key: props.selectedOpportunitiesDetails.key,
        action,
        flag_id: flag.id,
        org_id: localStorage.org_id,
        org_name: localStorage.org_name,
        user_id: localStorage.user_id,
        factcheck_details: {
          articleImage: flag.articleLink,
          claimReviewed: props.selectedOpportunitiesDetails.factcheck_details.claimReviewed,
          datePublished: props.selectedOpportunitiesDetails.factcheck_details.datePublished,
          url: props.selectedOpportunitiesDetails.factcheck_details.url
        }
      }
      let res = await axios.post(api, payload);
      if (res.status === 200 && res.data && res.data.statusCode && res.data.statusCode === 200) {
        let temp = Object.assign({}, props.selectedOpportunitiesDetails);
        delete temp.flags[index].action;
        temp.flags[index].loading = false;
        temp.flags[index].answered = true;
        if (action === 'ignored') {
          temp.flags.splice(index, 1);
        }
        props.setSelectedOpportunitiesDetails(temp);
        setActionTaken(false)
        setOpenSeekersModal(false);

        // Set cache control flag for recommendation API to fresh copy
        props.updateCacheControl('fresh');
      }
    } catch (error) {
      notification.error({ message: error.response?.data?.message })
    }
  }

  const getImage = (type) => {
    switch (type) {
      case 'web':
        return Web;
      case 'fb':
        return Facebook;
      case 'twitter':
        return Twitter;
      case 'intagram':
        return Twitter;
      case 'tiktok':
        return Twitter;
      default:
        return Web;
    }
  }
  const openWindow = (e, data) => {
    e.stopPropagation();
    if ((e.target.nodeName === 'BUTTON' || e.target.parentNode.nodeName === 'BUTTON')) {
      props.setShowOpportunitiesDetails(true); props.setSelectedOpportunitiesDetails(data);

    } else {
      window.open(data, '_blank')
    }
  }
  const goBack = (e) => {
    history.replace({ search: null })
    // props.setShowOpportunitiesDetails(false) 
  }
  return <div className="opportunity-detail-container">
    <div className="top-section">
      <div className="navigaction-section">
        <div className="back-btn-section">
          <Button type="text" className='back-btn' shape="circle" icon={<LeftOutlined />} size={'small'} onClick={goBack} />
        </div>
      </div>
      <div className="flag-section">

        <div div className="tiles" onClick={(e) => { props.setShowOpportunitiesDetails(true); openWindow(e, props.selectedOpportunitiesDetails.factcheck_details.url) }} >
          <div className="image-block">
            <img src={props?.selectedOpportunitiesDetails?.factcheck_details?.articleImage} alt="Article Image" width={100} />
          </div>
          <div className="description-block">

            <div className="question">
              <div className="text">
                {props?.selectedOpportunitiesDetails?.factcheck_details?.claimReviewed}
              </div>
              <div className="right-section">
                <div className="fact-seeker-counts">
                  <img src={fsHalf} alt="FS" width={10} />
                  <span>
                    {props?.selectedOpportunitiesDetails?.total_factseekers_count}
                  </span>
                </div>
                {/* <div className="see_flags">
                </div> */}
              </div>
            </div>
            <div className="date">
              <div className="publisher-name">
                {props?.selectedOpportunitiesDetails?.org_name}
              </div>
              <div className="date">
                {props?.selectedOpportunitiesDetails?.factcheck_details?.datePublished}
              </div>

              {/* {props.selectedOpportunitiesDetails.factcheck_details.datePublished} */}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="body-section">
      <div className="flags-heading">
        Flags
      </div>

      {questions && questions.flags && questions.flags.map(query =>
        <div className="similar-query-section" >
          <div className="falg-section" onClick={() => { setDrawerVisible(true); setFlagId(query.id) }}>


            <div className="query-top-section">
              <div className="heading">
                <div className="title">
                  {query.tags.join(", ")}
                </div>
                <div className="date">
                  {query.flag_created_elapsed_time}, {query.created_at}
                </div>
              </div>
              <div className="fact-seeker-counts">
                <img src={fsHalf} alt="FS" width={10} />
                <span>
                  {query.factseekers_count}
                </span>
              </div>
            </div>
            <div className="content">
              <div className="left-section">
                <div className="tweet_section">
                  <div className="user">
                    <div className="logo">
                      <img src={getImage(query.broadcaster_display_icon)} alt="FS" className='w-100' />
                    </div>
                    <div className="name">
                      {query.broadcaster_display_name}
                    </div>
                  </div>
                  <div className="description">
                    {query.content_title}
                  </div>
                </div>
                <div className="query-section">
                  <div className="user">
                    <div className="logo">
                      <img src={query.user_display_icon} alt="FS" className='w-100' />
                    </div>
                    <div className="name">
                      {query.user_display_name}
                    </div>
                  </div>
                  <div className="description">
                    {query.user_query}
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="action-section">
            {!query.answered && <div className="not-alerted">
              {/* <Button type="text" className='notify-btn' onClick={() => { doActions(query, 'answered') }} disabled={query.loading || actionTaken} loading={query.action === 'answered' && query.loading}>Notify fact seeker</Button> */}
              <Button type="text" className='notify-btn' onClick={() => { setOpenSeekersModal(true); setSelelctedFlag(query) }} disabled={query.loading || actionTaken} loading={query.action === 'answered' && query.loading}>Notify fact seeker</Button>
              <Button type="text" className='ignore-btn' onClick={() => { doActions(query, 'ignored') }} disabled={query.loading || actionTaken} loading={query.action === 'ignored' && query.loading}>Ignore and close</Button>
            </div>}
            {query.answered && <div className="alerted">
              <div className='alert-btn'>
                <img src={AlertedBellIcon} alt="FS" width={18} height={19} className="mr-2" />
                Notified
              </div>
            </div>}
          </div>
        </div>
      )}
    </div>
    <Drawer placement="right" closable={false} onClose={() => { setDrawerVisible(false) }} visible={drawerVisible} width='35%' >
      <FlagDetails
        flagId={flagId}
        setVisible={setDrawerVisible}
      />
    </Drawer>
    <Modal className='seekers-modal' title={null} visible={openSeekersModal} onOk={null} onCancel={() => { setOpenSeekersModal(false) }} footer={false}>
      {selectedFlag && <div className="header">
        Notify {selectedFlag.factseekers_list.length} fact seeker{selectedFlag.factseekers_list.length > 1 && <>s</>}.
      </div>}
      <div className="body-content">
        <div className={(selectedFlag && selectedFlag.factseekers_list && selectedFlag.factseekers_list.length) <= 1 ? " list-block d-flex" : 'list-block'}>
          {selectedFlag && selectedFlag.factseekers_list.map(user =>
            <div className='users-list'>
              <div className="circle"></div>
              <div className={user.profile_url ? "user-name" : "user-name no-profile"}>
                <a href={user.profile_url} target={'_blank'}>
                  {user.username}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        <div className="btn-cancel mr-3">
          <Button type="text" className='cancel' onClick={() => { setOpenSeekersModal(false) }} disabled={selectedFlag && selectedFlag.loading || actionTaken} loading={selectedFlag && selectedFlag.action === 'ignored' && selectedFlag.loading}>Cancel</Button>
        </div>
        <div className="confirm-btn">
          <Button type="text" className='confirm' onClick={() => { doActions(selectedFlag, 'answered') }} disabled={selectedFlag && selectedFlag.loading || actionTaken} loading={selectedFlag && selectedFlag.action === 'answered' && selectedFlag.loading} >Confirm</Button>
        </div>
      </div>
    </Modal>
  </div >;
}
