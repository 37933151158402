import navReducer from './navreducer';
import containerReducer from './containerreducer';
import flagReducer from './flagreducer';
import reviewReducer from './reviewreducer';
import {combineReducers} from 'redux'

const rootReducer = combineReducers({
    navReducer,
    containerReducer,
    flagReducer,
    reviewReducer
})

export default rootReducer