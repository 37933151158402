import React, { useState } from 'react';
import styles from "./watermark.module.scss";
import watermark from "watermarkjs";
import Watermark from "../../../../assets/images/watermark.png"

// import { InboxOutlined } from '@ant-design/icons';
// import {  Upload } from 'antd';




const ImageWatermarker = () => {
    // const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [watermarkedImage, setWatermarkedImage] = useState(null);
    const [imageHeight, setImageHeight] = useState(null);
    const [filename, setFilename] = useState(null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (uploadedImage) {
            setUploadedImage(null);
            setWatermarkedImage(null);
            setImageHeight(null); // Reset the image height state
        }
        if (file) {
            setFilename(file.name);
            const reader = new FileReader();
            reader.onload = (e) => {
                setUploadedImage(e.target.result);

                // Create an image object to load the uploaded image data
                const img = new Image();
                img.onload = () => {
                    // Once the image is loaded, set the height state
                    setImageHeight(img.height);
                };
                img.src = e.target.result; // Set the source to the data URL of the uploaded file
            };
            reader.readAsDataURL(file);
        }
    };

    const resizeWatermark = (watermarkSrc, callback) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set watermark height to be 10% of the target image height
        const watermarkHeight = imageHeight * 0.1;
        // Maintain a 1:3 width-to-height ratio for the watermark
        const watermarkWidth = watermarkHeight * 3;
        console.log(watermarkHeight, watermarkWidth, imageHeight)
        canvas.width = watermarkWidth;
        canvas.height = watermarkHeight;

        const img = new Image();
        img.onload = () => {
            ctx.drawImage(img, 0, 0, watermarkWidth, watermarkHeight);
            callback(canvas.toDataURL());
        };
        img.src = watermarkSrc;
    };

    var getX = function(image, mark) {
        // Position at the very start of the X-axis (left side)
        return image.width - mark.width;
    };

    var getY = function(image, mark) {
        // Position at the very end of the Y-axis (bottom side)
        // We subtract the height of the logo from the height of the boat image
        // to ensure the logo sits at the bottom without any padding
        return image.height - mark.height;
    };

    const applyWatermark = () => {
        // const watermarkSrc = 'img/logo.png'; // Path to your watermark image
        const targetImgHeight = uploadedImage.height;
        console.log(targetImgHeight)
        resizeWatermark(Watermark, (resizedWatermark) => {
            watermark([uploadedImage, resizedWatermark])
                .image(watermark.image.atPos(getX, getY, 0.85)) // Adjust positioning as needed
                .then((img) => {
                    const dataUrl = img.src; // Get the data URL of the watermarked image
                    setWatermarkedImage(dataUrl);
                });
        });
    };

    const downloadImage = () => {
        if (!watermarkedImage) return;

        const link = document.createElement('a');
        link.href = watermarkedImage;
        link.setAttribute('download', 'watermarked-image.png'); // Set the download file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };


    return (
        <div className={styles.container}>
            <div style={{textAlign: "center", paddingBottom: "15px"}}>
                <h3 className={styles.infoText}>Use this page to add a fact brief watermark to an image of your choice.</h3>
                <span style={{color: "#0071bc", fontSize: "16px", fontStyle: "italic"}}>NOTE: No images are stored on this console. Please download image files in order to save your work.</span>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <input className={styles.customFileInput} type="file" onChange={handleFileChange} accept="image/*" />
                {filename && <span style={{fontSize: "14px", color: "#3c3c3c", marginTop: "5px"}}>{filename}</span>}
            </div>
            {watermarkedImage ?
                <button className={styles.applyWatermarkButton} onClick={downloadImage}>Download watermark file</button>
                :
                <button className={styles.applyWatermarkButton} onClick={applyWatermark} disabled={!uploadedImage}>Apply Watermark</button>
            }

            {uploadedImage && !watermarkedImage && (
                <div className={styles.imageContainer}>
                    <img src={uploadedImage} alt="Uploaded" style={{ maxWidth: '100%' }} />
                </div>
            )}

            {watermarkedImage && (
                <div className={styles.imageContainer}>
                    <img src={watermarkedImage} alt="Watermarked" style={{ maxWidth: '100%' }} />
                </div>
            )}
        </div>
    );
};

export default ImageWatermarker;

