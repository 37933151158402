import React from 'react';
import './notification-tiles.scss';
import axios from 'axios';
import Utils from '../../../../../utils/utils';
import ThumbsUp from '../../../../../assets/images/console/thanks_notif_icon.svg';
import QuestionMark from '../../../../../assets/images/console/question_mark.svg';
import OpportunityIcon from '../../../../../assets/images/console/opportunity_notif_icon.svg';
import { GA } from '../../../utilities/ga';
// import ThumbsUp from '../../../../../../assets/images/console/thumbs_up.svg';


export default function NotificationTiles(props) {

    const openLink = (notification) => {
        GAEvents(notification);
        props.setSelectedFlag(notification);
        let data = props.notifications;
        let allFound = data.all.find((o, i) => o.created_at_key === notification.created_at_key);
        let unreadFound = data.unread.find((o, i) => o.created_at_key === notification.created_at_key);
        if (!notification.read) {
            readNotification(notification);
        }

        if (allFound)
            allFound.read = true;

        if (unreadFound) {
            if (!unreadFound.read) {
                data.unread_count -= 1;
            }
            unreadFound.read = true;
            data.unread.splice(data.unread.indexOf(unreadFound), 1);
        }

        props.setNotifications(data);

        window.open(notification.flag_link, '_blank')

    };

    const GAEvents = (notificaiton) => {
        switch (notificaiton.type) {
            case 'thanks_given':
                GA.trackEventWithValue("Notification: Thanks", "click", localStorage.user_id, notificaiton.flag_link)
                break;
            case 'new_opportunity':
                GA.trackEventWithValue("Notification: Opportunity", "click", localStorage.user_id, notificaiton.flag_link)
                break;
            default:
                break;
        }
    }

    const readNotification = (notification) => {
        let api = Utils.getAPIDescription('tipline-notifications-read');

        let payload = {
            "org_id": localStorage.getItem('org_id') !== 'undefined' ? localStorage.getItem('org_id') : 0,
            "created_at": notification.created_at_key,
            "event": "read"
        };

        axios.post(api, payload)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data && res.data.statusCode === 200) {


                    }
                }
            });

    };

    const getLogos = (type) => {
        switch (type) {
            case 'thanks_given':
                return ThumbsUp;
            case 'new_opportunity':
                return OpportunityIcon;
            default:
                return QuestionMark
        }
    };

    return (
        <div className="notification-notificationTiles" key={props.item_key}>
            <div className={`card-block ${props.notification.read === false ? 'unread' : ''}`} onClick={(e) => { openLink(props.notification) }}>
                <div className="top-nav">
                    <div className="logo">
                        <img src={getLogos(props.notification.type)} alt="" srcSet="" />
                    </div>
                    <div className="content" dangerouslySetInnerHTML={{ __html: props.notification.display_text }}>
                    </div>
                </div>
                <div className="body-content">
                    <div className="content" dangerouslySetInnerHTML={{ __html: props.notification.title }}>
                    </div>
                    <div className="hyperlink">
                        {props.notification.tags.join([', '])}
                    </div>
                </div>
            </div>
        </div>
    )
}