import React, {useRef, useState} from "react";
import { Button, Input, Select } from "antd";
import axios from "axios";
import OrgBanner from "../../../../OrgBanner/orgbanner.js";
import { SignUp } from "../../../authentication/signUp/sign-up.js"; // Assuming SignUp is exported as default from sign-up.js
import Utils from "../../../../../utils/utils.js";
import styles from "./create-new-organization.module.scss";
import SelfPublishingFlow from "../../../../selfpublishingflow/selfublishingflow.js";
import { notification } from "antd";

export const RequiredAstreix = () => {
  return <span style={{ color: "red", marginLeft: "5px" }}>*</span>;
};

export default function CreateNewOrganization() {
  const { Option } = Select;
  const [orgName, setOrgName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [orgType, setOrgType] = useState("Content Partner");
  const [setupType, setSetupType] = useState("self");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [qToken, setQToken] = useState("");
  const [showSelfPublishingFlow, setShowSelfPublishingFlow] = useState(true);
  const divRef = useRef();
  const isStep1Valid = () => {
    return (
      orgName.trim() !== "" && adminEmail.trim() !== "" && orgType.trim() !== ""
    );
  };

  const handleSetupTypeChange = (value) => {
    setSetupType(value);
    setShowSelfPublishingFlow(value === "self");
  };

  const handleCancel = () => {
    if(window.confirm("Cancel new org setup? This will clear out the form"))
    {
      setOrgName('');
      setAdminEmail('');
      setSetupType('self');
      setOrgType('Content Partner');
    }

    // setShowSelfPublishingFlow(false)
  };

  const handleStep1Submit = () => {
    const payload = {
      org_name: orgName,
      org_type: orgType,
      admin_email: adminEmail,
      setup_type: setupType,
    };

    if (setupType !== "self") {
      axios
        .post(Utils.getAPIDescription("create-new-org-link"), payload)
        .then((res) => {
          if (res.data?.statusCode === 201) {
            notification.success({
              message: "Organization created successfully!",
            });
          } else {
            notification.error({ message: res.data.message });
          }
        })
        .catch((err) => {
          console.log(err);
          notification.error({ message: "Failed to create organization." });
        });
    }
  };

  return (
    <>
      <div className={styles["org-tipline-admin"]}>
        <OrgBanner />
      </div>

      <div className={styles["new-org-setup-container"]}>
        <div className={styles["top-bar"]}>
          <div className={styles["heading-label"]} ref={divRef}>
            Create a new organization
          </div>
        </div>
        <div className={styles["body-content"]}>
          <div className={styles["form-section"]}>
            <div className={styles["input-section"]}>
              <div className={styles["label"]}>
                Organization name <RequiredAstreix />
              </div>
              <div className={styles["input-block"]}>
                <Input
                  placeholder="Type organization name"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
              </div>
            </div>
            {/*<div className={styles["input-section"]}>*/}
            {/*  <div className={styles["label"]}>*/}
            {/*    Organization type <RequiredAstreix />*/}
            {/*  </div>*/}
            {/*  <div className={styles["input-block"]}>*/}
            {/*    <Select*/}
            {/*      defaultValue="Content Partner"*/}
            {/*      className={styles["w-100"]}*/}
            {/*      value={orgType}*/}
            {/*      onChange={(value) => setOrgType(value)}*/}
            {/*    >*/}
            {/*      <Option value="Content Partner">Content Partner</Option>*/}
            {/*    </Select>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={styles["input-section"]}>
              <div className={styles["label"]}>
                Primary Admin email address <RequiredAstreix />
              </div>
              <div className={styles["input-block"]}>
                <Input
                  placeholder="Enter email address"
                  value={adminEmail}
                  onChange={(e) => setAdminEmail(e.target.value)}
                />
              </div>
            </div>
            <div className={styles["input-section"]}>
              <div className={styles["label"]}>
                Setup type <RequiredAstreix />
              </div>
              <div className={styles["input-block"]}>
                <Select
                  defaultValue="delegated"
                  className={styles["w-100"]}
                  value={setupType}
                  onChange={handleSetupTypeChange}
                >
                  <Option value="self">Self</Option>
                  <Option value="delegated">Delegated</Option>
                </Select>
              </div>
            </div>
            {showSelfPublishingFlow && (
              <div className={styles["input-section"]}>
                <div className={styles["input-block"]}>
                  <SelfPublishingFlow
                    org_name={orgName}
                    admin_email={adminEmail}
                    handleCancel={handleCancel}
                  />
                </div>
              </div>
            )}
            {!showSelfPublishingFlow && (
              <div className={styles["btn-section"]}>
                <div
                  className={styles["submit-btn"]}
                  style={{ display: "flex", gap: "20px" }}
                >
                  <Button
                    onClick={handleStep1Submit}
                    disabled={!isStep1Valid()}
                    style={{ flexGrow: "1" }}
                  >
                    {setupType !== "self"
                      ? "Create Organization"
                      : "Create Organization and Continue"}
                  </Button>
                  {isStep1Valid() &&
                    <Button
                      onClick={handleCancel}
                      style={{ width: "200px" }}
                      className={styles["cancel"]}
                    >
                      Cancel
                    </Button>
                    }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
