import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createStore} from 'redux'
import rootReducer from './redux/reducer'
import {Provider} from 'react-redux'
import Amplify from 'aws-amplify';
import {awsConfig} from './config';

import './index.css';
import './styles/styles.css';
import ErrorFallback from "./components/error/errorFallback";

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: awsConfig.cognito.REGION,
        userPoolId: awsConfig.cognito.USER_POOL_ID,
        identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: awsConfig.cognito.APP_CLIENT_ID,
        oauth: awsConfig.oauth
    },
    Storage: {
        region: awsConfig.s3.REGION,
        bucket: awsConfig.s3.BUCKET,
        identityPoolId: awsConfig.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            {
                name: "notes",
                endpoint: awsConfig.apiGateway.URL,
                region: awsConfig.apiGateway.REGION
            },
        ]
    }
});
const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

if (process.env.REACT_APP_ENV === 'PROD') {
    Sentry.init({
        environment: 'prod',
        dsn: "https://c7a856b5f5f94382a7022c10645fcf5b@o4504060167913472.ingest.sentry.io/4504125404807168",
        integrations: [new BrowserTracing()],

        // beforeSend(event, hint) {
        //     // Check if it is an exception, and if so, show the report dialog
        //     if (event.exception) {
        //         Sentry.showReportDialog({eventId: event.event_id});
        //     }
        //     return event;
        // },

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });
} 
// else {
//     Sentry.init({
//         environment: 'dev',
//         dsn: "https://da66788ebc4e450784c4b7207b7717ca@o4503975062732800.ingest.sentry.io/4503975086522368",
//         integrations: [new BrowserTracing()],
//
//         beforeSend(event, hint) {
//             // Check if it is an exception, and if so, show the report dialog
//             if (event.exception) {
//                 Sentry.showReportDialog({eventId: event.event_id});
//             }
//             return event;
//         },
//
//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 0.2,
//     });
// }



ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Sentry.ErrorBoundary
                fallback={({error, componentStack, resetError}) => ErrorFallback(error, componentStack, resetError)}>
                <App/>
            </Sentry.ErrorBoundary>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
