import Axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
// import Utils from '../../../../utils/utils';
import Loading from '../../../../assets/images/loader.gif';
import styles from './factcheckWorkbench.module.scss';
import {useHistory} from "react-router-dom";


const WORKBENCH_URL  = `${process.env.REACT_APP_FACTBRIEF_WORKBENCH_URL}?q=`;

export default function FactcheckWorkbench(props) {
    // const [iframeloading, setIframeloading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
          document.title = "Gigafact Partner Console";
        }, 1000);
        window.open(WORKBENCH_URL + localStorage.getItem("repustar_token"), "_blank");
        history.goBack();

      }, [history]);

    // const finishLoading = () => {
    //     setIframeloading(false);
    // };

    return (
        <div className={styles.workbenchContainer}>

            {/*{iframeloading ? <div className='text-center'><img alt={"Loading"} src={Loading} width='20px' className={styles.loader}/></div> : ''}*/}
            {/*<iframe id={'brief-preview'}*/}
            {/*        title={"Brief Preview"}*/}
            {/*        src={WORKBENCH_URL + localStorage.getItem("repustar_token")}*/}
            {/*        style={{border: 'none'}}*/}
            {/*        width={"100%"}*/}
            {/*        height={"100%"}*/}
            {/*        onLoad={finishLoading}/>*/}
        </div>

    )
}
