import React, { useState, useEffect } from 'react';
import { DatePicker, Button, Table, message } from 'antd';
import OrgBanner from '../OrgBanner/orgbanner';
import Utils from '../../utils/utils';
import axios from 'axios';
import 'antd/dist/antd.css'; 
import moment from 'moment';
import SpinFC from 'antd/lib/spin';

export const StatsExplorer = () => {
    const [orgDetails, setOrgDetails] = useState(null);
    const [stats, setStats] = useState([]);
    const [startDate, setStartDate] = useState('2024-01-01');
    const [endDate, setEndDate] = useState('2024-12-31');
    const [isLoading, setIsLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);

    useEffect(() => {
        getOrgsInfo();
    }, []);

    useEffect(() => {
        fetchStats();
    }, [startDate, endDate]);

    const getOrgsInfo = () => {
        const api = `${Utils.getAPIDescription('get-org-details')}?org_id=${localStorage.getItem('org_id')}`;
        axios.get(api)
          .then((res) => {
            if (res.data?.data) {
              setOrgDetails(res.data.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    };

    const fetchStats = async () => {
        setIsLoading(true);
        const api = `${Utils.getAPIDescription('get-stats')}?start_date=${startDate}&end_date=${endDate}&type=all`;
        try {
            const response = await axios.get(api);
            if (response.data) {
                setStats(response.data?.body);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleStartDateChange = (date, dateString) => {
        setStartDate(dateString);
    };

    const handleEndDateChange = (date, dateString) => {
        setEndDate(dateString);
    };

    const exportToCSV = async () => {
        setIsExporting(true);
        const api = `${Utils.getAPIDescription('export-stats')}?start_date=${startDate}&end_date=${endDate}`;
        try {
            const response = await axios.get(api);
            if (response.data) {
                const csvContent = convertToCSV(response.data);
                downloadCSV(csvContent);
            }
        } catch (err) {
            console.log(err);
            message.error('Failed to export data');
        } finally {
            setIsExporting(false);
        }
    };

    const convertToCSV = (data) => {
        if (data.length === 0) return '';
        
        const headers = Object.keys(data[0]);
        const csvRows = [];
        
        // Add header row
        csvRows.push(headers.join(','));
        
        // Add data rows
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }
        
        return csvRows.join('\n');
    };

    const downloadCSV = (csvContent) => {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `stats_export_${startDate}_to_${endDate}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const columns = [
        {
            title: 'NewsRoom',
            dataIndex: 'org_name',
            key: 'org_name',
        },
        {
            title: 'Fact Briefs Published',
            dataIndex: 'factbrief',
            key: 'factbrief',
        },
        {
            title: 'Tips Received',
            dataIndex: 'tips',
            key: 'tips',
        },
        {
            title: 'Quizzes Created',
            dataIndex: 'quiz',
            key: 'quiz',
        },
    ];

    return (
        <div className="dashboard-container">
            <div style={{marginBottom:'20px'}}>
                <OrgBanner orgdetails={orgDetails} />
            </div>
            <h2>Publication Activity</h2>
            <div style={{ marginBottom: '16px', display:'flex', justifyContent:'flex-end', gap:'30px', marginRight:'20px' }}>
                <DatePicker value={moment(startDate)} onChange={handleStartDateChange} />
                <DatePicker value={moment(endDate)} onChange={handleEndDateChange} />
                <Button type="primary" onClick={exportToCSV} loading={isExporting}>
                    Export to CSV
                </Button>
            </div>
            {isLoading ? (
                <div style={{width:'100%',height:'20vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <SpinFC/>
                </div>
            ) : (
                <Table dataSource={stats} columns={columns} />
            )}
        </div>
    );
};