import axios from 'axios';
import React, {useEffect, useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import Utils from '../../utils/utils';
import moment from 'moment-timezone';
import Loading from '../../assets/images/loader.gif';
import Header from '../header/header.js';
import Nav from '../nav/nav.js';
import './reviewDetails.css';
import Plus from '../../assets/images/Add-blue.svg';
import Cross from '../../assets/images/cross.svg';
import GigaIcon from '../../assets/images/logos/gigaFact-icon.png';
import Button from 'react-bootstrap/Button';
import { Select, Modal } from 'antd';
import { tuple } from 'antd/lib/_util/type';
import arrowImg from '../../assets/images/link_arrow.svg';
import Checks from '../../assets/images/checks.svg';
import {notification} from 'antd';


import FSBird from '../../assets/images/fs-half.svg';
import Question from '../../assets/images/question.png';
import LpQuizImgOp from '../../assets/images/lp_qz_img_op.svg';
import BannerArrowOp from '../../assets/images/banner-arrow_op.svg';
import XIcon from '../../assets/images/xicon.svg';
import RightBlack from '../../assets/images/quiz-right-black.svg';
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function ReviewDetails(props) {
    const [publishData, setPublishData] = useState(undefined);
    const [pageData, setPageData] = useState({});
    const [title, setTitle] = useState(null);
    const [score, setScore] = useState('');
    const [responseTo, setResponseTo] = useState({});
    const [interestedIn, setInterestedIn] = useState([]);
    const [relatedReviews, setRelatedReviews] = useState([]);
    const [sources, setSources] = useState([]);
    const [desc, setDesc] = useState('');
    const [isReview, setIsReview] = useState(true);
    const [createdBy, setCreatedBy] = useState('');
    const [fullName, setFullName] = useState('');
    const [joinedAt, setJoinedAt] = useState('');
    const [ownerId, setOwnerId] = useState(undefined);
    const [flagUrl, setFlagUrl] = useState(null);
    const [relatedListLoader, setRelatedListLoader] = useState(false);
    const [videoTime, setVideoTime] = useState(0);
    const [description, setDescription] = useState('');
    const [publishedDate, setPublishedDate] = useState('');
    const [currentSlug, setCurrentSlug] = useState('');
    const [reviewId, setReviewId] = useState('');
    const [isFYI, setIsFYI] = useState(false);
    const [inResponseToURL, setInResponseToURL] = useState(null);
    const [firstMod, setFirstMod] = useState({});
    const [secondMod, setSecondMod] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isDraft, setDraft] = useState(false);
    const [sourceData, setSourceData] = useState([]);
    const [selectedSource, setSelectedSource] = useState('Pick a source type');
    const [sourceUrl, setSourceUrl] = useState('');
    const [sourceLoading, setSourceLoading] = useState(false);
    const [sourceMsg, setSourceMsg] = useState('');
    const sourceItems = [{id: 0, value: 'Pick a source type', data: 'Pick a source type'}, {id: 1, value: 'Research finding', data: 'Research finding'}, {id: 2, value: 'Public document', data: 'Public document'}, {id: 3, value: 'Interview or eyewitness account', data: 'Interview or eyewitness account'}];
    const [seeMoreSourceData, setSeeMoreSourceData] = useState(false);
    const sourceContainerRef = useRef(null);
    const [sourceModal, setSourceModal] = useState(false);
    const [addUrl, setAddUrl] = useState(false);
    const [sourceComment, setSourceComment] = useState('');
    const [seeMoreLink, setSeeMoreLink] = useState('');
    const [orgDetails, setOrgDetails] = useState(null);
    const [orgLoading, setOrgLoading] = useState(false);
    const [isPreview, setisPreview] = useState(false);
    const [reviewerModal, setReviewerModal] = useState(false);
    const [recentReview, setRecentReview] = useState([]);
    const [recentRevSlice, setRecentRevSlice] = useState(3);
    const [parentPageSlug, setParentPageSlug] = useState(undefined);
    const [contentImage, setContentImage] = useState(null);

    const [newsAd, setNewsAd] = useState(null);
    const [showExploreModal, setShowExploreModal] = useState(false);
    const [quizItems, setQuizItems] = useState([]);
    const MAX_WEEK = 40;
    const QUIZ_LIMIT = 20;

    const [slug, setSlug] = useState(undefined);
    const containerRef = useRef(null);
    const { Option } = Select;
    let pageId = props.match.params.pageId;
    useEffect(() => {
        let parentPage = Utils.getParameterByName('parentPage');
        if (Utils.getParameterByName('draft')) {
            if (Utils.getParameterByName('preview')) {
                setisPreview(true);
            }
            let draft = Utils.getParameterByName('draft');
            if (draft) {
                setDraft(true);
                fetchData(true, parentPage);
            }

        }else{
            fetchData(false, parentPage);
        }
        getSources();
        fetchQuiz();
        //getOrgDetails();
    }, [])

    const isNumber = (id) => {
        let reg = new RegExp('/^\d+$/');
        // console.log(reg.test(id))
        return reg.test(id);
    }

    const handleQuizBannerClick = (source) => {
		    setShowExploreModal(true)
    }

    const fetchData = async (isDraft, parentPage) => {
        setIsLoading(true);
        let apiPath = ''
        if (isNumber(pageId)) {
            apiPath = Utils.getAPIDescription('review-details')+'?review_id='+pageId+'&show_draft='+isDraft + '&parent_page_slug=' + parentPage;
        }
        else {
            apiPath = Utils.getAPIDescription('review-details')+'?slug='+pageId+'&show_draft='+isDraft + '&parent_page_slug=' + parentPage;
        }
        try {
            let data =  await axios.get(apiPath);
            if(data && data.data){
                //setPublishers(data.data.publishers.publishers);
                //setTypes(data.data.sourceDataType.sourceDataType);
                setPublishData(data.data.body.review_details);

            }
        }catch (e) {
            console.log(`Error+${e}`);
        }
         setIsLoading(false);
        //sessionStorage.setItem('slug', slug);
        //let apiPath = Utils.getAPIDescription('graph-ql');
        /*fetch(apiPath, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: `query b {
                        article(slug: "${slug}", showDraft: ${isDraft}) {
                        pageType {
                            id
                            id
                            pageType
                        }
                        firstModerator {
                            id
                            username
                        }
                        secondModerator {
                            id
                            username
                        }
                        id
                        title
                        inResponseToURL
                        owner {
                            id
                            username
                            dateJoined
                        }
                        analysis
                        score {
                            score
                        }
                        topic {
                            image {
                                file
                                id
                            }
                        }
                        sources
                        timeTaken
                        flagUrl
                        firstPublishedAt
                        lastPublishedAt
                        slug
                        comments
                        PageState
                        relatedArticles
                    }
                }`,
            }),
        }).then(response => response.json())
            .then(data => {
                if(data && data.data && data.data.article && (data.data.article.length > 0)){
                    setPublishData(data.data.article);
                    console.log(data.data.article);
                }else{
                    //setLoadCount(1);
                    setIsReview(false);
                }
                setIsLoading(false);
                //setLoadCount(loadCount => loadCount + 1);
            })
            .catch(error => {
                console.log(error);
                //setLoadCount(loadCount => loadCount + 1);
                setIsLoading(false);
            });
            */
    }
    const getRelatedList = async() => {
        setRelatedListLoader(true);
        let apiPath = Utils.getAPIDescription('get-related-list');
        try {
            let data =  await axios.post(apiPath, {'title': `${title}`, 'description': `${description}`, 'review_url': `${currentSlug}`, 'review_id': `${reviewId}`, 'relatedArticleLinks': `${seeMoreLink}`});
            if(data && data.data && data.data.body){
                if(data.data.body.relevantResearch && data.data.body.relevantResearch.data){
                    setInterestedIn(data.data.body.relevantResearch.data);
                }
                if(data.data.body.content_partner_for_ad_display){
                    setNewsAd(data.data.body.content_partner_for_ad_display);
                }
                if(data.data.body.repustarChecks && data.data.body.repustarChecks.data){
                    let relatedRev = data.data.body.repustarChecks.data;
                    relatedRev = relatedRev.filter(item => (item.claimReviewed !== title));
                    setRelatedReviews(relatedRev);
                }
                setRelatedListLoader(false);
            }
        }catch (e) {
            console.log(`Error+${e}`);
            setRelatedListLoader(false);
        }
    }

    const fetchQuiz = () => {
      console.log('Fn called');
      let api = Utils.getAPIDescription('weekly-quiz-list') + '?num_weeks=' + MAX_WEEK +
        (localStorage.getItem('user_id') ? '&user_id=' + localStorage.getItem('user_id') :'');
      axios.get(api).then((res) => {
        let data = res.data
        if (data.statusCode === 200) {
          setQuizItems(data.response);
        }
        else{
          console.log(res.data.message);
        }
      }).catch(err => {
        console.log(err)
      })
    }

    const formatMonth = (fMonth) => {
        let rMonth = fMonth;
        if(fMonth.includes('Jun')){
            rMonth = fMonth.replace('Jun', 'June');
        }
        if(fMonth.includes('Jul')){
            rMonth = fMonth.replace('Jul', 'July');
        }
        return rMonth;
    }

    useEffect(() => {
        let review = false;
        if(publishData){
        //for(let i = 0; i < publishData.length; i++){
            review = true;
            setPageData(publishData);
            setFlagUrl(publishData.flagUrl);
            publishData.authorName.authorId && setOwnerId(parseInt(publishData.authorName.authorId));
            setFirstMod(publishData.firstModerator);
            setSecondMod(publishData.secondModerator);
            setPublishedDate(formatMonth(moment(publishData.firstPublishedAt).format('dddd, MMM DD, YYYY')));
            setTitle(publishData.title);
            setCurrentSlug(publishData.slug);
            setReviewId(publishData.id);
            setSources(publishData.sources);
            setCreatedBy(publishData.owner.username);
            setFullName(publishData.owner.firstName + ' ' +publishData.owner.lastName)
            setJoinedAt(publishData.owner.dateJoined);
            setInResponseToURL(publishData.inResponseToURL);
            setSeeMoreLink(publishData.relatedArticleLinks.length > 0 && publishData.relatedArticleLinks[0].URL);
            setOrgDetails(publishData.orgDetail);
            setSlug(publishData.slug);
            setParentPageSlug(publishData.parentPage.slug);
            if (publishData.contentImage.contentImageUrl) {
                setContentImage(publishData.contentImage)
            }
            let des = publishData.analysis;
            setDescription(des);
            (publishData.PageState === 'FYI') ? setIsFYI(true) : setIsFYI(false);
            des = des.replace(/<(?:.|\n)*?>/gm, '');
            (des.length > 200) ? setDesc(des.substring(0,200)) : setDesc(des)
            if(publishData.score && publishData.score.score){
                setScore(publishData.score.score);
            }else{
                setScore('UNRESOLVED');
            }
        // }
            if(!review){setIsReview(false);}
        }
    }, [publishData]);

    const getTips = async() => {
        let apiPath = Utils.getAPIDescription('get-my-tips');
        try {
            // Load async data from an inexistent endpoint.
            let data =  await axios.get(apiPath+'?id='+flagUrl);
            if(data && data.data && data.data.body[0]){
                setResponseTo(data.data.body[0]);
                if(data.data.body[0].videoTime){
                    let vT = data.data.body[0].videoTime;
                    let vTN = vT.split(':'); // split it at the colons
                    let vTNS = (+vTN[0]) * 60 * 60 + (+vTN[1]) * 60 + (+vTN[2]);
                    setVideoTime(vTNS);
                }
            }
        }catch (e) {
            console.log(`Error+${e}`);
        }
    }
    const getSources = async() => {
        let apiPath = Utils.getAPIDescription('get-sources');
        try {
            let data =  await axios.get(apiPath+'?page_id='+pageId);
            if(data && data.data && data.data.length > 0){
                let tData = data.data;
                let sourceArr = [];
                for(let i in tData){
                    let obj = {};
                    if(tData[i].status === 'discard'){
                       // if(localStorage && localStorage.getItem('user_id')){
                         //   if(tData[i].user_id === localStorage.getItem('user_id')){
                                obj.msg = 'A submitted source was removed by a Gigafact moderator because it did not meet our ';
                                sourceArr.push(obj);
                           // }
                        //}
                    }else{
                        obj.username = tData[i].username;
                        obj.source_url = tData[i].source_url;
                        obj.source_title = tData[i].source_title;
                        obj.creation_date = tData[i].creation_date;
                        sourceArr.push(obj);
                    }

                }
                setSourceData(sourceArr);
                //setSourceLoading(false);
                //setSourceModal(false);
                //sourceContainerRef.current.scrollTop = 0;
            }
        }catch (e) {
            console.log(`Error+${e}`);
        }
    }
    const getOrgDetails = async () => {
        setOrgLoading(true);
        let apiPath = Utils.getAPIDescription('get-org-details') + '?org_id=' + parseInt(localStorage.getItem('org_id'));
        let res = await axios.get(apiPath);
        try {
            if (res.data && res.data.data) {
                setOrgDetails(res.data.data);
            }
        }
        catch(e) {
            console.log(`Error+${e}`);
        }
        setOrgLoading(false);
    }
    const addSources = async() => {
        setSourceMsg('');
        let apiPath = Utils.getAPIDescription('add-sources');
        setSourceLoading(true);
        try {
            let data =  await axios.post(apiPath, {'url': `${sourceUrl}`, 'slug': `${currentSlug}`, 'reviewer_id': `${ownerId}`, 'user_id': `${localStorage.getItem('user_id')}`, 'source_type': `${selectedSource}`, 'comment' : `${sourceComment}`, 'username':  `${localStorage.getItem('username')}`});
            if(data && data.data){
                if(data.data.message === 'Success'){
                    getSources();
                }else{
                    setSourceMsg(data.data.message);
                }
            }
            setSourceLoading(false);
        }catch (e) {
            console.log(`Error+${e}`);
            setSourceLoading(true);
            setRelatedListLoader(false);
        }
    }

    useEffect(() => {
        if(flagUrl){
            getTips();
            getRelatedList();
        }
    }, [flagUrl]);
    const seeMore = (item, isMore) => {
        switch(item){
            case "source-data":
                setSeeMoreSourceData(isMore);
                break;
            default:
                break;
        }
    }
    const validateURL = (url) => {
        if(url.length > 0){
            let urlregex = new RegExp( "^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
            return urlregex.test(url);
        }
        return false;
    }

    const onUrlChange = (e) => {
        let _url = e.target.value.trim();
        setSourceUrl(_url);
        //if(validateURL(_url) && selectedSource !== "Pick a source type"){
        if(selectedSource !== "Pick a source type"){
            setAddUrl(true);
        }else{
            setAddUrl(false);
        }
    }

    const onSourceCommentChange = (e) => {
        let sourceComm = e.target.value.trim();
        setSourceComment(sourceComm);
    }

    const onSourceChange = (item) => {
        setSelectedSource(item);
       if(validateURL(sourceUrl) && item !== "Pick a source type"){
            setAddUrl(true);
        }else{
            setAddUrl(false);
        }
    }

    const getReveiwerDetails = () => {
        let apiPath = Utils.getAPIDescription('reviews-by-user');
        axios.post(apiPath, { ownerId: ownerId })
            .then(res => {
                setRecentReview(res.data.body.reviewsByUser);
            })
            .catch(error => {
                console.log(error);
            }
        );
    }

    const copyToClipboard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        notification.success({
                        message: "Preview link copied.",
                        placement: "topRight",
                    })
    };

    useEffect(() => {
        if(ownerId){
            getReveiwerDetails();
        }
    }, [ownerId]);

    return (
        <div className="app-workspace">
            <Header/>
            <div className="app-content">
                <Nav />
                <div className='review-details-layout'>
                    <div className="rev-det-left">
                        <div className='buttons mb-4'>
                            {isDraft && !isPreview && <Link to='/fact-briefs'><button className="blue-border-btn">BACK</button></Link>}
                            <span className={`pub-text${isDraft && !isPreview ? ' ml-5' : ''}`}>{isDraft ? 'NOT PUBLISHED' : 'PUBLISHED'}</span>
                            {isDraft  && <span className="blue-font cursor-pointer tell-us" onClick={() => {copyToClipboard((process.env.REACT_APP_ENV === 'PROD' ? 'https://repustar.com/fact-briefs/' : 'https://dev.repustar.com/fact-briefs/') + slug +'?draft='+isDraft)}}>&nbsp;&nbsp;&nbsp; Copy preview link</span>}
                        </div>
                        {isLoading ? <div className='text-center'><img src={Loading} width='20px' /></div> : ''}
                        {!isLoading && publishData ?
                            <>
                                {pageData.firstPublishedAt ? <div className='mt-2 fn-14'>{publishedDate}</div> : null}
                                <div className='my-3 revdet-title'>{pageData.title}</div>
                                <div className='why-txt'>
                                    By &nbsp;
                                    {
                                        ownerId ? <span className='blue-font cursor-pointer' onClick={() => setReviewerModal(true)}>{publishData.authorName.authorName}</span> :
                                        <span>{publishData.authorName.authorName}</span>
                                    }

                                    {firstMod && 'username' in firstMod ?
                                        <span className="ml-2">
                                            <span className="pipe-line">&nbsp;|&nbsp;&nbsp;</span>
                                            <span className="label bold-font">Moderated by</span>
                                            <span>{ firstMod.username }</span>

                                        </span> : ''
                                    }
                                    {orgDetails && <span className="ml-2">| &nbsp;For
                                        <span className="ml-1"><img src={orgDetails.logoURL} width="20px" height="20px" /></span>
                                        <a href={process.env.REACT_APP_ENV !== 'PROD' ? 'https://dev.repustar.com/contributor/' + `${publishData && publishData.orgDetail.slug}` : 'https://repustar.com/contributor/' + `${publishData && publishData.orgDetail.slug}`} target='_blank'>
                                            <span className="blue-font">&nbsp;&nbsp;{orgDetails.org_name}</span>
                                        </a>
                                    </span>}
                                {/* | Moderated by {firstMod ? <span className='blue-font'>{firstMod.username ? firstMod.username : 'N.A.'}</span> : 'N.A.'} */}
                                </div>
                                <div className="why-txt"> This Fact Brief is responsive to conversations such as <span className="blue-fonts cursor-pointer"><a href={!inResponseToURL ? ((responseTo && responseTo.campaignDetails) ?  (responseTo.campaignDetails.articleLink || (responseTo.campaignDetails.videoLink+'?t='+videoTime)) : '') : inResponseToURL} target='_blank'>this one.</a></span> </div>
                                <div className='analysis-board colw-80'>
                                    {!isFYI ? <div className={"pull-left " + score + "-btn text-center"}>{score.toUpperCase()}</div> : ''}
                                    <p dangerouslySetInnerHTML={{ __html: pageData.analysis }}></p>
                                    {contentImage && <figure>
                                        <img className="content-image" src={contentImage.contentImageUrl} height={contentImage.contentImageHeight} width={contentImage.contentImageWidth} />
                                        <figcaption>{contentImage.contentImageCaption}</figcaption>
                                    </figure>}
                                </div>
                                {seeMoreLink && orgDetails && <div className='see-fuller'>
                                    <a href={seeMoreLink} target='_blank' className='blue-font fn-18 text-underline'>See a full discussion of this at <span>{orgDetails.org_name}</span></a>
                                </div>}
                                <div className='source-board'>
                                    <div className='fn-18 font-weight-bolder mb-3'>Sources</div>
                                    {sources.length > 0 ?
                                        sources.map((item, idx) => (
                                            <div className="mt-1 fn-18"><span>{item.SiteName && item.SiteName}</span>&nbsp; &nbsp;<a href={item.URL} target='_blank' rel="noopener noreferrer" className="blue-font text-underline my-2" key={idx}>{item.Name && item.Name}</a></div>
                                        ))
                                        :
                                        <div className='fn-14 gray-font'>No Sources Aailable</div>
                                    }


                                </div>
                                {/* (process.env.REACT_APP_ENV !== 'PROD') ?
                                    <span>
                                        {<div className={"know-something " + "show-source"}><span style={{ fontWeight: '500' }}>Do you know something to add to this topic?</span><br /><span className="blue-fonts cursor-pointer"><img src={Plus} />&nbsp;<u className="tell-us" onClick={() => setSourceModal(true)}>Tell us</u></span></div>}
                                            {sourceData.length > 0 ?
                                                <div className={"source-data "+ "show-source" }>
                                                    <div className="source-title">Data and documents suggested by users</div>
                                                    <div className="source-data-content" ref={sourceContainerRef}>
                                                        {!seeMoreSourceData ?
                                                            <span>
                                                                {sourceData.slice(0,2).map((item, indx) => (
                                                                    <>
                                                                        {item.msg ?
                                                                            <div className="source-data-row msg-txt" key={'sdr_'+indx}><span>{item.msg}</span><span><Link to="/faq#what-are-the-goals-for-sourcing-on-repustar" target="_blank">sourcing goals.</Link></span></div>:
                                                                            <div className="source-data-row" key={'sdr_'+indx}>
                                                                                <div className='title'>{item.username ? (item.username+", " + item.creation_date) : item.creation_date}</div>
                                                                                <a href={item.source_url} target='_blank' rel="noopener noreferrer" className="blue-font">{item.source_title}</a>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                ))}
                                                                {sourceData.length > 2 ?
                                                                    <div className="colw-100 src-bg"><div className="pull-right cursor-pointer see-more" onClick={() => {seeMore('source-data', true)}}>See more</div></div> : null
                                                                }
                                                            </span> :
                                                            <span>
                                                                {sourceData.map((item, indx) => (
                                                                    <>
                                                                        {item.msg ?
                                                                            <div className="source-data-row msg-txt" key={'sdr_'+indx}><span>{item.msg}</span><span><Link to="/faq#what-are-the-goals-for-sourcing-on-repustar">sourcing goals.</Link></span></div>:
                                                                            <div className="source-data-row" key={'sdr_'+indx}>
                                                                                <div className='title'>{item.username ? (item.username+", " + item.creation_date) : item.creation_date}</div>
                                                                                <a href={item.source_url} target='_blank' rel="noopener noreferrer" className="blue-font">{item.source_title}</a>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                ))}
                                                                {sourceData.length > 2 ?
                                                                    <div className="src-bg colw-100"><div className="pull-right cursor-pointer see-more" onClick={() => {seeMore('source-data', false)}}>See less</div></div> : null
                                                                }
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            : null}

                                            </span> : '' */}
                                <div className="fn-14 mt-3"><span><span className="font-weight-bold">Revision History:</span> (0 revisions)</span></div>

                                {!isLoading && orgDetails && <div className="atc mt-5">
                                    <div className="atc-header">ABOUT THE CONTRIBUTOR</div>
                                    <div className="atc-box">
                                        <div className="atc-header colw-100">
                                            {orgDetails.logoURL && <img src={orgDetails.logoURL} width='25px' className='pull-left' style={{ borderRadius: '50%' }} /> }
                                            <a href={process.env.REACT_APP_ENV !== 'PROD' ? 'https://dev.repustar.com/contributor/' + `${publishData && publishData.orgDetail.slug}`: 'https://repustar.com/contributor/' + `${publishData && publishData.orgDetail.slug}`} target='_blank'><div className="pull-left blue-font fn-14 mt-1">&nbsp;&nbsp;{orgDetails.org_name}</div></a>
                                        </div>
                                        <div className="atc-body">
                                            {orgDetails.description}<br /><br />
                                            {localStorage.getItem('org_id') === '3' &&
                                                <>
                                                    <span className="atc-txt">TYPICALLY CONTRIBUTES TO:</span><br />
                                                    <span className="atc-con">Elections, Economy, Justice, Environment, Science, Health</span>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>}

                                {/* !relatedListLoader ? <div className='colw-80 display-desktop'>
                                    <hr className='dark-gray-line my-4' />
                                    <div className='my-3 fn-18 font-weight-bolder'>You may also like</div>
                                    <div>
                                        {(interestedIn && (interestedIn.length > 0)) ? interestedIn.slice(0, 3).map((item, indx) => (
                                            <div className="rel-row" key={'int_' + indx}>
                                                <div className="fn-18 lh-28">
                                                    <div className="fn-14 mb-1">{item.siteName}</div>
                                                    <a href={item.url} target='_blank' className='anchor-none'><div className="mb-1">{item.claimReviewed}</div></a>
                                                    <div className="fn-12 gray-font">{item.datePublished}</div>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div> : <div className='text-center display-desktop'><img src={Loading} width='20px'/></div> */}
                            </>
                            : ''
                        }
                        {!isLoading && !publishData ? <div className='fn-14 font-weight-bold gray-font text-center'>Review not available</div> : ''}
                        {sourceModal ? <div className="share-modal">
                            <div className="modal-content" ref={containerRef}>
                                <div className="inline-block colw-100">
                                    <div className="cross-btn inline-block" onClick={()=>{setSourceModal(false)}}><img src={Cross}/></div>
                                </div>
                                <div className="source-url-box text-left">
                                    <div className="colw-100 inline-block">
                                        <div className="text-left" style={{fontWeight: '600', fontSize: '15px', lineHeight:'20px'}}>Reviewers look for data, research and documents from credible, impartial sources. If you know a source they should consider adding to this Review, please tell us.</div>
                                    </div>
                                    <div className="colw-100 url-link inline-block">
                                        <div className="fn-14"><u className="cursor-pointer blue-font"><a href='https://repustar.com/faq' target="_blank" style={{color :'#0071bc'}}>Read more about Repustar's sourcing goals.</a></u></div>
                                        <div className='select-wrapper my-2'>
                                            <Select style={{ width: '250px' }} bordered={false} notFoundContent={null} onChange={onSourceChange} placeholder='Pick source type'>
                                                {sourceItems.length > 0 && sourceItems.map((item) => (
                                                    <Option value={item.value}>{item.value}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div><input type="text" placeholder="Add url link" onChange={onUrlChange}/></div>
                                        <div><textarea type="text" placeholder="Why are you adding this source? Leave a message for the reviewer" onChange={onSourceCommentChange} wrap='soft' style={{height: '50px'}}/></div>
                                    </div>
                                    <div className="semi-bold text-center">{sourceMsg}</div>
                                    <div className="btn-grp">
                                        <Button className="solid-btn" disabled={!addUrl} onClick={() => {addSources()} }>
                                            {sourceLoading ? <span>Adding Source...</span> : <span>Submit</span>}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                    </div>
                    <div className='rev-det-right'>
                        <div className='fn-18 font-weight-bold mt-3'>Related Fact Briefs</div>
                        <div className="related-reviews-contents">
                            { !relatedListLoader ? relatedReviews.slice(0, 3).map((item, indx) => (
                            <a href={item.url} key={'rl'+indx} target="_blank">
                            <div className="container">
                                <div className="row">
                                    <div className="rr-img pull-left"><img src={item.articleImage}/></div>
                                    <div className="txt pull-left pl-2">
                                        <div>{item.claimReviewed}</div>
                                        <div className="time-ago">{item.datePublished}</div>
                                    </div>
                                </div>
                            </div></a>)) : <div className='text-center'><img src={Loading} width='20px' /></div>}
                            {!relatedListLoader && !relatedReviews.length > 0 && <div className='fn-14 font-weight-bold gray-font text-center'>Review not available</div>}
                        </div>

                        {newsAd && <div className="display-desktop"><div className="lp-quiz-main-new cp-ad-new lp-mob-qz">
                              <div className="cp-ad-div">
                                <div className="left">
                                  <div className="logo display-desktop"><img src={FSBird} height={25} width={25}/></div>
                                  <div className="logo display-mobile"><img src={FSBird} height={25} width={17}/></div>
                                  <div className="ml-2">
                                    <div className="d-inline">&nbsp; Recommended Resource&nbsp;</div>
                                    <div className="d-inline-block cursor-pointer">
                                      <OverlayTrigger id='2' trigger="click" placement='bottom' rootClose overlay={
                                        <Popover id='2'>
                                          <Popover.Content>
                                            <div className='fn-14'>
                                              Recommended resources have been judged by editors to be high-quality sources of information, and have partnered with FactSparrow to amplify facts on social media.
                                            </div>
                                          </Popover.Content>
                                        </Popover>
                                      }>
                                        <img src={Question} width={12} height={12}/>
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <a href={newsAd.url} target="_blank" rel="noopener noreferrer">
                                    <div className="right">
                                      <div className="unset-image">
                                        <img src={newsAd.img.replace("www.", "")} className="pt-img-cls"/>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        { <div className="lp-quiz-main-new cp-ad-new cursor-pointer"  onClick={() => {handleQuizBannerClick('static')}}>
              							<div className="lp-quiz">
                							<div className="lp-qz-img">
                								<img src={LpQuizImgOp} layout='fill' objectFit='contain' priority/>
                							</div>
                							<div className="lp-qz-txt cursor-pointer">
                								{/* <div className="lp-qz-topic-title"></div> */}
                								<div className="lp-qz-topic-txt">How well can you sort the bogus from the bonafide?</div>
                								<div className="lp-qz-btn"><div className="lp-qz-banner-txt" >Test your smarts</div><img src={BannerArrowOp} alt="arrow"/></div>
                							</div>
              							</div>
              						</div>}

                        {/* !isLoading && orgDetails && <div className="atc ">
                            <div className="atc-header">ABOUT THE CONTRIBUTOR</div>
                            <div className="atc-box">
                                <div className="atc-header colw-100">
                                    {orgDetails.logoURL && <img src={orgDetails.logoURL} width='25px' className='pull-left' style={{ borderRadius: '50%' }} /> }
                                    <a href={process.env.REACT_APP_ENV !== 'PROD' ? 'https://dev.repustar.com/contributor/' + `${publishData && publishData.orgDetail.slug}`: 'https://repustar.com/contributor/' + `${publishData && publishData.orgDetail.slug}`} target='_blank'><div className="pull-left blue-font fn-14 mt-1">&nbsp;&nbsp;{orgDetails.org_name}</div></a>
                                </div>
                                <div className="atc-body">
                                    {orgDetails.description}<br /><br />
                                    {localStorage.getItem('org_id') === '3' &&
                                        <>
                                            <span className="atc-txt">TYPICALLY CONTRIBUTES TO:</span><br />
                                            <span className="atc-con">Elections, Economy, Justice, Environment, Science, Health</span>
                                        </>
                                    }

                                </div>
                            </div>
                        </div> */}
                        {/* !relatedListLoader ? <div className='colw-100 display-mobile'>
                            <hr className='dark-gray-line my-4' />
                            <div className='my-3 fn-18 font-weight-bolder'>You may also like</div>
                            <div>
                                {(interestedIn && (interestedIn.length > 0)) ? interestedIn.slice(0, 3).map((item, indx) => (
                                    <div className="rel-row" key={'int_' + indx}>
                                        <div className="fn-18 lh-28">
                                            <div className="fn-14 mb-1">{item.siteName}</div>
                                            <a href={item.url} target='_blank' className='anchor-none'><div className="mb-1">{item.claimReviewed}</div></a>
                                            <div className="fn-12 gray-font">{item.datePublished}</div>
                                        </div>
                                    </div>
                                )) : null}
                            </div>
                            </div> : <div className='text-center display-mobile'><img src={Loading} width='20px'/></div> */}
                        {reviewerModal ? <div className="share-modal review-modal text-left">
                            <div className="modal-content" ref={containerRef}>
                                <div className="inline-block colw-100">
                                    <div className="cross-btn inline-block" onClick={()=>{setReviewerModal(false)}}><img src={Cross}/></div>
                                </div>
                                <div className="modal-body">
                                    <div className="modal-row"><span className="created-by">{fullName !== ' ' ? fullName :  createdBy}</span></div>
                                    <div className="modal-row"><span className="label gray-font">Joined Network on:</span>&nbsp;<span className='value'>{moment(joinedAt).format('dddd, MMM DD, YYYY')}</span></div>
                                    <div className='modal-row'><a className="blue-font fn-14" href={(process.env.REACT_APP_ENV === 'PROD' ? 'https://repustar.com/reviewer/' : 'https://dev.repustar.com/reviewer/') + createdBy + '?ownerId=' + ownerId} target='_blank'>Go to Profile <img src={arrowImg}></img></a></div>
                                </div>
                                <div className="modal-body border-top">
                                    <>
                                        <div><span><img src={Checks} width='20px'/></span>&nbsp;<span className="rc-rev-wri ml-2">Recent reviews written</span></div>
                                        {
                                            recentReview.slice(0, recentRevSlice).map((item, indx) => (
                                                <div className="review-row" key={'review_'+indx}>
                                                    <a href={window.location.href.split('/fact-brief/')[0]+'/fact-brief/'+item.pageId} target='_blank'>
                                                        <div className="rw">{item.title}</div>
                                                        <div className="from-now">{moment(item.lastPublishedAt).fromNow()}</div>
                                                    </a>
                                                </div>
                                            ))
                                        }

                                    </>
                                    <div className="text-center blue-font cursor-pointer fn-16 mb-2" onClick={() => { setRecentRevSlice(recentRevSlice === 3 ? recentReview.length : 3) }}>
                                        {recentRevSlice === 3 ? 'See More' : 'See Less'}
                                    </div>
                                    {/* {recentReview.length > 3 ?
                                    <div className="text-center blue-fonts cursor-pointer" onClick={()=>{!viewMore ? setViewMore(true) : setViewMore(false)}}>{!viewMore ? <span>View More</span> : <span>View Less</span>}</div> : null} */}
                                </div>
                            </div>
                        </div> : null}
                    </div>
                </div>
            </div>

            <Modal visible={showExploreModal} centered footer={null} title={null} closable={false} onCancel={() => setShowExploreModal(false)} width={1000}>
                  <div className="explore-quiz-modal">
              			<div className="top-bar">
              				<div className="title">
              					Explore Quizzes
              				</div>
              				<div className="cursor-pointer" onClick={()=> setShowExploreModal(false)}>
              					<img src={XIcon} width={37} height={37} />
              				</div>
              			</div>
              			<div className="sub-title">
              				All quizzes are inspired by real claims being made on social media. See if you know your stuff!
              			</div>
              			<div className="explore-modal-main">
              				{quizItems.length > 0 && <div className="explore-board">
              					<div className="explore-grid">
              						{quizItems.map((item, idx) => <div className="explore-item" key={"explore_" + idx}
              							style={{ background: `url(${item.articleImage}) center center / cover  no-repeat` }}
                            onClick={() => {
                                window.open((process.env.REACT_APP_ENV !== 'PROD') ? `https://dev-factsparrow.repustar.com/quiz/${item.quiz_id}` : `https://factsparrow.repustar.com/quiz/${item.quiz_id}`, '_blank');
                            }}
              						>
              							<div className="explore-tag">{item.quiz_title}</div>
              							{'attempted' in item && <div className="explore-attempt">
              								<div className="check"><img src={RightBlack} width={17} height={17} layout="intrinsic" /></div> &nbsp;Correct Answers: {item.attempted.total_score}/3
              							</div>}
              						</div>)}
              					</div>
              				</div>}
              			</div>
              		</div>
			      </Modal>



        </div>
    )
}
