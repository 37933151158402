import React, { useEffect, useState, useRef } from "react";
import "./forgot-password.scss";
import Navbar from "../../layout/navbar/navbar";
import GFLogo from '../../../../assets/images/logos/gigafact_logo_short (1).svg';
import { ArrowRightOutlined, LeftOutlined } from "@ant-design/icons";
import { Input, Button, Carousel, notification } from "antd";
import { useHistory } from "react-router-dom";
import { forgotPassword, forgotPasswordSubmit } from "../authentication";

export const ForgotPassword = () => {
  const [email, setEmail] = useState(null);
  const [code, setCode] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const carousel = useRef();

  useEffect(() => {
    setTimeout(() => {
        document.title = "Gigafact Partner Console";
      }, 1000);
    }, [])

  

  const history = useHistory();
  const downloadDoc = (e) => {
    window.open(
      "https://docs.google.com/document/d/1JccA9TkEzyJzlWvucSa36gjP0Dq9zXmuG6CmUQHm44k/edit?usp=sharing"
    );
  };
  const resetPassword = async (e) => {
    e.preventDefault();
    let resp = await forgotPassword(email);
    setIsCodeSent(resp);
    if (resp.success) {
      notification.success({
        message: `Please check your email (${email}) for the confirmation code.`,
      });
      carousel.current.next();
    } else {
      notification.error({ message: resp.error.message });
    }
  };
  const resetPasswordSubmit = async (e) => {
    e.preventDefault();
    let resp = await forgotPasswordSubmit({ email, code, password });
    if (resp.success) {
      notification.success({ message: `Password changed!` });
      history.push("/partnerconsole/login");
    } else {
      notification.error({ message: resp.error.message });
    }
  };

  return (
    <div>
      <div className="container-fluid forgot-password-container m-0 p-0">
        <div className="row h-100 p-0 m-0">
          <div className="col-md-6 col-sm-12 left-panel ">
          <div className="partner_console_container">
          <div className="partner_console_text">
                <h1>Partner Console</h1>
                <h4>
                  For invited media partners who work to spread accurate
                  information
                </h4>
         
                    <img src={GFLogo} alt="" />
     
                <div className="line-3">Optimized for desktop</div>
              </div>
            </div>
            {/* <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10 col-sm-12 text-center">
                <div className="heading-1">FactSparrow Partner Console</div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-10 col-sm-12 px-3 text-center">
                <div className="line-1">
                  For invited media partners of FactSparrow who offer help to
                  fact-seeking consumers
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-7 col-md-10 sol-cm-12 text-center">
                <div className="line-2" onClick={downloadDoc}>
                  FactSparrow content partner criteria
                  <ArrowRightOutlined style={{ "padding-left": "10px" }} />
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10 col-sm-12 text-center pb-5">
                <div className="line-3">Optimized for desktop</div>
              </div>
            </div> */}
          </div>
          <div className="col-md-6 col-sm-12 right-panel h-100">
            <Carousel draggable={false} ref={carousel} dots={false}>
              <div>
                <div className="back-btn">
                  <Button
                    shape="circle"
                    icon={<LeftOutlined />}
                    onClick={() => history.push("/partnerconsole/login")}
                  />
                </div>

                <div className="form-panel">
                  <form onSubmit={resetPassword}>
                    <div className="heading-1">Forgot Password</div>
                    <div className="line-1">
                      Enter your email and we'll send you a reset password link.
                    </div>

                    <div className="input-block">
                      <div className="label">Email ID</div>
                      <Input
                        type="email"
                        placeholder="Enter your Email ID"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    <div className="button-block">
                      <Button
                        className="sign-in w-100 p-1"
                        type="primary"
                        htmlType="submit"
                      >
                        SUBMIT
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              <div>
                <div>
                  <div className="back-btn">
                    <Button
                      shape="circle"
                      icon={<LeftOutlined />}
                      onClick={() => history.push("/partnerconsole/login")}
                    />
                  </div>

                  <div className="form-panel">
                    <form onSubmit={resetPasswordSubmit}>
                      <div className="heading-1">Forgot Password</div>
                      {/* <div className="line-1">
                                            Enter your email and we'll send you a reset password link.
                                        </div> */}

                      <div className="input-block">
                        <div className="label">Confirmation code</div>
                        <Input
                          type="text"
                          placeholder="Confirmation cod"
                          value={code}
                          onChange={(e) => {
                            setCode(e.target.value);
                          }}
                        />
                      </div>
                      <div className="input-block">
                        <div className="label">Password</div>
                        <Input
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                      <div className="input-block">
                        <div className="label">Confirm Password</div>
                        <Input
                          type="password"
                          status={
                            password !== confirmPassword &&
                            confirmPassword !== null
                              ? "error"
                              : null
                          }
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                      </div>
                      <div className="button-block">
                        <Button
                          className="sign-in w-100 p-1"
                          type="primary"
                          htmlType="submit"
                          disabled={
                            password !== confirmPassword ||
                            code == null ||
                            password == null
                          }
                        >
                          RESET PASSWORD
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
