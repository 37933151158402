import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth/lib/types";
// import jwt_decode from "jwt-decode";
import Utils from '../../../utils/utils';
import axios from 'axios';

export const doLogin = async (type, authenticationData = undefined) => {

    return new Promise(async (resolve, reject) => {
        if (type === "Google") {
            let data = await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
            await checkLocalStorage()
        } else if (type === "Apple") {
            let data = await Auth.federatedSignIn({ provider: "Apple" });
        } else if (type === "Facebook") {
            let data = await Auth.federatedSignIn({ provider: "Facebook" });
        } else {

            try {
                let authResponse = await Auth.signIn(authenticationData);
                // await afterLogin(authResponse)
                // await checkLocalStorage();
                resolve(authResponse)
            } catch (error) {
                reject(error)
            }
        }
    });
};

export const forgotPassword = async (data) => {
    // console.log(data)
    try {
        let resp = await Auth.forgotPassword(data);
        // console.log(resp)

        return { success: true }
    } catch (e) {
        console.log(e)
        return { success: false, error: e }
    }
}
export const forgotPasswordSubmit = async (data) => {

    try {
        let resp = await Auth.forgotPasswordSubmit(data.email, data.code, data.password);
        // console.log(resp)
        return { success: true }
    } catch (e) {
        return { success: false, error: e }

    }
}

export const afterLogin = async (user) => {

    try {
        const apiPath = Utils.getAPIDescription('get_user_details') + '?userId=' + user.attributes.sub + '&source=login';
        let config = {
            headers: {
                email: user.attributes.email,
                Authorization: user.signInUserSession.idToken.getJwtToken()
            }
        };
        let data = await axios.get(apiPath, config);
        let res = data.data;
        if (res.statusCode === 200) {
            let roles = res.body?.userType;
            if (roles.includes("content_partner_admin") || roles.includes("content_partner")) {
                //
                // let orgDetailsApi = Utils.getAPIDescription('get-org-details') + '?org_id=' + res.body?.org_id;
                // let orgRes = await axios.get(orgDetailsApi);

                // res.body.orgDetails = orgRes.data.data;
                setStorage(res.body, user);

                return { login: true, message: "Success", user: res.body }
            } else {
                Auth.signOut();
                localStorage.clear();
                return { login: false, message: "Not authorized to access this page!" }
            }
        } else {
            return { login: false, message: "Something went wrong!" }
        }
    } catch (error) {
        throw error;
    }
}
export const CognitoLogout = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            await Auth.signOut({});
            let isUserLoggedIn = true;
            while (isUserLoggedIn) {
                try {
                    await Auth.currentAuthenticatedUser();
                    isUserLoggedIn = true;
                } catch (error) {
                    isUserLoggedIn = false;
                    sessionStorage.clear();
                    localStorage.clear();
                }
            }

            resolve(true)
        } catch (error) {
            console.log(error);
        }
    })
}

export const checkLocalStorage = async () => {
    return new Promise(async (resolve, reject) => {
        Auth.currentAuthenticatedUser({ bypassCache: true }).then(async (user) => {
            localStorage.setItem('repustar_token', user.signInUserSession.idToken.getJwtToken());
            await afterLogin(user);
            // if (user.storage.store.length > 0) {
            //     let accessToken = null;
            //     let data = Object.keys(user.storage.store).find((key, index) => {
            //         if (key.match(/^.*accessToken$/)) {
            //             accessToken = user.storage.store[key];
            //         }
            //         if (key.match(/^.*idToken$/)) {
            //             return true
            //         }
            //     });
            //
            // }
            resolve(true)
        }).catch((err) => {
        })
    })


}
export const createUser = (payload = undefined) => {

    return new Promise(async (resolve, reject) => {
        Auth.signUp(payload).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
            console.log(err);
        })
    });
};
const setStorage = (data, cognitoUser) => {
    localStorage.setItem('repustar_token', cognitoUser.signInUserSession.idToken.getJwtToken());
    localStorage.setItem('user_id', data.id);
    localStorage.setItem('user_types', data.userType);
    localStorage.setItem('email', data.email);
    localStorage.setItem('username', data.username ?? data.name);
    localStorage.setItem('status', data.status);

    localStorage.setItem('org_id', data.org_id);
    localStorage.setItem("org_name", data.org_details.org_name);
    localStorage.setItem("org_self_publish", data.org_details.self_publish_status || 'false');

    localStorage.setItem("is_audience_manager", data?.is_audience_manager || 'false');


    localStorage.setItem('firstname', data.firstname);
    localStorage.setItem('lastname', data.lastname);

    if(data.newsroom_user_name) localStorage.setItem('newsroom_user_name', data.newsroom_user_name);
    if(data.newsroom_user_application_password) localStorage.setItem('newsroom_user_application_password', data.newsroom_user_application_password);
    if(data.newsroom_home_url) localStorage.setItem('newsroom_home_url', data.newsroom_home_url);




    if (data.org_profile_img_url) localStorage.setItem('profile_img', data.org_profile_img_url);
    localStorage.setItem('email_verified', data.email_verified);
    try {
        if (data.state === 0) {
            localStorage.setItem('access', 'blocked');
        } else {
            localStorage.setItem('access', 'granted');
        }
        if(!data.org_details.logoURL){
            localStorage.setItem('initial_setup', "true");
        }

    } catch (err) {
        throw err;
    }
    // window.dispatchEvent(new Event('storage'))
};