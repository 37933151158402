import navAction from './navaction';
import containerAction from './containeraction';
import flagAction from './flagaction';
import reviewAction from './reviewaction';

const allActions = {
    navAction,
    containerAction,
    flagAction,
    reviewAction
}

export default allActions