import React, {useEffect, useState, useRef} from 'react';
import './dropdown.css';

function Dropdown(props) {
	const containerRef = useRef(null);
	const [dataSource, setDataSource] = useState([]);
	const [selectedItem, setSelectedItem] = useState({id: -1, value: ''});
	const [openDropdown, setOpenDropdown] = useState(false);
	const [prefix, setPrefix] = useState(null);
	const [itemIndex, setItemIndex] = useState(-1);
    useEffect(() => {
        createDropDown();
        document.addEventListener("mousedown", handleClickOutSide);
        return () => {
            //Component unmount codes need to be added here
            document.removeEventListener("mousedown", handleClickOutSide);
        }
    }, [props.datasource, props.itemindex]);

    const handleClickOutSide = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setOpenDropdown(false);
        }
    }

    const createDropDown = () => {
        if(props.datasource){
            setDataSource(props.datasource);
            setSelectedItem(props.datasource[props.itemindex]);
            setItemIndex(props.itemindex);
            (props.prefix) ? setPrefix(props.prefix) : setPrefix(null)
        }
    }

    const openDropdownOption = () =>{
    	setOpenDropdown(true);
    }

    const setItemSelection = item => (e) => {
    	setSelectedItem(item);
    	setItemIndex(item.id);
    	setOpenDropdown(false);
        props.onchange(item[props.value], props.index);
    }

    return (
        <>
            <div className="drop-down relative-position pull-left">
          	    <div className="selected-item" onClick={openDropdownOption}>{(prefix) ? ((prefix + ': ')+selectedItem[props.value]) : selectedItem[props.value]}<span className="arrow-button"></span></div>
          	    {openDropdown ? <div className="option-box absolute-position colw-100" ref={containerRef} >
          		{dataSource.map((item, index) => (<div className={"item-option "+ ((itemIndex === item[props.id]) ? 'option-selected': 'option-hover')} key={'opt-'+item[props.id]} onClick={setItemSelection(item)}>{item[props.value]}</div>))}
          	    </div> : null}
            </div>
        </>
    );
}
export default Dropdown;