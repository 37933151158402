import React, {useEffect, useState, useRef} from 'react';
import { Link } from "react-router-dom";
import './resetpassword.css';
import Utils from '../../utils/utils.js';
import RepustarLogo from '../../assets/images/logos/repustar_logo.png';
import { Form, FormGroup, FormControl, FormLabel, Button} from "react-bootstrap";
import {Auth, Hub} from "aws-amplify";
import {useHistory } from "react-router-dom";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth/lib/types";
import axios from 'axios';


export default function Signup(props) {
    const [forgotPassword, setForgotPassword] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [code, setCode] = useState("");
    const [pwd, setPwd] = useState("");
    const [conPwd, setConPwd] = useState("");
    const [loading, setLoading] = useState(false);
    const [isAddCode, setAddCode] = useState(false);
    const [confirmEmail, setConfirmEmail] = useState(false);
    const [emailCode, setEmailCode] = useState('');
    const [resendMsg, setResendMsg] = useState(false);
    const [count, setCount] = useState(30);
    const [intervalId, setIntervalId] = useState();
    const re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
    const history = useHistory();

    useEffect(() => {
        if(count == 0){
            //setCount(0);
            startTimer(false);
        }
    }, [count]);

    useEffect(() => {
        if(validateEmail()){
            //setErrMsg('');
        }else{
            //setErrMsg('Invalid Email.');
        }

    }, [email]);

    const validateForm = () => {
        return validateEmail();
    }

    const validateEmail = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const validateAddForm = () => {
        return pwd.length >= 8 && conPwd.length >= 8 && pwd === conPwd;
    }

    const validateEmailCode = () =>{
        return emailCode.length > 5;
    }


   const resendCode = async() => {
       setResendMsg(true);
        try {
            await Auth.resendSignUp(email);
            setCount(30);
            startTimer(true);
            // console.log('code resent');
            setErrMsg('');
        } catch (err) {
            console.log('error resending code: ', err);
            setResendMsg(false);
            setErrMsg(err.message);
        }
    }

    const confirmEmailCode = async() =>{
        setLoading(true);
        try {
          await Auth.confirmSignUp(email, emailCode);
          setErrMsg('');
          setConfirmEmail(false);
          setLoading(false);
        } catch (error) {
            console.log('error confirming sign up', error);
            setLoading(false);
            setErrMsg(error.message);
        }
    }
    
    const sendCode = async (event) => {
        event.preventDefault();
        /*if(!re.test(email)){
            setErrMsg('Invalid Email');
            return;
        }*/
        setErrMsg('');
        Auth.forgotPassword(email)
        .then(data => {
            setForgotPassword(false);
            setAddCode(true);
            // console.log(data);
        })
        .catch(err => {
            console.log(err);
            setErrMsg(err.message);
        });
    };

    const validateResetForm = () => {
        return (
          code.length > 0 && pwd.length > 0 && pwd === conPwd
        );
    }

    const handleConfirmClick = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
          await Auth.forgotPasswordSubmit(
            email,
            code,
            pwd
          );
          setLoading(false);
          setAddCode(false);
          setErrMsg('');
          history.push('/');
        } catch (e) {
            setErrMsg(e.message);
            setLoading(false);
           // setSignin(true);
           // setAddCode(false);
        }
    };

    const handleConfirmEmail = (event) =>{
        event.preventDefault();
        // console.log('confirm');
        confirmEmailCode();
    }
    const startTimer = (execute) => {
        if(execute){
            const id = setInterval(()=>{setCount(count => count - 1)}, 1000);
            setIntervalId(id);
        }else{
            clearInterval(intervalId);
        }
    }


    return (
        <>
            <div className="sign-up">
                <div className="text-center"><img className="reps-logo" src={RepustarLogo}/></div>
                <div className="social-signup">
                    <div className="email-signup">
                        <>
                            {forgotPassword && 
                                <form onSubmit={sendCode}>
                                    <FormLabel className="text-center transform-none top-label">Enter your email to continue.</FormLabel>
                                    <FormGroup controlId="email">
                                      <FormLabel>Email</FormLabel>
                                      <FormControl
                                        required
                                        autoFocus
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                      />
                                      {errMsg ? <div className="err-msg">{errMsg}</div> : ''}
                                    </FormGroup>
                                    <Button block disabled={!validateForm()} type="submit">
                                        {loading ? <span>Signing In...</span> : <span>Send Code</span>}
                                    </Button>
                                </form>
                            }
                            {confirmEmail &&
                                <form onSubmit={handleConfirmEmail}>
                                    <FormGroup controlId="email">
                                        <FormLabel className="check-code signup-code">
                                            <span>Plase check your email(</span><span className="signup-email">{email}</span><span>) for the confirmation code.</span>
                                        </FormLabel>
                                        <FormControl
                                            autoFocus
                                            type="text"
                                            value={emailCode}
                                            onChange={e => setEmailCode(e.target.value)}
                                        />
                                        {errMsg ? <div className="err-msg">{errMsg}</div> : ''}
                                    </FormGroup>
                                    <FormGroup controlId="resend-code">
                                        {resendMsg ? 
                                            <div className="msg"><span>Didn't get code? Please wait for 30 seconds.</span>
                                                <span>
                                                    {count > 0 ?  <span className="resend-timer"><span>&nbsp;{count}&nbsp;seconds remaining...</span></span> : 
                                                                                           
                                                    <span>&nbsp;
                                                        {loading ? <span> Sending Code...</span> : <span className="link-btn red-line" onClick={()=>{resendCode()}}>Resend</span>}
                                                    </span>
                                                }
                                                </span>
                                            </div> : ''
                                        }
                                    </FormGroup>
                                    <Button block disabled={!validateEmailCode()} type="submit">
                                        {loading ? <span> Confirming Email...</span> : <span>Confirm</span>}
                                    </Button>
                                </form> 
                            }
                        </>
                        <>
                            {isAddCode &&
                                <form onSubmit={handleConfirmClick} autocomplete="off">
                                    <FormLabel className="text-center transform-none top-label">Forgot Password.</FormLabel>
                                    <FormGroup controlId="code">
                                        <FormLabel>Confirmation Code</FormLabel>
                                        <FormControl
                                            autocomplete="off"
                                            required
                                            autoFocus
                                            type="text"
                                            value={code}
                                            onChange={e => setCode(e.target.value)}
                                        />
                                        {errMsg ? <div className="err-msg">{errMsg}</div> : ''}
                                        <FormLabel className="check-code">
                                            <span>Plase check your email(</span>{email}<span>) for the confirmation code.</span>
                                        </FormLabel>
                                    </FormGroup>
                                    <FormGroup controlId="pwd">
                                        <FormLabel>New Password</FormLabel>
                                        <FormControl
                                            value={pwd}
                                            onChange={e => setPwd(e.target.value)}
                                            type="password"
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="conPwd">
                                        <FormLabel>Confirm Password</FormLabel>
                                        <FormControl
                                            value={conPwd}
                                            onChange={e => setConPwd(e.target.value)}
                                            type="password"
                                        />
                                        <FormLabel className="transform-none">Use 8 or more characters.</FormLabel>
                                    </FormGroup>
                                    <Button block disabled={!validateAddForm()} type="submit">
                                        {loading ? <span>Confirming...</span> : <span>Confirm</span>}
                                    </Button>
                                </form> 
                            } 
                        </>
                    </div>
                </div>
            </div>
        </>
    );
}