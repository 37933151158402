import "./orgbanner.scss";

const OrgBanner = (props) => {

    let orgDetails = props.orgdetails;

    return (
        <div className="org-heading">
            <div className="org-info">
              <div className="org-logo">
                {orgDetails && (
                  <img 
  alt='Organisation logo' 
  src={orgDetails.logoURL} 
  style={{
    width: orgDetails.page_id === 3888 ? "90px" : "50px",
    height: "50px"
  }} 
/>                )}
              </div>
              <div className="org-name pl-4">
                {orgDetails && orgDetails.org_name}
              </div>
            </div>
            </div>
    );
}
export default OrgBanner;