import React, { useEffect, useState } from 'react';
import './addUser.css';
import Header from '../header/header.js';
import Nav from '../nav/nav.js';
import { Button } from 'antd';
import { Auth } from 'aws-amplify';
import { notification, Select } from 'antd';
import { useHistory } from "react-router-dom";
import Utils from '../../utils/utils';
import Axios from 'axios';

export default function AddUser(props) {
	const [formData, setFormData] = useState({});
	const [reqErr, setReqErr] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [role, setRole] = useState('Reviewer');
	const { Option } = Select;
	const history = useHistory();
	useEffect(() => {
		if (localStorage.getItem('user_types').includes('org_admin')) {
			setIsAdmin(true);
		}
		document.title = "Add User";
		let tmpdata = {
			"fname": "",
			"lname": "",
			"email": "",
			"password": "",
		}
		setFormData(tmpdata)
	}, [])
	const handleOnChange = (e) => {
		let tmpForm = {...formData}
		switch (e.target.name) {
			case "fname":
				tmpForm.fname = e.target.value
				break;
			case "lname":
				tmpForm.lname = e.target.value
				break;
			case "email":
				tmpForm.email = e.target.value.toLowerCase();
				break;
			case "password":
				tmpForm.password = e.target.value
				break;
		}
		setFormData(tmpForm);
	}

	const registerUser = () => {
		Auth.signUp({
			username: formData.email,
			password: formData.password,
			attributes: {
				'custom:first_name': formData.fname,
				'custom:last_name': formData.lname,
				'custom:role': role,
				'custom:org_id': localStorage.getItem('org_id'),
			}
		}).then((res) => {
			history.push('/edit-profile#2');
		}).catch((err) => {
			console.log(err);
			notification.error({ message: err.message, duration: 5, placement: 'topRight' });
		})
	}
	const checkForm = () => {
		if (formData.fname === "" || formData.lname === "" || formData.username === "" || formData.email === "" || formData.password === "" || role === "") {
			setReqErr(true);
		}
		else {
			registerUser();
		}
	}
	return (
		<div className="app-workspace">
            <Header/>
            <div className="app-content">
				<Nav />
				{isAdmin ? <div className="add-user-container pull-left">
					<button className='blue-border-btn mb-4 fn-14' onClick={() => { history.goBack() }}>BACK</button>
					<div className='create-heading'>Add Team Member</div>
					<div className="add-user">
						<div className="name-section">
							<div className="name-div">
								<div className="fn-16 fnw-600 mb-2">First Name:</div>
								<input className="user-input" name="fname" onChange={handleOnChange} value={formData.fname}/>
								{!formData.fname && reqErr && <div className="err-msg">This field is required</div>}
							</div>
							<div className="name-div ml-5">
								<div className="fn-16 fnw-600 mb-2" >Last Name:</div>
								<input className="user-input" name="lname" onChange={handleOnChange} value={formData.lname}/>
								{!formData.lname && reqErr && <div className="err-msg">This field is required</div>}
							</div>
						</div>
						<div className="fn-16 fnw-600 mb-2 mt-5">E-mail:</div>
						<input className="user-input" name="email" onChange={handleOnChange} />
						{!formData.email && reqErr && <div className="err-msg">This field is required</div>}
						<div className="fn-16 fnw-600 mb-2 mt-5">Password: <span className='ml-2 blue-font font-weight-bold fn-14 pull-right cursor-pointer' onClick={() => setShowPassword(!showPassword)}>{!showPassword ? 'Show' : 'Hide'}</span></div>
						<input type={!showPassword ? "password" : "text"} className="user-input" name="password" onChange={handleOnChange} />
						{!formData.password && reqErr && <div className="err-msg">This field is required</div>}
						<div className="fn-16 fnw-600 mt-5">
							<span className="">Role:</span>
							<span className='select-wrapper ml-2'>
								<Select style={{ width: '200px' }} bordered={false} notFoundContent={null} onChange={(val) => setRole(val)} defaultValue='Reviewer'>
									<Option value='org_admin'>Admin</Option>
									<Option value="Reviewer">Editor</Option>
								</Select>
							</span>
						</div>
						{!role && reqErr && <div className="err-msg">This field is required</div>}
						<div className="mt-5">
							<Button type="primary" size="large" onClick={checkForm}>Create</Button>
						</div>
					</div>
				</div> :
					<div className="add-user-container pull-left">
						<h2>Permission Denied</h2>
					</div>}
			</div>
		</div>
	)
}
