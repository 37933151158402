import Axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Utils from '../../utils/utils';
import Loading from '../../assets/images/loader.gif';
import Header from '../header/header.js';
import Nav from '../nav/nav.js';
import './editprofile.css';
import { Tabs } from 'antd';
import MyAccount from '../myaccount/myaccount';
import EditReviewers from '../editReviewers/editReviewers';
import AddAuthor from '../addauthor/addauthor';
import EditDomain from '../editDomain/editDomain';
export default function EditProfile(props) {
    const [activeKey, setActiveKey] = useState('1');
    const { TabPane } = Tabs;
    useEffect(() => {
        if (window.location.href) {
            let str =  window.location.href;
            let key = Utils.checkSeparatorMapping(str, '#', 1);
            if (key) {
                setActiveKey(key);
            }
        }
        document.title = "Edit Profile";
    }, [])
    const handleTabClick = (key) => {
        setActiveKey(key);
    }
    return (
        <div className="app-workspace">
            <Header/>
            <div className="app-content">
                <Nav />
                <div className='app-profile colw-80 pull-left'>
                    <div className='edit-heading'>Edit Profile</div>
                    <div className='profile-tabs'>
                        <Tabs size='large' activeKey={activeKey} onTabClick={handleTabClick}>
                            <TabPane tab="My Account" key="1">
                                <MyAccount />
                            </TabPane>
                            <TabPane tab="Editors" key="2">
                                <EditReviewers />
                            </TabPane>
                            {!Utils.checkContentPartnerWriter() && <TabPane tab="Authors" key="3">
                                <AddAuthor />
                            </TabPane>}
                            {/* <TabPane tab="Manage Domain & Topics" key="3">
                                <EditDomain />
                            </TabPane> */}
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>

    )
}
