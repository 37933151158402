import React, {useEffect, useState, useRef, useReducer} from 'react';
import { Link, useLocation } from "react-router-dom";
import './nav.css'
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../redux/action';
import dashboardIcon from '../../assets/images/Dashboard.svg';
import reviewsIcon from '../../assets/images/Reviews.svg';
import flagsIcon from '../../assets/images/Flags.svg';
import publishIcon from '../../assets/images/Publish.svg';
import engageIcon from '../../assets/images/Engage.svg';
import settingsIcon from '../../assets/images/Settings.svg';
import env from '../../assets/images/env.png';
import rightArrow from '../../assets/images/rightarrow.png';
// import favIcon from '../../assets/images/logos/favicon.ico';
import favIcon from '../../assets/favicon.ico';
import Utils from '../../utils/utils';

const inititalState = {id: 1, type: 'Dashboard', icon: dashboardIcon, title: 'Repustar Dashboard'};
const reducer = (state, action) => {
    switch(action.type){
        case 'dashboard': 
            return {...state, type: action.type, title: 'Repustar Dashboard'}
        case 'reviews': 
            return {...state, type: action.type, title: 'Repustar Fact Briefs'}
        case 'flags': 
            return {...state, type: action.type, title: 'Repustar Flags'}
        case 'publish': 
            return {...state, type: action.type, title: 'Repustar Publish'}
        case 'engage': 
            return {...state, type: action.type, title: 'Repustar Engage'}

    }
}

export default function Nav() {
    const [item, dispatch] = useReducer(reducer, inititalState);
    const dispatchAction = useDispatch(); //Redux dispatcher
    const [itemIndex, setItemIndex] = useState(1);
    const [isContentPartner, setIsContentPartner] = useState(false);

    useEffect(() => {
        if (Utils.checkContentPartnerWriter()) {
            setIsContentPartner(true)
        }
    }, [])
    

    const menuSource = [{id: 1, type: isContentPartner ? 'dashboard' :'reviews', icon: flagsIcon, title: isContentPartner ? 'Dashboard' : 'Fact Briefs', linkTo: isContentPartner ? '/dashboard' : '/fact-briefs'},
                        {id: 2, type: 'flags', icon: reviewsIcon, title: 'Flags', linkTo: '/flags'},
                        {id: 3, type: 'editprofile', icon: dashboardIcon, title: 'Edit Profile', linkTo: '/edit-profile'},
                    ]
    const subMenuSource = [{id: 1, type: 'Today', icon: favIcon, title: 'Edit Profile', className: 'fav-icon'},
                           {id: 2, type: 'Us Policy', icon: rightArrow, title: 'Edit Profile', className: 'right-arrow'},
                           {id: 3, type: 'Health Care', icon: rightArrow, title: 'Flags', className: 'right-arrow'},
                           {id: 4, type: 'Green Energy', icon: rightArrow, title: 'Fact Briefs', className: 'right-arrow'},
                           {id: 5, type: 'Climate Change', icon: rightArrow, title: 'Fact Briefs', className: 'right-arrow'},
                           {id: 6, type: 'Ecology', icon: rightArrow, title: 'Fact Briefs', className: 'right-arrow'}]

    const location = useLocation();

    useEffect(() => {
        if(location.pathname === '/'){
            triggerAction(item);
        }else{
            let item = location.pathname.substring(1);
            for(let i in menuSource){
                if(menuSource[i].type === item){
                    triggerAction(menuSource[i]);
                }
            }
        }
    }, []);

    const setItemSelection = itm => (e) => {
        triggerAction(itm);
    }

    const triggerAction = (itm) =>{
        document.title = itm.title;
        setItemIndex(itm.id);
        setItemSelection(itm);
        //dispatchAction(allActions.navAction.navClick(itm.type));
    }



    return (
        <div className="vertical-nav border-right pull-left">
            <ul id="nav">
                {/* <Link to='/' className="dash-board">
                    <div className="colw-100">
                        <div className="pull-left colw-20 env-img">
                            <img src={env}/>
                        </div>
                        <div className="pull-left colw-80 text-left user-info">
                            <span className="semi-bold-font">Naturanian Environmental Research Center</span><br/>
                            <span className="user-type">
                                <span>{localStorage.getItem('username')}</span>
                                <span>(Admin)</span>
                            </span>
                        </div>
                    </div>
                </Link> */}
                {React.Children.toArray(menuSource.map((itm, index) => (<li className={"menu-option "+ ((itemIndex === itm.id) ? 'menu-selected': 'menu-hover')} onClick={setItemSelection(itm)}>
                    <Link to={itm.linkTo}>
                        {/* <div className="gray-circle pull-left"></div> */}
                        <div className="pull-left lbl">{itm.title}</div>
                    </Link>
                </li>)))}
            </ul>
            {/*
            <ul id="subNav">
                <div className="colw-100">
                    <div className="more-from semi-bold-font">More from Repustar</div>
                </div>
                {React.Children.toArray(subMenuSource.map((itm, index) => (<li className={"menu-option "+ ((itemIndex === itm.id) ? 'menu-selected': 'menu-hover')} onClick={setItemSelection(itm)}>
                    <div className={"pull-left "+itm.className}><img src={itm.icon}/></div>
                    <div className="pull-left lbl">{itm.title}</div>
                </li>)))}
            </ul>*/}
        </div>
    );
}