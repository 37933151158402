import { Auth, Hub, Storage } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";


export const CognitoLogin = (type, authenticationData = undefined) => {
    return new Promise(async (resolve, reject) => {
        if (type === "Google") {
            let data = await Auth.federatedSignIn({ provider: 'Google' });
            // await checkLocalStorage()
        } else if (type === "Apple") {
            let data = await Auth.federatedSignIn({ provider: 'Apple' });
        } else if (type === "Facebook") {
            let data = await Auth.federatedSignIn({ provider: 'Facebook' });
        } else {
            try {
                let authResponse = await Auth.signIn(authenticationData);
                resolve(authResponse)
            } catch (error) {
                reject(error)
            }
        }
    });
};
export const CognitoLogout = async (type, authenticationData = undefined) => {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log("Signout Error:::", error);
    }
};
export const CognitoSignUp = async (payload = undefined) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await Auth.signUp(payload);
            console.log(response)
            resolve(response)
        } catch (error) {
            reject(error)
            console.log(error);
        }
    });
};


// Hub.listen('auth', (response) => {
//     console.log("Auth Data", response.payload.event);
//     const { payload } = response;
//     const { event, data } = payload;
//     switch (event) {
//         case 'signIn':
//             console.log(event, data);

//             break;
//         case 'signOut':
//             sessionStorage.clear();
//             localStorage.clear();
//             break;

//         default:
//             break;
//     }
// })