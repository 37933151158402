import React, {useEffect, useState, useRef} from 'react';
import { Link } from 'react-router-dom';
import './createflag.css'
import Logo from '../../assets/images/logos/repustar_logo.png';
import Utils from '../../utils/utils.js';
import axios from 'axios';
import Loading from '../../assets/images/loader.gif';
import moment from 'moment';
import NewTag from '../common/NewTag';
import Tag from '../common/Tag';
import { notification } from 'antd';
import Header from '../header/header.js';
import Nav from '../nav/nav.js';
import Web from '../../assets/images/website.png';
import Twitter from '../../assets/images/twitter.png';
import { useHistory } from "react-router-dom";
import { Select } from 'antd';

export default function CreateFlag() {
    const [flagUrl, setFlagUrl] = useState('');
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [flagMetadata, setFlagMetadata] = useState([]);
    const [articleMetadata, setArticleMetadata] = useState([]);
    const [flagTitle, setFlagTitle] = useState('');
    const [publishDate, setPublishDate] = useState('');
    const [contentSource, setContentSource] = useState('');
    const [broadcaster, setBroadcaster] = useState('');
    const [sponser, setSponser] = useState('');
    const [what, setWhat] = useState('');
    const [feedType, setFeedType] = useState(null);
    const [categoryArr, setCategoryArr] = useState([]);
    const [subcategoryArr, setSubcategoryArr] = useState([]);
    const [geotagArr, setGeotagArr] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [subcategoryList, setSubcategoryList] = useState([]);
    const [geoList, setGeoList] = useState([]);
    const [catSubcat, setCatSubcat] = useState();
    const [enableCreateFlag, setEnableCreateFlag] = useState(false);
    const [allTags, setAllTags] = useState({});
    const [tagArr, setTagArr] = useState([]);
    const [iscreating, setIsCreating] = useState(false);
    const [interestedIn, setInterestedIn] = useState([]);
    const [relatedReviews, setRelatedReviews] = useState([]);
    const [relatedFlags, setrelatedFlags] = useState([]);
    const [hasSearched, setHasSearched] = useState(false);
    const history = useHistory();
    const { Option } = Select;

    const handleOnChange = (e) => {
        if(e.target.name === 'link') {setFlagUrl(e.target.value)}
        if(e.target.name === 'sponser') {setSponser(e.target.value)}
        if(e.target.name === 'what') {setWhat(e.target.value)}
    }
    const validateURL = (url) => {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
    }

    const getDetails = async () => {
        setDetailsLoading(true);
        setEnableCreateFlag(false);

        let apiPath = Utils.getAPIDescription('ask-repustar');
        if(!validateURL(flagUrl)){
            alert('URL is not Valid');
            setDetailsLoading(false);
            return;
        }
        let query = {'query': flagUrl}
        let res = await axios.post(apiPath + '?source=host', query, {headers: {"userid": `${localStorage.getItem('user_id')}`}});
        if(res.status !== 200){
            alert('Something went wrong');
            setDetailsLoading(false);
        }
        setFeedType(parseInt(res.data.body.feedType));
        if (res.data.body.feedType === 1) { setFlagMetadata(res.data.body.metadata); }
        else { setArticleMetadata(res.data.body.metadata); }

        if (res.data.body.relevantResearch && res.data.body.relevantResearch.data && (res.data.body.relevantResearch.data.length > 0)) {
            setInterestedIn(res.data.body.relevantResearch.data);
        }
        if (res.data.body.repustarChecks && res.data.body.repustarChecks.data && (res.data.body.repustarChecks.data.length > 0)) {
            setRelatedReviews(res.data.body.repustarChecks.data);
        }

        setDetailsLoading(false);
        setHasSearched(true);

    }

    useEffect(() => {
        if(flagMetadata.title) {
            setFlagTitle(flagMetadata.title)
            setPublishDate(flagMetadata.date_published)
            setBroadcaster(flagMetadata.channel ?  flagMetadata.channel.name : '')
            setContentSource(flagMetadata.host || 'YouTube')
            setSponser(flagMetadata.channel ?  flagMetadata.channel.name : '')
        }
    }, [flagMetadata])

    useEffect(() => {
        if(articleMetadata.title){
            setFlagTitle(articleMetadata.title)
            setPublishDate(articleMetadata.publishedDate)
            setBroadcaster((articleMetadata.broadcaster) ? articleMetadata.broadcaster : articleMetadata.publisher)
            setContentSource((articleMetadata.publisher === 'Twitter') ? 'Twitter' : 'Article')
            setSponser(articleMetadata.publisher)
        }
    }, [articleMetadata])

    const fetchGeoList = async () => {
        let geoApi = Utils.getAPIDescription('geotag-source');
        try {
            let data = await axios.get(geoApi + 'all=true');
            if (data && data.data && data.data.body) {
                let gtag = data.data.body.map((item)=>{return item.key});
                setGeoList(gtag);
                // console.log(gtag);
            }
        }
        catch(e) {
            console.log('Error: ' + e);
        }
    }
    const fetchTags = async () => {
        fetchGeoList();
        setDetailsLoading(true);
        let contentDescription = '';
        if (parseInt(`${feedType}`) === 1) {
            if (flagMetadata.description) {
                contentDescription = flagMetadata.description.split(' ').slice(0, 500).join(' ');
            }
        } else {
            if (articleMetadata.text) {
                contentDescription = articleMetadata.text.split(' ').slice(0, 500).join(' ');
            }
        }

        let query = {
            'flagTitle': what,
            'contentTitle': flagTitle,
            'contentDescription': contentDescription.substring(0, 1000)
        };
        let tagApi = Utils.getAPIDescription('get-tags-content/flag');
        let res = await axios.post(tagApi, query);
        if (res.status === 200) {
            if (res.data) {
                setAllTags(res.data);
                setTagArr(res.data.flag_tags.tags);
                if (res.data.content_tags1.cat_subcat_ord) {
                    setCatSubcat(res.data.content_tags1.cat_subcat_ord);
                    let clst = Object.keys(res.data.content_tags1.cat_subcat_ord);
                    setCategoryList(Object.keys(res.data.content_tags1.cat_subcat_ord));
                }
                if (res.data.flag_tags.category) {
                    setCategoryArr(res.data.flag_tags.category);
                }

                if (res.data.flag_tags.sub_category) {
                    setSubcategoryArr(res.data.flag_tags.sub_category);
                }

                if (res.data.flag_tags.geo_tag && (res.data.flag_tags.geo_tag !== '')) {
                    setGeotagArr(res.data.flag_tags.geo_tag ? geotagArr => [...geotagArr, res.data.flag_tags.geo_tag] : '');
                }
                setDetailsLoading(false);
            }
            setEnableCreateFlag(true);
        } else {
            alert('Some Error Occured.');
        }

    };
    useEffect(() => {
        //if((flagTitle !== '') && (why !== '')){
        if (flagTitle !== '') {
            fetchTags();
        }
    }, [flagTitle]);
    const removeCategoryTag = (item) => {
        setCategoryArr(categoryArr.filter(tag => tag !== item));
    }

    const removeSubcategoryTag = (item) => {
        setSubcategoryArr(subcategoryArr.filter(tag => tag !== item));
    }

    const removeGeotag = (item) => {
        setGeotagArr(geotagArr.filter(tag => tag !== item));
    }

    const resetTags = () => {
        setCategoryArr([]);
        setSubcategoryArr([]);
        setGeotagArr([]);
    }

    const addCategory = (item) => {
        let indx = categoryArr.indexOf(item);
        if (indx !== -1) {
            alert('duplicate tag');
        } else {
            setCategoryArr(categoryArr => [...categoryArr, item]);
        }
    };

    const addSubcategory = (item) => {
        let indx = subcategoryArr.indexOf(item);
        if (indx !== -1) {
            alert('duplicate tag');
        } else {
            setSubcategoryArr(subcategoryArr => [...subcategoryArr, item]);
        }
    }

    const addGeotag = (item) => {
        let gItem = [item];
        setGeotagArr(gItem);
    }

    useEffect(() => {
        setSubcategoryList([]);
        if(categoryArr && categoryArr.length > 0){
            for(let item in categoryArr){
                setSubcategoryList(subcategoryList => subcategoryList.concat(catSubcat[categoryArr[item]]));
            }
        }
    }, [categoryArr]);

    const createFlag = async () => {
        setIsCreating(true);
        if (what === '') {
            alert('Please add What do you want to get reviewed?');
            setIsCreating(false);
            return;
        }
        let api = Utils.getAPIDescription('share-content');
        let metadata;
        if (parseInt(`${feedType}`) === 1) {
            metadata = JSON.parse(`${JSON.stringify(flagMetadata)}`);
        } else {
            metadata = JSON.parse(`${JSON.stringify(articleMetadata)}`);
        }
        let flag_tags = allTags.flag_tags;
        flag_tags.category = categoryArr;
        flag_tags.sub_category = subcategoryArr;
        flag_tags.geo_tag = geotagArr;

        let tagPayload = JSON.parse(`${JSON.stringify({
            'content_tags': allTags.content_tags,
            'flag_tags': flag_tags,
            'flag_tags1': allTags.flag_tags1,
            'content_tags1': allTags.content_tags1
        })}`);

        let res = await axios.post(api,  {"metadata": metadata, 'feedType': parseInt(`${feedType}`), 'keyword': `${flagUrl}`, 'why': `${what}`, 'isShort': true, 'tags': tagPayload}, { headers: { "userid": `${localStorage.getItem('user_id')}` } });
        if(res.status === 200) {
            if (res.data.errorMessage)
            {
                notification.error({
                    message: 'Some Error Occurred',
                    duration: 3,
                    placement: 'topRight',
                });
            }
            else
            {
                notification.success({
                    message: 'Successfully added the flag',
                    duration: 3,
                    placement: 'topRight',
                });
                //document.location.reload();
            }
        } else {
            alert('Some Error Occurred.');
        }
        setIsCreating(false);
        history.push('/flags/')
        //resetAll();
    }
    const resetAll = () => {
        setFlagUrl('');
        setDetailsLoading(false);
        setFlagMetadata([]);
        setArticleMetadata([]);
        setFlagTitle('');
        setPublishDate('');
        setContentSource('');
        setBroadcaster('');
        setSponser('');
        setWhat('');
        setFeedType(null);
        setCategoryArr([]);
        setSubcategoryArr([]);
        setGeoList([]);
        setGeotagArr([]);
        setCategoryList([]);
        setSubcategoryList([]);
        setAllTags({});
        setTagArr([]);
        setCatSubcat([]);
    }

    const onCategoryChange = (value) => {
        setCategoryArr([value]);
    }

    const onRegionChange = (value) => {
        setGeotagArr([value]);
    }
    return (
        <div className="app-workspace">
            <Header/>
            <div className="app-content">
                <Nav/>
                <div className='create-flag-main pull-left'>
                <div className='create-flag-con'>
                    <div className="app-createflag">
                        <div className='create-heading'>Create New Flag</div>
                        <div className='mb-2'>
                            <span className='fn-14 mb-2'>Paste Claim URL</span> <span className='fn-14 blue-font font-weight-bold ml-2 mb-2 cursor-pointer' onClick={getDetails}>Get Details</span>{detailsLoading ? <div className='loader-create'><img src={Loading}></img></div> : null}
                        </div>
                        <input name='link' className='link-input' placeholder="Enter URL" onChange={handleOnChange}></input>
                        <br />
                        <div className='fn-14 mb-2 mt-5'>Title</div>
                        <div className='value-text-box'>{flagTitle}</div>
                        <div className='flag-metadata'>
                            <div>
                                <div className='create-label'>Date of Source Upload</div>
                                <div className='value-text'>{ publishDate ?  moment(publishDate).format('MM-DD-YYYY') : 'N.A.'}</div>
                            </div>
                            <div>
                                <div className='create-label'>Content Source</div>
                                <div className='value-text'>{contentSource}</div>
                            </div>
                            <div>
                                <div className='create-label'>Broadcaster name</div>
                                <div className='value-text'>{broadcaster}</div>
                            </div>
                        </div>
                        <div className='fn-14 mb-2 mt-4'>Sponser</div>
                        <input name='sponser' className='sponser-input' value={sponser} onChange={handleOnChange} ></input>
                        <div className='fn-14 mb-2 mt-4'>Comment</div>
                        <textarea name='what' className='why-textarea' placeholder='Question you want to ask about this specific claim' onChange={handleOnChange}></textarea>
                        <div className='tags-board'>
                            <div className='tags-class'>
                                <div className='create-label'>Category</div>
                                <div className='select-wrapper'>
                                    <Select style={{ width: '220px' }} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        bordered={false}
                                        notFoundContent={null}
                                        disable={hasSearched}
                                        value={categoryArr.length > 0 ? categoryArr[0] : ''}
                                        onChange={onCategoryChange}
                                    >
                                        {categoryList.map((item, id) => (
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                                {/*<div>
                                <div className='create-label'>Sub Category</div>
                                <div className="colw-100">
                                    {(subcategoryArr && (subcategoryArr.length > 0)) ? subcategoryArr.map((item, indx) => (
                                        <div className="text-center my-2 pull-left mx-2 tag-box" key={'ptg_' + indx}>
                                            <Tag key={'sctg_' + indx} tagLabel={item} removeTag={() => {
                                                removeSubcategoryTag(item)
                                            }}/>
                                        </div>
                                    )) : ''}

                                    <div className="text-center pull-left my-2 mx-2">
                                        <NewTag addNewTag={addSubcategory} tags={subcategoryArr} label='Add Subcategory' datasource={subcategoryList}/>
                                    </div>
                                </div>
                                 </div>*/}
                            <div className='tags-class'>
                                <div className='create-label'>Region</div>
                                <div className="colw-100">
                                    <div className='select-wrapper'>
                                    <Select style={{ width: '220px' }} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} bordered={false} notFoundContent={null} disable={hasSearched} value={geotagArr.length > 0 ? geotagArr[0] : ''} onChange={onRegionChange}>
                                        {geoList.map((item, id) => (
                                            <Option value={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='create-flag-right'>
                        {hasSearched ?
                            <div className='flag-det-board'>
                                <div className='rev-box'>
                                    <div className='heading'>Related Fact Briefs</div>
                                    <div className='rev-board'>
                                        {relatedReviews.length > 0 ? relatedReviews.slice(0, 3).map((item) => (
                                            <div className='rev-card'>
                                                <a href={item.url} target='_blank' style={{ color: 'black', textDecoration: 'none' }}>{item.claimReviewed}</a>
                                                <div className='time-ago'>{item.datePublished}</div>
                                            </div>

                                        )) : <div className='no-data'>No data</div>}
                                    </div>
                                </div>
                                <div className='rev-box'>
                                    <div className='heading'>Related Flags</div>
                                    <div className='rev-board'>
                                        {relatedFlags.length > 0 ? relatedFlags.slice(0, 3).map((item) => (
                                            <div className='rev-card-flag'>
                                                <div>
                                                    <div className='blue-font'>{item.tags.join(', ')}</div>
                                                    <div className='my-1 cursor-pointer' >{item.what}</div>
                                                    <div className='mt-2 small-gray-font'>Editorial Comment: {item.comments}</div>
                                                    <div>
                                                        <span className='time-ago'>{moment(parseInt(item.createdAt)).fromNow()}</span>
                                                        <span className='num ml-1'>0</span><span className='time-ago'>&nbsp;Reviews</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <img src={item.__typename === 'twitter_flag' ? Twitter : Web} width='20px'></img>
                                                </div>
                                            </div>

                                        )) : <div className='no-data'>No data</div>}
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }
                    </div>
                </div>
                    <center><span className='color-ret mr-3 fn-14 cursor-pointer' onClick={() => history.push('/flags/')}>Delete</span><button className={'create-flag-button ' + (!enableCreateFlag ? 'disabled-button' : '')} type='primary' onClick={createFlag} disabled={!enableCreateFlag}>{iscreating ? 'Creating...' : 'Create'}</button>{iscreating ? <div className='loader-create'><img src={Loading}></img></div> : ''}</center>
                </div>
            </div>
        </div>
    );
}
