import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Utils from '../../../../utils/utils';

import Loading from '../../../../assets/images/loader.gif';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
// import moment from 'moment-timezone';
import './add-audience-manager.css';
import { notification } from 'antd';
import { Link } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';

export default function CPAddAudienceManager(props) {

    const [data, setData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);

    useEffect(() => {
        getManagers();
    }, []);

    const getManagers = () => {
        setDataLoading(true);
        let orgId =  localStorage.getItem('org_id') !== 'undefined' ? localStorage.getItem('org_id') : 0;
        let api = Utils.getAPIDescription('get-aud-manager') + '?org_id='+orgId;
        axios.get(api)
            .then((res) => {
                if (res.data.author_list){
                    let dta = res.data.author_list;
                    setData(dta.filter(item => item.id !== -1));
                }
                setDataLoading(false);
            });
    }



    const UserRow = (props) => {
        const [userStatus, setUserStatus] = useState(null);
        const [isLoading, setIsLoading] = useState(false);
        const [showDeleteModal, setShowDeleteModal] = useState(false);

        useEffect(() => {
            if (props.data && props.data.state) {
                setUserStatus(props.data.state);
            }
        },[props]);

        const removeManager = () => {
            setShowDeleteModal(false);
            //setIsLoading(true);
            let api = Utils.getAPIDescription('remove-aud-manager');
            axios.post(api, { "user_id": props.data.id })
                .then((res) => {
                    notification.success({
                        message: 'Manager removed successfully',
                        duration: 3,
                        placement: 'topRight',
                    });
                    getManagers();
                    //setIsLoading(false);
                })
        }
        return (
            <>
                <div className='user-row author'>
                    <div>
                        <Avatar size={60} icon={<UserOutlined />} />
                    </div>
                    <div className='mt-2 ml-2'>
                        <div className='fn-13 ln-50'>{props.data.name} ({props.data.email}){props.data.orgId === localStorage.getItem('org_id') && ' (You)'}</div>
                    </div>
                    <div className='mt-2'>
                        {localStorage.getItem('user_types').includes('org_admin') && <span className="blue-font under-line cursor-pointer" onClick={() => { setShowDeleteModal(true) }}>{isLoading ? <span>Removing...</span> : <span>Remove</span>}</span>}
                        {/* {localStorage.getItem('user_types').includes('org_admin') && 'isCognitoUser' in props.data && !props.data.isCognitoUser && <>&nbsp;&nbsp;<a className="blue-font cursor-pointer" href={"/add-user?author_name=" +  encodeURIComponent(props.data.author_name) + "&author_id=" + props.data.id}>Convert to Editor</a></>} */}
                    </div>
                </div>
                <div className="delete-modal-wrapper">
                    <Modal open={showDeleteModal} center classNames={{ modal: 'deleteModal', }} showCloseIcon={false} onClose={() => setShowDeleteModal(false)}>
                        <div >
                            <div className="review-txt">
                          <span>
                              <strong> Do you really want to delete this audience manager? </strong>
                          </span>
                            </div>
                            <div className="modal-footer mt-3">
                                <button className="delete-modal-btn" onClick={()=>{setShowDeleteModal(false)}}>Cancel</button>
                                <button className="delete-modal-btn blue-bg-btn" onClick={() => removeManager()}>Confirm</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </>
        )
    }

    return (

        <div className='mt-5'>
            <span className='blue-font'>{data.length > 1 ? data.length+' Audience Managers' : data.length+' Audience Manager'}</span>
            {localStorage.getItem('user_types').includes('org_admin') ? <Link Link to="/partnerconsole/add-audience-manager"><button className='blue-btn ml-4'>ADD NEW</button></Link> : null}
            <div className='revs-board' style={{textAlign: 'center'}}>
                {!dataLoading || data.length > 0 ? data.map((item, idx) => (
                    <UserRow data={item} key={"user_row_" + idx} />
                )) : <img src={Loading} width='30px'/>}
            </div>
        </div>


    )
}