import Axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Utils from '../../../../utils/utils';
import Loading from '../../../../assets/images/loader.gif';
import './edit-profile.scss';
import { Tabs } from 'antd';
import MyAccount from '../my-account/my-account';
import EditReviewers from '../edit-reviewers/edit-reviewers';
import EditDomain from '../../../editDomain/editDomain';
import CPAddAuthor from '../addauthor/addauthor';
import OrgBanner from '../../../OrgBanner/orgbanner';
import CPAddAudienceManager from "../add-audience-manager/add-audience-manager";
import useOrgStore from "../../store/orgsStore";

export default function CPEditProfile(props) {
    const [activeKey, setActiveKey] = useState('1');
    const { TabPane } = Tabs;

    useEffect(() => {
        setTimeout(() => {
          document.title = "Gigafact Partner Console";
        }, 1000);
      }, []);

    const { orgDetails, fetchOrgDetails, isFetched } = useOrgStore();

    useEffect(() => {
        if (!isFetched) {
            fetchOrgDetails();
        }
    }, [fetchOrgDetails, isFetched]);

    useEffect(() => {
        fetchOrgDetails()
        if (window.location.href) {
            let str = window.location.href;
            let key = Utils.checkSeparatorMapping(str, '#', 1);
            if (key) {
                setActiveKey(key);
            }
        }
    }, [])
    const handleTabClick = (key) => {
        setActiveKey(key);
    }
    return (
        <div className="app-workspace">
            <OrgBanner orgdetails={orgDetails} />
            <div className="app-content">
                <div className='app-profile w-100 pull-left'>
                    <div className='edit-heading'>Edit Profile</div>
                    <div className='profile-tabs'>
                        <Tabs size='large' activeKey={activeKey} onTabClick={handleTabClick}>
                            <TabPane tab="My Account" key="1">
                                <MyAccount />
                            </TabPane>
                            
                            {localStorage.getItem('user_types').includes('content_partner_admin') &&
                                <TabPane tab="Editors & Writers" key="2">
                                    <EditReviewers />
                                </TabPane>
                            }
                            {/* {Utils.checkContentPartnerContributor() && <TabPane tab="Authors" key="3">
                                <CPAddAuthor />
                            </TabPane>} */}

                            {localStorage.getItem('user_types').includes('content_partner_admin') &&
                                <TabPane tab="Audience Managers" key="4">
                                    <CPAddAudienceManager />
                                </TabPane>
                            }

                            {/* <TabPane tab="Manage Domain & Topics" key="3">
                                <EditDomain />
                            </TabPane> */}
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>

    )
}
