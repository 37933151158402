import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import Utils from '../../utils/utils.js';
import FlagTile from '../flagTile/flagTile.js';
import Header from '../header/header.js';
import Nav from '../nav/nav.js';
import BlackPin from '../../assets/images/black-pin.svg'
import BlackFS from '../../assets/images/black_fs.svg'
import BlackEye from '../../assets/images/black-eye.svg'
import BlackLink from '../../assets/images/black-link.svg'
import Loading from '../../assets/images/loader.gif';
import Twitter from '../../assets/images/logos/twitter.png';
import FB from '../../assets/images/logos/facebook.svg'
import Web from '../../assets/images/link.svg';
import FSLogo from '../../assets/images/FSLogo.svg';
import FlagDetails from '../flagdetails/flagdetails.js';
import Pagination from "react-js-pagination";
import { Drawer, Select, DatePicker, Popover, Collapse } from 'antd';
import moment from 'moment-timezone';
import './pindashboard.css'

export default function PinDashboars(props) {
	const [flagList, setFlagList] = useState([])
	const [flagLoading, setFlagLoading] = useState(true)
	const [drawerVisible, setDrawerVisible] = useState(false)
	const [flagId, setFlagId] = useState(undefined)
	const [orgDetails, setOrgDetails] = useState(null)
	const [orgLoading, setOrgLoading] = useState(true)
	const [activePage, setActivePage] = useState(1)
	const [totalCount, setTotalCount] = useState(0)
	const [sortValue, setSortValue] = useState('pin_date')
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const prevSort = useRef()

	const userid = localStorage.getItem('user_id')
	const PAGE_LIMIT = 10
	const { Option } = Select
	const { Panel } = Collapse;
	const { RangePicker } = DatePicker

	useEffect(() => {
		getOrgDetails()
	}, [])

	useEffect(() => {
		fetchPinnedFlags()
	}, [activePage])

	useEffect(() => {
		if (prevSort.current && prevSort.current !== sortValue) {
			fetchPinnedFlags()
		}
		prevSort.current = sortValue
	}, [sortValue])

	useEffect(() => {
		if (startDate) {
			fetchPinnedFlags()
		}
	}, [startDate])

	const toTimeStamp = (dt) => {
		let datum = Date.parse(dt);
		if (!datum) {
			return null;
		}
		return moment(datum).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).unix();
	}

	const toTimeStampEnd = (dt) => {
		let datum = Date.parse(dt);
		if (!datum) {
			return null;
		}
		return moment(datum).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).unix();
	}

	const fetchPinnedFlags = () => {
		setFlagLoading(true)
		let queryParams =
			`?limit=${PAGE_LIMIT}
			&page=${activePage}
			&list_type=org_flags
			&org_id=3
			${sortValue === 'latest' ? "&sort_by_creation=true" : ""}
			${startDate ? `&startAt=${toTimeStamp(startDate)}` : ""}
			${startDate ? `&endAt=${toTimeStampEnd(endDate)}` : ""}`
		
		let api = Utils.getAPIDescription('get-featured-flags') + queryParams
		axios.get(api).then((res) => {
			if (res.data?.statusCode === 200) {
				setFlagList(res.data?.pinned_flags ?? [])
				setTotalCount(res.data?.total_count ?? 0)
			}
			setFlagLoading(false)
		}).catch(err => {
			console.log(err)
			setFlagLoading(false)
		})
	}
	
	const getOrgDetails = () => {
		setOrgLoading(true)
		let api = Utils.getAPIDescription('get-org-details') + '?org_id=' + localStorage.getItem('org_id');
		axios.get(api).then((res) => {
			if (res.data?.data) {
				setOrgDetails(res.data.data)
			}
			setOrgLoading(false)
		}).catch(err => {
			console.log(err)
			setOrgLoading(false)
		})
	}
	
	const onflagClick = (fid) => {
		setFlagId(fid);
		setDrawerVisible(true);
		let flags = flagList.slice()
		flags = flags.map((item) => {
			if (item.id === fid && !('viewers' in item && item.viewers.includes(userid))) {
				if ('viewers' in item) {
					item.viewers = item.viewers + [userid]
				}
				item.viewers = [userid]
			}
			return item
		})
		setFlagList(flags)
	}
	
	const onClose = () => {
		setDrawerVisible(false);
		window.history.pushState({}, null, window.location.href.split('#')[0]);
	};
	
	const onPinAndUnpinning = (fid, proc, obj) => {
		// console.log(fid);
		// console.log(proc);
		// console.log(obj);
		let flags = flagList.slice();
		if (true) {
			flags = flags.filter(item => {
				return item.id !== fid
			})
		}

		flags = flags.map((item) => {
			if (item.id === fid) {
				if (proc === 1) {
					item['pinned_content'] = obj;
				}
				if (proc === 0) {
					if ('pinned_content' in item) {
						delete item['pinned_content'];
					}
				}
			}
			return item
		})
		setFlagList(flags);
	}
	
	const handlePageChange = (page) => {
		setActivePage(page)
	}

	const handleRangePick = (e) => {
		if (e) {
			setStartDate(e[0])
			setEndDate(e[1])
			return
		}
		setStartDate(null)
		setEndDate(null)
	}

	const getUserDisplayName = (item) => {
		return (item.__typename === 'twitter_flag'
						? `${item.user_display_name ?? item.twitter_user ?? ""}`
						: item.user_display_name ?? item.username)
	}
	const UserListJSX = (item) => {
		return (<div className='db-user-list'>
			<Collapse
				expandIconPosition='right'
				ghost={true}
			>
				<Panel
					header={(<div className='upvoter-list-header'>
						<div className='upvt-tag'>Pin Received</div>
						<div className='ml-2'>{getUserDisplayName(item)} {item?.upvoter_user_infos?.length > 0 && !(item?.upvoter_user_infos?.length === 1 && item?.upvoter_user_infos[0].id === item.twitter_user_id) && `and ${item?.upvoter_user_infos?.length} others`}</div>
					</div>)}
					key="upvoted"
					className='custom-panel'
				>
					<div className='db-user-row'>
						{<div className=''>{getUserDisplayName(item)}</div>}
					</div>
                    {item?.upvoter_user_infos?.length > 0 && item.upvoter_user_infos.map((pin_item, idx) => <div key={"up_" + idx} className=''>
                        {item?.twitter_user_id !== pin_item.id && <div className='db-user-row'>
                            <div className=''>{pin_item.name}</div>
                            {/* <div className='impact-score'>{item.impact_score_v2}</div> */}
                        </div>}
					</div>) }
				</Panel>
			</Collapse>
			{item?.share_user_infos?.length > 0 && <Collapse
				expandIconPosition='right'
				ghost={true}
			>
				<Panel
					header={(<div className='upvoter-list-header'>
						<div className='share-tag'> pin shared </div>
						<div className='ml-2'>{item.share_user_infos[0].name} {item.share_user_infos.length > 1 && `and ${item.upvoter_user_infos.length - 1} others`}</div>
					</div>)}
					key="upvoted"
					className='custom-panel'
				>
					{item.share_user_infos.map((pin_item, idx) => <div key={"sh_" + idx}>
						{item?.twitter_user_id !== pin_item.id && <div className='db-user-row'>
                            <div className=''>{pin_item.name}</div>
                            {/* <div className='impact-score'>{item.impact_score_v2}</div> */}
                        </div>}
					</div>) }
				</Panel>
			</Collapse>}
		</div>)
	}

	const UserListTitle = (
		<div>
			<img src={FSLogo} width="150px" />
		</div>
	)

	return (
	<div className="app-workspace">
		<Header login='none' />
		<div className='db-org-panel'>
			<div></div>
			<div className='db-org-panel-right'>
				{orgDetails && <div>
					<div className='logo-div'>
						<div className='logo'>
							<img src={orgDetails.logoURL} width="50px" height="50px" />
						</div>
						<div className='title'>
							{orgDetails.org_name}
						</div>
					</div>
					<div className='description'>
						{orgDetails.description}
					</div>
					<div className='url-div'>
						{orgDetails?.links?.Twitter && <div className='url-item'>
							<a href={orgDetails.links.Twitter} target='_blank' rel='noopener noreferrer' >
								<img src={Twitter} />
							</a>
						</div>}
						{orgDetails?.links?.Facebook && <div className='url-item'>
							<a href={orgDetails.links.Facebook} target='_blank' rel='noopener noreferrer' >
								<img src={FB} />
							</a>
						</div>}
						{orgDetails?.links?.Website && <div className='url-item'>
							<a href={orgDetails.links.Website} target='_blank' rel='noopener noreferrer' >
								<img src={Web} />
							</a>
						</div>}
					</div>
				</div>}
				{orgLoading && <div className="org-panel-loader">
					<img src={Loading} width={30} height={30} />
				</div>}
			</div>
		</div>
		<div className="app-content">
			<Nav />
			{/* <div className="pull-left app-dashboard-flag">
                <div className='rel-flags-title mb-4'>
					<div>PINS</div>
					<div className="d-flex">
						<div className="flag-sort-wrapper">
                            <Select onChange={(val) => { setSortValue(val); }}  style={{ width: '212px' }} value={sortValue} optionLabelProp="label">
                                <Option value="latest" label={<div>SORT BY:&nbsp;&nbsp;Latest</div>}>Latest</Option>
                                <Option value="pin_date" label={<div>SORT BY:&nbsp;&nbsp;Pinned Date</div>}>Pinned Date</Option>
                            </Select>
						</div>
						<div className='ml-2'>
							<RangePicker
								defaultValue={null}
								onChange={handleRangePick}
							/>
						</div>
					</div>

				</div>
				<div className="flag-list colw-100">
					{flagLoading ? <div className="loading-flag"><img src={Loading}/></div> : null}
					{flagList && flagList.length > 0 ? flagList.map((item, index) => ( <div className={(item?.pinned_content ? 'db-item' : 'db-item-single') + ('viewers' in item && item.viewers.includes(userid) ? ' db-item-viewed' : '')} key={item.id}>
						<FlagTile
							flagData={item}
							flagList={flagList}
							setFlagList={setFlagList}
							onFlagClick={onflagClick}
							userid={userid}
							viewed={'viewers' in item && item.viewers.includes(userid)}
							contentPartnerMode={Utils.checkContentPartner()}
							isDummy={true}
						/>
						<div className='db-item-right'>
							<div className='pin-info-item'>
								<div className='db-org-logo'>
									<img src={item.pinned_content.org_details.org_logo} width="27px" />
								</div>
								<div className='text'>
									{item.pinned_content.org_details.org_name}
								</div>
							</div>
							<a style={{ color: "#0071BC" }} href={item.pinned_content.url} target='_blank' rel="noopener noreferrer">
								<div className='pin-info-item align-items-start'>
									<div className='icon'>
										<img src={BlackPin} width="9" />
									</div>
									<div className='link-text'>
										<div className='title-clamp'>{item.pinned_content.pin_comment}</div>
										<div className='date'>{moment(item.pinned_added_ts * 1000).format('LL')}</div>
									</div>
								</div>
							</a>
							<div className='pin-info-item'>
								<div className='icon'>
									<img src={BlackFS} width="7" />
								</div>
								<div className='text cursor-pointer'>
									<Popover
										trigger="click"
										title={null}
										overlayClassName='user-list-popover'
										content={UserListJSX(item)}

									>
										{`${(item?.upvoter_user_infos?.length ?? 0) + (item?.share_user_infos?.length ?? 0) + 1}  user${item?.upvoter_user_infos?.length > 0 || item?.share_user_infos?.length > 0 ? 's' : ""}`}
									</Popover>
								</div>
							</div>
						</div>
					</div>))
						: <>
							{!flagLoading ? <div className="no-data-msg">No Data Found.</div> : ''}
						</>
					}
				</div>
				<div className="pagination-control pull-right">
					<Pagination
						itemClass="page-item"
						linkClass="page-link"
						activePage={activePage}
						itemsCountPerPage={PAGE_LIMIT}
						totalItemsCount={totalCount}
						pageRangeDisplayed={3}
						onChange={handlePageChange}
						hideDisabled={totalCount > 0 ? false: true}
					/>
				</div>
			</div> */}
			{/* <Drawer placement="right" closable={false} onClose={onClose} visible={drawerVisible} width='35%' >
				<FlagDetails flagId={flagId} onPinAndUnpinning={onPinAndUnpinning} setVisible={setDrawerVisible} contentPartnerMode={Utils.checkContentPartner()} />
			</Drawer> */}
		</div>
	</div>
	)
}