import { ArrowRightOutlined, DisconnectOutlined, LoadingOutlined, QuestionCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Carousel, Input, Modal, notification, Popover, Slider, Spin, Steps } from 'antd';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useHistory } from "react-router-dom";
import FSHalfLogo from '../../../../assets/images/fs-half.svg';
import FSLogo from '../../../../assets/images/FSLogo.svg';
import Utils from '../../../../utils/utils';
import { CognitoLogin, CognitoSignUp } from '../../utilities/Auth';
import { bytesToSize } from '../../utilities/Common';
import S3 from 'react-aws-s3';
import { doLogin } from '../authentication';
import './sign-up.scss';

const { Step } = Steps;



const bucketName = 'gigafact-app-images';
const folderName = 'resources';

const config = {
    bucketName: bucketName,
    dirName: folderName,
    region: 'us-east-2',
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY
};


export const SignUp = (props) => {
    const history = useHistory();
    const [token, setToken] = useState(null);
    const [isValidSession, setIsValidSession] = useState(true);
    const [validatingSession, setValidatingSession] = useState(true);
    const [orgData, setOrgData] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmData] = useState(null);
    const [link, setLink] = useState(null);
    const [form1Error, setForm1Error] = useState({ initial: "" });
    const [form2Error, setForm2Error] = useState({});
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const carouselRef = useRef(null);
    const [logo1, setLogo1] = useState(null);
    const [logo2, setLogo2] = useState(null);
    const [logo1File, setLogo1File] = useState(null);
    const [logo2File, setLogo2File] = useState(null);
    const [logo1Loading, setLogo1Loading] = useState(false);
    const [logo2Loading, setLogo2Loading] = useState(false);
    const [creationSteps, setCreationSteps] = useState(0);
    const [createOrgResp, setCreateOrgResp] = useState(null);
    const [userLoginData, setUserLoginData] = useState(null);
    const [image1Editor, setImage1Editor] = useState({ display: false, height: 0, width: 0, scale: 1 });
    const [image2Editor, setImage2Editor] = useState({ display: false, height: 0, width: 0, scale: 1 });
    const [fileSizeErrorModal, setFileSizeErrorModal] = useState({ display: false, currentSize: 0, maxSize: 0, elementId: "" });
    const editor1Ref = useRef(null);
    const editor2Ref = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);


    useEffect(() => {
        localStorage.clear();
        const query = new URLSearchParams(window.location.search);
        const qToken = query.get('token');
        if (qToken) {
            setToken(qToken)
        } else {
            setToken(null)
        }
    }, []);

    useEffect(() => {
        if (token) {
            validateToken();
        }
    }, [token]);

    useEffect(() => {
        const performStep = async () => {
            switch (creationSteps) {
                case 1:
                    createOrganization();
                    break;
                // case 2:
                //     createAuthUser();
                //     break;
                case 3:
                    try {
                        const result = await CognitoLogin('Manual', { username: orgData.admin_email, password });
                        setUserLoginData(result);
                        setCreationSteps(4);
                    } catch (err) {
                        console.log(err);
                    }
                    break;
                case 4:
                    fileUpload(logo1File, "logo1");
                    break;
                case 5:
                    fileUpload(logo2File, "logo2");
                    break;
                case 6:
                    updateOrganization();
                    break;
                case 7:
                    try {
                        const resp = await doLogin('Manual', { username: orgData.admin_email, password });
                        if (Utils.checkLogin()) {
                            if (sessionStorage.redirect_path) {
                                if (!sessionStorage.redirect_path.includes('/partnerconsole/'))
                                    window.location.href = '/partnerconsole/home';
                                else
                                    window.location.href = sessionStorage.redirect_path;
                            } else {
                                history.push(Utils.checkContentPartnerWriter() ? '/partnerconsole/home' : '/fact-briefs');
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                    break;
                default:
                    break;
            }
        };

        performStep();
    }, [creationSteps]);


    useEffect(() => {
        errorHandle();
    }, [firstName, lastName, password, confirmPassword, link]);
    useEffect(() => {
        carouselRef.current.goTo(currentSlide);
    }, [currentSlide]);
    useEffect(() => {
        if (createOrgResp) {
            createAuthUser()
        }
    }, [createOrgResp]);

    const validateToken = () => {
        let api = Utils.getAPIDescription('new-org-validate-token');
        let payload = {
            token: token, sys_time: new Date().toISOString().slice(0, -5)
        }
        axios.post(api, payload).then((res) => {
            if (res.data.statusCode && res.data.statusCode === 200) {
                setIsValidSession(true)
                setOrgData(res.data.response)
            } else {
                setIsValidSession(false)
            }
            setValidatingSession(false)
        }).catch(err => {
            console.log(err)
        })
    };

    const ContentPartnerRoles = {
        Admin: 'content_partner_admin',
        Partner: 'content_partner'
    };

    const createAuthUser = async () => {
        let payload = {
            username: orgData.admin_email,
            password: password,
            attributes: {
                'custom:first_name': firstName,
                'custom:last_name': lastName,
                'custom:role': ContentPartnerRoles.Admin,
                'custom:org_id': String(createOrgResp.org_id)
            }
        };
        try {
            await CognitoSignUp(payload);
            setCreationSteps(3);
        } catch (error) {
            notification.error({ message: error.message, duration: 5, placement: 'topRight' });
        }
    };


    const validateInputs = () => {
        let isValid = true;
        //validating the fields which should not be empty
        isValid = isValid && firstName != null && firstName.length > 0;
        isValid = isValid && lastName != null && lastName.length > 0;
        isValid = isValid && password != null && password.length > 0;
        isValid = isValid && confirmPassword != null && confirmPassword.length > 0 && password === confirmPassword;
        // isValid = isValid && link != null && link.length > 0;
        // isValid = isValid && logo1 != null && logo1.length > 0;
        // isValid = isValid && logo2 != null && logo2.length > 0;
        return isValid;
    }
    const errorHandle = () => {
        let error = {};
        if (firstName != null && firstName.length <= 0) error.firstName = "First Name Required"
        if (lastName != null && lastName.length <= 0) error.lastName = "Last Name Required"
        if (password != null && password.length <= 0) error.password = "Password Required"
        if (confirmPassword != null && confirmPassword !== password) error.confirmPassword = "Password and confirm password should be same";
        // if (link != null && link.length <= 0) error.link = "Link Required";
        // if (logo1 != null && logo1.length <= 0) logo1.password = "Logo 1 is required";
        // if (logo2 != null && logo2.length <= 0) logo1.password = "Logo 2 is Required";
        // if (link != null && link.length > 0 && !error.link && !(/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9-]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(link))) {
        //     error.link = "Invalid Link";
        // }
        if (password != null && password.length > 0 && !error.password && !(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password))) {
            error.password = "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
        }
        if (firstName === null || lastName === null || password === null || confirmPassword === null) {
            error.initial = ""
        }
        setForm1Error(error)
    }
    const downloadDoc = (e) => {
        window.open('https://docs.google.com/document/d/1JccA9TkEzyJzlWvucSa36gjP0Dq9zXmuG6CmUQHm44k/edit?usp=sharing')
    }

    const createOrganization = async () => {
        let api = Utils.getAPIDescription('create-organisation') + "?source=new-org";
        let payload = {
            org_name: orgData.org_name,
            token
        }
        axios.post(api, payload).then((res) => {
            if (res.data.statusCode && res.data.statusCode === 200) {
                setCreateOrgResp(res.data.response)
                setCreationSteps(2);
            } else {
                setCreationSteps(0);
                notification.error({ message: res.data.message })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const setFile = (e, type) => {
        if (type === 'logo1') {
            let maxFileSize = 1000 * 1000 * 2;
            if (maxFileSize >= e.target.files[0].size) {
                setLogo1(URL.createObjectURL(e.target.files[0]));
                setLogo1File(e.target.files[0]);
                setImage1Editor({ display: true, scale: 1 })
            } else {
                setFileSizeErrorModal({ display: true, currentSize: e.target.files[0].size, maxSize: maxFileSize, elementId: type })
            }
        }
        if (type === 'logo2') {
            let maxFileSize = 1000 * 1000 * 2;
            if (maxFileSize >= e.target.files[0].size) {
                setLogo2(URL.createObjectURL(e.target.files[0]));
                setLogo2File(e.target.files[0]);
                setImage2Editor({ display: true, scale: 1 })
            } else {
                setFileSizeErrorModal({ display: true, currentSize: e.target.files[0].size, maxSize: maxFileSize, elementId: type })
            }
        }
        e.target.value = null;
    };

    const fileUpload = async (file, type) => {

        let filename = `${(orgData.org_name).replaceAll(" ", "_")}_${type}_${new Date().getTime()}.${(file.name.split(".").reverse()[0])}`;

        if (type === 'logo1') setLogo1Loading(true);
        if (type === 'logo2') setLogo2Loading(true);

        try {

            const ReactS3Client = new S3(config);

            ReactS3Client.uploadFile(file, filename).then((data) => {
                if (type === 'logo1') {

                    setLogo1(data.location);
                    setCreationSteps(creationSteps + 1);
                }
                if (type === 'logo2') {
                    setLogo2Loading(false);
                    setLogo2(data.location);
                    setCreationSteps(creationSteps + 1);
                }
            }).catch((err) => {
                console.error(err);
                notification.error({ message: "Something went wrong!", duration: 3, placement: 'topRight', })
            }).finally(() => {
                if (type === 'logo1') setLogo1Loading(false);
                if (type === 'logo2') setLogo2Loading(false);
            });

        } catch (error) {
            console.error(error);
            notification.error({ message: "Something went wrong!", duration: 3, placement: 'topRight', })
        }
    };


    const updateOrganization = () => {
        let api = Utils.getAPIDescription('update-org');
        let payload = {
            logo1: logo1,
            logo2: logo2,
            links: { website: link },
            org_id: String(createOrgResp.org_id)
        };
        let config = {
            headers: {
                Authorization: userLoginData.signInUserSession.idToken.getJwtToken()
            }
        };
        axios.post(api, payload, config)
            .then(() => {
                notification.success({ message: 'Information Updated Successfully!', duration: 3, placement: 'topRight', });
                setCreationSteps(7);

            }).catch(err => {
                console.log(err);
            });
    };
    const formSubmit = (e) => {
        e.preventDefault();
        if (!logo1 || !logo2 || link === null || link === "") {
            if (Object.keys(form1Error).length <= 0) {
                carouselRef.current.goTo(1);
            }
        } else {
            if (validateInputs()) {
                setCreationSteps(1)
            }
        }
        // if(carouselRef.current.)
        // 
    }
    const imageUpdate = (ref) => {
        if (image1Editor.display) {
            setLogo1(ref.current.getImageScaledToCanvas().toDataURL());
            setImage1Editor(prev => ({ ...prev, display: false }));
            setLogo1File(new File(ref.current.getImageScaledToCanvas().toDataURL(), logo1File.name))

        }
        if (image2Editor.display) {
            setLogo2(ref.current.getImageScaledToCanvas().toDataURL());
            setImage2Editor(prev => ({ ...prev, display: false }))
            setLogo2File(new File(ref.current.getImageScaledToCanvas().toDataURL(), logo2File.name))

        }
    }


    return <div>
        {validatingSession && <div className="validating-session">
            Validating session, Please wait. &nbsp;<LoadingOutlined />
        </div>}
        {creationSteps !== 0 && <div className="validating-session">
            Configuring the organization, Please wait. &nbsp;<LoadingOutlined />
        </div>}
        <div className="container-fluid signup-container m-0 p-0">
            <div className="row h-100 p-0 m-0" >
                <div className="col-md-6 col-sm-12 left-panel ">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10 col-sm-12 text-center">
                            <div className="heading-1">
                                Gigafact Partner Console
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-10 col-sm-12 px-3 text-center">
                            <div className="line-1">
                                For invited media partners of Gigafact who offer help to fact-seeking consumers
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-10 sol-cm-12 text-center">
                            <div className="line-2" onClick={downloadDoc}>
                                Gigafact content partner criteria<ArrowRightOutlined style={{'padding-left' : '10px'}}/>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10 col-sm-12 text-center pb-5">
                            <div className="line-3">
                                Optimized for desktop
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-md-6 col-sm-12 right-panel h-100">
                    <form onSubmit={(e) => { formSubmit(e) }}>
                        <div className="main-block">
                            <div className="heading-1">
                                Sign up
                            </div>

                            <div className="heading-email">
                                <div className="label email-label">
                                    Email address
                                </div>
                                <div className="input-block email-text">
                                    {orgData && <>{orgData.admin_email}</>}
                                </div>
                            </div>
                            <Steps current={currentSlide} onChange={(e) => { setCurrentSlide(e) }}>
                                <Step title="Step 1" description="User information" />
                                <Step title="Step 2" description="Organization information" disabled={Object.keys(form1Error).length > 0} />
                            </Steps>

                            <Carousel dots={false} swipe={false} arrows={false} accessibility={false} ref={carouselRef}>
                                <div className='carousel-1'>
                                    <div className="form-panel">

                                        <div className="input-section">
                                            <div className="label">
                                                First Name*
                                            </div>
                                            <div className="input-block">
                                                <Input placeholder="Enter First Name" required value={firstName} status={form1Error.firstName ? "error" : ""} onChange={(e) => { setFirstName(e.target.value) }} />
                                            </div>
                                            <div className="error-block">
                                                {form1Error.firstName && <>{form1Error.firstName}</>}
                                            </div>
                                        </div>
                                        <div className="input-section">
                                            <div className="label">
                                                Last Name*
                                            </div>
                                            <div className="input-block">
                                                <Input placeholder="Enter Last Name" required value={lastName} status={form1Error.lastName ? "error" : ""} onChange={(e) => { setLastName(e.target.value) }} />
                                            </div>
                                            <div className="error-block">
                                                {form1Error.lastName && <>{form1Error.lastName}</>}
                                            </div>
                                        </div>
                                        <div className="input-section">
                                            <div className="label">
                                                Password*
                                            </div>
                                            <div className="input-block">
                                                <Input type={'password'} placeholder="Enter Password" required value={password} status={form1Error.password ? "error" : ""} onChange={(e) => { setPassword(e.target.value) }} />
                                            </div>
                                            <div className="error-block">
                                                {form1Error.password && <>{form1Error.password}</>}
                                            </div>
                                        </div>
                                        <div className="input-section">
                                            <div className="label">
                                                Confirm Password*
                                            </div>
                                            <div className="input-block">
                                                <Input type={'password'} placeholder="Confirm Password" required value={confirmPassword} status={form1Error.confirmPassword ? "error" : ""} onChange={(e) => { setConfirmData(e.target.value) }} />
                                            </div>
                                            <div className="error-block">
                                                {form1Error.confirmPassword && <>{form1Error.confirmPassword}</>}
                                            </div>
                                        </div>
                                        <div className="submit-btn">

                                            <Button htmlType='button' className='w-100' disabled={!token || !Object.keys(form1Error).length <= 0} onClick={(e) => { carouselRef.current.next(); setCurrentSlide(1); }}>Next</Button>
                                        </div>


                                    </div>

                                </div>
                                <div>
                                    <div className='carousel-2'>
                                        <div className="form-panel">
                                            <div className="input-section">
                                                <div className="label">
                                                    Console Image &nbsp; <Popover placement='right'
                                                        content={<div className='logo-tooltip-block'>
                                                            <div className="heading">Please upload Rectangle size for Console logo.</div>
                                                            <div className='logo-img logo1'>
                                                                <img src={FSHalfLogo} alt="Console Image" />
                                                            </div>
                                                            <div className="sample_logo">
                                                                (Sample Logo)
                                                            </div>
                                                            <div className="dimention">
                                                                Dimension: 250x250
                                                            </div>
                                                            <div className='format'>
                                                                File formats : JPG, JPEG, PNG,
                                                            </div>
                                                            <div className="max-size">
                                                                (Maxium upload file size: 2 mb).
                                                            </div>
                                                        </div>}
                                                        title={null}
                                                        trigger="click"
                                                    >
                                                        <QuestionCircleOutlined />
                                                    </Popover>
                                                </div>
                                                <div className="button-block">
                                                    <div className="upload-btn">
                                                        <input id="logo1" type="file" onChange={(e) => setFile(e, 'logo1')} style={{ display: "none" }} accept="image/*" />
                                                        <Button onClick={() => document.getElementById("logo1").click()} loading={logo1Loading}>Upload Image</Button>
                                                    </div>
                                                    <div className="preview-block">
                                                        {logo1Loading ? <Spin size="small" /> :
                                                            <img src={logo1} alt="" className='no-dummy-image' />}
                                                    </div>

                                                </div>
                                                <div className="error-block">
                                                    {form2Error && form2Error.logo1 && <>{form2Error.logo1}</>}
                                                </div>
                                            </div>
                                            <div className="input-section">
                                                <div className="label">
                                                    Tipline Image &nbsp; <Popover placement='right'
                                                        content={<div className='logo-tooltip-block'>
                                                            <div className="heading">Please upload Rectangle size for Console logo.</div>
                                                            <div className='logo-img logo2'>
                                                                <img src={FSLogo} alt="Console Image" />
                                                            </div>
                                                            <div className="sample_logo">
                                                                (Sample Logo)
                                                            </div>
                                                            <div className="dimention">
                                                                Dimension: 210x50
                                                            </div>
                                                            <div className='format'>
                                                                File formats : JPG, JPEG, PNG,
                                                            </div>
                                                            <div className="max-size">
                                                                (Maximum upload file size: 2 mb).
                                                            </div>
                                                        </div>}
                                                        title={null}
                                                        trigger="click"
                                                    >
                                                        <QuestionCircleOutlined />
                                                    </Popover>
                                                </div>
                                                <div className="button-block">
                                                    <div className="upload-btn">
                                                        <input id="logo2" type="file" onChange={(e) => setFile(e, 'logo2')} style={{ display: "none" }} accept="image/*" />

                                                        <Button onClick={() => document.getElementById("logo2").click()} loading={logo2Loading}>Upload Image</Button>
                                                    </div>
                                                    <div className="preview-block">

                                                        {logo2Loading ? <Spin size="small" /> :
                                                            <img src={logo2} alt="" className='no-dummy-image' />}
                                                    </div>
                                                </div>
                                                <div className="error-block">
                                                    {form2Error && form2Error.logo2 && <>{form2Error.logo2}</>}
                                                </div>
                                            </div>
                                            <div className="input-section">
                                                <div className="label">
                                                    Website URL*
                                                </div>
                                                <div className="input-block">
                                                    <div className="web-link">
                                                        <Input placeholder="Enter website URL" value={link} onChange={(e) => { setLink(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="error-block">
                                                    {form2Error && form2Error.link && <>{form2Error.link}</>}

                                                </div>
                                            </div>
                                            <div className="submit-btn">
                                                <Button htmlType='submit' className='w-100' disabled={!token || !validateInputs()}>Submit</Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </Carousel>
                        </div>
                    </form>
                </div>
            </div >
        </div >
        <Modal className='invalid-session-modal' title={null} visible={!isValidSession} onOk={null} footer={false} closable={false}>
            <div className="heading">
            </div>
            <div className="body-content">
                <div className="icon">
                    {/* <CloseCircleFilled /> */}<DisconnectOutlined />
                </div>
                <div className="text">
                    Link Expired! <br />
                </div>
            </div>
        </Modal>
        <Modal className='success-modal' title={null} visible={showSuccessModal} onOk={null} footer={false} closable={false}>
            <div className="heading">
            </div>
            <div className="body-content">

                <div className="text">
                    You have successfully created your new organization.
                </div>
                <div className="btn-section">
                    <Button htmlType='submit' className='w-75' onClick={() => { history.push("/partnerconsole/login") }}>Sign in to the partner console
                        <span className='circle'><ArrowRightOutlined /></span></Button>
                </div>
            </div>
        </Modal>
        <Modal className='image-edit-modal' title={null} visible={image1Editor.display} onOk={null} footer={false} closable={false}>
            <div className="row">
                <div className="col-7 d-flex justify-content-center align-items-center">
                    <AvatarEditor
                        ref={editor1Ref}
                        image={logo1}
                        width={250}
                        height={250}
                        border={1}
                        color={[129, 129, 129, 0.6]} // RGBA
                        scale={image1Editor.scale}
                        rotate={0}
                    />
                </div>
                <div className="col-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            Zoom Level:
                        </div>
                        <div className="col-12">
                            <Slider defaultValue={1} value={image1Editor.scale} min={0.1} max={5} step={0.01} onChange={(e) => { setImage1Editor(prev => ({ ...prev, scale: e })) }} />
                        </div>
                        <div className="col-12">
                            <Button className='confirm-btn' type="primary" onClick={() => { imageUpdate(editor1Ref) }}>Confirm </Button>
                        </div>
                    </div>
                </div>
            </div>

        </Modal>
        <Modal className='image-edit-modal' title={null} visible={image2Editor.display} onOk={null} footer={false} closable={false}>
            <div className="row">
                <div className="col-7 d-flex justify-content-center align-items-center">
                    <AvatarEditor
                        ref={editor2Ref}
                        image={logo2}
                        width={210}
                        height={50}
                        border={1}
                        color={[129, 129, 129, 0.6]} // RGBA
                        scale={image2Editor.scale}
                        rotate={0}
                    />
                </div>
                <div className="col-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            Zoom Level:
                        </div>
                        <div className="col-12">
                            <Slider defaultValue={1} value={image2Editor.scale} min={0.1} max={5} step={0.01} onChange={(e) => { setImage2Editor(prev => ({ ...prev, scale: e })) }} />
                        </div>
                        <div className="col-12">
                            <Button className='confirm-btn' type="primary" onClick={() => { imageUpdate(editor2Ref) }}>Confirm </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal className='file-size-error-modal' title={null} visible={fileSizeErrorModal.display} onOk={null} footer={false} closable={false}>
            <div className="img-block">
                <StopOutlined />
            </div>
            <div className="content">
                Image size exceeds the limit of {bytesToSize(fileSizeErrorModal.maxSize)}, <br /> Current size is {bytesToSize(fileSizeErrorModal.currentSize)};
            </div>
            <div className="button-block">
                <Button className='cancel' onClick={(e) => { setFileSizeErrorModal(prev => ({ ...prev, display: false })) }}>Cancel</Button>
                <Button className='choose' onClick={(e) => { setFileSizeErrorModal(prev => ({ ...prev, display: false })); document.getElementById(fileSizeErrorModal.elementId).click() }}>Replace</Button>
            </div>
        </Modal>
    </div >
};
