import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useHistory } from "react-router-dom";
import { Tweet } from 'react-twitter-widgets';
import FSBird from '../../../../../assets/images/fs-half.svg';
import Loading from '../../../../../assets/images/loader.gif';
import Utils from '../../../../../utils/utils.js';
import './flag-details.scss';
import { CloseOutlined } from '@ant-design/icons';
export default function FlagDetails(props) {

    const [flagId, setFlagId] = useState('');
    const [tipDetails, setTipDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isTiplineFlag, setIsTiplineFlag] = useState(false);
    const [isContributorFlag, setIsContributorFlag] = useState(false);
    const [isFromPartnerConsole, setIsFromPartnerConsole] = useState(false);

    const history = useHistory();
    const source = axios.CancelToken.source();
    let baseContURL = 'https://repustar.com/contributor/';
    if (process.env.REACT_APP_ENV === 'PROD') {
        baseContURL = 'https://repustar.com/contributor/';
    } else {
        baseContURL = 'https://dev.repustar.com/contributor/';
    }

    useEffect(() => {
        let path = history.location.pathname.split("/");
        setIsFromPartnerConsole(path && path.length > 0 && history.location.pathname.split("/")[1] === 'partnerconsole')
        return () => {
            source.cancel();
        }

    }, []);

    useEffect(() => {
        setFlagId(props.flagId);
    }, [props.flagId]);

    useEffect(() => {
        if (flagId) {
            getMyTips();

        }
    }, [flagId])

    useEffect(() => {
        if (tipDetails && 'pinned_content' in tipDetails && tipDetails.pinned_content.title) {
        }
        if (tipDetails) {
            setIsTiplineFlag(tipDetails.__typename === "tipline_flag" || tipDetails.__typename === 'tipline_test_flag')
            setIsContributorFlag(tipDetails.__typename === "contributor_flag")
        }

    }, [tipDetails])

    const getMyTips = async () => {
        setIsLoading(true);
        let apiPath = Utils.getAPIDescription('get-my-tips');
        try {
            // Load async data from an inexistent endpoint.
            let data = await axios.get(apiPath + '?id=' + flagId + '&subflags=' + true + '&source=' + (isFromPartnerConsole ? 'content_partner' : 'contributor'), { headers: { "userid": `${localStorage.getItem('user_id')}` } });
            if (data && data.data && data.data.body[0]) {
                setTipDetails(data.data.body[0]);
                setIsLoading(false);
            }
        } catch (e) {
            console.log(`Error+${e}`);
        }
    }

    return (
        <>
            <div className='flag-details-main'>
                {
                    tipDetails && !isLoading ?
                        <>
                            <div className='flag-header'>
                                <Button type="text" icon={<CloseOutlined />} onClick={()=>{props.setVisible(false)}}></Button>

                            </div>
                            <div className='flag-det-board'>
                                <div className='tag-list'>{tipDetails.tags.join(', ')}</div>
                                <div
                                    className='date'>{`${moment(parseInt(tipDetails.createdAt)).fromNow()} · ${moment(parseInt(tipDetails.createdAt)).format("Do MMMM YYYY, h:mm A")}`}</div>

                                <div className='status-tag-user-box'>
                                    {(tipDetails.__typename === 'twitter_flag' || tipDetails.__typename === 'Twitter')
                                        &&
                                        <a href={`https://factsparrow.repustar.com/user/${tipDetails.twitter_user}?userid=${tipDetails.twitter_user_id}`}
                                            target='_blank' rel='noreferrer noopener'>
                                            <div className='user-tag'>
                                                <img src={FSBird}
                                                    width="10" />&nbsp;&nbsp;{tipDetails.__typename === 'twitter_flag' ? tipDetails.user_display_name ?? tipDetails.twitter_user : tipDetails.author}
                                            </div>
                                        </a>}
                                </div>
                                {(isTiplineFlag || isContributorFlag) && <div className='user_query'>
                                    {tipDetails.what}
                                </div>}
                                {
                                    tipDetails.__typename === 'twitter_flag' ?
                                        <div className=''>
                                            <Tweet
                                                className='flag-tweet'
                                                tweetId={tipDetails.campaignDetails.articleLink.split('status/')[1]}
                                                renderError={() => <div className="tweet-err-box">
                                                    This Tweet has been deleted.
                                                </div>}
                                            />
                                        </div>
                                        :
                                        <a href={tipDetails.campaignDetails.videoLink ? tipDetails.campaignDetails.videoLink : tipDetails.campaignDetails.articleLink}
                                            target='_blank' style={{ color: 'black', textDecoration: 'none' }}>
                                            <div className='source'>
                                                <div className={'left-img ' + (tipDetails.tags.length > 0 ? '' : 'p-2')}><img src={tipDetails.previewImage}
                                                    style={{ width: `${tipDetails.tags.length > 0 ? '100%' : '50%'}` }} /></div>
                                                <div className='right-sor'>
                                                    <div
                                                        className='broadcaster'>
                                                        {tipDetails.campaignDetails.broadcasterName === 'Direct from source' ? tipDetails.campaignDetails.contentSource : tipDetails.campaignDetails.broadcasterName}</div>
                                                    <div
                                                        className={`title-con ${tipDetails.tags.length > 0 ? '' : 'no-preview'}`}>{tipDetails.campaignDetails && tipDetails.campaignDetails.name}</div>
                                                    {tipDetails.campaignDetails.publishedDate ?
                                                        <div className='fn-14 gray-font mt-2'>Published
                                                            on: {moment(tipDetails.campaignDetails.publishedDate).format("DD MMM YYYY")}</div> : null}
                                                </div>
                                            </div>
                                        </a>
                                }
                            </div>
                        </>
                        :
                        <center><img src={Loading} width='30px' /></center>
                }
            </div>
        </>
    )
}
