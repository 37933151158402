import React, {useEffect, useState, useRef} from 'react';
import {useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import './login.css';
import Lock from '../../assets/images/lock.svg';
import Mail from '../../assets/images/mail.svg';
import RadioToggle from '../../libs/components/radiotoggle/radiotoggle.js'; 
import Utils from '../../utils/utils.js';
import {Auth, Hub} from "aws-amplify";
import axios from 'axios';
import Loading from '../../assets/images/loader.gif';

export default function Login() {

	const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(''); 
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    
    useEffect(() => {
        if (Utils.checkLogin()) {
            history.push(Utils.checkContentPartnerWriter() ? '/partnerconsole/home' : '/fact-briefs')
        } else {
            handleLogin();
        };
    }, []);

    const handleLogin = () => {
        let miniorangeJWT = Utils.getParameterByName('id_token');
        let relay_state = Utils.getParameterByName('relay_state');
        if (miniorangeJWT != null) {
            afterLogin(miniorangeJWT, relay_state);
        } else {
            window.location.href = Utils.getMinioragneRedirectURI('login');
        }
    }

    const afterLogin = async(token, relay_state) => {
        let res = jwt_decode(token);
        localStorage.setItem('repustar_token', token);
        let email = res.email !== undefined ? res.email : res['OAuthConstants.ATTRIBUTES.EMAIL'];
        const apiPath = Utils.getAPIDescription('get_user_details') + '?userId=' + res.sub + '&source=login';
        let config = {
            headers: {
                email: email
            }
        };
        try {
            let data =  await axios.get(apiPath, config);
            if(data && data.data && data.data.body){
                localStorage.setItem('user_id', data.data.body.id);
                localStorage.setItem('user_types', data.data.body.userType);
                localStorage.setItem('email', data.data.body.email);
                localStorage.setItem('username', data.data.body.username ?? data.data.body.name);
                localStorage.setItem('status', data.data.body.status);
                localStorage.setItem('org_id', data.data.body.org_id);
                localStorage.setItem('firstname', data.data.body.firstname);
                localStorage.setItem('lastname', data.data.body.lastname);
                if(data.data.body.org_profile_img_url) localStorage.setItem('profile_img', data.data.body.org_profile_img_url);
                localStorage.setItem('email_verified', res.email_verified);
                try{
                    if(data.data.body.state === 0){
                        localStorage.setItem('access', 'blocked');
                    }else{
                        localStorage.setItem('access', 'granted');
                    }
                }catch(err){
                    console.log(err);
                }

                // if (Utils.getAdminPermission()) {
                //     history.push('/flags')
                // }
                if (relay_state) {
                    window.location.href = decodeURIComponent(relay_state);
                }
                else {
                    history.push(Utils.checkContentPartnerWriter() ? '/partnerconsole/home' : '/fact-briefs')

                    // history.push(Utils.checkContentPartner() ? '/dashboard' : '/fact-briefs');
                }
            }

        }catch (e) {
            console.log(`Error+${e}`);
        }
    }



  	const handleSubmit = (evt) => {
	    evt.preventDefault();
	    if (!email) {
	      return setError('Email is required');
	    }
	    if(!validateEmail()){
	    	return setError('Invalid Email');
	    }
	    if (!password) {
	      return setError('Password is required');
	    }
	    if(password.length < 8){
	    	return setError('Password should be 8 characters or more');
	    }
	    setError('');
	    handleSignin();
  	}

  	 const validateEmail = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

  	const handleEmailChange = (evt) => {
  		setEmail(evt.target.value);
  	}

  	const handlePasswordChange = (evt) => {
  		setPassword(evt.target.value);
  	}

  	const handleSignin = () => {
        setLoading(true);
        signIn().then((response) => {
            afterLogin(response);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });

    }


    const signIn = () => {
        return new Promise((resolve, reject) => {
            Auth.signIn(email, password).then((response) => {
                    resolve(response);
                }).catch(error => {
                    if(error.code ==="UserNotConfirmedException"){
                        //resendCode();
                        //setConfirmEmail(true);
                        setLoading(false);
                        //setSignin(false);
                        return;
                    }
                    setError(error.message)
                    reject(error);
                });
        });
    }

    return (
        <div className="app-login container">
        	{/*<div className="login-panel row">
        		<h1>Login</h1>
        		<div className="login-txt">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
        		<div className="form">
        			<form onSubmit={handleSubmit} >
			        	{error && <h6 className="err-msg">{error}</h6>}
						<div className="email">
							<img src={Mail}/>
							<input type="text" value={email} onChange={handleEmailChange} placeholder="Email" autoComplete="off"/>
						</div>
				        <div className="pwd">
				        	<img src={Lock}/>
				          	<input type="password" value={password} onChange={handlePasswordChange} placeholder="Password" autoComplete="off"/>
				        </div>
				        <div className="colw-100 forgot-password"><div className="colw-50 pull-left text-center"><RadioToggle data={{'id': '1', 'name': 'Remember Me' }}/></div><div className="colw-50 pull-left text-center fp">Forgot Password</div></div>
				        <div className="relative-position">
				        	{loading && <div className="loader"><img src={Loading}/></div>}
		          			<input type="submit" value={loading ? 'Logging In...' : 'Login'} />
		          		</div>
			        </form>
        		</div>
        	</div>*/}
        </div>
    );
}
