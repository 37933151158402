const flagReducer = (state = {}, action) => {
    switch(action.type){
        case "APPLY_FILTER":
            return {
                ...state,
                payload: action.payload
            }
        default:
            return state
    }
}

export default flagReducer;