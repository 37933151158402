
import React, {useEffect, useState, useRef} from 'react';
import { useHistory } from "react-router-dom";
import Loading from '../../assets/images/loader.gif';
import moment from 'moment-timezone';
import UpArrow from '../../assets/images/incre_arrow.svg';
import DownArrow from '../../assets/images/decre_arrow.svg';
import {  Select } from 'antd';

export default function FactGrid(props) {

    const [dataSource, setDataSoure] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [endCursor, setEndCursor] = useState('');
    const [anhorLink, setAnchorLink] = useState('');
    const [titleSort, setTitleSort] = useState(null);
    const [activitySort, setActivitySort] = useState(null);
    const [sortpara, setSortpara] = useState(null);

    const limit = 10;
    const totalLimit = 10000;
    const pageRangeDisplayed = 3;
    const scrollRef = useRef(null);
    const history = useHistory();
    const { Option } = Select;
    const is_platform_editor = localStorage.getItem('user_types') && localStorage.getItem('user_types').includes('repustar_platform_moderator');
    const orgOption = [ "newsco", "nevada", "gigafact", "lead-stories", "ap-news", "repustar", "newsco", "skeptical-sciences", "the-associated-press"]
    let env = process.env.REACT_APP_ENV;


    useEffect(() => {
        if(props?.datasource?.review_list && props?.datasource?.review_list?.length >= 0){
            let source = props.datasource.review_list;
            setTotalCount(props.datasource.total_count);
            setEndCursor(props.datasource.end_cursor);
            if(props.ispaginate){
                setDataSoure(dataSource => [...dataSource, ...source]);
            }else{
                setDataSoure(source);
            }
        }
    }, [props.datasource]);

    const handleScroll = (e) => {
        let listElm = document.querySelector('#infiniteScroller');
        let isBottom = false;
        let isSafari = navigator.userAgent.indexOf("Safari") > -1;
        //if(!isSafari){
            isBottom = (scrollRef.current.scrollTop + scrollRef.current.clientHeight >= scrollRef.current.scrollHeight);
       /* }else{
             isBottom = (listElm.scrollTop + listElm.clientHeight === listElm.scrollHeight);
        }*/
       // isBottom = (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight);
        if(dataSource.length > 0){
            if (isBottom) {
                if(dataSource.length < totalCount){
                    props.updatepage(endCursor, props.status);
                }
            }
        }
    }

    useEffect(() => {
      if(titleSort){
        props.updateSortval('titleincre');
      }
      else if (titleSort!==null) {
        props.updateSortval('titledesc');
      }
      else if (activitySort) {
        props.updateSortval('activitydesc');
      }
      else{
        props.updateSortval("");
      }
    }, [titleSort, activitySort]);

    const handleSortCh = (typ) => {
      if(typ === 'fb'){
        setActivitySort(null);
        setTitleSort(titleSort===null?true:!titleSort);
      }
      if(typ === 'activity'){
        setTitleSort(null);
        setActivitySort(activitySort===null?true:!activitySort);
      }
    }

    function handleOrgslugChange(value) {
      props.updateOrgslugval(value);
    }
    function handleEditorChange(value) {
      props.updateAuthorval(value);
    }

    const onRowClick = (item) => {
        switch (item.status) {
            case "Returned":
                window.open('/create-fact-brief/' + item.slug, "_blank");
                break;
            case "Submitted":
                window.open('/fact-brief/' + item.slug + '?draft=true&parentPage=' + item.parent_page_slug, "_blank");
                break;
            case "Draft":
                window.open('/create-fact-brief/' + item.slug, "_blank");
                break;
            case "Published":
                window.open('/fact-brief/' + item.slug + '?parentPage=' + item.parent_page_slug, "_blank");
                break;
        }
    }
    return (
        <>
            <div className="briefs-grid relative-position">
                <div className="grid-header colw-100">
                    <div className="colw-30 cursor-pointer" onClick={()=>{handleSortCh('fb')}}>Brief <span> {titleSort ? <img src={UpArrow} width='14px'/>:<img src={DownArrow} width='14px'/>} </span></div>
                    <div className="colw-20">Editor {/* props.status === "Published" && is_platform_editor && <Select style={{ width: '120px', marginRight: '0px' }} onChange={handleEditorChange} placeholder="Select Editor">
                      <Option value="">All</Option>
                      <Option value="testrepustar90@gmail.com">Moderator2 Test</Option>
                      <Option value="dhiren">Dhiren</Option>
                      <Option value="kiran">Kiran</Option>

                    </Select>*/}</div>
                    <div className="colw-20 cursor-pointer" onClick={()=>{handleSortCh('activity')}}>Activity <span> {!activitySort ? <img src={UpArrow} width='14px'/>:<img src={DownArrow} width='14px'/>} </span></div>
                    <div className="colw-20">Status</div>
                    {props.status === "Published" && is_platform_editor && <div className="colw-10" style={{ width: '10%'}}>Organization {is_platform_editor && <Select style={{ width: '120px', marginRight: '0px' }} onChange={handleOrgslugChange} placeholder="Select Org">
                      <Option value="">All</Option>
                      <Option value="newsco">News Co</Option>
                      <Option value="gigafact">Gigafact</Option>
                      <Option value="econofact">EconoFact</Option>
                      <Option value="lead-stories">Lead Stories</Option>
                      <Option value="usafacts">USAFacts</Option>
                      <Option value="nevada-independent">Nevada Independent</Option>
                      <Option value="skeptical-science">Skeptical Science</Option>
                      <Option value="the-associated-press">The Associated Press</Option>
                      <Option value="mcir">Mississippi Center for Investigative Reporting</Option>
                      <Option value="gossip-cop">Gossip Cop</Option>
                      <Option value="nevada">Nevada</Option>
                      <Option value="repustar">Repustar</Option>
                    </Select>}</div>}
                </div>
                <div className="grid-body" id='infiniteScroller' onScroll={handleScroll} ref={scrollRef}>
                    {dataSource.length > 0 ? React.Children.toArray(dataSource.map((item) => (
                        <div className='grid-row colw-100' onClick={()=>{onRowClick(item)}}>
                            <div className="colw-30"><span title={item.title} className="title-column colw-100">{item.title}</span><br/>{!item.author_id && <span>By{' '+item.author_name}</span>}</div>
                            <div className="colw-20">{item.owner_fname+' '+item.owner_lname}</div>
                            <div className="colw-20">{item.status === 'Published' ? moment((item.firstPublishedAt)).fromNow() :  moment((item.latestRevisionCreatedAt)).fromNow()}</div>
                            <div className="colw-20"><span className={item.status+'_dot'}></span><span>{item.status}</span></div>
                            {props.status === "Published" && is_platform_editor &&<div className="colw-10" style={{ width: '10%'}}>{item.org_name}</div>}
                        </div>

                        ))) : <div className='no-data'>{!props.isloading && <span>No Fact Briefs to show now.</span>}</div>
                    }
                </div>
                {props.isloading && <div className="loading-screen" style={{'left': '0px', 'top': '0px'}}><img src={Loading}/></div>}
            </div>
        </>
    );
}
