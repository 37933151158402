import {
  Collapse,
  DatePicker,
  Drawer,
  notification,
  Popover,
  Select,
  Switch,
  Tabs,
  List,
  Typography
} from "antd";
import axios from "axios";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import BlackPin from "../../../../assets/images/black-pin.svg";
import BlackFS from "../../../../assets/images/black_fs.svg";
import XIcon from "../../../../assets/images/cross.svg";
import Loading from "../../../../assets/images/loader.gif";
import Utils from "../../../../utils/utils.js";
import FlagTile from "../../../flagTile/flagTile";
import CPFlagDetails from "../flagdetails/flagdetails";
import "./flags.scss";

import OrgBanner from "../../../OrgBanner/orgbanner";
import useOrgStore from "../../store/orgsStore";

export default function CPFlags(props) {
  const userid = localStorage.getItem("user_id");
  const limit = 10;
  const totalLimit = 10000;
  const pageRangeDisplayed = 3;
  const [loader, setLoader] = useState(true);
  const [flagDetails, setFlagDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [totalCountBookmark, setTotalCountBookmark] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [activeBookmarkPage, setActiveBookmarkPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [listState, setListSTate] = useState(null);
  const [statusType, setStatusType] = useState("Valid,Eligible,Reviewed");
  const [revMessage, setRevMessage] = useState("");
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [subcategoryFilters, setSubcategoryFilters] = useState([]);
  const [geotagFilters, setGeotagFilters] = useState([]);
  const [categoryArr, setCategoryArr] = useState(undefined);
  const [subcategoryArr, setSubcategoryArr] = useState(undefined);
  const [geotagArr, setGeotagArr] = useState(undefined);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [flagId, setFlagId] = useState(undefined);
  const [selectedCat, setSelectedCat] = useState([]);
  const [sortType, setSortType] = useState(0);
  const [hideReviewed, setHideReviewed] = useState(false);
  const [hideViewed, setHideViewed] = useState(false);
  const [hideInternalFlags, setHideInternalFlags] = useState(false);
  const [hideInternalFlagsVal, setHideInternalFlagsVal] = useState(null);
  const [hideFactchecked, setHideFactchecked] = useState(false);
  const [quickVisible, setQuickVisible] = useState(false);
  const [quickUrl, setQuickUrl] = useState("");
  const [quickFlagComment, setQuickFlagComment] = useState("");
  const [urlError, setUrlError] = useState("");
  const [textError, setTextError] = useState("");
  const [enableQuick, setEnableQuick] = useState(false);
  const [isExisting, setIsExisting] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [quickCreating, setQuickCreating] = useState(false);
  const [feedType, setFeedType] = useState(null);
  const [flagInfo, setFlagInfo] = useState(null);
  const [articleInfo, setArticleInfo] = useState(null);
  const [flgId, setFlgId] = useState(null);
  const [updatingId, setUpdatingID] = useState("");
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [statusComment, setStatusComment] = useState("");
  const [activeFlagID, setActiveFlagID] = useState(null);
  const [sortUpdated, setSortUpdated] = useState(false);
  const [featuredOnly, setFeaturedOnly] = useState(false);
  const [pinnedContentOnly, setPinnedContentOnly] = useState(false);
  const [contentPartnerMode, setContentPartnerMode] = useState(false);
  const [hidePinned, setHidePinned] = useState(false);
  const [flagedOrgList, setFlagedOrgList] = useState([]);
  const [pinnedOrgList, setPinnedOrgList] = useState([]);
  const [pinnedOrgFilter, setPinnedOrgFilter] = useState("");
  const [flagedOrgFilter, setFlagedOrgFilter] = useState("");
  const [isFactCheckUrl, setIsFactCheckUrl] = useState(false);
  const [noDataMsg, setNoDataMsg] = useState("No Data Found.");
  const [isFSUser, setIsFSUser] = useState(false);


  const [flagsExists, setFlagsExists] = useState(false);
  const [existingsFlagsList, setExistingFlagsList] = useState([]);
  const [quickFlagData, setQuickFlagData] = useState(null);

  const [noClaimLinkFlagId, setNoClaimLinkFlagId] = useState(null);
  const [noClaimLinkFlagData, setNoClaimLinkFlagData] = useState(null);
  const [showNoClaimLinkFlagModal, setShowNoClaimLinkFlagModal] = useState(false);

  const timezone = moment.tz.guess();

  const flagState = useSelector((state) => state.flagReducer);
  const un = localStorage.getItem("user_id");
  const userType = localStorage.getItem("user_types");
  let env = process.env.REACT_APP_ENV;
  const history = useHistory();


  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { Panel } = Collapse;
  const { TabPane } = Tabs;
  const sortValueMap = {
    latest: 0,
    featured: 6,
    relevance: 5,
    pin_date: 7,
    upvote: 4,
  };
  const sortTypeMap = {
    0: "latest",
    4: "upvote",
    5: "relevance",
    6: "featured",
    7: "pin_date",
  };
  useEffect(() => {
    setTimeout(() => {
      document.title = "Gigafact Partner Console"
    }, 1000);
    if (window.location.href) {
      let str = window.location.href;
      let fId = Utils.checkSeparatorMapping(str, "#", 1);
      if (fId) {
        setFlagId(fId);
        setDrawerVisible(true);
      }
    }
    if (Utils.checkContentPartnerWriter()) {
      setContentPartnerMode(true);
    }

    if (sessionStorage.tab_of) {
      setListSTate(sessionStorage.tab_of);
    } else {
      setListSTate("all");
    }
    setIsFSUser(
      (env === "DEV" && localStorage.org_id === "3") ||
        (env === "PROD" && localStorage.org_id === "3")
    );
  }, []);
  useEffect(() => {
    // if (!drawerVisible) {
    //     fetchFlagDescription(pageNo, 1, searchQuery)
    // }
    if (!drawerVisible) {
      if (listState === "bookmark") {
        setSearchQuery("");
        setActiveBookmarkPage(1);
        setTotalCount(0);
        fetchBookmark(activePage);
      } else {
        if (!pinnedContentOnly && listState) {
          fetchFlagDescription(activePage, 1, searchQuery);
        }
      }
    }
  }, [drawerVisible]);
  useEffect(() => {

    if (!isFSUser) {
      // getAllPinnedOrgs();
      getAllFlagedOrgs();
    }
  }, [isFSUser]);

  const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    useEffect(() => {
      if (didMount.current) func();
      else didMount.current = true;
    }, deps);
  };

  useEffect(() => {
    setPinnedOrgFilter("");
    setFlagedOrgFilter("");
    // getAllPinnedOrgs();
    // getAllFlagedOrgs();
    if (listState === "bookmark") {
      setSearchQuery("");
      setActiveBookmarkPage(1);
      setTotalCount(0);
      fetchBookmark(activePage);
    } else {
      if (!pinnedContentOnly && listState) {
        fetchFlagDescription(1, 1, searchQuery);
      }
    }
    if (listState) {
      sessionStorage.tab_of = listState;
      setActivePage(1);
    }
    switch (listState) {
      case "all":
        setNoDataMsg("No Data Found.");
        break;
      case "bookmark":
        setNoDataMsg("You haven’t bookmarked any flags yet!");
        break;
      case "console_flag":
        setNoDataMsg(
          "Flags you have created when testing your tipline appear here."
        );
        break;
      default:
        setNoDataMsg("No Data Found.");
        break;
    }
  }, [listState]);

  useEffect(() => {
    if (statusType !== null) {
      setTotalCountBookmark(null);
      setActivePage(1);
    }
  }, [statusType]);

  useEffect(() => {
    if (featuredOnly) {
      setTotalCountBookmark(null);
      setActivePage(1);
      fetchFlagDescription(1, 1, searchQuery);
    }
  }, [featuredOnly]);

  useEffect(() => {
    if (pinnedContentOnly) {
      setTotalCountBookmark(null);
      setActivePage(1);
      fetchFlagDescription(1, 1, searchQuery);
    }
  }, [pinnedContentOnly]);

  useEffect(() => {
    if (flagState.payload) {
      setCategoryArr(flagState.payload.categoryArr);
      setSubcategoryArr(flagState.payload.subcategoryArr);
      setGeotagArr(flagState.payload.geotagArr);
    }
  }, [flagState.payload]);

  useEffect(() => {
    if (flagState.payload) {
      setActivePage(1);
      //fetchFlagDescription(1,1, searchQuery);
    }
  }, [categoryArr, subcategoryArr, geotagArr]);

  useEffect(() => {
    if (quickUrl === "") {
      setUrlError("");
      setEnableQuick(false);
    }
  }, [quickUrl]);

  const fetchBookmark = async (pgno) => {
    setFlagDetails([]);
    setTotalCountBookmark(null);
    setLoader(true);
    let api =
        Utils.getAPIDescription("get-bookmarked-flag") +
        "?userId=" +
        localStorage.getItem("user_id") +
        "&subflags=false&limit=10&sort=" +
        sortType +
        "&status=Valid,Eligible,Reviewed,Archived&page=" +
        pgno +
        "&hide_non_reviewed=" +
        hideReviewed +
        "&hideViewedFlag=" +
        hideViewed +
        "&designation_filters=" +
        hideInternalFlagsVal +
        "&show_only_ext_upvotes=" +
        true +
        (String(pinnedOrgFilter) != "" ? "&org_id=" + pinnedOrgFilter : "") +
        (String(flagedOrgFilter) != "" ? "&org_flags=" + flagedOrgFilter : "") +
        "&hide_pinned=" +
        (hidePinned ? "true" : "false") +
        "&source=content_partner" +
        "&state=global" +
        "&all=true";
    let res = await axios.get(api, { headers: { userid: `${userid}` } });
    if (res.status === 200 && res.data.statusCode === 200) {
      if (res.data.body && res.data.body.flags) {
        setFlagDetails(res.data.body.flags);
        setTotalCountBookmark(res.data.body.total_count);
      }
    } else {
      notification.error({ message: "Something went wrong!" });
    }
    setLoader(false);
  };
  const getAllPinnedOrgs = () => {
    let api = Utils.getAPIDescription("get-org-list");
    api = api + "?istipine=true";
    axios.get(api).then((res) => {
      let data = res.data.orgs;
      setPinnedOrgList(data);
    });
  };
  const getAllFlagedOrgs = () => {
    let api = Utils.getAPIDescription("get-org-list");
    api = api + "?flag_orgs=true";
    axios.get(api).then((res) => {
      let data = res.data.orgs;
      setFlagedOrgList(data);
    });
  };
  const { orgDetails, fetchOrgDetails, isFetched } = useOrgStore();

  useEffect(() => {
    if (!isFetched) {
      fetchOrgDetails();
    }
  }, [fetchOrgDetails, isFetched]);


  const fetchFlagDescription = (pageNumber, tabid, search) => {
    //setSearchErr('');
    setFlagDetails([]);
    setActivePage(pageNumber);
    setLoader(true);
    const exploreFlags = async () => {
      //if(!isPagination){setEntireFlagDetails(null);}
      if (
        localStorage.getItem("search_dict") === "undefined" ||
        localStorage.getItem("search_dict") === null
      ) {
        localStorage.setItem("search_dict", JSON.stringify({}));
      }
      let efAPI = Utils.getAPIDescription("explore-flags");
      let pag_token =
        JSON.parse(localStorage.getItem("search_dict"))[search] === undefined
          ? null
          : JSON.parse(localStorage.getItem("search_dict"))[search];
      if (search === "" || search === "") {
        pag_token = null;
      }
      if (sortType === 5 && (search === "" || searchQuery === "")) {
        return;
      }

      // console.log('search in ' + pag_token);

      let queryParams;
      queryParams =
        "?all=" +
        true +
        "&status=" +
        statusType +
        "&sort=" +
        (statusType === "Archived" ? 3 : sortType) +
        "&featured_only=" +
        featuredOnly +
        "&pinned_content_only=" +
        pinnedContentOnly +
        "&meta=" +
        false +
        "&tabid=" +
        tabid +
        "&version=" +
        3 +
        "&page=" +
        pageNumber +
        "&limit=" +
        10 +
        "&subflags=" +
        true +
        "&pagination_token=" +
        pag_token +
        "&category_filters=" +
        (selectedCat ? selectedCat.join(",") : "") +
        "&subCategory_filters=" +
        (subcategoryArr ? subcategoryArr.join(",") : "") +
        "&geoTag_filters=" +
        (geotagArr ? geotagArr.join(",") : "") +
        "&startAt_filter=" +
        toTimeStamp(startDate) +
        "&endAt_filter=" +
        toTimeStampEnd(endDate) +
        "&source=host" +
        "&hide_non_reviewed=" +
        hideReviewed +
        "&hideViewedFlag=" +
        hideViewed +
        "&designation_filters=" +
        hideInternalFlagsVal +
        "&hide_non_factchecked=" +
        hideFactchecked +
        "&show_only_ext_upvotes=" +
        true +
        "&hide_pinned=" +
        (hidePinned ? "true" : "false") +
        (String(pinnedOrgFilter) != "" ? "&org_id=" + pinnedOrgFilter : "") +
        (String(flagedOrgFilter) != "" ? "&org_flags=" + flagedOrgFilter : "") +
        "&source=content_partner" +
        "&state=global";

      if (listState == "console_flag") {
        queryParams +=
          (String(pinnedOrgFilter) != "" ? "&org_id=" + pinnedOrgFilter : "") +
          (String(flagedOrgFilter) != ""
            ? "&org_flags=" + flagedOrgFilter
            : "") +
          "&org_flags=" +
          localStorage.org_id +
          "&tipline_test_flag=" +
          true;
      }
      let res = await axios.post(
        efAPI + queryParams,
        { search: search },
        { headers: { userid: `${userid}`, timezone: timezone } }
      );

      if (res.status === 200 && res.data.statusCode === 200) {
        if (res.data.body && res.data.body.flags) {
          setFlagDetails(res.data.body.flags);
          setTotalCount(res.data.body.total_count);
          setCategoryFilters(res.data.body.category_filters);
          setSubcategoryFilters(res.data.body.subCategory_filters);
          setGeotagFilters(res.data.body.geoTag_filters);
          let temp_search_dict = JSON.parse(
            localStorage.getItem("search_dict")
          );
          if (res.data.body.search_id !== undefined) {
            // console.log(s);
            temp_search_dict[search] = res.data.body.search_id;
            localStorage.setItem(
              "search_dict",
              JSON.stringify(temp_search_dict)
            );
          }
          // console.log(`Error+${e}`);
        }
      } else {
        notification.error({ message: "Something went wrong!" });
      }
      setLoader(false);
    };
    if (listState) exploreFlags();
  };

  const onInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSortType(5);
      setListSTate("all");
      setTotalCountBookmark(null);
      fetchFlagDescription(1, 3, searchQuery);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    let page = pageNumber;
    setTotalCountBookmark(null);
    if (page <= totalCount) {
      fetchFlagDescription(page, 1, searchQuery);
    }
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleBookmarkPageChange = (pageNumber) => {
    // setActivePage(pageNumber);
    setActiveBookmarkPage(pageNumber);
    let page = pageNumber;
    if (page <= totalCountBookmark) {
      setTotalCount(0);
      fetchBookmark(page);
    }
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const clearSearch = async () => {
    setActivePage(1);
    setSearchQuery("");
    setTotalCountBookmark(null);
    fetchFlagDescription(1, 1, "");
  };

  const onflagClick = (fid, flagData) => {
    // console.log(flagData?.flag_url_link, flagData?.tipline_contributor_flag_id)
    if (flagData?.flag_url_link === null && flagData?.tipline_contributor_flag_id == null){
      setNoClaimLinkFlagId(fid);
      setNoClaimLinkFlagData(flagData);
      setShowNoClaimLinkFlagModal(true);
      return;
    }
    else if (flagData?.flag_url_link === null && flagData?.tipline_contributor_flag_id !== null){
      setFlagId(flagData?.tipline_contributor_flag_id);
      let flags = flagDetails.slice();
      flags = flags.map((item) => {
        if (
            item.id === flagData?.tipline_contributor_flag_id &&
            !("viewers" in item && item.viewers.includes(userid))
        ) {
          if ("viewers" in item) {
            item.viewers = item.viewers + [userid];
          }
          item.viewers = [userid];
        }
        return item;
      });
      setFlagDetails(flags);
    }
    else {
      setFlagId(fid);
      let flags = flagDetails.slice();
      flags = flags.map((item) => {
        if (
            item.id === fid &&
            !("viewers" in item && item.viewers.includes(userid))
        ) {
          if ("viewers" in item) {
            item.viewers = item.viewers + [userid];
          }
          item.viewers = [userid];
        }
        return item;
      });
      setFlagDetails(flags);
    }
    setDrawerVisible(true);

  };

  const onPinAndUnpinning = (fid, proc, obj) => {
    // console.log(fid);
    // console.log(proc);
    // console.log(obj);
    let flags = flagDetails.slice();
    if (pinnedContentOnly) {
      flags = flags.filter((item) => {
        return item.id !== fid;
      });
    }

    flags = flags.map((item) => {
      if (item.id === fid) {
        if (proc === 1) {
          item["pinned_content"] = obj;
        }
        if (proc === 0) {
          if ("pinned_content" in item) {
            delete item["pinned_content"];
          }
        }
      }
      return item;
    });
    setFlagDetails(flags);
  };

  const toTimeStamp = (dt) => {
    let datum = Date.parse(dt);
    if (!datum) {
      return null;
    }
    //return datum/1000;
    return moment(datum).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  };

  const toTimeStampEnd = (dt) => {
    let datum = Date.parse(dt);
    if (!datum) {
      return null;
    }
    //return datum/1000;
    return moment(datum).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });
  };

  useDidMountEffect(() => {
    if (listState === "all" || listState === "console_flag")
      fetchFlagDescription(1, 1, searchQuery);
    else fetchBookmark(activeBookmarkPage);
  }, [hideInternalFlagsVal]);

  useDidMountEffect(() => {
    if (hideInternalFlags) {
      setHideInternalFlagsVal("internal");
    } else {
      setHideInternalFlagsVal(null);
    }
  }, [hideInternalFlags]);

  useDidMountEffect(() => {
    if (listState === "all" || listState === "console_flag")
      fetchFlagDescription(1, 1, searchQuery);
    else fetchBookmark(activeBookmarkPage);
  }, [hideReviewed]);

  useDidMountEffect(() => {
    if (listState === "all" || listState === "console_flag")
      fetchFlagDescription(1, 1, searchQuery);
    else fetchBookmark(activeBookmarkPage);
  }, [hideViewed]);

  useDidMountEffect(() => {
    if (listState === "all" || listState === "console_flag")
      fetchFlagDescription(1, 1, searchQuery);
    else fetchBookmark(activeBookmarkPage);
  }, [hidePinned]);

  useDidMountEffect(() => {
    if (listState === "all" || listState === "console_flag")
      fetchFlagDescription(1, 1, searchQuery);
    else fetchBookmark(activeBookmarkPage);
  }, [startDate, endDate]);

  useDidMountEffect(() => {
    if (listState === "all" || listState === "console_flag") {
      fetchFlagDescription(1, 1, searchQuery);
      setActivePage(1);
    } else {
      setActiveBookmarkPage(1);
      fetchBookmark(activeBookmarkPage);
    }
  }, [sortType]);

  useDidMountEffect(() => {
    // setListSTate('all')
    setTotalCountBookmark(null);
    fetchFlagDescription(1, 1, searchQuery);
  }, [hideFactchecked]);

  useEffect(() => {
    if (startDate) {
      setTotalCountBookmark(null);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setTotalCountBookmark(null);
    }
  }, [endDate]);

  useEffect(() => {
    if (selectedCat.length > 0) {
      setTotalCountBookmark(null);
      fetchFlagDescription(1, 1, searchQuery);
    }
  }, [selectedCat]);

  useEffect(() => {
    //fetchFlagDescription(1, 1, searchQuery);
  }, [sortType]);

  useEffect(() => {
    if (listState === "bookmark") {
      setSearchQuery("");
      setActiveBookmarkPage(1);
      setTotalCount(0);
      fetchBookmark(1);
    } else {
      if (listState) {
        fetchFlagDescription(1, 1, searchQuery);
      }
    }
  }, [pinnedOrgFilter, flagedOrgFilter]);

  const onClose = () => {
    setDrawerVisible(false);
    window.history.pushState({}, null, window.location.href.split("#")[0]);
  };

  // const onCategoryChange = (item) => {
  //     if(selectedCat.indexOf(item) == -1){
  //         setSelectedCat(selectedCat => [...selectedCat, item]);
  //     }
  // }

  // const deleteCategory = (item) => {
  //     setSelectedCat(selectedCat.filter(el => el!==item))
  // }

  const onQuickClick = () => {
    console.log(noClaimLinkFlagData);
    setQuickCreating(false);
    setQuickVisible(true);
    setEnableQuick(false);
    setQuickUrl("");
    setQuickFlagComment(noClaimLinkFlagData?.what || "");
  };

  const validateURL = (url) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      url
    );
  };

  const askRepustar = async (qurl = quickUrl) => {
    setUrlError("");
    let apiPath = Utils.getAPIDescription("ask-repustar");
    try {
      // Load async data from an inexistent endpoint.
      if (qurl === "") {
        setUrlError("Please enter Article/Video Url to continue");
        return;
      }

      if (!validateURL(qurl)) {
        setUrlError("Please enter valid Url to continue.");
        return;
      }

      setUrlError("");
      setLoadingDetails(true);
      let query = { query: qurl };
      let res = await axios.post(apiPath + "?source=host", query, {
        headers: { userid: `${un}` },
      });
      if (res.status === 200) {
        if (!res.data.body) {
          setUrlError("No Details Found.");
          return;
        }

        setFeedType(parseInt(res.data.body.feedType));
        if (
          res.data.body.lstExistingData &&
          res.data.body.lstExistingData.length > 0
        ) {
          setFlgId(res.data.body.lstExistingData[0].id);
          setIsExisting(true);
          setLoadingDetails(false);
          setUrlError("Assignment already exist");
          notification.open({
            message: "Assignment already exists",
            duration: 3,
            placement: "bottomRight",
            className: "ca_toaster",
          });
          return;
        } else {
          setIsExisting(false);
        }
        if (res.data.message !== "Success") {
          setUrlError(res.data.message);
          return;
        }

        if (
          res.data.body.relevantResearch &&
          res.data.body.relevantResearch.data &&
          res.data.body.relevantResearch.data.length > 0
        ) {
          //setInterestedIn(res.data.body.relevantResearch.data);
        }

        if (
          res.data.body.repustarChecks &&
          res.data.body.repustarChecks.data &&
          res.data.body.repustarChecks.data.length > 0
        ) {
          // setRelatedReviews(res.data.body.repustarChecks.data);
        }
        if (res.data.body.metadata === null) {
          setLoadingDetails(false);
          return;
        }

        if (res.data.body.feedType === 1) {
          setFlagInfo(res.data.body.metadata);
        } else {
          setArticleInfo(res.data.body.metadata);
        }
        setEnableQuick(true);
        setLoadingDetails(false);
      }
    } catch (e) {
      console.log(`Error+${e}`);
      setEnableQuick(false);
    }
  };

  // const onQuickKeyDown = (e) => {
  //     if (e.key === 'Enter') {
  //         askRepustar(quickUrl);
  //     }
  // };

  const onQuickChange = (e) => {
    let url = e.target.value.trim();
    setQuickUrl(url);
  };

  const onQuickFlagCommentChange = (e) => {
    let comm = e.target.value;
    setQuickFlagComment(comm);
  };

  const createQuick = async (event) => {
    event.preventDefault();

    // Calling V3 Ask API
    setUrlError("");
    setTextError("");
    setQuickCreating(true);
    setIsFactCheckUrl(false);


    if (flagsExists && quickFlagData) {
      createQuickFlag(quickFlagData);
      return
    }

    try {
      if (quickUrl === "") {
        setUrlError("Please enter Article/Video Url to continue");
        return;
      }
      let query = {
        url: quickUrl,
        query: quickFlagComment,
        org_id: localStorage.org_id,
        flag_type: "contributor_flag",
      };
      let apiPath = Utils.getAPIDescription("ask-repustar-new");
      let res = await axios.post(apiPath, query, {
        headers: { userid: `${localStorage.getItem("user_id") ?? null}` },
      });
      if (res.status === 200) {
        if (res.data?.statusCode !== 200) {
          if (res.data?.error_type === "url") {
            setUrlError(res.data.display_message);
            setQuickCreating(false);
          } else {
            setTextError(res.data.display_message);
            setQuickCreating(false);
          }
          if (res.data?.message === "Lead Stories URL") {
            setIsFactCheckUrl(true);
            setQuickCreating(false);
          }
          return;
        }

        if (res.data?.flags_exists === true) {
          setFlagsExists(true);
          setExistingFlagsList(res.data?.existing_flag);
          setQuickCreating(false);
          setQuickFlagData(res.data);
          return
        }

        createQuickFlag(res.data);
      }
    } catch (e) {
      console.log(`Error+${e}`);
      setQuickCreating(false);
    }
  };
  const createQuickFlag = async (data) => {
    let apiPath = Utils.getAPIDescription("share-content-new");
    try {
      let payload = {
        metadata: data.metadata,
        has_scrapped: Object.keys(data.metadata).length !== 0,
        url: quickUrl,
        why: quickFlagComment,
        org_id: localStorage.org_id,
        url_type: data.url_type,
        query_type: data.query_type,
        flag_type: "contributor_flag",
        search_query: data.search_payload,
        user_id: localStorage.user_id,
      };

      if (noClaimLinkFlagId)
        payload['tipline_source_flag_id'] = noClaimLinkFlagId;

      let res = await axios.post(apiPath, payload);

      if (res.status === 200) {
        if (!res.data?.body === null) {
          console.log(`Flag not created`);
          return;
        }
        if (res.data?.statusCode !== 200) {
          console.log(`Flag creation failed`);
          return;
        }
        if (res.data?.body) {
          setQuickCreating(false);
          setFlagDetails(res.data.body);

          // On success create flag
          setQuickVisible(false);
          setExistingFlagsList([]);
          setFlagsExists(false);
          setQuickFlagData(null);
          setNoClaimLinkFlagData(null);
          setNoClaimLinkFlagId(null);

          setTimeout(() => {
            fetchFlagDescription(1, 1, "");
          }, 2000);
          notification.open({
            message: "Flag created successfully",
            duration: 3,
            placement: "bottomRight",
            className: "ca_toaster",
          });
        }
      }
    } catch (e) {
      console.log(`Error+${e}`);
      setQuickCreating(false);
    }
  };

  const allocateStatus = (id, status) => {
    setUpdatingID(id);
    setStatusComment("");
    let api = Utils.getAPIDescription("allocate-status");
    let body = {
      id: id,
      allocatedStatus: status,
    };
    if (status === "Eligible") {
      body["comments"] = statusComment;
    }
    axios
      .post(api, body)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUpdatingID("");
          handleStatusUpdate(id, status);
        } else {
          setUpdatingID("");
          notification.error({ message: "Something went wrong" });
        }
      })
      .catch((err) => {
        setUpdatingID("");
        console.log(err);
        notification.error({ message: "Something went wrong" });
      });
  };

  const handleStatusUpdate = (id, status) => {
    let flags = flagDetails.slice();
    if (
      status === "Archived" ||
      statusType === "Archived" ||
      statusType === "Eligible"
    ) {
      flags = flags.filter((item) => item.id !== id);
    } else {
      flags = flags.map((item) => {
        if (item.id === id) {
          item.allocatedStatus = status;
          item.comments = statusComment;
          return item;
        } else {
          return item;
        }
      });
    }
    notification.success({ message: `Flag status updated successfully.` });
    setShowPromoteModal(false);
    setFlagDetails(flags);
  };

  const handleSortChange = (value) => {
    setSortType(sortValueMap[value]);
  };

  const getUserDisplayName = (item) => {
    return item.__typename === "twitter_flag"
      ? `${item.user_display_name ?? item.twitter_user ?? ""}`
      : item.user_display_name ?? item.username;
  };
  const openProfilePage = (item) => {
    if (item?.fs_profile_url) {
      window.open(item.fs_profile_url, "_blank");
    }
  };
  const equalHeightPopUp = (event, index) => {
    let maxHeight = 265;
    // setTimeout(() => {
    //     let tab_container=document.getElementsByClassName('tab-container')
    //     console.log("Test",event);
    //     upvt-tag
    //     tab_container[tab_container.length-1].style.height="100px"
    // });
  };

  const UserListJSX = (item) => {
    return (
      <div className="db-user-list">
        <Tabs defaultActiveKey="0">
          <TabPane
            tab={
              <div className="tab-width">
                <div className="upvt-tag">
                  RECEIVED{" "}
                  <span className="received-count">{`${getReceivedUsersCounts(
                    item
                  )}`}</span>{" "}
                </div>
              </div>
            }
            key="1"
          >
            <div className="tab-container">
              <div className="tab-container-heading-label">
                <div className="name-label">NAME</div>
                <div className="impact-socre-label">IMPACT SCORE</div>
              </div>
              <div className="db-user-row">
                {
                  <div
                    className={
                      "upvote-user-name " +
                      (item.fs_profile_url ? "" : "cursor_default")
                    }
                    onClick={() => openProfilePage(item)}
                  >
                    {getUserDisplayName(item)}
                  </div>
                }
                <div className="impact-score">
                  {item.impact_score_v2 ? item.impact_score_v2 : "-"}
                </div>
              </div>
              {item?.upvoter_user_infos?.length > 0 &&
                item.upvoter_user_infos.map((pin_item, idx) => (
                  <div key={"up_" + idx} className="">
                    {item?.twitter_user_id !== pin_item.id && (
                      <div className="db-user-row">
                        <div
                          className={
                            "upvote-user-name " +
                            (item.fs_profile_url ? "" : "cursor_default")
                          }
                          onClick={() => openProfilePage(pin_item)}
                        >
                          {pin_item.name}
                        </div>
                        <div className="impact-score">
                          {pin_item.impact_score_v2}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </TabPane>
          <TabPane
            disabled={item?.share_user_infos?.length > 0 ? false : true}
            tab={
              <div className="tab-width">
                <div className="share-tag">
                  SHARED{" "}
                  <span className="shared-count">
                    {item?.share_user_infos ? item?.share_user_infos.length : 0}
                  </span>{" "}
                </div>
              </div>
            }
            key="2"
          >
            <div className="tab-container">
              <div className="tab-container-heading-label">
                <div className="name-label">NAME</div>
                <div className="impact-socre-label">IMPACT SCORE</div>
              </div>
              {item?.share_user_infos?.length > 0 &&
                item?.share_user_infos.map((pin_item, idx) => (
                  <div key={"sh_" + idx}>
                    <div className="db-user-row">
                      <div
                        className={
                          "shared-user-name " +
                          (item.fs_profile_url ? "" : "cursor_default")
                        }
                        onClick={() => openProfilePage(pin_item)}
                      >
                        {pin_item.name}
                      </div>
                      <div className="impact-score">{item.impact_score_v2}</div>
                    </div>
                  </div>
                ))}
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  };

  const afterBookmark = (flagId, action) => {
    let tmpList = flagDetails.slice();
    tmpList = tmpList.map((item) => {
      if (item.id === flagId) {
        item.is_bookmarked = action;
      }
      return item;
    });
    setFlagDetails(tmpList);
  };
  const getUserCounts = (item) => {
    let count = item?.upvoter_user_infos?.length ?? 0;
    count += item?.share_user_infos?.length ?? 0;
    count += 1;
    if (item?.upvoter_user_infos)
      item?.upvoter_user_infos.forEach((user) => {
        if (user.id == item?.twitter_user_id) {
          count--;
        }
      });
    return `${count} user${count > 1 ? "s" : ""}`;
  };
  const getReceivedUsersCounts = (item) => {
    let count = item?.upvoter_user_infos?.length ?? 0;
    count += 1;
    if (item?.upvoter_user_infos)
      item?.upvoter_user_infos.forEach((user) => {
        if (user.id == item?.twitter_user_id) {
          count--;
        }
      });
    return `${count}`;
  };

  useEffect(() => {
    fetchOrgDetails()
  }, []);

  const onCloseCreateFlagModal = () => {
    setExistingFlagsList([]);
    setFlagsExists(false);
    setQuickFlagData(null);
    setQuickVisible(false);
  };

  return (
    <div className="app-workspace">
      {loader ? (
        <div className="loading-flag">
          <img src={Loading} />
        </div>
      ) : null}
      <OrgBanner orgdetails={orgDetails} />
      <div className="app-content">
        <div className="pull-left app-dashboard-flag">
          <div className="rel-flags-title mb-4">
            <div>Explore Flags</div>
            <div className="flex-grow-1">
              {userType && userType.includes("cp_contributor") && (
                <button
                  className="opaque-btn ml-3"
                  onClick={() => {
                    onQuickClick();
                  }}
                >
                  Add a flag
                </button>
              )}
            </div>

            {/*<Link to='/create-flag'><button className='create-new'>Add New</button></Link> */}
          </div>
          <div className="colw-100">
            <div className="flag-search">
              <input
                className="search-input"
                placeholder="Search for flags, topics, @user"
                value={searchQuery}
                onChange={onInputChange}
                onKeyDown={(e) => {
                  setActivePage(1);
                  handleKeyDown(e);
                }}
              ></input>
              {searchQuery.length > 0 ? (
                <span
                  className="clr-btn-flg"
                  onClick={() => {
                    setSortType(0);
                    clearSearch();
                  }}
                >
                  <img src={XIcon} width="14px" />
                </span>
              ) : (
                ""
              )}
            </div>
            {/*<div className="pull-left date-field colw-40">
                            <div className="pull-left"><span>Start Date:&nbsp;&nbsp;</span><DatePicker  placeholderText="&nbsp;Select a day" selected={startDate} onChange={date => setStartDate(date)} maxDate={moment().toDate()}/></div>
                            <div className="pull-left"><span>End Date:&nbsp;&nbsp;</span><DatePicker  placeholderText="&nbsp;Select a day" selected={endDate} onChange={date => setEndDate(date)} maxDate={moment().toDate()}/></div>
														<div className="pull-right"><RangePicker onChange={(e) => {e!==null && setStartDate(e[0]);setEndDate(e[1]);}} /></div>
                        </div>*/}
          </div>

          <div className="colw-100 flags-nav">
            <div>
              <span
                className={
                  listState === "all" &&
                  !featuredOnly &&
                  !pinnedContentOnly &&
                  statusType === "Valid,Eligible,Reviewed"
                    ? "black-bottom cursor-pointer"
                    : "font-styl cursor-pointer"
                }
                onClick={() => {
                  setFeaturedOnly(false);
                  setPinnedContentOnly(false);
                  setListSTate("all");
                  setSortType(0);
                  setStatusType("Valid,Eligible,Reviewed");
                }}
              >
                All Flags
              </span>
              {/* {!contentPartnerMode && (
                <span
                  className={
                    "ml-4" +
                    (listState === "all" &&
                    !featuredOnly &&
                    !pinnedContentOnly &&
                    statusType === "Eligible"
                      ? " black-bottom cursor-pointer"
                      : " font-styl cursor-pointer")
                  }
                  onClick={() => {
                    setFeaturedOnly(false);
                    setPinnedContentOnly(false);
                    setListSTate("all");
                    setSortType(0);
                    setStatusType("Eligible");
                  }}
                >
                  Highlighted
                </span>
              )} */}
              <span
                className={
                  "ml-4" +
                  (listState === "bookmark"
                    ? " black-bottom cursor-pointer"
                    : " font-styl cursor-pointer")
                }
                onClick={() => {
                  setFeaturedOnly(false);
                  setPinnedContentOnly(false);
                  setListSTate("bookmark");
                  setSortType(0);
                  setStatusType(null);
                }}
              >
                Bookmarked
              </span>
              {Utils.getAdminPermission() && (
                <span
                  className={
                    "ml-4" +
                    (listState === "all" && !pinnedContentOnly && featuredOnly
                      ? " black-bottom cursor-pointer"
                      : " font-styl cursor-pointer")
                  }
                  onClick={() => {
                    setListSTate("all");
                    setStatusType("");
                    setFeaturedOnly(true);
                    setPinnedContentOnly(false);
                    setSortType(6);
                  }}
                >
                  Featured
                </span>
              )}
              {/* {(Utils.getAdminPermission() || contentPartnerMode) && <span className={'ml-4' + (listState === 'all' && !featuredOnly && pinnedContentOnly ? ' black-bottom cursor-pointer' : ' font-styl cursor-pointer')} onClick={() => { setListSTate('all'); setStatusType(''); setFeaturedOnly(false); setPinnedContentOnly(true); setSortType(0); setHidePinned(false) }}>Pinned</span>} */}
              {Utils.getAdminPermission() && (
                <span
                  className={
                    "ml-4" +
                    (listState === "all" &&
                    !featuredOnly &&
                    !pinnedContentOnly &&
                    statusType === "Archived"
                      ? " black-bottom cursor-pointer"
                      : " font-styl cursor-pointer")
                  }
                  onClick={() => {
                    setFeaturedOnly(false);
                    setPinnedContentOnly(false);
                    setListSTate("all");
                    setStatusType("Archived");
                  }}
                >
                  Archived
                </span>
              )}
              {/* <span
                className={
                  "ml-4 " +
                  (listState === "console_flag" &&
                  statusType === "Valid,Eligible,Reviewed"
                    ? "black-bottom cursor-pointer"
                    : "font-styl cursor-pointer")
                }
                onClick={() => {
                  setFeaturedOnly(false);
                  setPinnedContentOnly(false);
                  setListSTate("console_flag");
                  setSortType(0);
                  setStatusType("Valid,Eligible,Reviewed");
                }}
              >
                Console flags
              </span> */}
            </div>
          </div>

          <div className="date-field">
            <div className="flag-sort-wrapper">
              {listState !== "console_flag" && statusType !== "Archived" && (
                <>
                  <Select
                    onChange={(val) => {
                      setSortUpdated(true);
                      handleSortChange(val);
                    }}
                    style={{ width: "220px" }}
                    optionLabelProp="label"
                    value={sortTypeMap[sortType]}
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    {featuredOnly && (
                      <Option
                        title={null}
                        value="featured"
                        label={<div>SORT BY:&nbsp;&nbsp;Featured Recently</div>}
                      >
                        Featured Recently
                      </Option>
                    )}
                    {(!(searchQuery === "") || sortType === 5) && (
                      <Option
                        value="relevance"
                        label={<div>SORT BY:&nbsp;&nbsp;Relevance</div>}
                      >
                        Relevance
                      </Option>
                    )}
                    <Option
                      title={null}
                      value="latest"
                      label={<div>SORT BY:&nbsp;&nbsp;Flag Created Date</div>}
                    >
                      Flag Created Date
                    </Option>
                    {/* <Option
                      title={null}
                      value="upvote"
                      label={<div>SORT BY:&nbsp;&nbsp;Most Upvoted</div>}
                    >
                      Most Upvoted
                    </Option> */}
                    {/* {contentPartnerMode && <Option value="pin_date" label={<div>SORT BY:&nbsp;&nbsp;Pinned Date</div>}>Pinned Date</Option>} */}
                  </Select>
                  <div className="">
                    {!contentPartnerMode && (
                      <span
                        className="mr-4 mt-1 cursor-pointer filtfont"
                        onClick={() => setHideReviewed(!hideReviewed)}
                      >
                        Hide Answered Flags&nbsp;&nbsp;
                        <Switch
                          checked={hideReviewed}
                          onChange={() => setHideReviewed(!hideReviewed)}
                        />
                      </span>
                    )}
                    {listState !== "console_flag" && listState !== "bookmark" && (
                      <span
                        className="mr-4 mt-1 cursor-pointer filtfont"
                        onClick={() => setHideViewed(!hideViewed)}
                      >
                        Hide Viewed Flags&nbsp;&nbsp;
                        <Switch
                          checked={hideViewed}
                          onChange={() => setHideViewed(!hideViewed)}
                        />
                      </span>
                    )}
                    {/* {listState !== 'console_flag' && contentPartnerMode && !pinnedContentOnly && <span className='mr-4 mt-1 cursor-pointer filtfont' onClick={() => setHidePinned(!hidePinned)}>Hide Pinned Flags&nbsp;&nbsp;<Switch checked={hidePinned} onChange={() => setHidePinned(!hidePinned)} /></span>} */}
                    {!contentPartnerMode && (
                      <span
                        className="mr-4 mt-1 cursor-pointer filtfont"
                        onClick={() => setHideInternalFlags(!hideInternalFlags)}
                      >
                        Hide Flags from Internal Users&nbsp;&nbsp;
                        <Switch
                          checked={hideInternalFlags}
                          onChange={() =>
                            setHideInternalFlags(!hideInternalFlags)
                          }
                        />
                      </span>
                    )}
                  </div>
                </>
              )}{" "}
            </div>

            <div className="">
              {listState !== "console_flag" && contentPartnerMode && (
                <div className="d-inline-block">
                  <Select
                    value={flagedOrgFilter}
                    onChange={(val) => {
                      setFlagedOrgFilter(val);
                    }}
                    style={{ width: "160px", marginRight: "15px" }}
                    optionLabelProp="label"
                  >
                    <Option
                      title={null}
                      value=""
                      label={
                        <div style={{ color: "#AAAAAA" }}>See Flags from</div>
                      }
                      style={{ color: "#AAAAAA" }}
                    >
                      All
                    </Option>
                    {isFSUser &&
                      flagedOrgList &&
                      flagedOrgList.map((org, i) => {
                        return (
                          <Option
                            title={null}
                            value={org.page_id}
                            label={org.org_name}
                          >
                            {org.org_name}
                          </Option>
                        );
                      })}
                    {!isFSUser && (
                      <Option
                        title={null}
                        value={localStorage.org_id}
                        label={localStorage.org_name}
                      >
                        {localStorage.org_name}
                      </Option>
                    )}
                  </Select>
                  {/* <Select value={pinnedOrgFilter} onChange={(val) => { setPinnedOrgFilter(val) }} style={{ width: '160px', marginRight: "15px" }} optionLabelProp="label">
                                    <Option value="" label={<div style={{ color: "#AAAAAA" }}>See Pins from</div>} style={{ color: "#AAAAAA" }}>All</Option>
                                    {pinnedOrgList && pinnedOrgList.map((org, i) => {
                                        return <Option value={org.page_id} label={org.org_name}>{org.org_name}</Option>
                                    })}
                                </Select> */}
                </div>
              )}
              {
                <div className="d-inline-block position-relative" id={"datepickerDropdown"}>
                <RangePicker
                  defaultValue={null}
                  getPopupContainer={() => document.getElementById("datepickerDropdown")}
                  onChange={(e) => {
                    e !== null ? setStartDate(e[0]) : setStartDate(null);
                    e !== null ? setEndDate(e[1]) : setEndDate(null);
                  }}
                />
              </div>
              }
            </div>
          </div>

          <div
            className="flag-list"
            style={loader ? { overflowY: "hidden" } : { overflowY: "hidden" }}
          >
            {flagDetails && flagDetails.length > 0 ? (
              flagDetails.map((item, index) => (
                <div key={item.id}>
                  {!contentPartnerMode ? (
                    <FlagTile
                      flagData={item}
                      flagList={flagDetails}
                      setFlagList={setFlagDetails}
                      onFlagClick={onflagClick}
                      allocateStatus={allocateStatus}
                      statusType={statusType}
                      listState={listState}
                      featuredOnly={featuredOnly}
                      updatingId={updatingId}
                      setActiveFlagId={setActiveFlagID}
                      setShowPromoteModal={setShowPromoteModal}
                      userid={userid}
                      viewed={
                        "viewers" in item && item.viewers.includes(userid)
                      }
                      contentPartnerMode={contentPartnerMode}
                    />
                  ) : (
                    <div
                      className={
                        (item?.pinned_content ? "db-item" : "db-item-single") +
                        ("viewers" in item && item.viewers.includes(userid)
                          ? " db-item-viewed"
                          : "")
                      }
                    >
                      <FlagTile
                        flagData={item}
                        flagList={flagDetails}
                        setFlagList={setFlagDetails}
                        onFlagClick={onflagClick}
                        allocateStatus={allocateStatus}
                        userid={userid}
                        listState={listState}
                        viewed={
                          "viewers" in item && item.viewers.includes(userid)
                        }
                        contentPartnerMode={contentPartnerMode}
                        isDummy={true}
                      />
                      {item?.pinned_content && (
                        <div className="db-item-right">
                          {item.pinned_content?.org_details && (
                            <div className="pin-info-item">
                              <div className="db-org-logo">
                                <img
                                  src={
                                    item.pinned_content?.org_details?.org_logo
                                  }
                                  width="27px"
                                />
                              </div>
                              <div className="text">
                                {item.pinned_content?.org_details?.org_name}
                              </div>
                            </div>
                          )}
                          <a
                            style={{ color: "#0071BC" }}
                            href={item.pinned_content.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="pin-info-item align-items-start">
                              <div className="icon">
                                <img src={BlackPin} width="9" />
                              </div>
                              <div className="link-text">
                                <div className="title-clamp">
                                  {item.pinned_content.pin_comment}
                                </div>
                                <div className="date">
                                  {moment(item.pinned_added_ts * 1000).format(
                                    "LL"
                                  )}
                                </div>
                              </div>
                            </div>
                          </a>
                          <div className="pin-info-item">
                            <div className="icon">
                              <img src={BlackFS} width="7" />
                            </div>
                            <div className="text cursor-pointer">
                              <Popover
                                trigger="click"
                                title={null}
                                overlayClassName="user-list-popover"
                                content={UserListJSX(item)}
                                onClick={(e) => {
                                  equalHeightPopUp(e, index);
                                }}
                              >
                                {getUserCounts(item)}
                                {/* {`${(item?.upvoter_user_infos?.length ?? 0) + (item?.share_user_infos?.length ?? 0) + 1}  user${item?.upvoter_user_infos?.length > 0 || item?.share_user_infos?.length > 0 ? 's' : ""}`} */}
                              </Popover>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <>
                {!loader ? <div className="no-data-msg">{noDataMsg}</div> : ""}
              </>
            )}
          </div>
          {listState === "all" || listState === "console_flag" ? (
            <div className="pagination-control pull-right">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activePage}
                itemsCountPerPage={limit}
                totalItemsCount={totalCount}
                pageRangeDisplayed={pageRangeDisplayed}
                onChange={handlePageChange}
                hideDisabled={totalCount <= 0}
              />
            </div>
          ) : (
            ""
          )}

          {listState === "bookmark" ? (
            <div className="pagination-control pull-right">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={activeBookmarkPage}
                itemsCountPerPage={limit}
                totalItemsCount={totalCountBookmark}
                pageRangeDisplayed={pageRangeDisplayed}
                onChange={handleBookmarkPageChange}
                hideDisabled={totalCountBookmark > 0 ? false : true}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <Drawer
          placement="right"
          closable={false}
          onClose={onClose}
          visible={drawerVisible}
          width="35%"
        >
          <CPFlagDetails
            flagId={flagId}
            onPinAndUnpinning={onPinAndUnpinning}
            setVisible={setDrawerVisible}
            contentPartnerMode={contentPartnerMode}
            afterBookmark={afterBookmark}
            flagList={flagDetails}
            triggerFlagClick={onflagClick}
          />
        </Drawer>
        {/*<div className="colw-30 pull-left">
            		<FlagFilter categoryfilters={categoryFilters} subcategoryfilters={subcategoryFilters} geotagfilters={geotagFilters}/>
                    </div>*/}
      </div>
      <Modal
        open={quickVisible}
        onClose={onCloseCreateFlagModal}
        center
        classNames={{ modal: "med-modal" }}
      >
        <div className="modal-header quick-header">
          <h6>{noClaimLinkFlagData ? "Update this flag" : "Add a flag"}</h6>
        </div>
        <div className="quick-sub-header">
          <h6>
            Paste a link to the post or article where you spotted the claim.
          </h6>
        </div>
        <form onSubmit={(event) => createQuick(event)} className="quick-form">
          <div className="flex-line">
            <div>
              <div className="flex-line relative-position">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Paste the link to the claim"
                  // onKeyDown={onQuickKeyDown}
                  onChange={onQuickChange}
                  value={quickUrl}
                  // onPaste={pasteURL}
                  required
                />
                {/* {loadingDetails &&
                                    <img className='ml-2 loading-img' src={ Loading } width='20px' height='20px'/>
                                } */}
              </div>
              <div className="inline-block colw-100 err-msg">{urlError}</div>
            </div>
          </div>

          <div className="quick-sub-comm-header">
            <h6>What specific question do you have about this claim?</h6>
          </div>

          <div className="flex-line">
            <div>
              <div className="flex-line relative-position">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your question"
                  onChange={onQuickFlagCommentChange}
                  value={quickFlagComment}
                  required
                />
              </div>
              <div
                className="inline-block colw-100 err-msg"
                dangerouslySetInnerHTML={{ __html: textError }}
              />
            </div>
          </div>

          <div>
            {flagsExists &&
            <List
                bordered
                header={<div style={{fontWeight: 500}}>Looks like this link has already been added as a flag. Please check!</div>}
                dataSource={existingsFlagsList}
                renderItem={item => (
                    <List.Item>
                      <span><a href={item.link} target='_blank'>{item.query}</a></span>
                    </List.Item>
                )}
            />}
          </div>

          <div className="create-quick">
            <Button className="solid-sm-btn" type="submit">
              {quickCreating ? (
                <span>{noClaimLinkFlagData?.what ? "Updating..." : "Creating..."}</span>
              ) : (
                <span>{flagsExists ? "Create Flag anyways"  : noClaimLinkFlagData?.what ? "Update Flag" : "Create Flag"}</span>
              )}
            </Button>
          </div>

        </form>
      </Modal>
      <Modal
        open={showPromoteModal}
        onClose={() => {
          setShowPromoteModal(false);
        }}
        center
        classNames={{ modal: "med-modal" }}
      >
        <div className="modal-header quick-header">
          <h6>Highlight this flag</h6>
        </div>
        <div className="mt-4">
          <textarea
            className="promote-textarea"
            placeholder="Please add your comments here... (optional)"
            rows="6"
            onChange={(e) => {
              setStatusComment(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                allocateStatus(activeFlagID, "Eligible");
              }
            }}
          />
        </div>
        <div className="mt-4 d-flex justify-content-end align-items-center">
          {updatingId !== "" && (
            <div className="mr-3">
              <img src={Loading} width="20" height="20" />
            </div>
          )}
          <button
            className="blue-btn"
            onClick={() => {
              allocateStatus(activeFlagID, "Eligible");
            }}
          >
            Add
          </button>
        </div>
      </Modal>

      <Modal
          open={showNoClaimLinkFlagModal}
          onClose={() => {
            setShowNoClaimLinkFlagModal(false);
            setNoClaimLinkFlagId(null);
            setNoClaimLinkFlagData(null);
          }}
          center
      >
        <div className="mt-5 d-flex flex-column justify-content-end align-items-center">
          <h6>This flag does not have a claim link. Please add a link to an instance of this claim being made to write a fact brief.</h6>
          <button
              className="blue-btn mt-3"
              onClick={() => {
                setShowNoClaimLinkFlagModal(false);

                onQuickClick();
              }}
          >
            Update Flag
          </button>
        </div>
      </Modal>

    </div>
  );
}
