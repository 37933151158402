import React, {useEffect, useState} from 'react';
import {  Select } from 'antd';

import styles from './selectDropdown.module.css';


const SelectDropdown = (props) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let opts = props.datasource.map(item => ({
            value: item,
            label: item
        }));
        setOptions(opts);
    }, [props.datasource]);

    const handleChange = (values, options) => {
        props.onChange(values);
    };

    return (
        <div id="selectDropdown" style={{ position: 'relative' }}>
            <Select
                mode={props.mode}
                getPopupContainer={() => document.getElementById("selectDropdown")}
                style={{
                    width: "100%",
                    minHeight: "50px",
                    border: "1px solid #000",
                    outline: "none"
                }}
                value={props.selected || []} // set the value prop with the selected prop
                className={styles.select_dropdown} // make sure styles is defined or import it
                placeholder={props.placeholder}
                onChange={handleChange}
                options={options}
            />
        </div>
    );
};


export default SelectDropdown;
