import React, { useEffect, useState } from 'react';
import './add-new-audience-manager.css';
import { notification, Select } from 'antd';
import { useHistory } from "react-router-dom";
import Utils from '../../../../utils/utils';
import axios from 'axios';

export default function CPAddNewAudienceManager(props) {
    const [reqErr, setReqErr] = useState(false);
    const [isAdmin, setIsAdmin] = useState('testing');
    const [email, setEmail] = useState('');
    const [authorName, setAuthorName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    useEffect(() => {
        if (localStorage.getItem('user_types').includes('org_admin')) {
            setIsAdmin(true);
        }else{
            setIsAdmin(false);
        }
        document.title = "Add Audience Manager";
    }, []);



    const checkForm = () => {
        if (authorName === "" ) {
            setReqErr(true);
        }
        if (email === "") {
            setReqErr(true);
        }
        else {
            addManager();
        }
    }

    const addManager = () => {
        let api = Utils.getAPIDescription('add-aud-manager');
        setIsLoading(true);
        axios.post(api, { "email": email, "name": authorName,  "orgId": JSON.parse(localStorage.getItem('org_id'))})
            .then((res) => {
                notification.success({
                    message: 'Audience Manager added successfully',
                    duration: 3,
                    placement: 'topRight',
                });
                setIsLoading(false);
            });
    }
    return (
        <div className="app-workspace">
            <div className="app-content">
                {isAdmin ? <div className="add-user-container pull-left">
                        <button className='blue-border-btn mb-4 fn-14' onClick={() => { history.goBack() }}>BACK</button>
                        <div className='create-heading'>Add New Audience Manager</div>
                        <div className="add-user">
                            <div className="name-section">
                                <div className="name-div">
                                    <div className="fn-13 fnw-600 mb-2">Manager Email:</div>
                                    <input className="user-input"  type="email" name="email" onChange={(evt)=>{setEmail(evt.currentTarget.value)}} />
                                    {!email && reqErr && <div className="err-msg">This field is required</div>}
                                </div>
                                <div className="name-div">
                                    <div className="fn-13 fnw-600 mb-2">Manager Name:</div>
                                    <input className="user-input" name="fname" onChange={(evt)=>{setAuthorName(evt.currentTarget.value)}} />
                                    {!authorName && reqErr && <div className="err-msg">This field is required</div>}
                                </div>
                                <div>
                                    <button class="blue-btn ml-4" onClick={()=>{checkForm()}}>{isLoading ? <span>ADDING...</span> : <span>ADD NEW</span>}</button>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="add-user-container pull-left">
                        <h2>Permission Denied</h2>
                    </div>}
            </div>
        </div>
    )
}
