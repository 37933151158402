// ServicesModal.js

import React from 'react';
import { Modal, Button, Switch } from 'antd';
import { GlobalOutlined, FacebookOutlined, TwitterOutlined, LinkedinOutlined, InstagramOutlined, RedditOutlined } from '@ant-design/icons'; // Import specific icons

import Users from '../users/users';
import styles from './ServicesModal.module.scss'; // Import CSS Modules styles

import { CloseCircleFilled } from "@ant-design/icons";

const ServicesModal = ({
  orgPreview,
  visible,
  services,
  selfPublishStatus,
  onCancel,
  onConfirm,
  onServiceChange,
  onSelfPublishChange
}) => {

  const handleServiceChange = (key, checked) => {
    // Check if turning off FactBrief service and show confirmation message
    if (key === 'factbrief_service' && !checked) {
      Modal.confirm({
        title: 'Confirm',
        content: 'Are you sure you want to revoke the Fact brief Service from this organisation?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk() {
          onServiceChange(key, checked);
        },
        onCancel() {
          // Do nothing if user cancels
        },
      });
    }
      else if (key === 'claimsdb_service' && !checked) {
        Modal.confirm({
          title: 'Confirm',
          content: 'Are you sure you want to revoke the Parser Service from this organisation?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk() {
            onServiceChange(key, checked);
          },
          onCancel() {
            // Do nothing if user cancels
          },
        });
    } else {
      // For other services, directly call the change handler
      onServiceChange(key, checked);
    }
  };

  const handleSelfPublishChange = (checked) => {
    Modal.confirm({
      title: 'Confirm',
      content: !checked ? (
        <span>
          Are you sure you want to change this member’s self-publishing status?<br /><br />
          Changing their status will no longer allow them to publish fact briefs without Gigafact approval.
        </span>
      ) : (
        <span>
          Are you sure you want to change this member’s self-publishing status?<br /><br />
          Changing their status will allow them to publish fact briefs without Gigafact approval.
        </span>
      ),
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk() {
        onSelfPublishChange(checked);
      },
      onCancel() {
        // Do nothing if user cancels
      },
    });
  };

  return (
    <Modal
        className={styles.orgdetailsModal}
      visible={visible}
      closable={true}
      closeIcon={<div onClick={onCancel}><CloseCircleFilled className={styles.closeIcon}/></div>}
      width={'100%'}
      centered
      destroyOnClose={true}
      footer={null
       }
    >
      <div>
        {orgPreview && (
          <div className={styles['org-info']}>
            {orgPreview?.logoURL!=='' &&
            <div className={styles['org-logo']}>
              <img src={orgPreview?.logoURL} alt="org logo" className={styles['org-logo-img']} />
            </div>
            }
            <div className={styles['org-details']}>
              <span className={styles['org-name']}>{orgPreview?.org_name}</span>
              <span className={styles['org-description']}>{orgPreview?.description}</span>
              <div className={styles['social-links']}>
                {orgPreview?.links?.Website && (
                  <div className={styles['social-link']}>
                    <a href={orgPreview?.links?.Website} target="_blank" rel="noopener noreferrer"><GlobalOutlined /> Website</a>
                  </div>
                )}
                {orgPreview?.links?.Facebook && (
                  <div className={styles['social-link']}>
                    <a href={orgPreview?.links?.Facebook} target="_blank" rel="noopener noreferrer"><FacebookOutlined /> Facebook</a>
                  </div>
                )}
                {orgPreview?.links?.Twitter && (
                  <div className={styles['social-link']}>
                    <a href={orgPreview?.links?.Twitter} target="_blank" rel="noopener noreferrer"><TwitterOutlined /> Twitter</a>
                  </div>
                )}
                {orgPreview?.links?.LinkedIn && (
                  <div className={styles['social-link']}>
                    <a href={orgPreview?.links?.LinkedIn} target="_blank" rel="noopener noreferrer"><LinkedinOutlined /> LinkedIn</a>
                  </div>
                )}
                {orgPreview?.links?.Instagram && (
                  <div className={styles['social-link']}>
                    <a href={orgPreview?.links?.Instagram} target="_blank" rel="noopener noreferrer"><InstagramOutlined /> Instagram</a>
                  </div>
                )}
                {orgPreview?.links?.reddit && (
                  <div className={styles['social-link']}>
                    <a href={orgPreview?.links?.reddit} target="_blank" rel="noopener noreferrer"><RedditOutlined /> Reddit</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <h3 style={{ marginBottom: '20px' }}>Services</h3>
        <div className={styles['services-container']}>
          <div className={styles['service-panel']}>
            <p className={styles['service-title']}>Fact brief Service</p>
            <Switch
              checked={services.factbrief_service}
              onChange={(checked) => handleServiceChange('factbrief_service', checked)}
            />
            {services.factbrief_service && (
              <div style={{ marginLeft: '20px', marginTop: '10px', borderLeft: '2px solid #f0f0f0', paddingLeft: '10px' }}>
                <p style={{ fontSize: '14px', marginBottom: '5px' }}>Self-Publishing Status</p>
                <Switch
                  checked={selfPublishStatus}
                  onChange={handleSelfPublishChange}
                />
              </div>
            )}
          </div>
          <div className={styles['service-panel']}>
            <p className={styles['service-title']}>Parser Service</p>
            <Switch
              checked={services.claimsdb_service}
              onChange={(checked) => handleServiceChange('claimsdb_service', checked)}
            />
          </div>
          <div className={styles['service-panel']}>
            <p className={styles['service-title']}>Drafter AI Service</p>
            <Switch
              checked={services.drafterai_service}
              onChange={(checked) => handleServiceChange('drafterai_service', checked)}
            />
          </div>
        </div>

        <Users org_id={orgPreview?.page_id} type="services_modal" services={services}/>
      </div>
      <div className={styles.antModalFooter} >
        <Button key="cancel" onClick={onCancel} size={"large"}>
          Cancel
        </Button>
        <Button key="confirm" type="primary" onClick={onConfirm} size={"large"}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default ServicesModal;
