import {
  ArrowRightOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppleLogo from "../../../../assets/images/apple_logo.svg";
import FaceBookLogo from "../../../../assets/images/fb_logo.svg";
import GooleLogo from "../../../../assets/images/google-logo.svg";
import GFLogo from '../../../../assets/images/logos/gigafact_logo_short (1).svg';
import Utils from "../../../../utils/utils";
import { cognitoLogin } from "../../utilities/Auth";
import { doLogin, afterLogin } from "../authentication";
// import { FactSparrowDocument } from '../fact-sparrow-doc/fact-sparrow-doc';
import "./sign-in.scss";

export const SignIn = (props) => {
  const history = useHistory();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openDocModal, setOpenDocModal] = useState(false);



  // const loginCheck = () => {
  //   // console.log("Called from ", window.location )
  //   if (Utils.checkLogin()) {
  //     if (sessionStorage.redirect_path && !sessionStorage.redirect_path.includes("partnerconsole/login")) {
  //       window.location.href = sessionStorage.redirect_path;
  //     } else {
  //       history.push(
  //         Utils.checkContentPartner() ? "/partnerconsole/home" : "/fact-briefs"
  //       );
  //     }
  //   }
  // };

  useEffect(() => {
    setTimeout(() => {
      document.title = "Gigafact Partner Console";
    }, 1000);

    console.log(process.env)
    // window.addEventListener("storage", loginCheck);
    // localStorageCheck();
    // loginCheck();
  }, []);

  const downloadDoc = (e) => {
    // setOpenDocModal(true);
    window.open(
      "https://docs.google.com/document/d/1JccA9TkEzyJzlWvucSa36gjP0Dq9zXmuG6CmUQHm44k/edit?usp=sharing"
    );
  };

  // const localStorageCheck = () => {
  //   let tokenKey = null;
  //   // Object.keys(localStorage).reduce(function(obj, str) {
  //   //     console.log(str);
  //   //     /^.*accessToken$/.test(str)
  //   // }, {});
  //
  //   // console.log(tokenKey);
  // };
  const submitLogin = async (e, type = "Manual") => {
    e.preventDefault();
    setLoading(true);
    try {
      let cognitoUser = await doLogin(type, { username, password })
      // console.log(cognitoUser);
      let afterLoginResponse = await afterLogin(cognitoUser);
      // console.log(afterLoginResponse);
      setLoading(false);
      if (!afterLoginResponse.login){
        notification.error({ message: `${afterLoginResponse.message}` });
      }
      else{
        props.loginCallback(afterLoginResponse);
      }
      //     .then((resp) => {
      //   console.log(resp)
      //   setLoading(false);
      //   props.loginCallback(resp);
      // });

      // loginCheck();
    } catch (error) {
      console.log(error);
      notification.error({ message: `${error.message}` });
      setLoading(false);
      // loginCheck();
    }
  };
  return (
    <div>
      <div className="container-fluid login-container m-0 p-0">
        <div className="row h-100 p-0 m-0">
          <div className="col-md-6 col-sm-12 left-panel ">
            <div className="partner_console_container">
              <div className="partner_console_text">
                <h1>Partner Console</h1>
                <h4>
                  For invited media partners who work to spread accurate
                  information
                </h4>
         
                    <img src={GFLogo} alt="" />
     
                <div className="line-3">Optimized for desktop</div>
              </div>
            </div>
            {/* <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10 col-sm-12 text-center">
                <div className="heading-1">FactSparrow Partner Console</div>
              </div>
            </div> */}
            {/* <div className="row justify-content-center">
              <div className="col-lg-7 col-md-10 col-sm-12 px-3 text-center">
                <div className="line-1">
                  For invited media partners of FactSparrow who offer help to
                  fact-seeking consumers
                </div>
              </div>
            </div> */}
            {/* <div className="row justify-content-center">
              <div className="col-lg-7 col-md-10 sol-cm-12 text-center">
                <div className="line-2" onClick={downloadDoc}>
                  FactSparrow content partner criteria
                  <ArrowRightOutlined style={{ "padding-left": "10px" }} />
                </div>
              </div>
            </div> */}
            {/* <div className="row justify-content-center">
              <div className="col-lg-6 col-md-10 col-sm-12 text-center pb-5">
                <div className="line-3">Optimized for desktop</div>
              </div>
            </div> */}
          </div>
          <div className="col-md-6 col-sm-12 right-panel h-100">
            <div className="form-panel">
              <form
                onSubmit={(e) => {
                  submitLogin(e, "Manual");
                }}
              >
                <div className="heading-1">Sign in</div>

                <div className="input-block">
                  <div className="label">Email ID</div>
                  <Input
                    type="text"
                    placeholder="Enter your Email ID"
                    value={username}
                    onChange={(e) => setUsername(e.target.value.trim())}
                    required
                  />
                </div>
                <div className="input-block">
                  <div className="label">Password</div>
                  <Input
                    className="password-input"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    suffix={
                      <span
                        className="hover"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </span>
                    }
                    required
                  />
                </div>
                <div className="button-block text-right">
                  <Button
                    type="link"
                    className="forgot-password p-0 m-0 pt-1"
                    onClick={() => {
                      history.push("/partnerconsole/forgot-password");
                    }}
                  >
                    Forgot Password
                  </Button>
                </div>
                <div className="button-block">
                  <Button
                    className="sign-in w-100 p-1"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    SIGN IN
                  </Button>
                </div>
              </form>
            </div>
            {/* <div className="other-login text-center">
                        <div className="label">
                            <div className="hr">
                                <hr />
                            </div>
                            <div className="text px-3">
                                Or continue with
                            </div>
                            <div className="hr">
                                <hr />
                            </div>
                        </div>
                        <div className="social-login">
                        <div className="google-block px-3">
                                <Button className='' onClick={(e)=>{submitLogin(e, 'Google') }}>
                                    <img className="fs-logo" src={GooleLogo} />
                                </Button>
                            </div>
                            <div className="apple-block px-3">
                                <Button className='' onClick={(e)=>{submitLogin(e, 'Apple') }}>
                                    <img className="fs-logo" src={AppleLogo} />
                                </Button>
                            </div>
                            <div className="google-block px-3">
                                <Button className='' onClick={(e)=>{submitLogin(e, 'Facebook') }}>
                                    <img className="fs-logo w-100" src={FaceBookLogo} />
                                </Button>
                            </div>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
      {/* <Modal title={null} footer={null} visible={openDocModal} onOk={null} onCancel={() => { setOpenDocModal(false) }}>
            <FactSparrowDocument />
        </Modal> */}
    </div>
  );
};
