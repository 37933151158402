import Axios from 'axios';
import React, {useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Link } from 'react-router-dom';
import {Auth, Hub} from "aws-amplify";
import Utils from '../../utils/utils';
import { Modal } from 'react-responsive-modal';
import './factbriefs.css';
import ReviewFilter from '../../components/filters/reviewfilter/reviewfilter.js';
import Header from '../header/header.js';
import Nav from '../nav/nav.js';
import ReveiwBox from '../common/reviewBox/reviewBox';
import Loading from '../../assets/images/loader.gif';
import XIcon from '../../assets/images/cross.svg';
import SearchImg from '../../assets/images/search.png';

import { Tabs } from 'antd';
import FactGrid from './factgrid.js';

export default function FactBriefs() {
    const [searchQuery, setSearchQuery] = useState('');
    const [reviewData, setReviewData] = useState({});
    const [searchData, setSearchData] = useState([]);
    const [endCursor, setEndCursor] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isReviewList, setIsReviewList] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [categoryFilters, setCategoryFilters] = useState([]);
    const [subcategoryFilters, setSubcategoryFilters] = useState([]);
    const [geotagFilters, setGeotagFilters] = useState([]);
    const [categoryArr, setCategoryArr] = useState([]);
    const [subcategoryArr, setSubcategoryArr] = useState([]);
    const [geotagArr, setGeotagArr] = useState([]);
    const [draftsData, setDraftsData] = useState({});
    const [submittedData, setSubData] = useState({});
    const [returnData, setReturnData] = useState({});
    const [myFactBriefs, setMyFactBriefs] = useState({});
    const [modalVisible, setModalVisible] = useState(false);
    const itemsCountPerPage = 15;
    const scrollRef = useRef(null);
    const searchInput = useRef();
    const reviewState = useSelector(state => state.reviewReducer);
    const user_email = localStorage.getItem('email');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [editEmail, setEditEmail] = useState(false);
    const [validate, setValidate] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const un = localStorage.getItem('user_id');

	const [reviewerList, setReviewerList] = useState([]);
    const [code, setCode] = useState('');

    const authorEmail = localStorage.getItem('email');
    const orgId = parseInt(localStorage.getItem('org_id'));
    const isAdmin = localStorage.getItem('user_types') && localStorage.getItem('user_types').includes('org_admin');
    const is_platform_editor = localStorage.getItem('user_types') && localStorage.getItem('user_types').includes('repustar_platform_moderator');
    const [activeKey, setActiveKey] = useState('1');
    const { TabPane } = Tabs;
    const orgName = localStorage.getItem('org_name');
    const [briefCount, setBriefCount] = useState(0);
    const [draftCount, setDraftCount] = useState(0);
    const [submitCount, setSubmitCount] = useState(0);
    const [returnCount, setReturnCount] = useState(0);
    const [reviewCount, setReviewCount] = useState(0);
    const [paginate, setPaginate] = useState(false);
    const [sortParam, setSortParam] = useState("");
    const [authorVal, setAuthorVal] = useState("");
    const [orgslugVal, setOrgslugVal] = useState("");

    const [status, setStatus] = useState('my_fact_brief');


    useEffect(() => {
        //getCategories();
        //getSubcategories();
        //getRegion();
        checkUserInfo();
        fetchFactBriefs();
        getReviewerList();
    }, []);


    useEffect(() => {
        if(reviewState.payload){
            setCategoryArr(reviewState.payload.categoryArr);
            setSubcategoryArr(reviewState.payload.subcategoryArr);
            setGeotagArr(reviewState.payload.geotagArr);
        }
    }, [reviewState.payload]);

    useEffect(() => {
        if(reviewState.payload){
           // fetchReviews();
       }
    }, [categoryArr, subcategoryArr, geotagArr]);

    useEffect(() => {
      fetchFactBriefs('', '', false, searchQuery);
    }, [sortParam]);

    useEffect(() => {
      fetchFactBriefs('', '', false, searchQuery);
    }, [authorVal]);

    useEffect(() => {
      if(status == "Published"){
        fetchFactBriefs('', "Published", false, searchQuery);
      }
    }, [orgslugVal]);

    const getCategories = async() => {
        let apiPath = Utils.getAPIDescription('graph-ql');
        fetch(apiPath, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `query cat{
                          getCategories{
                            category
                            id
                          }
                        }`,
                    }
                )
        }).then(response => response.json())
            .then(data => {
                setCategoryFilters(data.data.getCategories);
            })
            .catch(error => {
                console.log(error);
            }
        );
    }
    const getReviewerList = () => {
		let api = Utils.getAPIDescription('get-reviewers') + '?org_id=' + (localStorage.getItem('org_id') !== 'undefined' ? localStorage.getItem('org_id') : 0);
        Axios.get(api)
            .then((res) => {
                if (res.data.reviewers) setReviewerList(res.data.reviewers);
        });
    }

    const checkUserInfo = () => {
        setEmail(localStorage.getItem('email'));
        localStorage.getItem('firstname') && setFirstName(localStorage.getItem('firstname'));
        localStorage.getItem('lastname') && setLastName(localStorage.getItem('lastname'));
        localStorage.getItem('username') &&  setUsername(localStorage.getItem('username'));
        if(localStorage.getItem('firstname') === '' || localStorage.getItem('lastname') === ''){
            setModalVisible(true);
        }/*else if(localStorage.getItem('email_verified') && !JSON.parse(localStorage.getItem('email_verified'))){
            sendCode();
            setVerifyEmail(true);
            setModalVisible(true);
        }*/
    }

    const getSubcategories = async() => {
        let apiPath = Utils.getAPIDescription('graph-ql');
        fetch(apiPath, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `query subcat{
                          getSubCategories{
                            subCategory
                            id
                          }
                        }`,
                    }
                )
        }).then(response => response.json())
            .then(data => {
                setSubcategoryFilters(data.data.getSubCategories);
            })
            .catch(error => {
                console.log(error);
            }
        );
    }

    const getRegion = async() => {
        let apiPath = Utils.getAPIDescription('graph-ql');
        fetch(apiPath, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query: `query region{
                          getRegions{
                            region
                            id
                          }
                        }`,
                    }
                )
        }).then(response => response.json())
            .then(data => {
                setGeotagFilters(data.data.getRegions);
            })
            .catch(error => {
                console.log(error);
            }
        );
    }

    const fetchFactBriefs = async (ec = '', sts='', ip=false, qry='') => {
        setNoResults(false);
        setIsReviewList(true);
        setIsLoading(true);
        setSearchData([]);
        /*setReviewData([]);
        setDraftsData([]);
        setSubData([]);
        setReturnData([]);
        setMyFactBriefs([]);*/
        setPaginate(ip);
        let api = Utils.getAPIDescription('review-listing-new');
       // await Axios.post(api, { "organization_id": parseInt(localStorage.getItem('org_id')), "search_query": `${searchQuery}`, "authors": [], "isAdmin" : localStorage.getItem('user_types').includes('org_admin') })
        await Axios.post(api, {"author_email":  authorEmail,"organization_name":orgName, "organization_id": orgId, "isAdmin" : isAdmin, "is_platform_editor": is_platform_editor, "search_query": qry, 'status':sts, 'sortPara': sortParam, 'orgslug': orgslugVal ,'editorusername': authorVal,'after': ec})
            .then(res => {
                switch (sts) {
                    case "" :
                        setMyFactBriefs(res.data.body.my_fact_brief);
                        setReturnData(res.data.body.returned_reviews);
                        setSubData(res.data.body.submitted_reviews);
                        setDraftsData(res.data.body.draft_reviews);
                        setReviewData(res.data.body.published_reviews);
                        setBriefCount(res.data.body.my_fact_brief.total_count);
                        setReturnCount(res.data.body.returned_reviews.total_count);
                        setSubmitCount(res.data.body.submitted_reviews.total_count);
                        setDraftCount(res.data.body.draft_reviews.total_count);
                        setReviewCount(res.data.body.published_reviews.total_count);
                        break;
                    case "my_fact_brief" :
                        setMyFactBriefs(res.data.body.my_fact_brief);
                        setBriefCount(res.data.body.my_fact_brief.total_count);
                        break;
                    case "Returned":
                        setReturnData(res.data.body.returned_reviews);
                        setReturnCount(res.data.body.returned_reviews.total_count);
                        break;
                    case "Submitted":
                        setSubData(res.data.body.submitted_reviews);
                        setSubmitCount(res.data.body.submitted_reviews.total_count);
                        break;
                    case "Draft":
                        setDraftsData(res.data.body.draft_reviews);
                        setDraftCount(res.data.body.draft_reviews.total_count);
                        break;
                    case "Published":
                        setReviewData(res.data.body.published_reviews);
                        setReviewCount(res.data.body.published_reviews.total_count);
                        break;
                }

                setTotalCount(res.data.body.published_reviews.review_list.length);

            }).catch(err => {
                console.log(err);
            })
        setIsLoading(false);
    }

    const onInputChange = (e) => {
        setSearchQuery(e.target.value)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchFactBriefs('', '', false, searchQuery);
        }
    };

    const validateForm = (type) => {
        if(type === 'user'){
            if(firstName === '' || lastName === ''){
                setValidate(true);
                return;
            }
            updateUserDetails();
        }else{
           if(code === ''){
                setValidate(true);
                return;
            }

            confirmSignUp();
        }
        setValidate(false);
    }

    const updateUserDetails = async() => {
        setUpdating(true);
        let apiPath = Utils.getAPIDescription('update-user');
        Axios.post(apiPath,
            { "userid": `${un}`,
               "firstname": `${firstName}`,
               "lastname": `${lastName}`,
               "username": `${username}`
           })
            .then((res) => {
                if(res.data.statusCode === 200){
                    setUpdating(false);
                    setModalVisible(false);
                    localStorage.setItem('username', username);
                    localStorage.setItem('firstname', firstName);
                    localStorage.setItem('lastname', lastName);
                   // !JSON.parse(localStorage.getItem('email_verified')) && setModalVisible(true); setVerifyEmail(true);
                }
            });
    }

    const sendCode = async() =>{
        try {
            await Auth.resendSignUp(un);
            console.log('code resent successfully');
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    const confirmSignUp = async() =>{
        setUpdating(true);
        try {
          await Auth.confirmSignUp(un, code);
          setUpdating(false);
        } catch (error) {
            console.log('error confirming sign up', error);
        }
    }


    const handleTabClick = (key) => {
        setActiveKey(key);
        switch (key) {
            case "1" :
                setStatus("my_fact_brief");
                break;
            case "2":
                setStatus("Returned");
                break;
            case "3":
                setStatus("Submitted");
                break;
            case "4":
                setStatus("Draft");
                break;
            case "5":
                setStatus("Published");
                break;
        }
    }

    const updatePage = (ec, st) => {
        fetchFactBriefs(ec, st, true, searchQuery);
    }

    const updateSortVal = (val) => {
      setSortParam(val);
    }

    const updateAuthorVal = (val) => {
      setAuthorVal(val);
    }
    const updateOrgslugVal = (val) => {
      setOrgslugVal(val);
    }

    return (
        <div className="app-workspace">
            <Header/>
            <div className="app-content-review">
                <Nav/>
                <div className="app-reviews pull-left">
                    {!isLoading && reviewData.length === 0 && draftsData.length === 0 && returnData.length === 0 && submittedData.length === 0 && searchQuery === ''?
                        <div className='banner-bg pl-100'>You have no fact briefs at this time.</div>
                        :
                        <div className='review-flex'>
                            <div className="flex-line">
                                <div className='rel-flags-title colw-100 rev-width mb-2 mt-5'>
                                <div className="flex-line">
                                    <div className="pull-left" style={{'marginLeft': '75px', 'lineHeight': '40px'}}>Fact Briefs</div>
                                    <div className='flag-search fact-brief-search pull-right relative-position ml-2'>
                                        <input className='search-input colw-100' placeholder='Search for a Fact Brief' onChange={onInputChange} onKeyDown={handleKeyDown} value={searchQuery}/>
                                        {searchQuery.length > 0 ? <span className="clear-btn" onClick={()=>{setSearchQuery(''); fetchFactBriefs();}}><img src={XIcon}/></span> : ''}
                                        {isLoading ? <img className="loading" src={Loading}/>: <img className="search" src={SearchImg}/>}
                                    </div>
                                </div>
                                </div>
                            </div>
                            <Tabs size='large' activeKey={activeKey} onTabClick={handleTabClick}>
                                <TabPane tab={<><span>My Fact Briefs</span>&nbsp;&nbsp;<span className="brief-count">{briefCount}</span></>} key="1">
                                    <FactGrid datasource={myFactBriefs} updatepage={updatePage} updateSortval={updateSortVal} updateAuthorval={updateAuthorVal} updateOrgslugval={updateOrgslugVal} isloading={isLoading} status='my_fact_brief' ispaginate={paginate}/>
                                </TabPane>
                                <TabPane tab={<><span>Drafts</span>&nbsp;&nbsp;<span className="draft-count">{draftCount}</span></>} key="2">
                                    <FactGrid datasource={draftsData} updatepage={updatePage} updateSortval={updateSortVal} updateAuthorval={updateAuthorVal} updateOrgslugval={updateOrgslugVal} isloading={isLoading} status='Draft' ispaginate={paginate}/>
                                </TabPane>
                                <TabPane tab={<><span>Submitted</span>&nbsp;&nbsp;<span className="submitted-count">{submitCount}</span></>} key="3">
                                    <FactGrid datasource={submittedData} updatepage={updatePage} updateSortval={updateSortVal} updateAuthorval={updateAuthorVal} updateOrgslugval={updateOrgslugVal} isloading={isLoading} status='Submitted' ispaginate={paginate}/>
                                </TabPane>
                                <TabPane tab={<><span>Returned</span>&nbsp;&nbsp;<span className="returned-count">{returnCount}</span></>} key="4">
                                    <FactGrid datasource={returnData} updatepage={updatePage} updateSortval={updateSortVal} updateAuthorval={updateAuthorVal} updateOrgslugval={updateOrgslugVal} isloading={isLoading} status='Returned' ispaginate={paginate}/>
                                </TabPane>
                                <TabPane tab={<><span>Published</span>&nbsp;&nbsp;<span className="published-count">{reviewCount}</span></>} key="5">
                                    <FactGrid datasource={reviewData} updatepage={updatePage} updateSortval={updateSortVal} updateAuthorval={updateAuthorVal} updateOrgslugval={updateOrgslugVal} isloading={isLoading} status='Published' ispaginate={paginate}/>
                                </TabPane>
                            </Tabs>
                        </div>
                    }
                </div>
            </div>

            <Modal open={modalVisible} onClose={() => { setModalVisible(false);}} center classNames={{modal: 'big-modal'}}>
                {!verifyEmail ?
                    <>
                        <div className="modal-header">
                            <h6>Before you continue to create a review, enter the below details</h6>
                        </div>
                        <div className="modal-body">
                            <div className="modal-row">
                                <div className="pull-left">
                                    <label>First Name</label><br/>
                                    <input type="text" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}}/>
                                    {validate && <>{firstName === '' && <div className="err-msg">Enter First Name</div>}</>}
                                </div>
                                <div className="pull-left">
                                    <label>Last Name</label><br/>
                                    <input type="text" value={lastName} onChange={(e)=>{setLastName(e.target.value)}}/>
                                    {validate && <>{lastName === '' && <div className="err-msg">Enter Last Name</div>}</>}
                                </div>
                            </div>
                            {/* <div className="modal-row">
                                <div className="pull-left">
                                    <br/>
                                    <label>User Name</label><br/>
                                    <input type="text" value={username} onChange={(e)=>{setUsername(e.target.value)}}/>
                                    {validate && <>{username === '' && <div className="err-msg">Enter Username</div>}</>}
                                </div>
                            </div> */}
                            <div className="modal-row">
                                <div className="pull-left">
                                    <br/>
                                    {/*<label>Email</label><br/>
                                    {editEmail ? <input type="text" value={email} autoFocus onChange={(e)=>{setEmail(e.target.value)}}/> : <input type="text" value={email} disabled style={{border: 'none'}}/>}
                                    <span className="link-btn blue-font" onClick={()=>{!editEmail ? setEditEmail(true) : setEditEmail(false)}}>{!editEmail ? <span>CHANGE</span> : <span>DONE</span>}</span>*/}
                                </div>
                            </div>
                            <div className="modal-row colw-100 inline-block">
                            <br/><br/>
                                <div className="pull-right">
                                    <div className="blue-btn cursor-pointer" onClick={()=>{validateForm('user')}}>{updating ? <span>Saving...</span> : <span>Save</span>}</div>
                                </div>
                            </div>
                        </div>
                    </> :
                    <>
                        <div className="modal-header">
                            <h6>Verify your email:</h6>
                        </div>
                        <div className="modal-body">
                            <div className="modal-row">
                                Please enter the code sent to <span>{email}</span>
                            </div>
                            <div className="modal-row">
                                <br/>
                               <label>Code</label><br/>
                               <input type="text" value={code} onChange={(e)=>{setCode(e.target.value)}}/>
                               {validate && <>{code === '' && <div className="err-msg">Enter Verification Code</div>}</>}
                            </div>
                            <div className="modal-row">
                               <span className="link-btn blue-font" onClick={()=>{sendCode()}}>RESEND</span>
                            </div>
                            <br/>
                            <div className="modal-row colw-100 inline-block">
                                <div className="pull-right">
                                    <div className="blue-btn cursor-pointer" onClick={()=>{validateForm('email')}}>{updating ? <span>Confirming...</span> : <span>Confirm</span>}</div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal>
        </div>
    );
}
