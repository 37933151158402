import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Utils from '../../../../utils/utils';
import Loading from '../../../../assets/images/loader.gif';
import { Avatar, Popover, Switch,Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import './edit-reviewers.scss';
import { notification } from 'antd';
import { Link } from 'react-router-dom';


const userType = {
    AUDIENCE_MANAGER: "Audience Manager"
};

export default function EditReviewers(props) {

    const [revsData, setRevsData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = () => {
        setDataLoading(true);
        let api = Utils.getAPIDescription('get-reviewers') + '?org_id=' + (localStorage.getItem('org_id') !== 'undefined' ? localStorage.getItem('org_id') : 0);
        api += `&login_state=content_partner`;

        if (props.userType)
            api += `&user_type=${props.userType}`;

        axios.get(api)
            .then((res) => {
                if (res.data.reviewers) setRevsData(res.data.reviewers);
                setDataLoading(false);
            });
    }

    const UserRow = (props) => {
        const [userStatus, setUserStatus] = useState(null);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {

            if (props.data && props.data.state && props.userType !== userType.AUDIENCE_MANAGER) {
                setUserStatus(props.data.state ? true : false);
            }
            else if (props.data && props.userType === userType.AUDIENCE_MANAGER) {
                setUserStatus(props.data.isAudienceManager);
            }
        }, [props]);

        const changeStatus = (newStatus) => {
            setIsLoading(true);
            let api = Utils.getAPIDescription('manage-reviewer-state');
            let body = { "reviewer_id": props.data.id, state: newStatus };

            if (props.userType === userType.AUDIENCE_MANAGER){
                body = { "reviewer_id": props.data.id, isAudienceManager: newStatus }
            }

            axios.post(api, body)
                .then((res) => {
                    notification.success({
                        message: 'State changed successfully',
                        duration: 3,
                        placement: 'topRight',
                    });
                    setIsLoading(false);
                    setUserStatus(newStatus);
                    getDetails();
                })
        };

        return (
            <div className='user-row'>
                <div>
                    <Avatar size={60} icon={<UserOutlined />} />
                </div>
                <div className='ml-2' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div>
                    <Tooltip placement='right' title={`${props.data.email}`}>
                        <div className='fn-18 inline-block'>
                            {'firstname' in props.data && 'lastname' in props.data ? props.data.firstname.trim() + ' ' + props.data.lastname.trim() : props.data.name}
                            {props.data.email === localStorage.getItem('email') && ' (You)'}
                        </div>
                    </Tooltip>
                    <div className='gray-font fn-14 mt-2'>
                        {/* <span>{props.data.reviews} Fact Briefs</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                        {/* <span className='ml-3'>{props.data.views} Views</span> */}
                        {/*<span className=''>last login: {moment(props.data.lastLogin).fromNow()}</span>*/}
                        <span className=''>Last login: {props.data.lastLogin ? moment.utc(props.data.lastLogin).local().fromNow() : '-'}</span>
                    </div>
                    </div>
                    <span className='label-style' style={{backgroundColor:props.data.isOrgAdmin?'#F0F8FF':'#f5f5dc',color:props.data.isOrgAdmin?'#0470bb':'#808000'}}>{props.data.isOrgAdmin ? ' Editor':' Writer'}</span>
                </div>
                {localStorage.getItem('user_id')!==props.data.id && localStorage.getItem('user_types').includes('org_admin') && <div className="d-flex align-items-center justify-content-around">
                    <Link to={"/partnerconsole/update-editor?userId=" + props.data.id}><button className="blue-border-btn-1px">Edit</button></Link>
                    <div className="">
                        <Switch checked={userStatus} onChange={changeStatus} loading={isLoading} />
                    </div>
                </div>}
            </div>
        )
    }

    return (
        <div className='mt-5'>
            <div className='revs-board'>
                <div className="editor-header mb-3">
                    <div className='blue-font'>{revsData.length} Users</div>
                    {localStorage.getItem('user_types').includes('org_admin') ? <Link Link to="/partnerconsole/add-user"><button className='blue-btn'>ADD NEW</button></Link> : null}
                    <div className="self-end gray-font mr-2 align-self-end">
                        {props.userType ? props.userType : "User Status"}
                    </div>
                </div>

                {!dataLoading || revsData.length > 0 ? revsData.map((item) => (
                    <UserRow data={item} userType={props.userType}/>
                )) : <center><img src={Loading} width='30px' /></center>}
            </div>
        </div>
    )
}