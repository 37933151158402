import {Checkbox, Divider, Drawer, notification, Radio} from 'antd';
import {CheckOutlined, PlusOutlined} from '@ant-design/icons';
import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {Modal} from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import {Prompt, useHistory} from "react-router-dom";
import {Tweet} from 'react-twitter-widgets';
import Cookies from 'universal-cookie';
import Loading from '../../../../../assets/images/loader.gif';
import Dropdown from '../../../../../components/common/dropdown/dropdown';
import Tilelist from '../../../../../components/common/tilelist/tilelist';
import RichTextEditor from '../../../../../components/richtexteditor/richtexteditor';
import SourceControl from '../../../../../components/sourcecontrol/sourcecontrol';
import Utils from '../../../../../utils/utils';
import FlagDetails from '../../../../flagdetails/flagdetails';
import './createreview.css';
import OrgBanner from "../../../../OrgBanner/orgbanner";
import SelectDropdown from "../../../../selectDropdown/selectDropdown";
import useOrgStore from "../../../store/orgsStore";


export default function CPCreateReview(props) {
    const [form, setForm] = useState(undefined);
    const [credibleSource, setCredibleSource] = useState([{data: ''}]);
    const [relevantLinks, setRelevantLinks] = useState([{data: ''}]);
    const [relevantPublishers, setRelevantPublishers] = useState([{data: ''}]);
    const [relevantPublisherTypes, setRelevantPublisherTypes] = useState(['']);
    const [relevantSiteNames, setRelevantSiteNames] = useState(['']);
    const [platform, setPlatform] = useState(['']);
    const [relevantArticleLink, setRelevantArticleLink] = useState('');
    const [contentTypeSuggestion, setContentTypeSuggestion] = useState(['']);
    const [selectedContentType, setSelectedContentType] = useState(['']);
    const [title, setTitle] = useState('');
    const [archiveLink, setArchiveLink] = useState(null);
    const [categoryArr, setCategoryArr] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [tagArr, setTagArr] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [geoList, setGeoList] = useState([]);
    const [geotagArr, setGeotagArr] = useState([]);
    const [factuality, setFactuality] = useState(undefined);
    const [validate, setValidate] = useState(false);
    const [credible, setCredible] = useState(false);
    const [relevant, setRelevant] = useState(false);
    const [relevantArticle, setRelevantArticle] = useState(false);
    const [category, setCategory] = useState('');
    const [sources, setSources] = useState([]);
    const [catSubcat, setCatSubcat] = useState();
    const [publishers, setPublishers] = useState([]);
    const [types, setTypes] = useState([]);
    const [pageId, setPageId] = useState(undefined);
    const [draftSlug, setDraftSlug] = useState("");
    const [isValidURL, setValidURL] = useState(true);
    const [articleTitle, setArticleTitle] = useState(null);
    const [articleImg, setArticleImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [slug, setSlug] = useState(undefined);
    const [isReturned, setIsReturned] = useState(false);
    const [tipDetails, setTipDetails] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [startIndex, setStartIndex] = useState(-1);
    const [flagIdDraft, setFlagIdDraft] = useState(null);
    const [showInfo, setShowInfo] = useState([false]);
    const [windowVisible, setWindowVisible] = useState(false);
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [modComment, setModComment] = useState('Comment');
    const [draftDataLoading, setDraftDataLoading] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [reminder, setReminder] = useState(false);
    const [relevantArticleLoader, setRelevantArticleLoader] = useState(false);
    const flagId = new URLSearchParams(props.location.search).get('flagId');
    const history = useHistory();
    const [isDrag, setIsDrag] = useState(false);
    const [contentIndex, setContentIndex] = useState(0);
    const WORD_LIMIT = 150;
    const cookie = new Cookies();
    const [review, setReview] = useState('');
    const [draftReview, setDraftReview] = useState('');
    const [authors, setAuthors] = useState([]);
    const [editorsAndWriters,setEditorsAndWriters] = useState([])
    const [authorIndex, setAuthorIndex] = useState(0);
    const [authorName, setAuthorName] = useState('Select');
    const [parentPageSlug, setParentPageSlug] = useState(null);
    const [tagImages, setTagImages] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [imgLoader, setImgLoader] = useState(false);
    const [imgNames, setImgNames] = useState([]);
    const [suggestionLoader, setSuggestionLoader] = useState(false);
    const imgContainerRef = useRef(null);
    const [killDropDown, setKillDropDown] = useState(false);
    const [selectedTagImage, setSelectedTagImage] = useState('');
    const [generatedImage, setGeneratedImage] = useState('');
    const [fallbackImages, setFallbackImages] = useState([]);
    const [allImages, setAllImages] = useState([]);
    const [imgUrl, setImgUrl] = useState(null)
    const [imgCaption, setImgCaption] = useState('')
    const [imgHeight, setImgHeight] = useState('auto')
    const [imgWidth, setImgWidth] = useState('100%')
    const [disableSaveDraft, setDisableSaveDraft] = useState(false);
    const newDraft = new URLSearchParams(props.location.search)
    const [DraftNotSaved, setDraftNotSaved] = useState(true ? newDraft.has('new') : false)
    const [specialTagAdded, setSpecialTagAdded] = useState(false);
    const [specialTagsArr, setSpecialTagsArr] = useState([]);


    const [isSourceControlScrapping, setIsSourceControlScrapping] = useState(false);


    // const devImages = {
    //     "327": "https://d1287cfywfpjjq.cloudfront.net/resources/nevada_fb_metaimage.jpg",
    //     "1031": "https://d1287cfywfpjjq.cloudfront.net/resources/wisconsin_fb_metaimage.jpg",
    // }
    // const prodImages = {
    //     "2680": "https://d1287cfywfpjjq.cloudfront.net/resources/nevada_independent_SocialCard.jpg",
    //     "3181": "https://d1287cfywfpjjq.cloudfront.net/resources/Wisconsin_Watch_SocialCard.jpg",
    //     "3182": "https://d1287cfywfpjjq.cloudfront.net/resources/spotlight_fb_metaimage.jpg",
    //     "3185": "https://d1287cfywfpjjq.cloudfront.net/resources/Arizona_CIR_SocialCard.jpg",
    //     "3188": "https://d1287cfywfpjjq.cloudfront.net/resources/MinnPost_factbrief_metaimage.png"
    // };

    const { orgDetails, fetchOrgDetails, isFetched } = useOrgStore();

    useEffect(() => {
        if (!isFetched) {
            fetchOrgDetails();
        }
    }, [fetchOrgDetails, isFetched]);

    useEffect(() => {
        // assignImage();
        fetchGeo();
        fetchFormData();
        fetchTags();
        fetchFlagDetails();
        // fetchAuthors();
        getEditorsAndWriters();
        fetchOrgDetails();
        if (cookie.get('remind')) {
            setForm("publish");
        }


        document.addEventListener("mousedown", handleClickOutSide);
        return () => {
            //Component unmount codes need to be added here
            document.removeEventListener("mousedown", handleClickOutSide);
        }
    }, []);

    // const assignImage = (orgDetails) => {
    //
    //     if (process.env.REACT_APP_ENV === 'PROD') {
    //         let metaImg = orgDetails?.factbrief_metaimg || null;
    //
    //         if (metaImg) {
    //             setSelectedTagImage(metaImg)
    //         }
    //         else {
    //             if(Object.keys(prodImages).includes(localStorage.org_id)){
    //                 setSelectedTagImage(prodImages[localStorage.org_id])
    //             }else{
    //                 setSelectedTagImage('https://d1287cfywfpjjq.cloudfront.net/resources/wisconsin_fb_metaimage.jpg')
    //             }
    //         }
    //
    //     } else {
    //         if(Object.keys(devImages).includes(localStorage.org_id)){
    //             setSelectedTagImage(devImages[localStorage.org_id])
    //         }else{
    //             setSelectedTagImage('https://d1287cfywfpjjq.cloudfront.net/resources/wisconsin_fb_metaimage.jpg')
    //         }
    //     }
    // }

    const handleClickOutSide = (event) => {
        if (imgContainerRef.current && !imgContainerRef.current.contains(event.target)) {
            setKillDropDown(true);
        }
    }

    useEffect(() => {
        credibleSource.map(item => {
            item.data === '' && setCredible(false)
        });
        credibleSource.map(item => {
            item.data !== '' && setCredible(true);
        });
    }, [credibleSource]);

    useEffect(() => {
        relevantLinks.map(item => {
            item.data === '' && setRelevant(false)
        });
        relevantLinks.map(item => {
            item.data !== '' && setRelevant(true)
        });
    }, [relevantLinks]);


    useEffect(() => {
        /*relevantArticleLinks.map(item=>{item === '' && setRelevantArticle(false)});
        relevantArticleLinks.map(item=>{item !== '' && setRelevantArticle(true)});*/
        relevantArticleLink !== '' ? setRelevantArticle(true) : setRelevantArticle(false);
    }, [relevantArticleLink]);

    useEffect(() => {
        showInfo.map(item => {
            item === false && setIsDrag(true)
        });
        showInfo.map(item => {
            item !== false && setIsDrag(false)
        });
    }, [showInfo]);

    useEffect(() => {
        let arr = [];
        for (let i in credibleSource) {
            let ar = [];
            if (credibleSource[i]['data'] && credibleSource[i]['data'] !== '') {
                let payload = {}
                //ar.push(relevantSiteNames[i] +": " + credibleSource[i]['data']);
                payload['title'] = credibleSource[i]['data'] || '';
                relevantLinks[i] && relevantLinks[i]['data'] ? payload['source_url'] = relevantLinks[i]['data'] : payload['source_url'] = ''
                //payload['source_url'] = relevantLinks[i]['data'] || '';
                payload['type'] = selectedContentType[i];
                payload['publisher_type'] = relevantPublisherTypes[i];
                payload['sitename'] = relevantSiteNames[i];
                if (platform && platform[i] && platform[i] !== '') {
                    payload['platform'] = platform[i];
                }
                arr.push(payload);
            }
        }
        setSources(arr);
    }, [credibleSource, relevantLinks, relevantPublisherTypes, selectedContentType, relevantSiteNames, platform]);

    useEffect(() => {
        getTagImages(categoryArr);
    }, [categoryArr]);
    // useEffect(() => {
    //     //let arr = new Set(tagArr);
    //     // setTagArr(arr);
    //     if (tagArr.length > 0) {
    //         //getTagImages(tagArr);
    //     }
    // }, [tagArr]);

    useEffect(() => {
        //if (types && types.length > 0) {
        if ('pageId' in props.match.params) {
            setDraftSlug(props.match.params.pageId);
            setForm('publish');
        } else {
            if (flagId && form !== 'publish') {
                if (cookie.get('remind')) {
                    setForm("publish");
                } else {
                    setForm('notice');
                }
            } else {
                setForm('publish');
            }
        }
        //}
    }, [flagIdDraft])

    useEffect(() => {
        if (flagIdDraft) {
            fetchFlagDetails();
        }
    }, [flagIdDraft])


    useEffect(() => {
        setAuthorIndex(Utils.getCollectionIdexbyValue(authors, 'value',authorName,'authorName'));
    }, [authors, authorName])

    const articleScrapper = async (val = undefined) => {
        setRelevantArticleLoader(true);
        let apiPath = Utils.getAPIDescription('scrap-article');
        try {
            // Load async data from an inexistent endpoint.
            let vl = undefined;
            // if(props.value && props.value !== ''){
            //     vl = props.value;
            // }
            if (val) {
                vl = val;
            }
            let data = await axios.post(apiPath, {"url": vl});
            if (data && data.data) {
                let sdata = data.data;
                setArticleTitle(sdata.claimReviewed);
                if (sdata.siteName === "Twitter" || sdata.siteName === "X") {
                    setArticleImg('https://help.twitter.com/content/dam/help-twitter/twitter-logo.png');
                } else {
                    setArticleImg(sdata.articleImage);
                }
                setRelevantArticleLoader(false);
            }
        } catch (e) {
            console.log(`Error+${e}`);
        }
    }

    const validateURL = (str) => {
        let pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return pattern.test(str);
    }

    const relatedArticleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (!validateURL(relevantArticleLink)) {
                setValidURL(false);
            } else {
                setValidURL(true);
                //fetchSource();
                articleScrapper(relevantArticleLink);
            }
        }
    };

    const pasteRelatedURL = (type, indx) => (e) => {
        updateItem(type, indx);
        let clipboardData, pastedData;
        clipboardData = e.clipboardData || window.clipboardData;
        pastedData = clipboardData.getData('Text');

        if (!validateURL(pastedData)) {
            setValidURL(false);
        } else {
            setValidURL(true);
            // fetchSource();
            // props.updateitem('links', props.index, pastedData);
            articleScrapper(pastedData);
        }
    }

    const fetchFlagDetails = async () => {
        if (flagId) {
            //setIsLoading(true);
            let apiPath = Utils.getAPIDescription('get-my-tips');
            try {
                // Load async data from an inexistent endpoint.
                let data = await axios.get(apiPath + '?id=' + flagId + '&subflags=' + true, {headers: {"userid": `${localStorage.getItem('user_id')}`}});
                if (data && data.data && data.data.body[0]) {
                    setTipDetails(data.data.body[0]);
                    //setIsLoading(false);
                }
            } catch (e) {
                console.log(`Error+${e}`);
            }
        }
        if (flagIdDraft) {
            let apiPath = Utils.getAPIDescription('get-my-tips');
            try {
                // Load async data from an inexistent endpoint.
                let data = await axios.get(apiPath + '?id=' + flagIdDraft + '&subflags=' + true, {headers: {"userid": `${localStorage.getItem('user_id')}`}});
                if (data && data.data && data.data.body[0]) {
                    setTipDetails(data.data.body[0]);
                    //setIsLoading(false);
                }
            } catch (e) {
                console.log(`Error+${e}`);
            }
        }
    }

    const getEditorsAndWriters = () => {
        let api = Utils.getAPIDescription('get-reviewers') + '?org_id=' + (localStorage.getItem('org_id') !== 'undefined' ? localStorage.getItem('org_id') : 0);
        api += `&login_state=content_partner`;
      
        if (props.userType) {
          api += `&user_type=${props.userType}`;
        }
      
        axios.get(api)
          .then((res) => {
            if (res.data.reviewers) {
                console.log(res.data.reviewers)
                const editorAndWriterNames = res.data.reviewers.map((reviewer) => ({
                    id: reviewer.id,
                    value: `${reviewer.firstname} ${reviewer.lastname} (${reviewer.email})`,
                  }));
                  editorAndWriterNames.unshift({ id: '0', value: 'Select' });
                  setAuthors(editorAndWriterNames);
            }
          })
          .catch((error) => {
            console.log(error)
          });
      }
      


    const fetchAuthors = async () => {
        //setIsLoading(true);
        let apiPath = Utils.getAPIDescription('get-author-list') + '?org_id=' + JSON.parse(localStorage.getItem('org_id'));
        try {
            // Load async data from an inexistent endpoint.
            let data = await axios.get(apiPath);
            if (data && data.data && data.data.author_list) {
                let lst = data.data.author_list;
                let arr = [];
                for (let i in lst) {
                    let obj = {};
                    obj.id = i;
                    obj.value = lst[i]['author_name'];
                    arr.push(obj);
                }
                setAuthors(arr);
            }
        } catch (e) {
            console.log(`Error+${e}`);
        }
    }


    const fetchDraftData = async (required) => {
        setDraftDataLoading(true);
        let api = Utils.getAPIDescription('show-draft');
        let edits = {}
        let res = {}
        if (required == true) {
            res = await axios.post(api, {
                "flagId": (flagId ? flagId : flagIdDraft),
                "slug": draftSlug,
                'parentPage_id': localStorage.getItem('org_id'),
                'user_id': localStorage.getItem('user_id')
            })
            edits = res.data.body.show_draft_response.showDraftEdit;
            //setTitle(edits.draftTitle.replace('<p>', ''));
            setArchiveLink(edits.flagArchivedUrl);
            setTitle(edits.title);
            setFactuality(edits.score ? edits.score.score : 'Unresolved');
            //setReview(edits.analysis.replace('<p>', '').replace('</p>','').replace(/<br\s*\/?>/mg,"\n"));
            let anls = edits.analysis;
            anls = modifyBackAnalysis(anls)
            setReview(anls);
            setDraftReview(anls);
            //setValue(edits.analysis);
            setSlug(edits.slug);
            setFlagIdDraft(edits.flagUrl);
            setTagArr(edits.tags || []);
            if (edits.category && Array.isArray(edits.category)) {
                setCategoryArr(edits.category.map(item => item.category || ""));
            } else {
                setCategoryArr([]);
            }

            setSelectedTagImage(edits?.reviewImageUrl ? edits?.reviewImageUrl : '')

            setPageId(edits.pageId);
            setParentPageSlug(edits.parentPage.slug);
            edits.authorName && edits.authorName.authorName ? setAuthorName(edits.authorName.authorName.trim()) : setAuthorName('Select');
            if (edits.sources.length > 0) {
                let links = [], tsource = [], sitenames = [], sinfo = [], rtype = [], ptype = [], cTypeSug = [],
                    cType = [], pform = [];
                for (let i = 0; i < edits.sources.length; i++) {
                    tsource.push({data: edits.sources[i].Name});
                    links.push({data: edits.sources[i].URL});
                    ptype.push(edits.sources[i].SourcePublisherType.publisher);
                    //rtype.push(edits.sources[i].SourceType);

                    cTypeSug.push(edits.sources[i].contentTypeSuggestion);
                    cType.push(edits.sources[i].SourceType.sourceType);

                    sinfo.push(false);
                    if (typeof edits.sources[i].SiteName === 'string') {
                        sitenames.push(edits.sources[i].SiteName);
                    }
                    if (edits.sources[i].Platform) {
                        pform.push(edits.sources[i].Platform);
                    } else {
                        pform.push('');
                    }
                }
                setCredibleSource(tsource);
                setRelevantSiteNames(sitenames);
                setShowInfo(sinfo);
                // setTypes(rtype);
                setRelevantPublisherTypes(ptype);
                setContentTypeSuggestion(cTypeSug);
                setSelectedContentType(cType);
                setPlatform(pform);
                setRelevantLinks(links);
                setPlatform(pform);
            }
            if (edits.relatedArticleLinks.length > 0) {
                let rLnk = edits.relatedArticleLinks[0].URL;
                setRelevantArticleLink(rLnk);
                updateItem('article', -1, rLnk);
                articleScrapper(rLnk);
            }
            if (edits.inModeration && edits.inModeration.status === "NEEDS_CHANGES") {
                setIsReturned(true);
                setModComment(edits.moderatorComments ? edits.moderatorComments : '');
            }
            if (edits.contentImage.contentImageUrl) {
                setImgUrl(edits.contentImage.contentImageUrl)
                setImgCaption(edits.contentImage.contentImageCaption)
                setImgHeight(edits.contentImage.contentImageHeight)
                setImgWidth(edits.contentImage.contentImageWidth)
            }
        }
        setDraftDataLoading(false);
    }

    useEffect(() => {
        if (draftSlug !== "") {
            fetchDraftData(true);
            //setForm('publish');

        }
    }, [draftSlug])

    useEffect(() => {
        setChangesMade(true)
    }, [imgUrl, imgCaption, imgHeight, imgWidth])


    useEffect(() => {
        if (form === "publish") {
            setWindowVisible(false);
        }
    }, [form])

    // useEffect(() => {
    //     if (generatedImage) {
    //         saveDraft('submit',false);
    //     }
    // }, [generatedImage])

    const updateCredibleSource = (event) => {
        console.log(event.target.id);
    }

    const updateRelevantLinks = (event) => {
        console.log(event.target.id);
    }

    const addItem = (type) => {
        setChangesMade(true);
        switch (type) {
            case 'source':
                // setCredibleSource([...credibleSource, {'data': ''}]);
                break;
            case 'links':
                setCredibleSource([...credibleSource, {'data': ''}]);
                setRelevantLinks([...relevantLinks, {'data': ''}]);
                setRelevantPublishers([...relevantPublishers, {'data': ''}]);
                setRelevantPublisherTypes([...relevantPublisherTypes, '']);
                setRelevantSiteNames([...relevantSiteNames, '']);
                setContentTypeSuggestion([...contentTypeSuggestion, '']);
                setSelectedContentType([...selectedContentType, '']);
                setPlatform([...platform, '']);
                setShowInfo([...showInfo, false]);
                break;
            case 'article':
                //setRelevantArticleLinks([...relevantArticleLinks, '']);
                break;
            default:
            // code block
        }
    }

    const removeItem = (type, index) => (event) => {
        //setShowInfo(false);
        setChangesMade(true);
        switch (type) {
            case 'source':
                //setCredibleSource(credibleSource.filter((e, i)=>(index !== i)));
                break;
            case 'links':
                setCredibleSource(credibleSource.filter((e, i) => (index !== i)));
                setRelevantLinks(relevantLinks.filter((e, i) => (index !== i)));
                setRelevantPublishers(relevantPublishers.filter((e, i) => (index !== i)));
                setRelevantPublisherTypes(relevantPublisherTypes.filter((e, i) => (index !== i)));
                setRelevantSiteNames(relevantSiteNames.filter((e, i) => (index !== i)));
                setContentTypeSuggestion(contentTypeSuggestion.filter((e, i) => (index !== i)));
                setSelectedContentType(selectedContentType.filter((e, i) => (index !== i)));
                setPlatform(platform.filter((e, i) => (index !== i)));
                setShowInfo(showInfo.filter((e, i) => (index !== i)));
            case 'article':
                //setRelevantArticleLinks(relevantArticleLinks.filter((e, i)=>(index !== i)));
                break;
            default:
            // code block
        }
    }

    const updatePlatform = (indx, data) => {
        setPlatform(
            platform.map((item, index) =>
                indx === index
                    ? data
                    : item
            ));
    }

    const updatePublisherType = (indx, data) => {
        setRelevantPublisherTypes(
            relevantPublisherTypes.map((item, index) =>
                indx === index
                    ? data
                    : item
            ));
    }

    const updateSiteName = (indx, data) => {
        setRelevantSiteNames(
            relevantSiteNames.map((item, index) =>
                indx === index
                    ? data
                    : item
            ));
    };

    const updateShowInfo = (indx, data) => {
        setShowInfo(
            showInfo.map((item, index) =>
                indx === index
                    ? data
                    : item
            ));
    }

    const updateItem = (type, indx, val = undefined) => (event) => {
        let data;
        setChangesMade(true);
        if (!val) {
            data = event.target.value;
        } else {
            data = val;
        }

        if (indx === -1) {
            switch (type) {
                case 'title':
                    setTitle(data);
                    setChangesMade(true);
                    break;
                case 'review':
                    setReview(data.replace('<br>', '<br/>'));
                    setChangesMade(true);
                    //setValue(data);
                    break;
                case 'article':
                    setRelevantArticleLink(data);
                    setChangesMade(true);
                    break;
                case 'archiveLink':
                    setArchiveLink(data);
                    setChangesMade(true);
                    break;

                default:
                // code block
            }
            return;
        }

        switch (type) {
            case 'source':
                setCredibleSource(
                    credibleSource.map((item, index) =>
                        indx === index
                            ? {...item, data: data}
                            : item
                    ));
                break;
            case 'links':
                setRelevantLinks(
                    relevantLinks.map((item, index) =>
                        indx === index
                            ? {...item, data: data}
                            : item
                    ));
                break;
            /*case 'sitename':
                setRelevantSiteNames(
                    relevantSiteNames.map((item, index) =>
                        indx === index
                        ? data
                        : item
                ));

            break;*/
            default:
            // code block
        }

    }

    const clearRelinks = (event) => {
        // removeItem('article', 0)
        setRelevantArticleLink('');
        setArticleTitle(null);
    }

    const updateSource = (data, indx) => {
        setCredibleSource(
            credibleSource.map((item, index) =>
                indx === index
                    ? {...item, data: data}
                    : item
            ));
        setChangesMade(true)
    }

    const publishersChange = (itm, indx) => {
        setRelevantPublishers(
            relevantPublishers.map((item, index) =>
                indx === index
                    ? itm
                    : item
            ));

    }

    const typesChange = (itm, indx) => {
        /* setRelevantTypes(
             relevantTypes.map((item, index) =>
                 indx === index
                 ? itm
                 : item
             ));*/
    }

    const updateContents = (itm, indx) => {
        setContentTypeSuggestion(
            contentTypeSuggestion.map((item, index) =>
                indx === index
                    ? itm
                    : item
            ));
        setChangesMade(true)
    }

    const contentChange = (itm, indx) => {
        setSelectedContentType(
            selectedContentType.map((item, index) =>
                indx === index
                    ? itm
                    : item
            ));
    }

    const onFactualityChange = ({target: {value}}) => {
        setFactuality(value);
        console.log(value);
        setChangesMade(true);
    }

    const removeCategory = (item) => {
        setCategoryArr(categoryArr.filter(tag => tag !== item));
    }

    const addCategory = (items) => {
        let newCategories = [...items];

        // items.forEach(item => {
        //     let indx = categoryArr.indexOf(item);
        //     if (indx !== -1) {
        //         alert(`${item} is a duplicate entry`);
        //     } else if (categoryList.indexOf(item) === -1) {
        //         alert(`Please select ${item} from the list`);
        //     } else {
        //         newCategories.push(item);
        //     }
        // });

        setCategoryArr(newCategories);
        console.log(newCategories)
    }


    const addTag = (items) => {
        // let indx = tagArr.indexOf(item);
        // if (indx !== -1) {
        //     alert('duplicate entry');
        // } else {
        //     setTagArr([...tagArr, item]);
        // }
        let newTags = [...items];

        // items.forEach(item => {
        //     let indx = categoryArr.indexOf(item);
        //     if (indx !== -1) {
        //         alert(`${item} is a duplicate entry`);
        //     } else if (categoryList.indexOf(item) === -1) {
        //         alert(`Please select ${item} from the list`);
        //     } else {
        //         newCategories.push(item);
        //     }
        // });

        setTagArr(newTags);


    };


    const removeTag = (item) => {
        setTagArr(tagArr.filter(tag => tag !== item));
    }

    const removeGeotag = (item) => {
        setGeotagArr(geotagArr.filter(tag => tag !== item));
    }

    const addGeotag = (item) => {
        let indx = geotagArr.indexOf(item);
        if (indx !== -1) {
            alert('duplicate entry');
        } else {
            setGeotagArr([...geotagArr, item]);
        }
    }

    const fetchGeo = async () => {
        let apiPath = Utils.getAPIDescription('geotag-source');
        try {
            // Load async data from an inexistent endpoint.
            let data = await axios.get(apiPath + 'all=true');
            if (data && data.data && data.data.body) {
                let gtag = data.data.body.map((item) => {
                    return item.key
                });
                setGeoList(gtag);
                //setFlagDetails(data.data.body);
                //setLoadingDetails(false);
            }
        } catch (e) {
            console.log(`Error+${e}`);
        }
    }

    const fetchTags = async () => {
        //setLoading(true);
        let apiPath = Utils.getAPIDescription('get-tag-new');
        let body = {
            "query": [title, review],
            "stage": process.env.REACT_APP_ENV !== 'PROD' ? 'dev' : 'prod',
            'version': 'pConsole'
        };
        try {
            let data = await axios.post(apiPath, body);

            if (data && data.data) {
                /* if(data.data.cat_subcat_ord) {
                     setCategoryList(Object.keys(data.data.cat_subcat_ord));
                     setCatSubcat(data.data.cat_subcat_ord);
                 }*/
                let resBody = data.data.body;

                if (resBody.cat_list) {
                    setCategoryList(resBody.cat_list)
                }

                // if(resBody.topics_ord) {
                //     let tags_list = [];
                //     for (let i=0; i<resBody.topics_ord.length; i++) {
                //         if (tags_list.indexOf(resBody.topics_ord[i][0]) === -1)
                //             tags_list.push(resBody.topics_ord[i][0])
                //     }
                //     setTagList(tags_list);
                // }

                if (resBody.tags) {
                    setTagList(resBody.tags)
                }

                if (resBody.geo_tag) {
                    let geoArr = [];
                    let geoTag = '';
                    if (resBody.geo_tag !== '') {
                        geoTag = resBody.geo_tag;
                        geoArr.push(geoTag);
                    }
                    setGeotagArr(geoArr);
                }

                // if(resBody.sub_category){
                //     let arr = resBody.sub_category;
                //     setSubcategoryList(arr);
                //     //getTagImages(arr);
                //     //setTagArr(tagArr => tagArr.concat(arr));
                // }

                if (resBody.tags) {
                    if (tagArr.length === 0) {
                        setTagList(resBody.tags);
                        // console.log(tagList)
                    }
                }

                // if (data.data.sub_category) {
                //     let arr = data.data.sub_category;
                //     let geoTag = '';
                //     if (data.data.geo_tag !== '') {
                //         geoTag = data.data.geo_tag;
                //         arr.push(geoTag);
                //     }
                //     if (tagArr.length === 0) {
                //         setTagArr(arr);
                //     }
                //     //getTagImages(arr);
                //     //setTagArr(tagArr => tagArr.concat(arr));
                // }
                //setLoading(false);

            }
        } catch (e) {
            console.log(`Error+${e}`);
            //setRelatedListLoader(false);
        }
    }

    const orgName = localStorage.getItem('org_name');

    const imageDictionary = {
        'Wisconsin Watch': {
            id: 'wisconsinwatch',
            image_url: 'https://gigafact-app-images.s3.us-east-2.amazonaws.com/fact-briefs-metaimages/Wisconsin_Watch_SocialCard.jpg',
        },
        'The Nevada Independent': {
            id: 'nevadaindependent',
            image_url: 'https://gigafact-app-images.s3.us-east-2.amazonaws.com/fact-briefs-metaimages/Nevada_Independent_SocialCard.jpg',
        },
        'MinnPost': {
            id: 'minnpost',
            image_url: 'https://gigafact-app-images.s3.us-east-2.amazonaws.com/fact-briefs-metaimages/MinnPost_SocialCard.jpg',
        },
        'Arizona Center for Investigative Reporting': {
            id: 'arizonacir',
            image_url: 'https://gigafact-app-images.s3.us-east-2.amazonaws.com/fact-briefs-metaimages/Arizona_CIR_SocialCard.jpg',
        },
        'Fort Worth Report' :{
            id: 'fort-worth-report',
            image_url: 'https://gigafact-app-images.s3.us-east-2.amazonaws.com/fact-briefs-metaimages/Fortworthreport_SocialCard.png',
        },
        'Oklahoma Watch' :{
            id: 'oklahoma-watch',
            image_url: 'https://gigafact-app-images.s3.us-east-2.amazonaws.com/fact-briefs-metaimages/Oklahomawatch_SocialCard.png',
        }
    };


    const getTagImages = async (tgArr) => {
        setImgLoader(true);
        let apiPath = Utils.getAPIDescription('suggest-images');
        let body = {"categories": categoryArr};
        try {
            let data = await axios.post(apiPath, body);
            console.log(data)
            if (data && data.data) {
                let lst = data.data.suggested_images;
                let fi = data.data.fallback_images;
                const orgNameItem = imageDictionary[orgName]
                // console.log(lst)
                if(orgNameItem){
                    lst = [orgNameItem, ...lst];
                    fi = [orgNameItem, ...fi];
                }
                // console.log(lst)
                setTagImages(lst)
                setFallbackImages(fi);
                if (lst.length > 0) {
                    setSelectedTagImage(lst[0]['image_url']);
                }
            }
        } catch (e) {
            console.log(`Error+${e}`);
            //setRelatedListLoader(false);
        }
        setImgLoader(false);
    }

    const suggestTagImages = async (query) => {
        setSuggestionLoader(true);
        let apiPath = Utils.getAPIDescription('image-search') + '?acQuery=' + query;
        try {
            let data = await axios.get(apiPath);
            if (data && data.data) {
                let ls = data.data.word_list;
                setImgNames(ls);
                ls.length > 0 && setKillDropDown(false);
            }
        } catch (e) {
            console.log(`Error+${e}`);
        }
        setSuggestionLoader(false);
    }

    const searchTagImages = async (query) => {
        setImgLoader(true);
        let apiPath = Utils.getAPIDescription('image-search');
        let body = {"query": query};
        try {
            let data = await axios.post(apiPath, body);
            if (data && data.data) {
                setFallbackImages(data.data.fallback_images);
                setTagImages(data.data.suggested_images);
            }
        } catch (e) {
            console.log(`Error+${e}`);
            //setRelatedListLoader(false);
        }
        setImgLoader(false);
    };

    // Helper function to validate the archiveLink
    const isValidArchiveLink = (link) => {
        // const pattern = /^https:\/\/(archive\.today|archive\.ph|archive\.org|archive\.is|web\.archive\.org|internetarchive\.org)\/.*/;
        // return pattern.test(link);
        return true;
    };

    // Helper function to remove HTML tags and count words
    const countWords = (text) => {
        return text.replace(/<[^>]*>/g, " ") // Remove HTML tags
            .trim() // Trim whitespace
            .split(' ') // Split by spaces to get words
            .filter(char => /[a-zA-Z]/.test(char)) // Filter out non-alphabetical characters
            .length; // Get the number of words
    };

    const validateForm = async (type = '') => {

        // Check if any of the required fields are empty or invalid
        const isTitleEmpty = !title;
        const isFactualityEmpty = !factuality;
        const isReviewEmpty = !review;
        const isCategoryEmpty = categoryArr.length <= 0;
        const isTagEmpty = tagArr.length <= 0;
        const isCredibilityInvalid = !credible;
        const isRelevanceInvalid = !relevant;
        const isSaveDraftDisabled = disableSaveDraft;
        const isArchiveLinkInvalid = !isValidArchiveLink(archiveLink);

        // Check for word limit exceedance
        const reviewWordCountExceeded = countWords(review) > WORD_LIMIT;
        const imgCaptionWordCountExceeded = countWords(imgCaption) > WORD_LIMIT;

        // If any condition is true, set validation flag and return
        if (isTitleEmpty || isFactualityEmpty || isReviewEmpty || isCategoryEmpty || isTagEmpty || isCredibilityInvalid || isRelevanceInvalid || isSaveDraftDisabled || reviewWordCountExceeded || imgCaptionWordCountExceeded || isArchiveLinkInvalid) {
            setValidate(true);
            return;
        }

        setValidate(false);
        // if (type !== "preview") {
        // fetchTags();
        // }
        // if (selectedTagImage !== '' && process.env.REACT_APP_ENV !== 'PROD') {
        //     generateImage();
        // }
        // else {

        if (Utils.checkIfOrgCanSelfPublish() && type !== 'preview')
            saveDraft('', false);
        else
            saveDraft(type, false);
        // }
    }

    // window.addEventListener('beforeunload', (event) => {
    //     event.preventDefault();
    //     if (DraftNotSaved===true){
    //         return (event.returnValue = 'Are you sure you want to close? Please note that your changes may not be saved. Kindly, save your changes using the save draft button.');
    //     }
    // });


    const generateImage = () => {
        setLoading(true);
        let api = "https://api.repustar.com/dev/v3/app/generate-image";
        let body = {
            "slug": slug,
            "url": selectedTagImage,
            "question": title,
            "sources": "Sources: " + sources.map((item) => item.sitename !== "" ? item.sitename : item.source_url).join(", ")
        }
        // console.log(body)
        axios.post(api, body).then((res) => {
            if (res.data.statusCode === 200) {
                setGeneratedImage(res.data.image_url);
            } else {
                notification.error({message: "Something went wrong!"})
                setLoading(false);
            }
        }).catch(err => {
            console.log(err);
            notification.error({message: "Something went wrong!"})
            setLoading(false);
        })
    }

    const modifyAnalysis = (anls) => {
        anls?.includes('</br>') && (anls = anls.replace('</br>', '<br/>'));
        anls?.includes('<br/>') && (anls = anls.replace(/\<br\/\>/g, ""));
        anls?.includes('<br><p>') && (anls = anls.replace('<br><p>', '<p>'))
        anls?.includes('<br><br>') && (anls = anls.replace('<br><br>', '<br>'))
        anls?.includes('<ins>') && (anls = anls.replace('<ins>', '<u>'));
        anls?.includes('</ins>') && (anls = anls.replace('</ins>', '</u>'));
        anls?.includes('<del>') && (anls = anls.replace('<del>', '<s>'));
        anls?.includes('</del>') && (anls = anls.replace('</del>', '</s>'));

        return anls
    }

    const modifyBackAnalysis = (anls) => {
        anls?.includes('</br>') && (anls = anls.replace('</br>', ''));
        anls?.includes('<br>') && (anls = anls.replace('<br>', '<br/>'));
        anls?.includes('<ins>') && (anls = anls.replace('<ins>', '<u>'));
        anls?.includes('</ins>') && (anls = anls.replace('</ins>', '</u>'));
        anls?.includes('<del>') && (anls = anls.replace('<del>', '<s>'));
        anls?.includes('</del>') && (anls = anls.replace('</del>', '</s>'));

        return anls
    }

    const saveDraft = async (type, autosave) => {
        let apiPath = Utils.getAPIDescription('save-draft-wrapper');
        setLoading(true);
        if (autosave) {
            apiPath = Utils.getAPIDescription('save-draft-wrapper') + "?autosave=" + autosave;
        }

        let obj = {};
        let anls = review;
        anls = modifyAnalysis(anls);

        let specialTags = [];

        if (specialTagAdded) {
            specialTags.push('Election integrity');
        }
        const authorNameParts = authorName.split(' (');
        const extractedName = authorNameParts[0].trim();
        
        let body = {
            "flagId": (flagId ? flagId : flagIdDraft),
            "user_id": localStorage.getItem('user_id'),
            "analysis": `${anls}`,
            "category": categoryArr,
            "flagUrl": (flagId ? flagId : flagIdDraft),
            "relevant_article": [relevantArticleLink],
            "organization_id": parseInt(localStorage.getItem('org_id')),
            "region": geotagArr,
            "score": `${factuality}`,
            "page_id": pageId,
            "sources": sources,
            "subCategory": tagArr,
            "specialTags": specialTags,
            "title": `${title}`,
            "flagArchivedUrl": archiveLink,
            "author_name": `${authorName === 'Select' ? '' : extractedName}`,
            "reviewImageUrl": `${selectedTagImage}`,
            "content_image": {
                "content_image_url": imgUrl,
                "content_image_height": imgHeight,
                "content_image_width": imgWidth,
                "content_image_caption": imgCaption
            }
        };
        if (!imgUrl) {
            body['content_image'] = {
                "content_image_url": "",
                "content_image_caption": "",
                "content_image_height": "",
                "content_image_width": ""
            }
        }
        if (slug) {
            body['page_slug'] = `${slug}`
        }
        try {
            let data = await axios.post(apiPath, body);
            if (data && data.data && data.data.message === 'Success') {
                let dr = {}
                let pSlug = ""
                let page_id_draft = ""
                if (autosave === false) {
                    setDraftNotSaved(false)
                    dr = data.data.body.draft_response.upsertDraft
                    pSlug = dr.page.parentPage?.slug
                    page_id_draft = dr.page.pageId
                    setParentPageSlug(pSlug);
                    setPageId(page_id_draft);
                    let slg = dr.page.slug;
                    setSlug(slg);

                    if (type !== 'submit') {
                        setLoading(false);
                        setChangesMade(false);
                        notification.success({
                            message: "Draft saved successfully",
                            placement: "bottomRight",
                        })
                    }
                    if (type === 'preview' && Utils.checkIfOrgCanSelfPublish()) {
                        console.log("here")
                        history.push('/partnerconsole/fact-brief/' + dr.page.slug + '?draft=true&parentPage=' + pSlug + "?pageId=" + dr.page.pageId)
                    }
                    if(type === 'preview' && !Utils.checkIfOrgCanSelfPublish()){
                        const url = '/partnerconsole/fact-brief/' + dr.page.slug + '?draft=true&parentPage=' + pSlug + "?pageId=" + dr.page.pageId;
                        window.open(url, '_blank');
                    }
                    if (type === 'submit') {
                        submitForReview(dr.page.pageId);
                    }
                } else {
                    setLoading(false);
                    setChangesMade(false);
                    notification.success({
                        message: "Draft saved successfully",
                        placement: "bottomRight",
                    })
                }
                if (type === 'preview' && Utils.checkIfOrgCanSelfPublish()) {
                    // Utils.openNewTab(Utils.getBaseURL() + '/fact-briefs/' + slg + '|draft');
                    // window.open('/partnerconsole/fact-brief/' + slg + '?draft=true&parentPage=' + pSlug);
                    history.push('/partnerconsole/fact-brief/' + dr.page.slug + '?draft=true&parentPage=' + pSlug + "?pageId=" + dr.page.pageId)
                }
                if (type === 'submit') {
                    submitForReview(dr.page.pageId);
                }

            }

        } catch (e) {
            console.log(`Error+${e}`);
            alert("something went wrong white submitting draft");
            setDisableSaveDraft(true);
            setLoading(false);

        } finally {
            if (windowVisible)
                setWindowVisible(false);
        }
    }
    const submitForReview = async (pId) => {
        setLoading(true);
        let curPageId = pageId ? pageId : pId;
        let apiPath = Utils.getAPIDescription('page-submit');
        let body = {"pageId": `${curPageId}`}
        try {
            let data = await axios.post(apiPath, body);
            if (data && data.data && data.data.message === 'Success') {
                setChangesMade(false);
                //setPageId(data.data.body.draft_response.upsertDraft.page.id);
                if(form !== 'save'){
                    history.push('/partnerconsole/fact-briefs/');
                }
                }
        } catch (e) {
            console.log(`Error+${e}`);
            //setRelatedListLoader(false);
        }
    };

    // const saveDraftForSelfPublish = async () => {
    //     setLoading(true);
    //     // Fetech tags and open tag addition modal. Save draft is within the Modal action button
    //     fetchTags().then(() => {
    //         setWindowVisible(true);
    //         setLoading(false);
    //     })
    // };

    const DeleteReview = async (pId) => {
        let curPageId = pageId ? pageId : pId;
        let apiPath = Utils.getAPIDescription('delete-review');
        let body = {"pageId": `${curPageId}`}
        try {
            let data = await axios.post(apiPath, body);
            if (data && data.data && data.data.message === 'Success') {
                history.push('/partnerconsole/fact-briefs/');
            }
        } catch (e) {
            console.log(`Error+${e}`);
            //setRelatedListLoader(false);
        }
    }

    const fetchFormData = async () => {
        let apiPath = Utils.getAPIDescription('form-data');
        try {
            let data = await axios.get(apiPath);
            if (data && data.data) {
                //setPublishers(data.data.publishers.publishers);
                //setTypes(data.data.sourceDataType.sourceDataType);
                //contentChange(data.data.sourceDataType.sourceDataType[0]['sourceType'], 0);
                //setContentIndex(0);
            }
        } catch (e) {
            console.log(`Error+${e}`);
        }
    }

    const onClose = () => {
        setDrawerVisible(false);
    };

    const dragStart = (event) => {
        setStartIndex(parseInt(event.currentTarget.id.split('_')[1]));
    }

    const dragging = (event) => {

    }


    const drop = (event) => {
        //We are tracking all states while drag drop happens to retain to saved info
        event.preventDefault();
        let linksArr = swap(relevantLinks, startIndex, parseInt(event.currentTarget.id.split('_')[1]));
        let sourceArr = swap(credibleSource, startIndex, parseInt(event.currentTarget.id.split('_')[1]));
        let relPubArr = swap(relevantPublishers, startIndex, parseInt(event.currentTarget.id.split('_')[1]));
        let relPubTypArr = swap(relevantPublisherTypes, startIndex, parseInt(event.currentTarget.id.split('_')[1]));
        let relSiteArr = swap(relevantSiteNames, startIndex, parseInt(event.currentTarget.id.split('_')[1]));
        let sugContentArr = swap(contentTypeSuggestion, startIndex, parseInt(event.currentTarget.id.split('_')[1]));
        let selContentArr = swap(selectedContentType, startIndex, parseInt(event.currentTarget.id.split('_')[1]));
        let platformArr = swap(platform, startIndex, parseInt(event.currentTarget.id.split('_')[1]));


        setRelevantLinks(linksArr);
        setCredibleSource(sourceArr);
        setRelevantPublishers(relPubArr);
        setRelevantPublisherTypes(relPubTypArr);
        setRelevantSiteNames(relSiteArr);
        setContentTypeSuggestion(sugContentArr);
        setSelectedContentType(selContentArr);
        setPlatform(platformArr);
        setChangesMade(true);
    }

    const allowDrop = (event) => {
        event.preventDefault();
    }

    const swap = (arr, a, b) => {
        return arr.map((current, idx) => {
            if (idx === a) return arr[b]
            if (idx === b) return arr[a]
            return current
        });
    }

    const onReminderClick = (e) => {
        let date = new Date();
        cookie.set('remind', e.target.checked, {'expires': new Date(date.setMonth(date.getMonth() + 8))});
    }

    const updateReview = (data) => {
        //debugger;
        setReview(data);
        setChangesMade(true)
    }

    const onAuthorChange = (itm) => {
        setAuthorName(itm.trim());
        setChangesMade(true)
    }

    const onSelect = (item) => {
        console.log(item)
        setSelectedTagImage(item.image_url);
    }

    const onInputChange = (e) => {
        let query = e.target.value;
        if (query !== '') {
            setKillDropDown(false);
            suggestTagImages(query);
        } else {
            setKillDropDown(true);
            setImgNames([]);
        }
        setSearchQuery(query);


    };

    const handleGoBack = () => {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.push('/');
        }
    };

    const onEnterKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchQuery.length > 0 && searchTagImages(searchQuery);
            setKillDropDown(true);
        }
    };


    const getSpecialTagButton = () => {
        if (!specialTagAdded) {
            return <span className="special-tag-button-unchecked">{<PlusOutlined/>}Election integrity</span>
        } else {
            return <span className="special-tag-button-checked">{<CheckOutlined/>}Election integrity</span>
        }
    };

    return (
        <div className="app-workspace">
            <OrgBanner orgdetails={orgDetails}/>
            <div className="app-content">
                <div className='app-dashboard-creview pull-left'>
                    <div className='create-review colw-60 pull-left'>
                        <button style={{marginLeft: "30px"}} className='blue-border-btn mb-4 fn-14' onClick={handleGoBack}>BACK</button>
                        <br/>

                        <div style={{display: "flex", alignItems: "center", minHeight: "40px"}}>
                            <span className='publish-title'>Publish a Fact Brief</span>{form === "publish" && (draftSlug || pageId) && !isReturned && !draftDataLoading &&
                            <span className='draft-box'>Draft</span>}
                            {form === "publish" && isReturned && !draftDataLoading && <span className='ret-box'>Returned</span>}

                            {(!form || draftDataLoading) &&
                            <div className='blur-overlay'>
                                <img src={Loading} alt={"Loader"}/>
                            </div>
                            }
                        </div>


                        {form === "notice" &&
                            <div style={{marginLeft: '20px'}}>
                                <div className="header">Before you start writing a Fact Brief:</div>
                                <div className="content colw-60">
                                    By using the Gigafact trademarks and the resources of this site, you agree to follow the
                                    Gigafact <span><a>Trademark Guidelines</a></span> in our Brand Guidelines - as well as
                                    our <span><a>Terms of Service</a></span> and all other <span><a>Gigafact Rules and Policies</a></span>.
                                    If you have any questions, contact us at info@gigafact.org
                                </div>
                                <div className="btn-group">
                                    <div className="btn-default" onClick={() => {
                                        setForm('publish');
                                    }} style={{marginLeft: '0px'}}>I agree
                                    </div>
                                    <div className="ml-5 flex-column justify-content-center d-flex"><Checkbox
                                        onChange={onReminderClick}>Don't remind me again</Checkbox></div>
                                </div>
                            </div>
                        }
                        {
                            form === "publish" &&
                            <>
                                <div className="colw-100 mt-5">
                                    <ol>
                                        <li>
                                            <div className="li-div">
                                                <label>Enter the link to the claim that you want displayed on your fact brief. If possible, archive the source using an archiving service like <a href={"https://archive.today/"} target={"_blank"} rel={"nofollow noopener noreferrer"}>archive.today</a>, or <a href={"https://archive.org/"} target={"_blank"} rel={"nofollow noopener noreferrer"}>internetarchive.org</a> to ensure your link will always be available.</label><br/>
                                                <textarea className="colw-100"
                                                          style={{height: "50px", padding: "14px 10px"}} placeholder=""
                                                          onChange={updateItem('archiveLink', -1)} value={archiveLink}/>

                                                {validate && <>{!archiveLink && <div className="err-msg">Please enter the archived flag link</div>}</>}
                                                {validate && <>{(archiveLink && !isValidArchiveLink(archiveLink)) && <div className="err-msg">Please enter a valid link</div>}</>}
                                                {/* {isReturned && <div className='fn-14 font-weight-normal'><span className='err-msg'>Editorial Comment: </span>{titleComment}</div> } */}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="li-div">
                                                <label>Question (What is the question your fact brief answers?)</label><br/>
                                                <textarea className="colw-100"
                                                          style={{height: "50px", padding: "14px 10px"}} placeholder=""
                                                          onChange={updateItem('title', -1)} value={title}/>

                                                {validate && <>{!title &&
                                                <div className="err-msg">Enter a Question</div>}</>}
                                                {/* {isReturned && <div className='fn-14 font-weight-normal'><span className='err-msg'>Editorial Comment: </span>{titleComment}</div> } */}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="li-div">
                                                <label>Answer</label><br/>
                                                {/*<input type="radio" id="yes" name="factuality" value="Yes" onChange={event => onFactualityChange(event)} checked={factuality && factuality.toLowerCase() === "yes"} />*/}
                                                {/*<label htmlFor="yes">Yes</label>*/}
                                                {/*<input type="radio" id="no" name="factuality" value="No" onChange={event => onFactualityChange(event)} checked={factuality && factuality.toLowerCase() === "no"} />*/}
                                                {/*<label htmlFor="no">No</label><br />*/}
                                                <Radio.Group options={[{'label': 'Yes', value: 'YES'}, {
                                                    'label': 'No',
                                                    value: 'NO'
                                                }]} onChange={onFactualityChange} value={factuality} optionType="button"
                                                             buttonStyle="solid"/>
                                                {validate && <>{!factuality &&
                                                <div className="err-msg">Select an Answer</div>}</>}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="li-div">
                                                <label className="your-review">Summary <span
                                                    className={review.replace(/<[^>]*>/g, " ").trim().split(' ').filter(char => /[a-zA-Z0-9]/.test(char)).length > WORD_LIMIT ? "err-msg" : ""}>{`(Total words: ${review !== "" ? review.replace(/<[^>]*>/g, " ").trim().split(' ').filter(char => /[a-zA-Z0-9]/.test(char)).length : 0}. Can not exceed ${WORD_LIMIT} words.)`}</span></label><br/>
                                                {/*<textarea onChange={updateItem('review', -1)} value={review}/>*/}
                                                <RichTextEditor review={draftReview} updatereview={updateReview}/>
                                                {validate &&
                                                <>
                                                    <span>{!review &&
                                                    <div className="err-msg">Enter Summary</div>}</span>
                                                    <span>{review.replace(/<[^>]*>/g, " ").trim().split(' ').filter((char) => /[a-zA-Z0-9]/.test(char)).length > WORD_LIMIT &&
                                                    <div className="err-msg">Summary can't
                                                        exceed {WORD_LIMIT} words.</div>}</span>
                                                </>}
                                                {/* {review.trim().split(' ').length > 100 && <div className='fn-14 font-weight-normal'><span className='err-msg'>It's {review.trim().split(' ').length} words, cannot exceed 100 words.</span></div> } */}
                                            </div>
                                        </li>
                                        {/* <li>
                                            <div className="li-div">
                                                <label>Related Graphic (Optional) </label><br />

                                                <ImageControl
                                                    imgUrl={imgUrl}
                                                    imgCaption={imgCaption}
                                                    imgheight={imgHeight}
                                                    imgWidth={imgWidth}
                                                    setImgCaption={setImgCaption}
                                                    setImgUrl={setImgUrl}
                                                    setImgHeight={setImgHeight}
                                                    setImgWidth={setImgWidth}
                                                />
                                            </div>
                                        </li> */}
                                        <li>
                                            <div className="li-div">
                                                <label>Sources (Tip: Reorder your sources by clicking the dots on the
                                                    left and dragging the sources to the order you prefer)</label>

                                                <div className="input-div">
                                                    {React.Children.toArray(relevantLinks?.map((item, indx) => (
                                                        <div className="add-new-div colw-100" id={'source_' + indx}
                                                             key={'key' + indx} draggable={isDrag}
                                                             onDragStart={dragStart} onDrag={dragging} onDrop={drop}
                                                             onDragOver={allowDrop}>
                                                            <SourceControl
                                                                index={indx}
                                                                updatecontents={updateContents}
                                                                updatesource={updateSource}
                                                                updateitem={updateItem}
                                                                value={item.data}
                                                                linksource={relevantLinks}
                                                                relevantpublishertypes={relevantPublisherTypes}
                                                                titlesource={credibleSource}
                                                                platform={platform}
                                                                removeitem={removeItem}
                                                                publishers={publishers}
                                                                types={types.length > 0 && types}
                                                                publisherschange={publishersChange}
                                                                updatepublishertype={updatePublisherType}
                                                                updateplatform={updatePlatform}
                                                                typeschange={typesChange}
                                                                contentchange={contentChange}
                                                                updatesitename={updateSiteName}
                                                                updateshowinfo={updateShowInfo}
                                                                sitenames={relevantSiteNames}
                                                                setIsSourceControlScrapping={setIsSourceControlScrapping}
                                                                showinfo={showInfo}
                                                                selectedcontenttype={selectedContentType}
                                                                contenttypesuggestion={contentTypeSuggestion}
                                                                contentindex={contentIndex}/>
                                                        </div>

                                                    )))}

                                                    {validate && <>{!credible &&
                                                    <div className="err-msg">Add at least one Source</div>}</>}
                                                    {/* {isReturned && <div className='fn-14 font-weight-normal'><span className='err-msg'>Editorial Comment: </span>{sourceComment}</div> } */}
                                                    {(relevantLinks.length > 0 && relevantLinks[(relevantLinks.length - 1)]['data'] !== '' && !isSourceControlScrapping) && isDrag &&
                                                    <div className="add-new pull-left" onClick={() => {
                                                        addItem('links')
                                                    }}>Add Another Source</div>
                                                    }
                                                </div>

                                            </div>
                                        </li>
                                        {
                                            authors && authors.length > 0 &&
                                            <li>
                                                <div className="li-div">
                                                    <label>Select an author, if the author is someone other than you. (Optional)</label>
                                                    <div className="input-div solid-bg">
                                                        <Dropdown datasource={authors} id="id" value="value"
                                                                  itemindex={authorIndex} onchange={onAuthorChange}/>
                                                    </div>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="li-div">
                                                <label>Link to a related long form article from your newsroom. (Optional)</label> {relevantArticleLoader ?
                                                <img className='ml-2' src={Loading} width='20px'/> : null}
                                                <div className="input-div">
                                                    <div className="input-div">
                                                        <div className="add-new-div relevant-article colw-100">
                                                            <input type="text" className="pull-left"
                                                                   placeholder="Paste link here"
                                                                   onChange={updateItem('article', -1)}
                                                                   onKeyDown={relatedArticleKeyDown}
                                                                   onPaste={pasteRelatedURL('article', -1)}
                                                                   value={relevantArticleLink}/>
                                                        </div>
                                                    </div>
                                                    {articleTitle &&
                                                    <div className='ef-bg mt-2 cursor-pointer'>
                                                        <div className="relative-position">
                                                            <a href={relevantArticleLink}
                                                               className="default-link"
                                                               style={{textDecoration: "none"}} target='_blank'>
                                                                <div className='source-article'>
                                                                    <div className='left-img'><img
                                                                        src={articleImg}
                                                                        style={{width: '100%'}}/></div>
                                                                    <div className='right-sor'>
                                                                        <div
                                                                            className='title-con ml-3'>{articleTitle}</div>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <span className="clr-btn-relinks"
                                                                  onClick={clearRelinks}> <img
                                                                src="/static/media/cross.151d67a3.svg"
                                                                width="14px"/> </span>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='li-div'>
                                                <label>Select your fact brief’s topic
                                                    categories {validate && <>{categoryArr.length === 0 && <span
                                                        style={{color: 'red'}}>(Please select at least one category)</span>}</>}</label>
                                                {/*<SelectBox datasource={categoryList} placeholder='Add Category' onchange={addCategory}/>*/}
                                                <SelectDropdown datasource={categoryList} selected={categoryArr}
                                                                placeholder='Add Category' onChange={addCategory}
                                                                mode={"multiple"}/>
                                            </div>
                                            {/*<div className="no-tag-msg" style={{margin:'20px',display:'flex',flexWrap:'wrap'}}>*/}
                                            {/*    {categoryArr.length > 0 && React.Children.toArray(categoryArr.map((item, indx) => (*/}
                                            {/*        <div className="tag-box" key={'ptg_' + indx}>*/}
                                            {/*            <Tag key={'sctg_' + indx} tagLabel={item} removeTag={() => {*/}
                                            {/*                removeCategory(item)*/}
                                            {/*            }} />*/}
                                            {/*        </div>*/}
                                            {/*    )))}*/}
                                            {/*</div>*/}
                                            {/*{validate ? <>{categoryArr.length===0 && <div className="no-tag-msg" style={{color:'red'}}>Please select atleast one category</div>}</>:*/}
                                            {/*    categoryArr.length === 0 && <div className="no-tag-msg"><span>No Category Added.</span></div>*/}
                                            {/*}*/}
                                        </li>
                                        <li>
                                            <div className='li-div'>

                                                <label>Add some tags to help users discover your fact brief. Tags can be
                                                    topics, names or key words relating to the claim. {validate && <>{tagArr.length === 0 && <span
                                                        style={{color: 'red'}}>(Please provide at least one tag)</span>}</>}</label>
                                                {/*<SelectBox datasource={tagList} placeholder='Add Tags' onchange={addTag} />*/}
                                                <SelectDropdown datasource={tagList} selected={tagArr}
                                                                placeholder='Add Tags' onChange={addTag} mode={"tags"}/>

                                            </div>
                                            {/*<div className="no-tag-msg" style={{margin:'20px',display:'flex',flexWrap:'wrap'}}>*/}
                                            {/*    {tagArr.length > 0 && React.Children.toArray(tagArr.map((item, indx) => (*/}
                                            {/*        <div className="tag-box" key={'ptg_' + indx}>*/}
                                            {/*            <Tag key={'sctg_' + indx} tagLabel={item} removeTag={() => {*/}
                                            {/*                removeTag(item)*/}
                                            {/*            }} />*/}
                                            {/*        </div>*/}
                                            {/*    )))}*/}
                                            {/*</div>*/}
                                            {/*{*/}
                                            {/*    tagArr.length === 0 && <div className="no-tag-msg"><span>No Tags Added.</span></div>*/}
                                            {/*}*/}
                                        </li>
                                        <li>
                                            <div className='li-div'>
                                                <label>Choose a featured image for your fact brief. (You can choose your organization’s logo, or an illustration that pertains to your brief.)</label>

                                                <div className="rep-bg">
                                                    {/*<div className="window-ruler"/>*/}
                                                    <div className="w-box">
                                                        <div className="tag-images">
                                                            <div className="search-img-header"/>
                                                            <div className="tile-container">
                                                                {tagImages.length > 1 ? (
                                                                    <Tilelist
                                                                        datasource={tagImages}
                                                                        data='image_url'
                                                                        selectedindex={
                                                                            selectedTagImage
                                                                                ? tagImages.find(item => item.image_url === selectedTagImage)?.id
                                                                                : tagImages[0].id
                                                                        }
                                                                        onselect={onSelect}
                                                                        id='id'
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <Tilelist
                                                                            datasource={fallbackImages}
                                                                            data='image_url'
                                                                            selectedindex={fallbackImages[0]?.id}
                                                                            id='id'
                                                                            onselect={onSelect}
                                                                        />
                                                                        {fallbackImages.length === 1 && (
                                                                            <div className="no-data-msg" style={{
                                                                                position: 'absolute',
                                                                                top: '100px'
                                                                            }}>
                                                                                {!imgLoader && (
                                                                                    <span>A relevant image was not available. This general image will be submitted.</span>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                                {imgLoader && (
                                                                    <div className="loading-screen">
                                                                        <img height={"50px"} alt="Loading"
                                                                             src={Loading}/>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </li>
                                    </ol>

                                    <Divider style={{borderTop: "1.5px solid rgba(0, 0, 0, 0.09)", marginLeft: "40px", minWidth: "97%", width: "97%"}}/>

                                    <div className="btn-group pull-right">
                                            {pageId &&
                                            <div className="btn-link">
                                                <span className="cursor-pointer pull-left red-font" onClick={() => {
                                                    setshowDeleteModal(true)
                                                }}>Delete</span>
                                            </div>
                                            }
                                            
                                            {!Utils.checkIfOrgCanSelfPublish() &&
                                            <>


                                            <div className="btn-link">
                                                <span onClick={() => validateForm('preview')}>Preview</span>
                                            </div>

                                            {/*<div className={'btn-default ' + (changesMade ? 'gray-bg' : 'ef-bg')} onClick={() => { validateForm() }} disabled={!changesMade}>Save Draft</div>*/}

                                            <div className={'btn-default gray-bg'} onClick={() => {
                                                validateForm('savedraft')
                                            }}>Save Draft
                                            </div>

                                            {isReturned ?
                                                <div className="btn-default blue-bg" onClick={() => {
                                                    validateForm('submit')
                                                }}>Re-Submit for Approval</div> :
                                                <div className="btn-default blue-bg" onClick={() => {
                                                    validateForm('submit')
                                                }}>Submit for Approval</div>
                                            }
                                            {loading &&
                                            <div className='loading-center'>
                                                <div>
                                                    <img src={Loading} height='30px'/>
                                                </div>
                                            </div>
                                            }
                                        </>
                                        }

                                        {Utils.checkIfOrgCanSelfPublish() &&
                                        <>
                                            {Utils.checkContentPartnerEditor() &&
                                            <>
                                                <div className={'btn-default gray-bg'} onClick={() => {
                                                    validateForm('submit')
                                                }}>Save Draft
                                                </div>
                                                <div className="btn-default blue-bg" onClick={() => {
                                                    validateForm('preview')
                                                }}>Preview and Publish
                                                </div>
                                            </>
                                            }
                                            {Utils.checkContentPartnerWriter() &&
                                            <div className="btn-default blue-bg" onClick={() => {
                                                validateForm('submit')
                                            }}>Save Draft</div>}

                                            {loading &&
                                            <div className='loading-center'>
                                                <div>
                                                    <img src={Loading} height='30px'/>
                                                </div>
                                            </div>
                                            }
                                        </>}

                                    </div>

                                </div>

                            </>
                        }

                    </div>
                    {form === 'publish' &&
                    <>
                        <div className='colw-30 pull-right text-left ml-3 associat-flag cursor-pointer'>
                            {tipDetails && <div className='flag-det-board' onClick={() => setDrawerVisible(true)}>
                                {/*<div className='fn-12 gray-font'>ASSOCIATED FLAG</div>*/}
                                <div className='encon'>ASSOCIATED FLAG</div>
                                {
                                    tipDetails.__typename === 'twitter_flag' ?
                                        <div className='px-3'>
                                            <Tweet className='flag-tweet'
                                                   tweetId={tipDetails.campaignDetails.articleLink.split('status/')[1]}/>
                                        </div>
                                        :
                                        <>
                                            <div className='source'>
                                                <div className='left-img'>
                                                    <img src={tipDetails.previewImage}
                                                         style={{width: '100%', padding: "20px 0"}}
                                                         alt={"Flag image"}/>
                                                </div>
                                                <div className='right-sor'>
                                                    <div className='broadcaster'>
                                                        {tipDetails.campaignDetails.broadcasterName === 'Direct from source' ?
                                                            tipDetails.campaignDetails.contentSource :
                                                            tipDetails.campaignDetails.broadcasterName !== "N.A" ?
                                                                tipDetails.campaignDetails.broadcasterName :
                                                                tipDetails.campaignDetails.articleLink ?
                                                                    Utils.extractDomain(tipDetails.campaignDetails.articleLink) :
                                                                    ""
                                                        }
                                                    </div>
                                                    <div
                                                        className='title-con' style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                                        <a href={`${tipDetails.campaignDetails?.articleLink}`} target={"_blank"} rel={"noopener noreferrer"}>{tipDetails.campaignDetails?.articleLink}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                }
                                <div className="link-btn blue-font text-right" style={{'fontSize': '13px'}}
                                     onClick={() => setDrawerVisible(true)}>View Details
                                </div>
                            </div>}
                        </div>
                        <div className='colw-30 pull-right text-left ml-3 associat-flag cursor-pointer'>
                            {isReturned && <div className='edt-comment'>
                                <div className='err-msg encon'
                                     style={{'fontSize': '12px', 'paddingTop': '10px', 'paddingBottom': '5px'}}>EDITOR'S
                                    COMMENTS
                                </div>
                                <div style={{'fontSize': '13px'}}>{modComment}</div>
                            </div>}
                        </div>
                    </>
                    }
                    <Drawer placement="right" closable={false} onClose={onClose} visible={drawerVisible} width='35%'>
                        <FlagDetails flagId={flagId ? flagId : flagIdDraft} setVisible={setDrawerVisible}
                                     fromReview={true}/>
                    </Drawer>

                    <div className="delete-modal-wrapper">
                        <Modal open={showDeleteModal} center classNames={{modal: 'deleteModal',}} showCloseIcon={false}
                               onClose={() => setshowDeleteModal(false)}>
                            <div>
                                <div className="review-txt">
                                    <span>
                                        <strong> Do you really want to delete this Fact Brief? </strong>
                                    </span>
                                    {/*<div className="add-new" onClick={()=>{history.push('/reviews')}}>Done</div>*/}
                                </div>
                                <div className="modal-footer mt-3">
                                    <button className="delete-modal-btn" onClick={() => {
                                        setshowDeleteModal(false)
                                    }}>Cancel
                                    </button>
                                    <button className="delete-modal-btn blue-bg-btn" onClick={() => {
                                        DeleteReview()
                                    }}>Confirm
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <Prompt when={changesMade} message="Your changes are not saved, do you want to leave the page?"/>

                </div>
            </div>
        </div>

    )
}
